import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton.js';
import ContentSections from '../../../components/base/ContentSections.js';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb.js';
import CustomListComponent from '../../../components/base/CustomListComponent.js';
import { PermissionCodes } from '../../../config/PermissionCodes.js';
import {
  createAction,
  getHashKeyString,
  checkHasPermission,
} from '../../../utils/index.js';
import Loading from '../../../components/base/Loading.js';
import { LanguageConfig } from '../../../config/CustomEnums.js';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt.js';
import {
  StepFiveContentSection,
  StepFivePropertiesSection,
} from '../../../components/gamificationCampaign/CreateGamificationCampaignStepFive.js';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent.js';
import './GamificationCampaignDetail.scss';
import {} from '../../../models/GamificationCampaignModel.js';
import { ImportResource } from '../../../models/DownloadImportModel';
import PreviewButton from '../../../components/gamificationCampaign/PreviewButton';
import { GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE } from '../../../models/GamificationCampaignModel';
import { OptionButton } from '../goodieBags/GoodieBagEntitlement/GoodieBagEntitlement';
import { downloadZipFiles } from '../../merchants/stores/QRCodeDownloadUtil';
import { FORTUNEBAG_OPTIONS_BUTTON_TYPE } from '../../../models/FortuneBagEntitlementModel';

const queryString = require('query-string');

const tabConfig = {
  overview: 'Detail',
};

function EntitlementSection({ data, user }) {
  const dispatch = useDispatch();

  const entitlements = data?.entitlements?.sort(
    (a, b) => a.batchIndex - b.batchIndex,
  );
  const hasDownloadPermission =
    checkHasPermission(
      user,
      PermissionCodes.changeGamificationCampaignEntitlement,
    ) && checkHasPermission(user, PermissionCodes.addExportjob);
  return (
    <div>
      <label className="create-section-title">entitlement - Unique LINK</label>
      <table
        border="1"
        className="content-section-table-container goodie-bag-detail-table"
      >
        <thead>
          <tr>
            <td>Batch no.</td>
            <td style={{ width: '20%' }}>No. of links</td>
            <td>Create time</td>
            <td>Unique link list</td>
          </tr>
        </thead>
        <tbody>
          {entitlements?.map((entitlement, index) => {
            const batch = entitlement?.batch || {};
            return (
              <tr>
                <td>
                  <label className="batch-no">{batch.index}</label>
                </td>
                <td>
                  <label className="">{batch.fortuneBagQuantity || 0}</label>
                </td>
                <td>
                  <label className="">{entitlement.displayCreationDate}</label>
                </td>
                <td>
                  {/* <label className="">{entitlement.fortuneBagUrls}</label> */}
                  {hasDownloadPermission ? (
                    <OptionButton
                      type={FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD}
                      content={entitlement.fortuneBagUrls}
                      toastMessage={null}
                      customAction={() => {
                        // downloadZipFiles(entitlement.fortuneBagUrls);
                        dispatch({
                          type: 'downloadAndImport/createDownloadTask',
                          payload: {
                            from: ImportResource.gamificationCampaignUniqueLink,
                            related: {
                              entitlement: entitlement.pk,
                            },
                          },
                        });
                      }}
                    />
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const GamificationCampaignDetail = ({
  detail = {},
  dispatch,
  languages,
  selectedAllListLoading,
  user,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(
      createAction('gamificationCampaign/getOneGamificationCampaign')({
        id,
        fromDetail: true,
      }),
    );
    return () => {
      dispatch(createAction('gamificationCampaign/clearData')());
    };
  }, []);

  const tabs = [
    {
      name: tabConfig.overview,
      content:
        detail.pk && !selectedAllListLoading ? (
          <div className="scroll-container-common">
            <StepFiveContentSection data={detail} />
            <ContentSections
              sections={[<StepFivePropertiesSection data={detail} />].concat(
                !detail?.isPublic &&
                  detail?.entitlementType ===
                    GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.UNIQUE.value
                  ? [<EntitlementSection data={detail} user={user} />]
                  : [],
              )}
              hidePreview={true}
            />
            <SaveAndBackButtons
              saveTempText={''}
              saveText={''}
              backAction={() => history.push('/gamification_campaign')}
              saveAction={() => {}}
              saveTempAction={() => {}}
              extraButtons={[
                detail?.isPublished ? (
                  <PreviewButton
                    gamificationCampaign={detail}
                    fromDetail={true}
                  />
                ) : null,
              ]}
            />
          </div>
        ) : (
          <Loading />
        ),
    },
  ];

  const getButtons = () => {
    const buttons = [];
    buttons.push(
      <AuthButton
        title="Edit"
        action={() => {
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeGamificationCampaign}
      />,
    );
    if (id && detail?.isPublished && !detail?.isPublic) {
      buttons.unshift(
        <AuthButton
          title="Entitlement"
          customClass="btn-further goodie-bag-entitlement-button"
          action={() => {
            history.push({
              pathname: `/gamification_campaign/${id}/entitlement/`,
              state: {
                gamificationCampaignName: detail?.name,
                gamificationCampaignEntitlementType: detail?.entitlementType,
              },
            });
            // dispatch({
            //   type: 'adminActivityLog/createAdministratorActivityLog',
            //   payload: {
            //     activityType: AdminActivityType.VIEW_ENTITLEMENT.pk,
            //     objectId: id,
            //   },
            // });
          }}
          requires={[
            PermissionCodes.changeGamificationCampaignEntitlement,
            PermissionCodes.viewGamificationCampaignEntitlement,
            // PermissionCodes.changeGamificationCampaign,
            // PermissionCodes.viewGamificationCampaign,
          ]}
        />,
      );
    }

    return buttons;
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        defaultActiveKey={tabs[0].name}
        buttons={getButtons()}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.gamificationCampaign.detail,
  languages: state.language.allList,
  selectedAllListLoading: state.gamificationCampaign.selectedAllListLoading,
  user: state.users,
});
export default connect(mapPropsToState)(GamificationCampaignDetail);
