import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ImageUploader from '../base/ImageUploader';
import { insertKeyToAllLanguages } from '../../utils';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import { LanguageConfig } from '../../config/CustomEnums';

export default function CoverPhotoSection({
  languages,
  language,
  title,
  suggest,
  minWidth,
  minHeight,
  skipSize,
  skipCheckImageSize,
  initialValue,
  photoKey,
  value,
  aspect,
  sideDefaultImage,
  titleSideDefaultImage,
  titleSideTextElement,
  titleSideClick = () => {},
  setCustomValue,
  allowJson,
  maxImageNum = 1,
  applyToAllLanguage,
}) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const valueKey = language ? `translations.${language}.${photoKey}` : photoKey;

  return (
    <div
      className={`image-container ${
        sideDefaultImage || titleSideDefaultImage || titleSideTextElement
          ? 'with-side'
          : ''
      }`}
    >
      <div className="image-content">
        <div className="head-container">
          <div
            className={`title-container ${
              titleSideDefaultImage || titleSideTextElement ? 'with-side' : ''
            }`}
          >
            <div
              className={
                'create-section-label create-section-label-bottom-space'
              }
            >
              {title}
            </div>
            {titleSideDefaultImage ? (
              <div className="title-side-default-image-container">
                <div className="text">Default</div>
                <img
                  className="title-side-default-image"
                  src={titleSideDefaultImage}
                />
              </div>
            ) : null}
            {titleSideTextElement ? (
              <label
                className="title-side-text"
                onClick={() => {
                  titleSideClick();
                }}
              >
                {titleSideTextElement}
              </label>
            ) : null}
          </div>
          <div className="second-section_description">{suggest}</div>
        </div>
        {setCustomValue ? (
          <ImageUploader
            minWidth={minWidth}
            minHeight={minHeight}
            skipSize={skipSize}
            skipCheckImageSize={skipCheckImageSize}
            disabled={
              applyToAllLanguage &&
              language &&
              language !== LanguageConfig.english
            }
            images={value ? [value] : []}
            onImageStateChange={(newState) => {
              setCustomValue(maxImageNum === 1 ? newState[0] : newState);
            }}
            aspect={aspect}
            maxImageNum={maxImageNum}
            language={language || LanguageConfig.english}
            uploadImageClicked={() => {}}
            allowJson={allowJson}
          />
        ) : (
          <Controller
            control={control}
            name={valueKey}
            render={({ field: { value } }) => (
              <ImageUploader
                minWidth={minWidth}
                minHeight={minHeight}
                skipSize={skipSize}
                skipCheckImageSize={skipCheckImageSize}
                disabled={
                  applyToAllLanguage &&
                  language &&
                  language !== LanguageConfig.english
                }
                images={value ? (maxImageNum === 1 ? [value] : value) : []}
                onImageStateChange={(newState) => {
                  if (language) {
                    const applyLanguages = applyToAllLanguage
                      ? languages
                      : [language];
                    applyLanguages.forEach((item) => {
                      setValue(
                        `translations.${item?.code || item}.${photoKey}`,
                        maxImageNum === 1
                          ? newState
                            ? newState?.[0]
                              ? newState?.[0]
                              : null
                            : null
                          : newState || [],
                        {
                          shouldDirty: true,
                        },
                      );
                    });
                  } else {
                    setValue(
                      photoKey,
                      maxImageNum === 1 ? newState[0] : newState,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
                aspect={aspect}
                maxImageNum={maxImageNum}
                language={language || LanguageConfig.english}
                uploadImageClicked={() => {}}
                errorMessage={
                  hasError(errors, valueKey)
                    ? errorMessage(errors, valueKey)
                    : ''
                }
                allowJson={allowJson}
              />
            )}
          />
        )}
      </div>
      {sideDefaultImage ? (
        <>
          <img className="side-default-image" src={sideDefaultImage} />
        </>
      ) : null}
    </div>
  );
}
