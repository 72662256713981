import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext, Controller } from 'react-hook-form';
import { validate } from './CreateGamificationCampaignValidate';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import { LanguageConfig } from '../../config/CustomEnums';
import {
  CustomTitleLabel,
  CustomNumberInput,
} from '../earning/CustomBaseComponments';
import defaultStampIcon from '../../assets/images/gamificationCampaign/stamp-icon.png';
import defaultStampIconCollected from '../../assets/images/gamificationCampaign/stamp-icon-collected.png';
import defaultRewardIcon from '../../assets/images/gamificationCampaign/reward-icon.png';
import defaultRewardIconCollected from '../../assets/images/gamificationCampaign/reward-icon-collected.png';
import addIcon from '../../assets/images/gamificationCampaign/add-icon.png';
import editIcon from '../../assets/images/gamificationCampaign/edit-icon.png';
import deleteIcon from '../../assets/images/gamificationCampaign/delete-icon.png';
import CoverPhotoSection from './CoverPhotoSection';
import { ImageButton } from '../base/CustomImageButton';
import EditStampRewardPrompt from './EditStampRewardPrompt';
import SortableComponent from '../base/SortableComponent';
import { GAMIFICATION_CAMPAIGN_REWARD_TYPE } from '../../models/GamificationCampaignModel';

function StampIconSection() {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const stampIcon = watch('stampIcon');
  const stampIconCollected = watch('stampIconCollected');
  const rewardIcon = watch('rewardIcon');
  const rewardIconCollected = watch('rewardIconCollected');

  return (
    <div className="create-gamification-campaign-stamp-icon-container">
      <div className="first-section-title">Stamp Icon</div>
      <div className="cover-images-for-stamp-icons">
        <CoverPhotoSection
          title={'Stamp icon - before collected'}
          suggest={
            'Suggested Image Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'stampIcon'}
          value={stampIcon}
          setCustomValue={(value) =>
            setValue('stampIcon', value, {
              shouldDirty: true,
            })
          }
          aspect={1}
          titleSideDefaultImage={defaultStampIcon}
        />
        <CoverPhotoSection
          title={'Stamp icon - collected'}
          suggest={
            'If uploading an image, suggested Image Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, support JPG, PNG and GIF only.) If uploading JSON, suggested aspect ratio of the animation is align with the image asset: 300 px * 300 px'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'stampIconCollected'}
          value={stampIconCollected}
          setCustomValue={(value) =>
            setValue('stampIconCollected', value, {
              shouldDirty: true,
            })
          }
          aspect={1}
          titleSideDefaultImage={defaultStampIconCollected}
          allowJson={true}
        />
      </div>
      <div className="cover-images-for-reward-icons">
        <CoverPhotoSection
          title={'Reward icon - before collected'}
          suggest={
            'Suggested Image Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'rewardIcon'}
          value={rewardIcon}
          setCustomValue={(value) =>
            setValue('rewardIcon', value, {
              shouldDirty: true,
            })
          }
          aspect={1}
          titleSideDefaultImage={defaultRewardIcon}
          allowJson={true}
        />
        <CoverPhotoSection
          title={'Reward icon - collected'}
          suggest={
            'If uploading an image, suggested Image Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, support JPG, PNG and GIF only.) If uploading JSON, suggested aspect ratio of the animation is align with the image asset: 300 px * 300 px'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'rewardIconCollected'}
          value={rewardIconCollected}
          setCustomValue={(value) =>
            setValue('rewardIconCollected', value, {
              shouldDirty: true,
            })
          }
          aspect={1}
          titleSideDefaultImage={defaultRewardIconCollected}
          allowJson={true}
        />
      </div>
    </div>
  );
}

function StampItem({
  index,
  stampReward,
  setSelectedStampReward,
  isPublished,
}) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const stampIcon = watch('stampIcon');
  const rewardIcon = watch('rewardIcon');
  const stampRewards = watch('stampRewards') || [];

  return (
    <div className="stamp-item-container">
      <div className="index-content">{index + 1}</div>
      {isPublished ? null : (
        <ImageButton
          action={() => {
            setSelectedStampReward({
              index,
              stampReward,
            });
          }}
          image={stampReward.quantity ? editIcon : addIcon}
          customClass="edit-icon"
          disabled={isPublished}
        />
      )}
      {stampReward.quantity && !isPublished ? (
        <ImageButton
          action={() => {
            const value = stampRewards.map((item, subindex) => {
              if (subindex !== index) return item;
              return { index: subindex + 1 };
            });
            setValue('stampRewards', value, {
              shouldDirty: true,
            });
          }}
          image={deleteIcon}
          customClass="delete-icon"
          disabled={isPublished}
        />
      ) : null}
      <img
        className="stamp-icon"
        src={
          stampReward.rewardIcon?.value ||
          stampReward.rewardIcon ||
          (stampReward.quantity
            ? rewardIcon?.value || rewardIcon || defaultRewardIcon
            : stampIcon?.value || stampIcon || defaultStampIcon)
        }
      />
    </div>
  );
}

const formatStampRewardPrizeCategories = (prizeCategories) => {
  return Object.values(prizeCategories || {})
    .map((item) => item.title || '')
    .filter((item) => item);
};

const formatStampRewards = (items) => {
  const data = items.map((item, index) => {
    if (!item.quantity) {
      return {};
    }
    let selectedObj = item.goodieBag;
    if (item.rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value) {
      selectedObj = item.couponTemplate;
    }
    const translations = item.translations || {};
    let enTranslation = translations[LanguageConfig.english] || {};
    let tcTranslation = translations[LanguageConfig.traditionalChinese] || {};
    let scTranslation = translations[LanguageConfig.simplifiedChinese] || {};

    return {
      ...item,
      rowIndex: `#${index + 1}`,
      label: selectedObj ? `[ID:${selectedObj.pk}] ${selectedObj.name}` : '-',
      rows: (item.rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value
        ? [
            `Available Coupon: ${selectedObj?.stock || 0}`,
            `Coupon set quantity: ${item.quantity}`,
          ]
        : [
            `Valid goodie bag: ${selectedObj?.validGoodiebagNumber || '-'}`,
            `Goodie bag quantity: ${item.quantity}`,
          ]
      ).concat([
        `Upload special stamp icon: ${
          item.rewardIcon || item.rewardIconCollected ? 'Yes' : 'No'
        }`,
      ]),
      sections: [
        {
          title: 'Prize name (EN)',
          rows: [
            `Title: ${enTranslation.prizeTitle || '-'}`,
            `Description: ${enTranslation.prizeContent || '-'}`,
            `tags: ${formatStampRewardPrizeCategories(
              enTranslation.prizeCategories,
            )}`,
            `<div>Prize image for desktop</div>${
              enTranslation.coverPhotoForPrize?.value ||
              enTranslation.coverPhotoForPrize
                ? `<img src="${
                    enTranslation.coverPhotoForPrize?.value ||
                    enTranslation.coverPhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
            `<div>Prize image for mobile</div>${
              enTranslation.mobilePhotoForPrize?.value ||
              enTranslation.mobilePhotoForPrize
                ? `<img src="${
                    enTranslation.mobilePhotoForPrize?.value ||
                    enTranslation.mobilePhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
          ],
        },
        {
          title: 'Prize name (TC)',
          rows: [
            `Title: ${tcTranslation.prizeTitle || '-'}`,
            `Description: ${tcTranslation.prizeContent || '-'}`,
            `tags: ${formatStampRewardPrizeCategories(
              tcTranslation.prizeCategories,
            )}`,
            `<div>Prize image for desktop</div>${
              tcTranslation.coverPhotoForPrize?.value ||
              tcTranslation.coverPhotoForPrize
                ? `<img src="${
                    tcTranslation.coverPhotoForPrize?.value ||
                    tcTranslation.coverPhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
            `<div>Prize image for mobile</div>${
              tcTranslation.mobilePhotoForPrize?.value ||
              tcTranslation.mobilePhotoForPrize
                ? `<img src="${
                    tcTranslation.mobilePhotoForPrize?.value ||
                    tcTranslation.mobilePhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
          ],
        },
        {
          title: 'Prize name (SC)',
          rows: [
            `Title: ${scTranslation.prizeTitle || '-'}`,
            `Description: ${scTranslation.prizeContent || '-'}`,
            `tags: ${formatStampRewardPrizeCategories(
              scTranslation.prizeCategories,
            )}`,
            `<div>Prize image for desktop</div>${
              scTranslation.coverPhotoForPrize?.value ||
              scTranslation.coverPhotoForPrize
                ? `<img src="${
                    scTranslation.coverPhotoForPrize?.value ||
                    scTranslation.coverPhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
            `<div>Prize image for mobile</div>${
              scTranslation.mobilePhotoForPrize?.value ||
              scTranslation.mobilePhotoForPrize
                ? `<img src="${
                    scTranslation.mobilePhotoForPrize?.value ||
                    scTranslation.mobilePhotoForPrize ||
                    ''
                  }" />`
                : ''
            }`,
          ],
        },
      ],
    };
  });
  return data.filter((item) => item.rewardType);
};

function StampCardSection() {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const isPublished = watch('isPublished');
  const stampCount = watch('stampCount');
  const stampRewards = watch('stampRewards') || [];

  const [numberOfStamp, setStampCount] = useState(stampCount);
  const [selectedStampReward, setSelectedStampReward] = useState();

  return (
    <div className="create-gamification-campaign-stamp-card-container">
      <div className="first-section-title">Stamp Card</div>
      <Controller
        control={control}
        name="stampCardResetLimit"
        render={({ field: { value } }) => (
          <>
            <CustomTitleLabel
              title={'Max. no. of times for full collection of stamps'}
            />
            <CustomNumberInput
              isNumberOnly={true}
              disabled={isPublished}
              setValue={(value) => {
                setValue('stampCardResetLimit', value, {
                  shouldDirty: true,
                });
              }}
              defaultValue={value}
              errorId="stampCardResetLimit"
              error={hasError(errors, 'stampCardResetLimit')}
              errorMessage={errorMessage(errors, 'stampCardResetLimit')}
            />
          </>
        )}
      />
      <div className="d-flex flex-column">
        <CustomTitleLabel title={'Stamp card capacity'} />
        <div className="stamp-input-with-button">
          <input
            type="number"
            min="0"
            onKeyDown={(event) => {
              if (
                event.keyCode === 189 || //-
                event.keyCode === 69 || //e
                event.keyCode === 187 //+
              ) {
                event.preventDefault();
                return false;
              }
            }}
            onChange={({ target }) => {
              setStampCount(target.value);
            }}
            className="custom-number-input-long"
            value={numberOfStamp}
            onFocus={() => {}}
            disabled={isPublished}
          />
          <button
            type="button"
            className="btn-link custom-upload-btn confirm-button"
            disabled={isPublished}
            onClick={() => {
              setValue('stampCount', numberOfStamp, {
                shouldDirty: true,
              });
              if (numberOfStamp <= stampRewards.length) {
                setValue('stampRewards', stampRewards.slice(0, numberOfStamp), {
                  shouldDirty: true,
                });
              } else {
                setValue(
                  'stampRewards',
                  stampRewards.concat(
                    Array(numberOfStamp - stampRewards.length).fill({}),
                  ),
                  {
                    shouldDirty: true,
                  },
                );
              }
              // if (numberOfStamp <= stampRewards.length) {
              //   setValue(
              //     'stampRewards',
              //     stampRewards.slice(0, numberOfStamp).map((item, subindex) => {
              //       return { index: subindex };
              //     }),
              //     {
              //       shouldDirty: true,
              //     },
              //   );
              // } else {
              //   setValue(
              //     'stampRewards',
              //     stampRewards
              //       .concat(Array(numberOfStamp - stampRewards.length).fill({}))
              //       .map((item, subindex) => {
              //         return { index: subindex };
              //       }),
              //     {
              //       shouldDirty: true,
              //     },
              //   );
              // }
            }}
          >
            Generate
          </button>
        </div>
        <ErrorFieldMessage
          id={'stampCount'}
          error={hasError(errors, 'stampCount')}
          message={errorMessage(errors, 'stampCount')}
        />
      </div>

      <div className="d-flex flex-column">
        <CustomTitleLabel title={'Stamp list'} />
        <label className="tips-message">
          Click the stamp icon to assign a reward to the stamp.
        </label>
        {stampRewards.length ? (
          <div className="stamp-list-container">
            {stampRewards.map((stampReward, index) => {
              return (
                <StampItem
                  index={index}
                  stampReward={stampReward}
                  setSelectedStampReward={setSelectedStampReward}
                  isPublished={isPublished}
                />
              );
            })}
          </div>
        ) : null}
        <SortableComponent
          title="Prize list"
          customRowClass="stamp-reward-item-container"
          data={formatStampRewards(stampRewards)}
          disabledSort={true}
          displayDropDownArrow={false}
          displayDropDown={true}
          hideClearAll={true}
          hideDelete={true}
          showLiBeforIndex={true}
        />
      </div>

      <EditStampRewardPrompt
        show={!!selectedStampReward}
        stampReward={selectedStampReward?.stampReward}
        onClose={() => {
          setSelectedStampReward();
        }}
        onConfirm={(stampRewardData) => {
          const value = stampRewards.map((item, subindex) => {
            if (subindex !== selectedStampReward.index) return item;
            return {
              ...item,
              ...stampRewardData,
            };
          });
          setValue('stampRewards', value, {
            shouldDirty: true,
          });
        }}
      />
    </div>
  );
}

function CreateGamificationCampaignStepThree({ languageList }) {
  const { getValues, clearErrors, setError, watch } = useFormContext();
  console.log('@@284: ', getValues());
  const params = useParams();
  const dispatch = useDispatch();

  const getTabs = (language) => {
    let tabs = [];
    if (language === LanguageConfig.english) {
      tabs = tabs.concat([<StampIconSection />, <StampCardSection />]);
    }
    return tabs;
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'gamificationCampaign/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });
  };

  return (
    <>
      <ContentSections
        // languageTabContent={{
        //   containers: languageList.map((language) => {
        //     return {
        //       container: getTabs(language.code),
        //       key: language.code,
        //       title: language.sourceName,
        //     };
        //   }),
        // }}
        sections={getTabs(LanguageConfig.english)}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(CreateGamificationCampaignStepThree);
