import moment from 'moment';
import { createModel } from './BaseModel';
import {
  getMissionLocations,
  updateMissionLocation,
  deleteMissionLocations,
  getMissionLocationQRCode,
} from '../services/MissionApiHelper';

import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  createAction,
} from '../utils';
import { APIStatus, StatusTag } from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';

export const parseMissionLocationDetail = (node) => {
  return {
    ...node,
    name: node.location?.name,
    label: `[ID:${node.location?.pk}] ${node.location?.name}`,
    qrCodeString: node.code,
    order: node.displayPriority,
    isFeatured: node.isFeatured,
    displayIsFeatured: node.isFeatured ? 'Yes' : 'No',
    status: node.isForcedInactive ? StatusTag.inactive : StatusTag.active,
    missions: node.location?.missionLocations?.edges?.map((item) => {
      return {
        pk: item.node?.mission?.pk,
        name: item.node?.mission?.name,
        label: `[ID:${item.node?.mission?.pk}] ${item.node?.mission?.name}`,
      };
    }),
    locationForMissoin: node.location,
    locationPK: node.location?.pk,
    locationName: node.location?.name,
    locationLink: `/locations/${node.location?.pk}`,
  };
};

const parseMissionLocationList = (data) =>
  data?.missionLocations?.edges?.map((item) =>
    parseMissionLocationDetail(item?.node),
  );

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  detail: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  apiStatus: APIStatus.none,
  createStatus: APIStatus.none,
});

export default createModel({
  namespace: 'missionLocation',
  params: {
    dataKey: 'missionLocations',
    listAPI: getMissionLocations,
    pkNode: 'MissionLocationNode',
    // detailAPI: getMissionLocationDetail,
    parse: parseMissionLocationList,
    parseDetail: (data) => parseMissionLocationDetail(data.mission),
    deleteAPI: deleteMissionLocations,
    objectKey: 'missionLocation',
  },
  states: getInitialState(),
  reducers: {
    updateMission(state, { payload }) {
      console.log('updateMissionLocation: ', payload);
      const detail = {
        ...state.detail,
        ...payload,
      };
      return { ...state, detail: detail };
    },

    clearData(state, { payload }) {
      const newState = {
        ...state,
        ...getInitialState(),
      };
      return newState;
    },
  },
  effects: {
    updateMissionLocation: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        let serviceArgs = [
          updateMissionLocation,
          payload.data || {
            id: payload.pk,
            code: payload.code,
            displayPriority: payload.displayPriority,
            isFeatured: payload.isFeatured || false,
            isForcedInactive: payload.isForcedInactive,
          },
        ];
        console.log('@@309: ', payload, serviceArgs);

        function* onSuccess(data) {
          afterAction();
        }
        function* onError(response) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    deactiveMissionLocation: [
      function* ({ payload }, { call, select, put }) {
        const { pk } = payload;
        const serviceArgs = [
          updateMissionLocation,
          { id: pk, isForcedInactive: true },
        ];
        function* onSuccess() {
          const deactiveAction = payload.afterAction || (() => {});
          console.log('@@237: ', deactiveAction);
          yield deactiveAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    activeMissionLocation: [
      function* ({ payload }, { call }) {
        const { pk } = payload;
        const activeAction = payload.afterAction || (() => {});
        const serviceArgs = [
          updateMissionLocation,
          { id: pk, isForcedInactive: false },
        ];
        function* onSuccess() {
          yield activeAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    downloadQRCode: [
      function* ({ payload }, { call }) {
        const { code } = payload;
        const activeAction = payload.activeAction || (() => {});
        const serviceArgs = [getMissionLocationQRCode, code];
        function* onSuccess(data) {
          if (data?.missionLocationCode) {
            const linkSource = `data:image/png;base64,${data?.missionLocationCode}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = 'qrcode.png';
            downloadLink.click();
          }
          yield activeAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
