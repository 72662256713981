import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import deleteImage from '../../../assets/images/earning/deletex1.png';
import { removeElementFromArray } from '../../../utils';
import { Button, Image } from 'react-bootstrap';
import './SearchSemTagInput.scss';

export default function SearchSemTagInput() {
  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;

  const comma = ',';
  const searchTags = watch('searchTags') || [];
  const semTags = watch('semTags') || [];

  return (
    <>
      <CustomTitleLabel title="Search tags (Optional)" />
      <label className="tips-message">Separate each tag by comma.</label>
      <textarea
        onChange={({ target }) => {
          const originalValue = target.value.split(comma);
          const tags = originalValue
            .map((item, index) =>
              index == originalValue.length - 2 ? item.trim() : item,
            )
            .filter(
              (item, index) =>
                index == originalValue.length - 1 || item.trim().length,
            );
          setValue('searchTags', tags, { shouldDirty: true });
        }}
        onBlur={({ target }) => {
          const originalValue = target.value.split(comma);
          const tags = originalValue.filter(
            (item, index) => item.trim().length,
          );
          setValue('searchTags', tags, { shouldDirty: true });
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        value={searchTags.join(comma)}
      />

      {/* <ErrorFieldMessage
        id="searchTags"
        error={hasError(errors, `searchTags`)}
        message="This field is required"
      /> */}

      <CustomTitleLabel title="Inputted search tags" />
      <div className="inputted-tags">
        {searchTags.map((element) => {
          return (
            <div className="qr-codes-delete-button">
              <label className="custom-textarea-input-font qr-codes-name-label">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  setValue(
                    'searchTags',
                    removeElementFromArray(searchTags, element),
                    { shouldDirty: true },
                  );
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>

      <CustomTitleLabel title="SEM tags (Optional)" />
      <label className="tips-message">Separate each tag by comma.</label>
      <textarea
        onChange={({ target }) => {
          const originalValue = target.value.split(comma);
          const tags = originalValue
            .map((item, index) =>
              index == originalValue.length - 2 ? item.trim() : item,
            )
            .filter(
              (item, index) =>
                index == originalValue.length - 1 || item.trim().length,
            );
          setValue('semTags', tags, { shouldDirty: true });
        }}
        onBlur={({ target }) => {
          const originalValue = target.value.split(comma);
          const tags = originalValue.filter(
            (item, index) => item.trim().length,
          );
          setValue('semTags', tags, { shouldDirty: true });
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        value={semTags.join(comma)}
      />

      {/* <ErrorFieldMessage
        id="semTags"
        error={hasError(errors, `semTags`)}
        message="This field is required"
      /> */}

      <CustomTitleLabel title="Inputted sem tags" />
      <div className="inputted-tags">
        {semTags.map((element) => {
          return (
            <div className="qr-codes-delete-button">
              <label className="custom-textarea-input-font qr-codes-name-label">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  setValue(
                    'semTags',
                    removeElementFromArray(semTags, element),
                    { shouldDirty: true },
                  );
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
}
