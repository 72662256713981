import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import arrayMove from 'array-move';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext, Controller } from 'react-hook-form';
import { validate } from './CreateGamificationCampaignValidate';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CoverPhotoSection from './CoverPhotoSection';
import SortableComponent from '../base/SortableComponent';
import AuthButton from '../base/AuthButton';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import MissionTablePrompt from '../campaign/MissionTablePrompt';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import MissionSectionImageReference from './MissionSectionImageReference';
import { ApplySwitch } from '../base/LabelWithSwitch';
import { insertKeyToAllLanguages, filterWorkingTeams } from '../../utils';
import { LanguageConfig } from '../../config/CustomEnums';
import CustomRadios from '../base/CustomRadios';
import { CARD_ORIENTATION_TYPE } from '../../models/GamificationCampaignModel';

function MissionSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch('translations') || {};
  const applyCoverPhotoForMissionSectionToAllLanguage = watch(
    'applyCoverPhotoForMissionSectionToAllLanguage',
  );
  const coverPhotoForMissionSection =
    translations[language]?.coverPhotoForMissionSection || '';
  const mobilePhotoForMissionSection =
    translations[language]?.mobilePhotoForMissionSection || '';
  const missionCardOrientation = watch('missionCardOrientation');

  const [showImageReference, setShowImageReference] = useState(false);

  const hasMissionCardFieldsError = hasError(
    errors,
    'translations.en.missionCardTitle',
  );

  return (
    <div className="create-gamification-campaign-mission-section-container">
      <div className="first-section-title">Mission Section</div>
      <CustomTitleLabel
        title={'Mission section image'}
        className="display-as-block"
      />
      <div className="cover-images-for-mission-section">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Desktop'}
          suggest={
            "The height of image varies with no. of missions (width at least 1024px). Click “See card size reference” at top right corner for details. If aspect ratio of the uploaded image doesn't match the requirement, it will show the image in full width in frontend, then scale the height proportionally."
          }
          skipSize={true}
          skipCheckImageSize={true}
          photoKey={'coverPhotoForMissionSection'}
          applyToAllLanguage={applyCoverPhotoForMissionSectionToAllLanguage}
          titleSideTextElement={'See card size reference'}
          titleSideClick={() => {
            setShowImageReference(true);
          }}
        />
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Mobile'}
          suggest={
            'Suggested Image Size: 1920px*1440. At least : 480px*360px(Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={480}
          minHeight={360}
          photoKey={'mobilePhotoForMissionSection'}
          aspect={480 / 360}
          applyToAllLanguage={applyCoverPhotoForMissionSectionToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english &&
        //   !coverPhotoForMissionSection?.value
        // }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoForMissionSectionToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoForMissionSectionToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languages,
              key: 'coverPhotoForMissionSection',
              value: coverPhotoForMissionSection,
              setValue,
              getValues,
            });
          }
        }}
      />

      <div className="d-flex flex-column mission-section-fields-container">
        <CustomTitleLabel title={'Mission section header'} />
        <Controller
          control={control}
          name={`translations.${language}.missionSectionTitle`}
          render={({ field: { value } }) => (
            <>
              <input
                type="text"
                onChange={({ target }) => {
                  setValue(
                    `translations.${language}.missionSectionTitle`,
                    target.value,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                defaultValue={value}
                className={`custom-markdown-area-title custom-markdown-area-title-short`}
                placeholder="Please input title"
              />
              <ErrorFieldMessage
                id={'translations.en.missionSectionTitle'}
                error={hasError(errors, 'translations.en.missionSectionTitle')}
                message={errorMessage(
                  errors,
                  'translations.en.missionSectionTitle',
                )}
              />
            </>
          )}
        />
        <Controller
          control={control}
          name={`translations.${language}.missionSectionDescription`}
          render={({ field: { value } }) => (
            <>
              <input
                type="text"
                onChange={({ target }) => {
                  setValue(
                    `translations.${language}.missionSectionDescription`,
                    target.value,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                defaultValue={value}
                className={`custom-markdown-area-title custom-markdown-area-title-short`}
                placeholder="Please input description"
              />
              <ErrorFieldMessage
                id={'translations.en.missionSectionDescription'}
                error={hasError(
                  errors,
                  'translations.en.missionSectionDescription',
                )}
                message={errorMessage(
                  errors,
                  'translations.en.missionSectionDescription',
                )}
              />
            </>
          )}
        />
      </div>
      <div className="d-flex flex-column mission-section-fields-container">
        <CustomTitleLabel title={'Mission card introduction wording'} />
        <label className="tips-message">Input at least one field.</label>
        <Controller
          control={control}
          name={`translations.${language}.missionCardTitle`}
          render={({ field: { value } }) => (
            <input
              type="text"
              onChange={({ target }) => {
                setValue(
                  `translations.${language}.missionCardTitle`,
                  target.value,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              defaultValue={value}
              className={`custom-markdown-area-title custom-markdown-area-title-short`}
              placeholder="Please input title"
            />
          )}
        />
        <Controller
          control={control}
          name={`translations.${language}.missionCardSubtitle`}
          render={({ field: { value } }) => (
            <input
              type="text"
              onChange={({ target }) => {
                setValue(
                  `translations.${language}.missionCardSubtitle`,
                  target.value,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              defaultValue={value}
              className={`custom-markdown-area-title custom-markdown-area-title-short`}
              placeholder="Please input subtitle"
            />
          )}
        />
        {hasMissionCardFieldsError ? (
          <ErrorFieldMessage
            id={'mission-section-fields'}
            error={hasMissionCardFieldsError}
            message={'Input at least one field.'}
          />
        ) : null}
      </div>

      <CustomTitleLabel title="Mission card orientation" />
      <div style={{ display: 'flex', marginTop: 12 }}>
        <CustomRadios
          options={Object.values(CARD_ORIENTATION_TYPE)}
          default={missionCardOrientation}
          onChange={(value) => {
            setValue('missionCardOrientation', value, {
              shouldDirty: true,
            });
          }}
        />
      </div>
      <MissionSectionImageReference
        show={showImageReference}
        onClose={() => setShowImageReference(false)}
      />
    </div>
  );
}

const formatMissions = (items) => {
  return items.map((item) => {
    return {
      ...item,
      rows: [
        `Type: ${item.displayMissionType}`,
        `Max. stamp earning limit: ${item.maxNumberOfStampEarningLimit || '-'}`,
        `Active Period: ${item.activePeriodList}`,
        `Visible Period: ${item.visiblePeriodList}`,
      ],
    };
  });
};

function LinkMissionSection() {
  const history = useHistory();

  const { missionList, userWorkingTeamIds, userIsSuperuser } = useSelector(
    (state) => ({
      missionList: state.mission.pagedList,
      userWorkingTeamIds: state.users.workingTeams,
      userIsSuperuser: state.users.isSuperuser,
    }),
  );

  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const watchWorkingTeams = watch('workingTeams');
  const missions = watch('missions') || [];
  const stampCount = watch('stampCount') || 0;

  const [showSelectMissionTable, setShowSelectMissionTable] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState();

  const totalStampsMissionsCanBeEarned = missions
    .filter((item) => item.maxNumberOfStampEarningLimit)
    .reduce(function (total, item) {
      return total + item.maxNumberOfStampEarningLimit;
    }, 0);

  return (
    <div className="create-gamification-campaign-stamp-card-container">
      <div className="first-section-title">Link Mission (Optional)</div>

      <AuthButton
        title={'Add Mission'}
        requires={PermissionCodes.viewMission}
        customClass={'general-section-add-new btn-add-button-common'}
        action={() => {
          setShowSelectMissionTable(true);
        }}
      />
      <SortableComponent
        title="Selected Mission"
        tips="Drag cards to set order"
        customRowClass="mission-item-container"
        data={formatMissions(missions)}
        onSortEnd={(oldIndex, newIndex) => {
          setValue('missions', arrayMove(missions, oldIndex, newIndex), {
            shouldDirty: true,
          });
        }}
        setData={(value) => {
          setValue('missions', value, {
            shouldDirty: true,
          });
        }}
        disabledSort={false}
        displayDropDownArrow={false}
        displayDropDown={true}
        hideClearAll={false}
        hideDelete={false}
      />
      <AuthButton
        title={'Create Mission'}
        requires={PermissionCodes.changeMission}
        customClass={'general-section-add-new btn-add-button-common'}
        action={() => {
          setAddPromptConfig({
            button: {
              text: 'create mission',
              actionPath: '/mission/create',
            },
            title: 'Go to create Misson?',
            description: `Go to create mission.`,
          });
        }}
      />

      <CustomTitleLabel
        title={'Total stamps that can be earned'}
        className={'display-as-block'}
      />
      <div>{totalStampsMissionsCanBeEarned}</div>
      {totalStampsMissionsCanBeEarned < stampCount ? (
        <>
          <label className={`error-field-message-style`}>
            It should be greater than or equal to stamp card capacity set in
            previous page.
          </label>
        </>
      ) : null}

      <MissionTablePrompt
        show={showSelectMissionTable}
        selectedData={missions}
        baseSearch={{
          working_team: filterWorkingTeams(
            userIsSuperuser,
            userWorkingTeamIds,
            watchWorkingTeams,
          ),
        }}
        onClose={() => setShowSelectMissionTable(false)}
        onConfirm={(selectedData, selectedDataRemoved) => {
          const removedPKs = selectedDataRemoved?.map((item) => item.pk) || [];
          const values = [
            ...missions.filter((item) => !removedPKs.includes(item.pk)),
            ...selectedData,
          ].sort((a, b) => a.pk - b.pk);
          setValue(
            'missions',
            formatMissions(
              values.filter((item, pos) => {
                return values.map((item) => item.pk).indexOf(item.pk) == pos;
              }),
            ),
            {
              shouldDirty: true,
            },
          );
          setShowSelectMissionTable(false);
        }}
      />

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig.button.text,
                action: () => {
                  setAddPromptConfig(null);
                  history.push({
                    pathname: addPromptConfig.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'Continue to Create Gamification campaign',
                      content:
                        'You can continue to create the gamification campaign.',
                    },
                  });
                },
              }
            : {}
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </div>
  );
}

function ActivePeriodSection() {
  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;

  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <>
      <label className="create-section-title">Active Period</label>

      <label className="create-section-label create-section-label-bottom-space">
        It is an all-time active campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isAlwaysActivePeriod}
        onChange={(value) => {
          setValue('isAlwaysActivePeriod', value, { shouldDirty: true });
        }}
      />
      {!isAlwaysActivePeriod ? (
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Start date
          </label>
          <CustomDateTimeSelect
            disabled={isAlwaysActivePeriod}
            defaultTime={watchStartDate}
            onTimeChange={(date) => {
              setValue('startDate', date, { shouldDirty: true });
            }}
          />
          <label className="create-section-label create-section-label-bottom-space">
            End date
          </label>
          <CustomDateTimeSelect
            error={hasError(errors, 'endDate')}
            defaultTime={watchEndDate}
            disabled={isAlwaysActivePeriod}
            onTimeChange={(date) => {
              setValue('endDate', date, { shouldDirty: true });
            }}
            seconds={59}
          />
          <ErrorFieldMessage
            id={`endDate`}
            error={hasError(errors, 'endDate')}
            message={errorMessage(errors, 'endDate')}
          />
        </div>
      ) : null}
    </>
  );
}

function VisiblePeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isAlwaysVisiblePeriod = watch('isAlwaysVisiblePeriod');
  const watchDisplayStartDate = watch('displayStartDate');
  const watchDisplayEndDate = watch('displayEndDate');

  return (
    <>
      <label className="create-section-title">Visible Period</label>

      <label className="create-section-label create-section-label-bottom-space">
        It is an all-time visible campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isAlwaysVisiblePeriod}
        onChange={(value) => {
          setValue('isAlwaysVisiblePeriod', value, { shouldDirty: true });
        }}
      />
      {!isAlwaysVisiblePeriod ? (
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Start date
          </label>
          <CustomDateTimeSelect
            disabled={isAlwaysVisiblePeriod}
            defaultTime={watchDisplayStartDate}
            onTimeChange={(date) => {
              setValue('displayStartDate', date, { shouldDirty: true });
            }}
          />
          <label className="create-section-label create-section-label-bottom-space">
            End date
          </label>
          <CustomDateTimeSelect
            error={hasError(errors, 'displayEndDate')}
            defaultTime={watchDisplayEndDate}
            disabled={isAlwaysVisiblePeriod}
            onTimeChange={(date) => {
              setValue('displayEndDate', date, { shouldDirty: true });
            }}
            seconds={59}
          />
          <ErrorFieldMessage
            id={`displayEndDate`}
            error={hasError(errors, 'displayEndDate')}
            message={errorMessage(errors, 'displayEndDate')}
          />
        </div>
      ) : null}
    </>
  );
}

function CreateGamificationCampaignStepFour({ languageList }) {
  const { getValues, clearErrors, setError, watch } = useFormContext();
  console.log('@@284: ', getValues());
  const dispatch = useDispatch();

  const getTabs = (language) => {
    return [
      <MissionSection language={language} languages={languageList} />,
      <LinkMissionSection />,
      <ActivePeriodSection />,
      <VisiblePeriodSection />,
    ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'gamificationCampaign/stepChange',
      payload: { isBack: isBack, step: 3, isValid },
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(CreateGamificationCampaignStepFour);
