import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GoodieBagRewardType,
  AdminActivityType,
} from '../../../config/CustomEnums';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { checkHasPermission, createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  getGoodieBagCouponCampaignsList,
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE,
  GOODIE_BAG_LIST_FIELDS,
} from '../../../models/GoodieBagsModel';
import Filter from './Filter.js';
import GoodieBagsAllocationPrompt from '../../../components/goodieBags/GoodieBagsAllocationPrompt';
import FilterExportPrompt from '../../../components/base/prompt/FilterExportPrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
import RecordExportPrompt from '../../../components/base/prompt/RecordExportPrompt.js';

const TabsConfig = [
  { key: GoodieBagRewardType.allTypes, name: 'All Type' },
  { key: GoodieBagRewardType.cashVoucher, name: 'Cash Voucher' },
  { key: GoodieBagRewardType.gift, name: 'Goodie Bag Coupon' },
  { key: GoodieBagRewardType.both, name: 'Both Voucher & Coupon' },
  {
    key: GoodieBagRewardType.fortuneBag,
    name: 'Goodie Bag Offer (Direct Assign)',
  },
];

const GoodieBagsList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showSetAllocatePrompt, setShowSetAllocatePrompt] = useState(false);
  const [showExportRecord, setShowExportRecord] = useState(false);
  const [showExportConfig, setShowExportConfig] = useState(false);
  const [currentGoodieBag, setCurrentGoodieBag] = useState({});
  const [hasEntitlementPermission, setHasEntitlementPermission] =
    useState(false);

  const {
    pageInfo,
    totalCount,
    goodieBagsList,
    totalPage,
    checkedList,
    adminUser,
    recordCount,
    existsCouponOutOfStock,
  } = useSelector((state) => ({
    pageInfo: state.goodieBags.pageInfo,
    totalCount: state.goodieBags.totalCount,
    goodieBagsList: state.goodieBags.pagedList,
    totalPage: state.goodieBags.totalPage,
    checkedList: state.goodieBags.checkedList,
    adminUser: state.users,
    recordCount: state.goodieBags.recordCount,
    existsCouponOutOfStock: state.goodieBags.existsCouponOutOfStock,
  }));

  const getFilterConditionsFromUrl = () => {
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const searchEntitlementMethod = parsedSearch['entitlement_method'] || '';
    const searchActivePeriod = parsedSearch['active_period'] || '';
    const searchVisiblePeriod = parsedSearch['visible_period'] || '';
    const searchCouponAcquiredPeriod =
      parsedSearch['coupon_acquired_period'] || '';
    const searchCouponAcquiredDate = parsedSearch['absolutePeriod'] || '';
    const searchRelativeDay = parsedSearch['relativeDay'] || '';
    const searchCreateAt = parsedSearch['create_at'] || '';
    const searchLastModified = parsedSearch['last_modified'] || '';
    const searchStatus = parsedSearch['status'] || '';
    const searchGroupIn = parsedSearch['goodie_bag_group'] || '';

    let filterConditions = {
      name_icontains: searchKey,
      entitlement_method_in: searchEntitlementMethod,
      is_published: searchStatus,
    };
    if (searchCouponAcquiredPeriod) {
      switch (searchCouponAcquiredPeriod) {
        case 'absolutePeriod':
          if (searchCouponAcquiredDate) {
            const couponAcquiredDateRange = searchCouponAcquiredDate.split(',');
            filterConditions = {
              ...filterConditions,
              absolute_gte: couponAcquiredDateRange[0],
              absolute_lte: couponAcquiredDateRange[1],
            };
          }
          break;
        case 'relativeDay':
          if (searchRelativeDay) {
            filterConditions = {
              ...filterConditions,
              relative_day: searchRelativeDay,
            };
          }
          break;
        default:
          filterConditions = {
            ...filterConditions,
            all_time: true,
          };
          break;
      }
    }
    if (searchActivePeriod) {
      filterConditions = {
        ...filterConditions,
        active_time_range: searchActivePeriod,
      };
    }
    if (searchVisiblePeriod) {
      const visibleDateRange = searchVisiblePeriod.split(',');
      filterConditions = {
        ...filterConditions,
        display_start_date_gte: visibleDateRange[0],
        display_end_date_lte: visibleDateRange[1],
      };
    }
    if (searchCreateAt) {
      const createAtDateRange = searchCreateAt.split(',');
      filterConditions = {
        ...filterConditions,
        creation_date_gte: createAtDateRange[0],
        creation_date_lte: createAtDateRange[1],
      };
    }
    if (searchLastModified) {
      const lastModifiedDateRange = searchLastModified.split(',');
      filterConditions = {
        ...filterConditions,
        last_modified_date_gte: lastModifiedDateRange[0],
        last_modified_date_lte: lastModifiedDateRange[1],
      };
    }
    if (searchGroupIn) {
      filterConditions = {
        ...filterConditions,
        group_in: searchGroupIn,
      };
    }
    return filterConditions;
  };

  // const entitlementPermission =
  //   [
  //     PermissionCodes.viewGoodiebagEntitlement,
  //     PermissionCodes.changeGoodiebagEntitlement,
  //   ].filter((val) => checkHasPermission(adminUser, val)) > 0;
  // console.log(
  //   '@@49: ',
  //   [
  //     PermissionCodes.viewGoodiebagEntitlement,
  //     PermissionCodes.changeGoodiebagEntitlement,
  //   ].filter((val) => checkHasPermission(adminUser, val)),
  //   entitlementPermission,
  // );

  useEffect(() => {
    setHasEntitlementPermission(
      [
        PermissionCodes.viewGoodiebagEntitlement,
        PermissionCodes.changeGoodiebagEntitlement,
      ].filter((val) => checkHasPermission(adminUser, val)) > 0,
    );
  }, [adminUser]);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log('@37', searchDict);
    dispatch(
      createAction('goodieBags/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
        loading: true,
        needQuantity: true,
      }),
    );
  }, [dispatch, location]);

  const buttonsLeft = [
    <AuthButton
      customClass="btn-further btn-download"
      title="Manual check stock status"
      action={() => {
        dispatch({
          type: 'goodieBags/checkExistsCouponOutOfStockFortuneBag',
          payload: { sendEmail: false },
        });
      }}
      requires={PermissionCodes.viewGoodieBag}
    />,
    <AuthButton
      title="Filter out-of-stock items"
      action={() => {
        const queryData = {
          page: 1,
          coupon_out_of_stock: true,
        };
        history.push({
          pathname: location.pathname,
          hash: location.hash,
          search: queryString.stringify(queryData),
        });
      }}
      disabled={!existsCouponOutOfStock}
      requires={PermissionCodes.viewGoodieBag}
    />,
  ];
  const buttons = [
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export record"
      action={() => {
        setShowExportRecord(true);
      }}
    />,
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export configuration"
      action={() => {
        setShowExportConfig(true);
      }}
    />,
    <AuthButton
      title="Create Goodie Bag"
      action={() => {
        history.push('/goodie_bags/create');
      }}
      requires={PermissionCodes.addGoodieBag}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteGoodieBag,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={400}
          tabs={TabsConfig}
          groupActions={groupActions}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={GOODIE_BAG_LIST_FIELDS}
              model={'goodieBags'}
              permissionGroup={PermissionCodes.goodieBag}
              dataList={goodieBagsList}
              totalPage={totalPage}
              //   sortable
              hasOtherActions
              rankDisabled
              deleteInfo={{
                data: [],
                title: 'Goodie Bags',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
              customActions={{
                publish: {
                  condition: (item) => {
                    return item.isPublished === false;
                  },
                  action: (item) => {
                    if (!item.overallAmount) {
                      dispatch({
                        type: 'navBars/updateState',
                        payload: {
                          saveDiscardToastShowing: {
                            value: true,
                            type: 'When publish goodie bag at first time, must have overall amount value',
                          },
                        },
                      });
                      return;
                    }

                    console.log('@126, publish', item);
                    setCurrentGoodieBag(item);
                    // const campaignList = getGoodieBagCouponCampaignsList(item);
                    // if (campaignList?.length <= 0) {
                    //   dispatch({
                    //     type: 'navBars/updateState',
                    //     payload: {
                    //       saveDiscardToastShowing: {
                    //         value: true,
                    //         type: 'When publish goodie bag at first time, must have one campaign related',
                    //       },
                    //     },
                    //   });
                    //   return;
                    // }
                    const optionCOTACount = (item.ota || []).filter(
                      (item) =>
                        item.handleOption?.value ===
                        GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC,
                    )?.length;
                    console.log('@@131: ', optionCOTACount, item.overallAmount);
                    if (optionCOTACount > 1 && item.overallAmount > 0) {
                      setShowSetAllocatePrompt(true);
                    } else if (
                      optionCOTACount === 1 &&
                      item.overallAmount > 0
                    ) {
                      dispatch(
                        createAction('goodieBags/publish')({
                          goodieBagPk: item.pk,
                          otaAllocatedNum: [
                            {
                              brandId: item.ota?.[0]?.pk,
                              number: item.overallAmount,
                            },
                          ],
                          afterAction: () => {
                            history.push(location);
                          },
                        }),
                      );
                    } else {
                      dispatch(
                        createAction('goodieBags/publish')({
                          goodieBagPk: item.pk,
                          otaAllocatedNum: [],
                          afterAction: () => {
                            console.log('@136', location);
                            history.push(location);
                          },
                        }),
                      );
                    }
                  },
                  requires: PermissionCodes.changeGoodieBag,
                },
              }}
              specificActions={[
                {
                  condition: (item) => {
                    return item.isPublished === true;
                  },
                  buttons: [
                    {
                      name: 'View entitlement',
                      action: (item) => {
                        history.push({
                          pathname: `/goodie_bags/${item.pk}/entitlement/`,
                          state: {
                            goodieBagName: item.name,
                            goodieBagMethod: item.entitlementMethod,
                          },
                        });
                        dispatch({
                          type: 'adminActivityLog/createAdministratorActivityLog',
                          payload: {
                            activityType: AdminActivityType.VIEW_ENTITLEMENT.pk,
                            objectId: item.pk,
                          },
                        });
                      },
                      requires: [
                        PermissionCodes.changeGoodiebagEntitlement,
                        PermissionCodes.viewGoodiebagEntitlement,
                      ],
                    },
                  ],
                },
              ]}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Goodie Bags',
          title: 'Goodie Bags',
        }}
        buttonsLeft={buttonsLeft}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('goodieBags/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Goodie Bags'}
      />
      <GoodieBagsAllocationPrompt
        show={showSetAllocatePrompt}
        closeAction={() => {
          setShowSetAllocatePrompt(false);
          setCurrentGoodieBag({});
        }}
        nextAction={(otaAllocatedNum) => {
          setShowSetAllocatePrompt(false);
          setCurrentGoodieBag({});
          dispatch(
            createAction('goodieBags/publish')({
              goodieBagPk: currentGoodieBag?.pk,
              otaAllocatedNum,
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
        ota={
          currentGoodieBag?.otaCount && currentGoodieBag?.otaCount > 100
            ? null
            : currentGoodieBag?.ota
        }
        goodieBag={currentGoodieBag}
      />
      <RecordExportPrompt
        show={showExportRecord}
        onClose={() => setShowExportRecord(false)}
        title={'Export goodie bag record'}
        exportSource={ImportResource.goodieBagParticipationRecord}
        exportFilterConditions={getFilterConditionsFromUrl()}
        otherBody={{ reportSperate: true }}
        total={recordCount}
      />
      <FilterExportPrompt
        show={showExportConfig}
        onClose={() => setShowExportConfig(false)}
        title={'Export goodie bag configuration'}
        exportSource={ImportResource.goodieBagConfig}
        exportFilterConditions={getFilterConditionsFromUrl()}
        otherBody={{ reportSperate: true }}
      />
    </>
  );
};

export default GoodieBagsList;
