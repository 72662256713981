import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import './ManageSearchContent.scss';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import NextStepPrompt from '../base/prompt/NextStepPrompt';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { OnlySaveButton } from '../base/BottomStepComponent';
import { APIStatus } from '../../config/CustomEnums';
import {
  SEARCH_SETTING_TYPE,
  revertParseType,
} from '../../models/SearchSettingModel';

function ManageSearchContent() {
  const params = useParams();
  const dispatch = useDispatch();
  const { searchSettingType } = params;

  const { watch, getValues, setValue, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;

  const watchCampaignNameWeight = watch('campaignNameWeight');
  const watchCampaignShortDescriptionWeight = watch(
    'campaignShortDescriptionWeight',
  );
  const watchCampaignCategoryNameWeight = watch('campaignCategoryNameWeight');
  const watchBrandNameWeight = watch('brandNameWeight');
  const watchCouponNameWeight = watch('couponNameWeight');
  const watchStoreNameWeight = watch('storeNameWeight');
  const watchStoreAddressWeight = watch('storeAddressWeight');
  const watchTourCardNameWeight = watch('tourCardNameWeight');
  const watchAttributeTagNameWeight = watch('attributeTagNameWeight');
  const watchAttributeTagKeyNameWeight = watch('attributeTagKeyNameWeight');
  const watchSearchTagsWeight = watch('searchTagsWeight');
  const watchSemTagsWeight = watch('semTagsWeight');
  const watchNameWeight = watch('nameWeight');
  const watchDescriptionWeight = watch('descriptionWeight');
  const watchRemarksWeight = watch('remarksWeight');

  const watchThreshold = watch('threshold');

  const saveButtonClicked = () => {
    let data = getValues();
    let toUpdateData = {};
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'pk') {
        toUpdateData[key] = value || null;
      }
    }
    toUpdateData['searchSettingType'] = revertParseType(searchSettingType);
    dispatch({
      type: 'searchSetting/updateSearchSettings',
      payload: toUpdateData,
    });
  };

  const onNumberInputKeyDown = (event) => {
    console.log('@@91: ', event.keyCode);
    if (
      event.keyCode === 189 || //-
      event.keyCode === 109 || //-
      event.keyCode === 69 || //e
      event.keyCode === 187 || //=
      event.keyCode === 107 || //+
      event.keyCode === 110 //.
    ) {
      event.preventDefault();
      return false;
    }
  };

  function getWeightsFields() {
    const isCampaignRelated = [
      SEARCH_SETTING_TYPE.CAMPAIGN,
      SEARCH_SETTING_TYPE.GAMIFICATION_MIXED,
      SEARCH_SETTING_TYPE.MISSION_CAMPAIGN,
    ].includes(searchSettingType);
    const isMissionCampaignRelated = [
      SEARCH_SETTING_TYPE.GAMIFICATION_MIXED,
      SEARCH_SETTING_TYPE.MISSION_CAMPAIGN,
    ].includes(searchSettingType);
    const isLocationRelated = [SEARCH_SETTING_TYPE.MISSION_CHECK_IN].includes(
      searchSettingType,
    );
    const allFields = [
      {
        title: 'Campaign name',
        value: watchCampaignNameWeight,
        onChange: (value) => setValue('campaignNameWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Location name',
        value: watchNameWeight,
        onChange: (value) => setValue('nameWeight', value),
        isShow: isLocationRelated,
      },
      {
        title: 'Short description',
        value: watchCampaignShortDescriptionWeight,
        onChange: (value) => setValue('campaignShortDescriptionWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Brand name',
        value: watchBrandNameWeight,
        onChange: (value) => setValue('brandNameWeight', value),
        isShow: isCampaignRelated || isLocationRelated,
      },
      {
        title: 'Description',
        value: watchDescriptionWeight,
        onChange: (value) => setValue('descriptionWeight', value),
        isShow: isLocationRelated,
      },
      {
        title: 'Coupon name',
        value: watchCouponNameWeight,
        onChange: (value) => setValue('couponNameWeight', value),
        isShow: isMissionCampaignRelated,
      },
      {
        title: 'Store name',
        value: watchStoreNameWeight,
        onChange: (value) => setValue('storeNameWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Tour cards name',
        value: watchTourCardNameWeight,
        onChange: (value) => setValue('tourCardNameWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Address for frontend display',
        value: watchStoreAddressWeight,
        onChange: (value) => setValue('storeAddressWeight', value),
        isShow: isCampaignRelated || isLocationRelated,
      },
      {
        title: 'Remarks',
        value: watchRemarksWeight,
        onChange: (value) => setValue('remarksWeight', value),
        isShow: isLocationRelated,
      },
      {
        title: 'Attribute keys',
        value: watchAttributeTagKeyNameWeight,
        onChange: (value) => setValue('attributeTagKeyNameWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Attribute tags',
        value: watchAttributeTagNameWeight,
        onChange: (value) => setValue('attributeTagNameWeight', value),
        isShow: isCampaignRelated,
      },
      {
        title: 'Search tags',
        value: watchSearchTagsWeight,
        onChange: (value) => setValue('searchTagsWeight', value),
        isShow: isCampaignRelated || isLocationRelated,
      },
      {
        title: 'SEM tags',
        value: watchSemTagsWeight,
        onChange: (value) => setValue('semTagsWeight', value),
        isShow: isCampaignRelated || isLocationRelated,
      },
      {
        title: 'Category name',
        value: watchCampaignCategoryNameWeight,
        onChange: (value) => setValue('campaignCategoryNameWeight', value),
        isShow: isCampaignRelated,
      },
    ];
    return allFields.filter((item) => item.isShow);
  }
  const weightsFields = getWeightsFields();

  const content = (
    <>
      <div className="manage-search-container">
        <div>
          <div className="row">
            <div className="col">
              <label className="create-section-label">Fields</label>
            </div>
            <div className="col">
              <label className="create-section-label">Weights</label>
            </div>
          </div>
        </div>
        <label className="tips-message">
          Please input positive numbers or 0 (blank = 0). If set weight as 0,
          system will exclude the field when calculate the score.
        </label>
        <div>
          {weightsFields?.map((item) => {
            return (
              <>
                <div className="row">
                  <div className="col">
                    <label className="create-section-label">{item.title}</label>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      min={0}
                      onChange={({ target }) => item.onChange(target.value)}
                      value={item.value}
                      className={`custom-markdown-area-title custom-markdown-area-title-short custom-number-input-short`}
                      onKeyDown={onNumberInputKeyDown}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="d-flex flex-column">
          <CustomTitleLabel title="Threshold" />
          <label className="tips-message">
            Only{' '}
            {searchSettingType === SEARCH_SETTING_TYPE.CAMPAIGN
              ? 'campaigns '
              : ''}
            scoring &gt;= threshold will be resulted after search. Please input
            positive numbers or 0 (blank = 0).
          </label>
          <input
            type="number"
            onChange={({ target }) => {
              setValue('threshold', target.value);
            }}
            value={watchThreshold}
            className={`custom-markdown-area-title custom-markdown-area-title-short custom-number-input-short`}
            onKeyDown={onNumberInputKeyDown}
          />
        </div>
      </div>
    </>
  );
  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        sections={[content]}
        hidePreview={true}
      />
      <OnlySaveButton
        customBaseClassName="flex-end-save-button"
        saveAction={saveButtonClicked}
        saveText="Save"
      />
    </>
  );
}

export default ManageSearchContent;
