import React, { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext, Controller } from 'react-hook-form';
import { validate } from './CreateMissionValidate';
import {
  createAction,
  insertKeyToAllLanguages,
  insertKeyToTranslations,
} from '../../utils';
import LabelWithSwitch, { ApplySwitch } from '../base/LabelWithSwitch';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomEditor from '../base/CustomEditor';
import BaseMarkDownArea from '../base/BaseMarkDownArea';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import i18n from '../../I18n';
import ImageUploader from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import TutorialSection from '../gamificationCampaign/TutorialSection';
import './CreateMissionStepTwo.scss';

function GeneralSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;
  const translations = watch('translations') || {};
  const coverPhoto = translations[language]?.coverPhoto || '';
  const mobilePhoto = translations[language]?.mobilePhoto || '';
  const detailPhotos = translations[language]?.detailPhotos || [];
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );

  const coverPhotoSection = ({
    title,
    suggest,
    minWidth,
    minHeight,
    initialValue,
    photoKey,
    aspect,
  }) => {
    return (
      <>
        <div
          className={'create-section-label create-section-label-bottom-space'}
        >
          {title}
        </div>
        <div className="second-section_description">{suggest}</div>
        <Controller
          control={control}
          name={`translations.${language}.${photoKey}`}
          render={({ field: { value } }) => (
            <ImageUploader
              minWidth={minWidth}
              minHeight={minHeight}
              images={value ? [value] : []}
              disabled={
                language !== LanguageConfig.english &&
                applyCoverPhotoToAllLanguage
              }
              onImageStateChange={(newState) => {
                const applyLanguages = applyCoverPhotoToAllLanguage
                  ? languages
                  : [language];
                insertKeyToAllLanguages({
                  languages: applyLanguages,
                  key: photoKey,
                  value: newState[0],
                  setValue,
                  getValues,
                });
              }}
              aspect={aspect}
              maxImageNum={1}
              language={language}
              uploadImageClicked={() => {}}
              errorMessage={
                hasError(errors, `translations.${language}.${photoKey}`)
                  ? errorMessage(errors, `translations.${language}.${photoKey}`)
                  : ''
              }
            />
          )}
        />
      </>
    );
  };

  return (
    <div className="create-mission-step-two">
      <div className="first-section-title">General</div>
      <Controller
        control={control}
        name={`translations.${language}.pk`}
        render={() => () => {}}
      />
      <Controller
        control={control}
        name={`translations.${language}.name`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Name"
              customClass="create-mission-input"
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`translations.${language}.name`, val, {
                  shouldDirty: true,
                });
              }}
              error={{
                id: `translations.${language}.name`,
                error: hasError(errors, `translations.${language}.name`),
                message: errorMessage(errors, `translations.${language}.name`),
              }}
            />
          </>
        )}
      />

      <div className={'create-section-label create-section-label-bottom-space'}>
        Short description
      </div>

      <Controller
        control={control}
        name={`translations.${language}.shortDescription`}
        render={({ field: { value } }) => (
          <>
            <CustomEditor
              initialValue={value}
              onValueChange={(value) => {
                setValue(`translations.${language}.shortDescription`, value, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {}}
              errorMessage={errorMessage(
                errors,
                `translations.${language}.shortDescription`,
              )}
              error={hasError(
                errors,
                `translations.${language}.shortDescription`,
              )}
            />
          </>
        )}
      />

      {coverPhotoSection({
        title: 'Cover photo for desktop (and mobile)',
        suggest:
          'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)',
        minWidth: 480,
        minHeight: 270,
        photoKey: 'coverPhoto',
        aspect: 480 / 270,
      })}
      {coverPhotoSection({
        title: 'Cover photo for mobile (optional)',
        suggest:
          'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)',
        minWidth: 480,
        minHeight: 270,
        photoKey: 'mobilePhoto',
        aspect: 480 / 270,
      })}
      <span id={`coverPhoto-${language}`} />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
            insertKeyToAllLanguages({
              languages,
              key: 'mobilePhoto',
              value: mobilePhoto,
              setValue,
              getValues,
            });
          }
        }}
      />

      <CustomTitleLabel title={i18n.t('other_photos', { locale: 'en' })} />
      <div className="second-section_description">
        Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than
        2MB, support JPG, PNG and GIF only.)
      </div>
      <ImageUploader
        minWidth={480}
        minHeight={270}
        disabled={
          language !== LanguageConfig.english && applyDetailPhotosToAllLanguage
        }
        images={detailPhotos?.filter((item) => item)}
        onImageStateChange={(newState) => {
          const applyLanguages = applyDetailPhotosToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'detailPhotos',
            value: newState,
            setValue,
            getValues,
          });
        }}
        aspect={480 / 270}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
      />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'detailPhotos',
              value: detailPhotos,
              setValue,
              getValues,
            });
          }
        }}
      />
    </div>
  );
}

function CreateMissionStepTwo({ languageList }) {
  const { getValues, clearErrors, setError, watch } = useFormContext();
  console.log('@@284: ', getValues());
  const dispatch = useDispatch();

  const getTabs = (language) => {
    return [
      <GeneralSection language={language} languages={languageList} />,
      <TutorialSection language={language} languages={languageList} />,
    ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'mission/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(CreateMissionStepTwo);
