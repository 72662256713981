import React, { useEffect, useState } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb.js';
import CustomListComponent from '../../../components/base/CustomListComponent.js';
import { PermissionCodes } from '../../../config/PermissionCodes.js';
import AuthButton from '../../../components/base/AuthButton.js';
import { useHistory, useLocation } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt.js';
import { useDispatch, connect } from 'react-redux';
import BaseTabListContainer from '../../base/BaseTabListContainer.js';
import Filter from './Filter.js';
import BaseListContainer from '../../base/BaseListContainer.js';
import queryString from 'query-string';
import './GamificationCampaignList.scss';
import { t } from 'i18n-js';
import { GAMIFICATION_CAMPAIGN_LIST_FIELDS } from '../../../models/GamificationCampaignModel.js';
import { createAction, checkHasPermission } from '../../../utils/index.js';
import { ImportResource } from '../../../models/DownloadImportModel';
import RecordExportPrompt from '../../../components/base/prompt/RecordExportPrompt.js';
import FilterExportPrompt from '../../../components/base/prompt/FilterExportPrompt';

function GamificationCampaignList({
  pageInfo,
  totalCount,
  gamificationCampaignList,
  totalPage,
  checkedList,
  user,
  recordCount,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showExportRecord, setShowExportRecord] = useState(false);
  const [showExportConfig, setShowExportConfig] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const getFilterConditionsFromUrl = () => {
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const searchEntitlementType = parsedSearch['entitlement_type'] || '';
    const searchStatus = parsedSearch['status_in'] || '';
    const searchActivePeriod = parsedSearch['active_period'] || '';
    const searchVisiblePeriod = parsedSearch['visible_period'] || '';
    const searchCreateAt = parsedSearch['create_at'] || '';
    const searchLastModified = parsedSearch['last_modified'] || '';

    let filterConditions = {
      name_icontains: searchKey,
    };
    if (searchEntitlementType === 'public') {
      filterConditions['is_public'] = true;
    } else if (searchEntitlementType) {
      filterConditions['entitlement_type'] = searchEntitlementType;
    }
    if (searchStatus) {
      let statusInValue = searchStatus;
      if (searchStatus === 'true') {
        statusInValue = 'upcoming,expired,active';
      } else if (searchStatus === 'false') {
        statusInValue = 'inactive';
      }
      filterConditions['status_in'] = statusInValue;
    }
    if (searchActivePeriod) {
      filterConditions = {
        ...filterConditions,
        active_time_range: searchActivePeriod,
      };
    }
    if (searchVisiblePeriod) {
      const visibleDateRange = searchVisiblePeriod.split(',');
      filterConditions = {
        ...filterConditions,
        display_start_date_gte: visibleDateRange[0],
        display_end_date_lte: visibleDateRange[1],
      };
    }
    if (searchCreateAt) {
      const createAtDateRange = searchCreateAt.split(',');
      filterConditions = {
        ...filterConditions,
        creation_date_gte: createAtDateRange[0],
        creation_date_lte: createAtDateRange[1],
      };
    }
    if (searchLastModified) {
      const lastModifiedDateRange = searchLastModified.split(',');
      filterConditions = {
        ...filterConditions,
        last_modified_date_gte: lastModifiedDateRange[0],
        last_modified_date_lte: lastModifiedDateRange[1],
      };
    }
    return filterConditions;
  };

  useEffect(() => {
    console.log('@37', gamificationCampaignList);

    const searchDict = queryString.parse(location.search);
    console.log('@38', searchDict);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch({
      type: 'gamificationCampaign/getList',
      payload: {
        fromList: true,
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
        search: null,
      },
    });
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export record"
      action={() => {
        setShowExportRecord(true);
      }}
    />,
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export configuration"
      action={() => {
        setShowExportConfig(true);
      }}
    />,
    <AuthButton
      title="Create Gamification campaign"
      action={() => {
        history.push('/gamification_campaign/create');
      }}
      requires={PermissionCodes.changeGamificationCampaign}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeGamificationCampaign,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={GAMIFICATION_CAMPAIGN_LIST_FIELDS}
              model={'gamificationCampaign'}
              permissionGroup={PermissionCodes.gamificationCampaign}
              dataList={gamificationCampaignList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Gamification campaign',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
              hasOtherActions={true}
              customActions={{
                publish: {
                  condition: (item) => {
                    return item.isPublished
                      ? checkHasPermission(
                          user,
                          PermissionCodes.unPublishGamificationCampaign,
                        )
                      : checkHasPermission(
                          user,
                          PermissionCodes.publishGamificationCampaign,
                        );
                  },
                  action: (item) => {
                    dispatch(
                      createAction('gamificationCampaign/publish')({
                        gamificationCampaignPk: item.pk,
                        isPublished: item.isPublished,
                        afterAction: () => {
                          history.push(location);
                        },
                      }),
                    );
                  },
                  requires: [
                    PermissionCodes.publishGamificationCampaign,
                    PermissionCodes.unPublishGamificationCampaign,
                  ],
                },
              }}
              specificActions={[
                {
                  condition: (item) => {
                    return item.isPublished === true && !item.isPublic;
                  },
                  buttons: [
                    {
                      name: 'View entitlement',
                      action: (item) => {
                        history.push({
                          pathname: `/gamification_campaign/${item.pk}/entitlement/`,
                          state: {
                            gamificationCampaignName: item.name,
                            gamificationCampaignEntitlementType:
                              item.entitlementType,
                          },
                        });
                        // dispatch({
                        //   type: 'adminActivityLog/createAdministratorActivityLog',
                        //   payload: {
                        //     activityType: AdminActivityType.VIEW_ENTITLEMENT.pk,
                        //     objectId: item.pk,
                        //   },
                        // });
                      },
                      requires: [
                        PermissionCodes.changeGamificationCampaignEntitlement,
                        PermissionCodes.viewGamificationCampaignEntitlement,
                        // PermissionCodes.changeGamificationCampaign,
                        // PermissionCodes.viewGamificationCampaign,
                      ],
                    },
                  ],
                },
              ]}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: 'Gamification campaign',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'gamificationCampaign/delete',
            payload: {
              afterAction: () => history.push(location),
            },
          });
        }}
        data={checkedList}
        title={'Gamification campaign'}
      />
      <RecordExportPrompt
        show={showExportRecord}
        onClose={() => setShowExportRecord(false)}
        title={'Export gamification record'}
        exportSource={ImportResource.gamificationCampaignParticipationRecord}
        exportFilterConditions={getFilterConditionsFromUrl()}
        otherBody={{ reportSperate: true }}
        total={recordCount}
      />
      <FilterExportPrompt
        show={showExportConfig}
        onClose={() => setShowExportConfig(false)}
        title={'Export gamification configuration'}
        exportSource={ImportResource.gamificationCampaignConfig}
        exportFilterConditions={getFilterConditionsFromUrl()}
        otherBody={{ reportSperate: true }}
      />
    </>
  );
}
const mapPropsToState = (state) => ({
  pageInfo: state.gamificationCampaign.pageInfo,
  totalCount: state.gamificationCampaign.totalCount,
  gamificationCampaignList: state.gamificationCampaign.pagedList,
  totalPage: state.gamificationCampaign.totalPage,
  checkedList: state.gamificationCampaign.checkedList,
  user: state.users,
  recordCount: state.gamificationCampaign.recordCount,
});
export default connect(mapPropsToState)(GamificationCampaignList);
