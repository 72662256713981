import { BaseHelper } from './BaseGraphQLAPIHelper';

const getMissionNode = ({ isList, isSimplify, isAll }) => {
  if (isSimplify) {
    return `{
      pk
      name
    }`;
  }

  if (isAll) {
    return `{
      pk
      name
      missionType
      checkInMode
      startDate
      endDate
      displayStartDate
      displayEndDate
      isPublished
      perActionStampRewardQuantity
      perHeadLimit
    }`;
  }

  return `{
    creationDate
    lastModifiedDate
    language
    id
    pk
    name
    missionType
    checkInMode
    startDate
    endDate
    displayStartDate
    displayEndDate
    isPublished
    numberOfCampaigns
    numberOfGoodieBags
    numberOfLocations
    perActionStampRewardQuantity
    perHeadLimit
    missions {
      id
      pk
      gamificationCampaign {
        pk
        name
        startDate
        endDate
        displayStartDate
        displayEndDate
        isPublished
      }
    }
    ${
      isList
        ? ``
        : `
      coverPhoto
      mobilePhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      shortDescription
      tutorialTitle
      tutorialContent
      isFollowGamificationCampaignActivePeriod
      isFollowGamificationCampaignVisiblePeriod
      hideOutOfStockCampaign
      showStatementWordingForStampLimit
      statementWordingForStampLimit
      tutorials(first: 100, orderBy: "index") {
        edges {
          node {
            pk
            applyImageToAllLanguage
            image
            content
            translations {
              edges {
                node {
                  pk
                  language
                  image
                  content
                }
              }
            }
          }
        }
      }
      workingTeams {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    `
    }
    ${
      isList
        ? ``
        : `
      translations {
        edges {
          node {
            pk
            language
            name
            coverPhoto
            mobilePhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            shortDescription
            tutorialTitle
            tutorialContent
            statementWordingForStampLimit
          }
        }
      }
    `
    }
  }`;
};

export const getMissionList = (afterCursor, others) => {
  let filter = '';
  console.log('@68', afterCursor, others);
  const {
    filterName,
    sort,
    status,
    active_period,
    visible_period,
    create_at,
    last_modified,
    search,
    isSimplify,
    isAll,
    type,
    linked_gamification,
    working_team,
    tags,
    gamificationCampaignIn,
    excludeInOtherGamificationCampaign,
  } = others || {};
  if (search) {
    filter = `, search:"${search}"`;
  }
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (sort) {
    filter += `, orderBy: "${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (type) {
    filter += `, type:"${type}"`;
  }
  if (status) {
    if (status === 'inactive') {
      filter += `, isExpired: true`;
    } else if (status === 'unexpired') {
      filter += `, isExpired: false`;
    } else {
      filter += `, isPublished: ${status}`;
    }
  }
  if (active_period) {
    filter += `, activeTimeRange: "${active_period}"`;
  }
  // if (visible_period) {
  //   const displayStartDateGte = visible_period?.split(',')?.[0];
  //   const displayEndDateLte = visible_period?.split(',')?.[1];
  //   filter += `, displayStartDateGte: "${displayStartDateGte}", displayEndDateLte: "${displayEndDateLte}"`;
  // }
  if (visible_period) {
    filter += `, displayTimeRange: "${visible_period}"`;
  }
  if (create_at) {
    const creationDateGte = create_at?.split(',')?.[0];
    const creationDateLte = create_at?.split(',')?.[1];
    if (creationDateGte && creationDateLte) {
      filter += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
    }
  }
  if (last_modified) {
    const lastModifiedDateGte = last_modified?.split(',')?.[0];
    const lastModifiedDateLte = last_modified?.split(',')?.[1];
    if (lastModifiedDateGte && lastModifiedDateLte) {
      filter += `, lastModifiedDateGte: "${lastModifiedDateGte}", lastModifiedDateLte: "${lastModifiedDateLte}"`;
    }
  }
  if (working_team) {
    filter += `, workingTeamsInAndNull: "${working_team}"`;
  }
  if (tags) {
    filter += `, attributeTagsIn: "${tags}"`;
  }
  if (gamificationCampaignIn || linked_gamification) {
    filter += `, gamificationCampaignIn: "${
      gamificationCampaignIn || linked_gamification
    }"`;
  }
  if (
    excludeInOtherGamificationCampaign ||
    excludeInOtherGamificationCampaign === 0
  ) {
    filter += `, gamificationCampaignInOrNull: "${excludeInOtherGamificationCampaign}"`;
  }

  const query = `{
    missions(first: ${
      isAll ? others.pageSize : 20
    }, after:"${afterCursor}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${getMissionNode({
          isList: true,
          isSimplify,
          isAll,
          needQuantity: others?.needQuantity,
        })}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getMissionDetail = (id) => {
  const query = `{
    mission(id: "${id}")
      ${getMissionNode({})}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createMission = (payload) => {
  console.log('@198', payload);
  const query = `mutation createMission($input: CreateMissionInput!) {
    createMission(input: $input) {
      node ${getMissionNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateMission = (payload) => {
  console.log('@216', payload);
  const query = `mutation updateMission($input: UpdateMissionInput!) {
    updateMission(input: $input) {
      node ${getMissionNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMission = (ids) => {
  console.log('@@251', ids);
  const query = `mutation deleteMissions($input: DeleteMissionsInput!) {
    deleteMissions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishMission = (input) => {
  const query = `mutation PublishMission($input: PublishMissionInput!) {
    publishMission(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishMission = (input) => {
  const query = `mutation UnpublishMission($input: UnpublishMissionInput!) {
    unpublishMission(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMissionLocations = (afterCursor, filter) => {
  let userFilter = '';
  userFilter += `, first: ${filter?.isAll ? filter?.pageSize || 1000 : 20}`;
  // let orderBy = filter?.reverse ? 'displayPriority' : '-displayPriority';
  let orderBy = filter?.reverse ? '-pk' : 'pk';
  if (filter?.sort) {
    orderBy = filter?.sort;
  }
  if (orderBy) {
    userFilter += `, orderBy: "${orderBy}"`;
  }

  if (filter?.search) {
    userFilter += `, nameIcontains: "${filter?.search}"`;
  }
  if (filter?.missionIn) {
    userFilter += `, missionIn: "${filter?.missionIn}"`;
  }

  const query = `{
    missionLocations(after: "${afterCursor}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          pk
          location {
            pk
            name
            missionLocations(first: 100, orderBy: "displayPriority") {
              edges {
                node {
                  mission {
                    pk
                    name
                  }
                }
              }
            }
          }
          code
          displayPriority
          isFeatured
          isForcedInactive
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updateMissionLocation = (payload) => {
  console.log('@216', payload);
  const query = `mutation updateMissionLocation($input: UpdateMissionLocationInput!) {
    updateMissionLocation(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMissionLocations = (ids) => {
  console.log('@@251', ids);
  const query = `mutation deleteMissionLocations($input: DeleteMissionLocationsInput!) {
    deleteMissionLocations(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMissionLocationQRCode = (code) => {
  const query = `{
    missionLocationCode(code: "${code}")
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
