import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import WeekHours from './WeekHours';
import HolidayHours from './HolidayHours';
import CustomEditor from '../../../components/base/CustomEditor';
import { LanguageConfig, StoreType } from '../../../config/CustomEnums';

export default function BusinessHours({ language, focusChange, storeType }) {
  console.log('BusinessHours');
  const { setValue, control } = useFormContext();
  return (
    <>
      <label className="create-section-title">Opening hours</label>
      {language === LanguageConfig.english ? (
        <>
          <WeekHours language={language} focusChange={focusChange} />
          <HolidayHours language={language} focusChange={focusChange} />
        </>
      ) : null}

      {storeType === StoreType.LOCATION ? (
        <Controller
          name={`storeTranslations.${language}.remarks`}
          control={control}
          render={({ field: { value } }) => (
            <>
              <label
                className={`create-section-label`}
                style={{ marginBottom: '8px' }}
              >
                Remarks (optional)
              </label>
              <CustomEditor
                initialValue={value}
                onValueChange={(data) => {
                  setValue(`storeTranslations.${language}.remarks`, data, {
                    shouldDirty: true,
                  });
                }}
              />
            </>
          )}
        />
      ) : null}
    </>
  );
}
