import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getSearchSettings = (searchSettingType) => {
  const query = `{
      campaignSearchFieldSettings(searchSettingType: "${searchSettingType}") {
        pk
        searchSettingType
        campaignNameWeight
        campaignShortDescriptionWeight
        campaignCategoryNameWeight
        brandNameWeight
        couponNameWeight
        storeNameWeight
        storeAddressWeight
        tourCardNameWeight
        attributeTagNameWeight
        attributeTagKeyNameWeight
        searchTagsWeight
        semTagsWeight
        nameWeight
        descriptionWeight
        remarksWeight
        threshold
      }
    }
    `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSearchSettings = (payload) => {
  const query = `mutation UpdateCampaignSearchFieldSettings($input: UpdateCampaignSearchFieldSettingsInput!) {
        updateCampaignSearchFieldSettings(input: $input) {
          success
          errors {
            field
            messages
          }
        }
      }
    `;
  const variables = { input: payload };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
