import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import './SortableComponent.scss';
import CustomViewer from '../base/CustomViewer';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import EditIcon from '../../assets/images/coupon/edit.svg';
import DeleteIcon from '../../assets/images/coupon/delete.svg';
import DropDownIcon from '../../assets/images/drop_down_arrow_solid.svg';
import DropUpIcon from '../../assets/images/drop_up_arrow_solid.svg';
import {
  SortableDivContainer,
  SortableRow,
} from '../tourCard/SortableTourCard';

const SortableComponent = ({
  title,
  customTitleClass,
  tips,
  data,
  setData,
  disabled = false,
  disabledSort = false,
  onSortEnd,
  customClass,
  customRowClass,
  customSortableRow,
  displayDropDownArrow = false,
  displayDropDown = true,
  hideEdit = true,
  hideDelete = false,
  hideClearAll = true,
  showLiBeforIndex = false,
}) => {
  const handleSortStart = ({ node, helper }) => {
    document.body.style.cursor = 'move';
    const color = window
      .getComputedStyle(node, null)
      .getPropertyValue('background-color');
    helper.style.backgroundColor = color;
    // const tds = helper.childNodes;
    // node.childNodes.forEach(
    //   (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`),
    // );
    node.classList.add('drag-item-ghost');
  };

  return (
    <>
      <div className="sortable-container">
        {title ? (
          <div className={`create-section-label-bottom-space`}>
            <label className={`create-section-label ${customTitleClass}`}>
              {title}
            </label>
            {hideClearAll ? null : (
              <label className="clear-all" onClick={() => setData([])}>
                Clear all
              </label>
            )}
          </div>
        ) : null}
        {tips ? <label className="tips-message">{tips}</label> : null}
        <SortableDivContainer
          customClass={customClass}
          // hasError={hasError}
          distance={10}
          shouldCancelStart={(e) => {
            if (disabled || disabledSort) {
              return true;
            }
            console.log(e.target.tagName);
            if (e.target.tagName === 'A' || e.target.tagName === 'INPUT') {
              return true;
            }
            if (e.target.className.indexOf('sort-disabled') !== -1) {
              return true;
            }
          }}
          hideSortableGhost={false}
          helperClass="sortable-helper"
          onSortStart={handleSortStart}
          onSortEnd={({ oldIndex, newIndex, nodes }) => {
            nodes.forEach((node, idx) => {
              node.node.classList.remove('drag-item-ghost');
            });
            document.body.style.cursor = '';
            onSortEnd && onSortEnd(oldIndex, newIndex);
          }}
        >
          {data.map((item, index) => (
            <SortableRow index={index}>
              {customSortableRow || (
                <div className={`base-sortable-row ${customRowClass}`}>
                  <div className="sortable-left">
                    <div className="row-index">
                      {showLiBeforIndex ? (
                        <li>{item.rowIndex || index + 1}</li>
                      ) : (
                        item.rowIndex || index + 1
                      )}
                    </div>
                    <div className="sortable-content">
                      <div
                        className="row-title"
                        onClick={() => {
                          if (displayDropDownArrow) {
                            setData(
                              data.map((val) => {
                                if (val.pk === item.pk) {
                                  val.hideDropDownArrow =
                                    !val.hideDropDownArrow;
                                  return val;
                                }
                                return val;
                              }),
                            );
                          }
                        }}
                      >
                        {displayDropDownArrow ? (
                          <img
                            className="drop-icon"
                            src={
                              !item.hideDropDownArrow
                                ? DropUpIcon
                                : DropDownIcon
                            }
                          />
                        ) : null}
                        {item.label}
                      </div>
                      {!displayDropDownArrow || !item.hideDropDownArrow ? (
                        <div className="row-content">
                          <ul>
                            {displayDropDown &&
                              item.rows?.map((row) => {
                                // return <li>{row}</li>;
                                return (
                                  <li>
                                    <CustomViewer initValue={row} />
                                  </li>
                                );
                              })}
                          </ul>
                          {displayDropDown &&
                            item.sections?.map((section) => {
                              return (
                                <div className="section-container">
                                  <div className="row-title">
                                    {section.title}
                                  </div>
                                  <div className="row-content">
                                    <ul>
                                      {section.rows?.map((row) => {
                                        return (
                                          <>
                                            <li>
                                              <CustomViewer initValue={row} />
                                            </li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="sortable-right">
                    {disabled || hideDelete ? null : (
                      <Image
                        draggable={false}
                        src={DeleteIcon}
                        className="delete-icon"
                        onClick={() => {
                          setData(data.filter((val) => val.pk !== item.pk));
                        }}
                      />
                    )}
                    {disabled || hideEdit ? null : (
                      <Image
                        draggable={false}
                        src={EditIcon}
                        className="edit-icon"
                        onClick={() => {
                          console.log('edit click');
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </SortableRow>
          ))}
        </SortableDivContainer>
      </div>
    </>
  );
};

export default SortableComponent;
