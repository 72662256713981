import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomEditor from '../base/CustomEditor';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import DeleteRowIcon from '../../assets/images/deleteIcon.png';
import CoverPhotoSection from './CoverPhotoSection';
import AuthButton from '../base/AuthButton';
import { ImageButton } from '../base/CustomImageButton';
import { ApplySwitch } from '../base/LabelWithSwitch';

export default function TutorialSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch('translations') || {};
  const tutorials = translations[language].tutorials;
  const enTutorials = translations['en'].tutorials;
  const tutorialsKeys = watch('tutorialsKeys') || [];

  return (
    <div>
      <div className="first-section-title">Tutorial</div>
      <Controller
        control={control}
        name={`translations.${language}.tutorialTitle`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Title"
              extra={{ placeholder: 'Please input title' }}
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`translations.${language}.tutorialTitle`, val, {
                  shouldDirty: true,
                });
              }}
              error={{
                id: `translations.${language}.tutorialTitle`,
                error: hasError(
                  errors,
                  `translations.${language}.tutorialTitle`,
                ),
                message: errorMessage(
                  errors,
                  `translations.${language}.tutorialTitle`,
                ),
              }}
            />
          </>
        )}
      />
      <div className={'create-section-label create-section-label-bottom-space'}>
        Description
      </div>
      <Controller
        control={control}
        name={`translations.${language}.tutorialContent`}
        render={({ field: { value } }) => (
          <>
            <CustomEditor
              initialValue={value}
              onValueChange={(value) => {
                setValue(`translations.${language}.tutorialContent`, value, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {}}
              errorMessage={errorMessage(
                errors,
                `translations.${language}.tutorialContent`,
              )}
              error={hasError(
                errors,
                `translations.${language}.tutorialContent`,
              )}
            />
          </>
        )}
      />

      <div className="tutorial-container">
        {tutorialsKeys
          ?.filter((key) => !tutorials?.[key]?.isDeleted)
          ?.map((key, index) => {
            const applyImageToAllLanguage =
              enTutorials?.[key]?.applyImageToAllLanguage || false;
            const imageToApply = enTutorials?.[key]?.image;
            return (
              <>
                <div
                  key={`tutorial-${key}`}
                  className="tutorial-item-container"
                >
                  {index > 0 ? (
                    <ImageButton
                      action={() => {
                        const temp = languages?.forEach((item) => {
                          setValue(
                            `translations.${item.code}.tutorials.${key}.isDeleted`,
                            true,
                            {
                              shouldDirty: true,
                            },
                          );
                        });
                      }}
                      image={DeleteRowIcon}
                      customClass="delete-icon"
                    />
                  ) : null}
                  <Controller
                    control={control}
                    name={`translations.${language}.tutorials.${key}.pk`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.tutorials.${key}.isDeleted`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.tutorials.${key}.image`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <CustomTitleLabel
                            title={`Step ${index + 1} image`}
                            className="display-as-block"
                          />
                          <CoverPhotoSection
                            languages={languages}
                            language={language}
                            title={`Desktop and Mobile`}
                            suggest={
                              'Suggested Image Size: 1920px*1440. At least : 480px*360px(Less than 2MB, support JPG, PNG and GIF only.)'
                            }
                            minWidth={480}
                            minHeight={360}
                            aspect={480 / 360}
                            value={value}
                            setCustomValue={(val) => {
                              const applyLanguages = applyImageToAllLanguage
                                ? languages
                                : [language];
                              applyLanguages.forEach((item) => {
                                setValue(
                                  `translations.${
                                    item.code || item
                                  }.tutorials.${key}.image`,
                                  val,
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              });
                            }}
                            applyToAllLanguage={applyImageToAllLanguage}
                          />
                          <ErrorFieldMessage
                            id={`translations.${language}.tutorials.${key}.image`}
                            error={hasError(
                              errors,
                              `translations.${language}.tutorials.${key}.image`,
                            )}
                            message={errorMessage(
                              errors,
                              `translations.${language}.tutorials.${key}.image`,
                            )}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.tutorials.${key}.applyImageToAllLanguage`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <ApplySwitch
                            // disabled={
                            //   language === LanguageConfig.english &&
                            //   !coverPhoto?.value &&
                            //   !mobilePhoto?.value
                            // }
                            show={language === LanguageConfig.english}
                            checked={value}
                            onChange={(applyValue) => {
                              setValue(
                                `translations.${language}.tutorials.${key}.applyImageToAllLanguage`,
                                applyValue,
                                {
                                  shouldDirty: true,
                                },
                              );
                              if (applyValue) {
                                languages.forEach((item) => {
                                  setValue(
                                    `translations.${
                                      item.code || item
                                    }.tutorials.${key}.image`,
                                    imageToApply,
                                    {
                                      shouldDirty: true,
                                    },
                                  );
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.tutorials.${key}.content`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <div
                            className={
                              'create-section-label create-section-label-bottom-space'
                            }
                          >
                            Step {index + 1} image description
                          </div>
                          <CustomEditor
                            initialValue={value}
                            onValueChange={(val) => {
                              setValue(
                                `translations.${language}.tutorials.${key}.content`,
                                val,
                                {
                                  shouldDirty: true,
                                },
                              );
                            }}
                            errorMessage={errorMessage(
                              errors,
                              `translations.${language}.tutorials.${key}.content`,
                            )}
                            error={hasError(
                              errors,
                              `translations.${language}.tutorials.${key}.content`,
                            )}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </>
            );
          })}
        <div>
          <AuthButton
            title={'Add a step'}
            customClass={'general-section-add-new btn-add-button-common'}
            action={() => {
              const lastKey = tutorialsKeys[tutorialsKeys.length - 1];
              setValue(
                'tutorialsKeys',
                tutorialsKeys.concat([parseInt(lastKey) + 1]),
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
