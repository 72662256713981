import React, { isValidElement, useEffect, useRef, useState } from 'react';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../I18n';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from '../../../components/earning/CustomBaseComponments';
import {
  hasError,
  errorMessage,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { LanguageConfig, StoreType } from '../../../config/CustomEnums';
import MapLocationSelector, {
  checkLocationValid,
} from '../../../components/base/MapLocationSelector';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';

export default function DetailSection({ language, focusChange }) {
  const dispatch = useDispatch();

  const { districtList } = useSelector((state) => ({
    districtList: state.storeModel.districtList,
  }));
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  // const watchDistrict = watch('district');
  // const watchAddress = watch(`storeTranslations.${language}.address`);
  // const watchLongitude = watch('longitude');
  // const watchLatitude = watch('latitude');
  // const watchRadius = watch('radius');
  // const watchMapAddress = watch('mapAddress');
  const storeType = watch('storeType');

  const districtArea = (
    <Controller
      control={control}
      name={'district'}
      rules={
        language === LanguageConfig.english
          ? { required: StoreErrorHandleFields.district.required }
          : {}
      }
      render={({ field: { value } }) => (
        <>
          <CustomTitleWithDropDown
            title={'District'}
            source={districtList}
            defaultValue={value}
            setValue={(value) => {
              setValue('district', value, { shouldDirty: true });
            }}
            onFocus={() => focusChange('district')}
            loadMoreAction={'storeModel/getDistrictList'}
            filterAction={'storeModel/getDistrictList'}
            defaultFilter={{ isSimplify: true }}
          />
          <ReactHookFormErrorMessage errors={errors} id="district" />
        </>
      )}
    />
  );

  const displatAddressArea = (
    <Controller
      control={control}
      name={`storeTranslations.${language}.address`}
      rules={{
        required: StoreErrorHandleFields.address.required,
      }}
      render={({ field: { value } }) => (
        <>
          <CustomTitleWithInput
            customClass={'latitude-and-latitude'}
            title={i18n.t('Address for frontend display', { locale: 'en' })}
            // type={type}
            defaultValue={value}
            setValue={(value) =>
              setValue(`storeTranslations.${language}.address`, value, {
                shouldDirty: true,
              })
            }
            focusChange={() => focusChange('Address for frontend display')}
            // disabled={disabled}
            // blurAction={blurAction}
            useDefaultValue={false}
          />
          {language === LanguageConfig.english ? (
            <ReactHookFormErrorMessage
              errors={errors}
              id={`storeTranslations.${language}.address`}
            />
          ) : null}
        </>
      )}
    />
  );
  console.log('@@store-91: ', errors);
  return (
    <>
      <label className="create-section-title">
        {i18n.t('detail_required', { locale: 'en' })}
      </label>
      {language !== LanguageConfig.english ? null : districtArea}
      {displatAddressArea}
      {language !== LanguageConfig.english ? null : (
        <Controller
          control={control}
          name={'location'}
          rules={{
            validate: {
              inner: (data) => {
                const { valid, errors } = checkLocationValid(
                  data,
                  false,
                  storeType === StoreType.LOCATION,
                );
                console.log('@@store-108: ', valid, errors);
                if (valid) {
                  return true;
                }

                return JSON.stringify(errors);
              },
            },
          }}
          render={({ field: { value } }) => (
            <MapLocationSelector
              data={value || {}}
              setData={(value) => {
                setValue('location', value, { shouldDirty: true });
              }}
              errors={
                errors?.location ? JSON.parse(errors?.location.message) : {}
              }
              withRadius={storeType === StoreType.LOCATION}
              radiusTitle={'Radius for check-in mission (optional)'}
              radiusCustomClass="radius-container"
              optional={true}
            />
          )}
        />
      )}

      {/* {language === LanguageConfig.english &&
      storeType === StoreType.LOCATION ? (
        <>
          <Controller
            control={control}
            name={'radius'}
            render={({ field: { value } }) => (
              <>
                <CustomTitleLabel
                  title={'Radius for check-in mission (optional)'}
                />
                <CustomNumberInputWithUnit
                  customContainerClass="radius-container"
                  setValue={(value) => {
                    setValue('radius', value, { shouldDirty: true });
                  }}
                  defaultValue={value}
                  unit="meters"
                  errorId="radius"
                  error={hasError(errors, 'radius')}
                  errorMessage={errorMessage(errors, 'radius')}
                />
              </>
            )}
          />
        </>
      ) : null} */}
    </>
  );
}
