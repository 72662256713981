import {
  CheckStatus,
  CUSTOMER_ID_TYPE,
  SavedStatus,
  StatusTag,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  getTrackingCodesList,
  getTrackingCodeDetail,
  createEntitlementTrackingCode,
  updateEntitlementTrackingCode,
  getTrackingCodeCount,
} from '../services/EntitlementTrackingCodeAPIHelper';
import { convertCursorToNumber } from '../utils';
import {
  formatDate,
  TimeFormater,
  checkCampaignIsExpired,
} from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { loading } from './LoadingUtil';

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  detail: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  trackingCodeCount: null,
});

const parseTrackingCode = (data) => {
  const startDate = data.startDate;
  const endDate = data.endDate;
  const isExpired = endDate && checkCampaignIsExpired(endDate);
  let status = StatusTag.active;
  if (!data.isForcedInactive) {
    if (isExpired) {
      status = StatusTag.expired;
    }
  } else {
    status = StatusTag.inactive;
  }
  return {
    ...data,
    isActive: !data.isForcedInactive,
    displayStartDate: formatDate(
      startDate,
      TimeFormater.dayMonthYearWeekTimeMinute,
    ),
    displayEndDate: formatDate(
      endDate,
      TimeFormater.dayMonthYearWeekTimeMinute,
    ),
    isExpired,
    status,
    genericURL: `&t=${data.encodeTrackingCode}`,
    wechatGenericURL: `&t=${data.encodeTrackingCode}`,
    activePeriod: {
      isFollowGoodieBag: data.isFollowGoodieBagActivePeriod,
      startDate,
      endDate,
    },
    locationCountDisplay: data.isCheckGps ? data.locationCount : 0,
    checkGPS: data.isCheckGps,
    locations: data?.locations?.edges?.map((item) => ({
      id: item.node.pk,
      mapAddress: item.node.mapAddress,
      latitude: item.node.latitude,
      longitude: item.node.longitude,
      radius: item.node.radius,
    })),
  };
};

const parseTrackingCodesList = (data) =>
  data?.entitlementTrackingCodes?.edges?.map((item) =>
    parseTrackingCode(item?.node),
  );

export default createModel({
  namespace: 'entitlementTrackingCodes',
  states: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.ENTITLEMENT_TRACKING_CODE_SESSION_KEY,
    dataKey: SESSION_KEYS.ENTITLEMENT_TRACKING_CODES_SESSION_KEY,
    listAPI: getTrackingCodesList,
    pkNode: 'EntitlementTrackingCodeNode',
    detailAPI: getTrackingCodeDetail,
    parse: parseTrackingCodesList,
    parseDetail: (data) => parseTrackingCode(data.entitlementTrackingCode),
    objectKey: 'entitlementTrackingCodes',
  },
  reducers: {
    clearData(state, { payload }) {
      const newState = {
        ...state,
        ...getInitialState(),
      };
      console.log('@170', newState);
      return newState;
    },
  },
  effects: {
    updateTrackingCode: [
      function* ({ payload }, { select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [updateEntitlementTrackingCode, payload.data];
        function* onSuccess(data) {
          console.log('onSuccess', data);
          afterAction();
        }
        function* onFailure(data) {
          console.log('@128 onFailure', data);
        }
        yield loading(serviceArgs, onSuccess, onFailure, onFailure);
      },
      { type: 'takeLatest' },
    ],
    updateTrackingCodeGPS: [
      function* ({ payload }, { select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [updateEntitlementTrackingCode, payload.data];
        function* onSuccess(data) {
          console.log('onSuccess', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });

          afterAction();
        }
        function* onFailure(data) {
          console.log('@128 onFailure', data);
        }
        yield loading(serviceArgs, onSuccess, onFailure, onFailure);
      },
      { type: 'takeLatest' },
    ],
    createUpdateTrackingCodes: [
      function* ({ payload }, { select, put }) {
        const isUpdate = !!payload.data?.[0]?.pk;
        const afterAction = payload.afterAction || (() => {});

        let serviceArgs = [];
        if (isUpdate) {
          const inputBody = {
            id: payload.data[0].pk,
            isFollowGoodieBagActivePeriod:
              payload.data[0].isFollowGoodieBag || false,
            startDate: payload.data[0].startDate,
            endDate: payload.data[0].endDate,
          };

          serviceArgs = [updateEntitlementTrackingCode, inputBody];
        } else {
          const inputBody = {
            goodieBag: payload.merchantData.goodiebagId || null,
            merchant: payload.merchantData.merchantId || null,
            gamificationCampaign:
              payload.merchantData.gamificationCampaignId || null,
            trackingCodes: payload.data?.map((item) => ({
              trackingCode: item.name.toUpperCase(),
              isFollowGoodieBagActivePeriod: item.isFollowGoodieBag || false,
              startDate: item.startDate,
              endDate: item.endDate,
            })),
          };

          serviceArgs = [createEntitlementTrackingCode, inputBody];
        }

        function onSuccess(data) {
          console.log('241', data);
          const errors = payload.errors || {};
          (data.createEntitlementTrackingCode?.errors || []).forEach((item) => {
            if (
              item.field === 'tracking_code' &&
              item.messages?.[0] !== 'Please input valid code.'
            ) {
              errors[item.index] = [...(errors[item.index] || []), 'nameUsed'];
            }
          });
          afterAction(errors);
        }

        function onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getTrackingCodeCount: [
      function* ({ payload }, { select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [getTrackingCodeCount, payload];
        function* onSuccess(data) {
          const trackingCodeCount = data?.entitlementTrackingCodeCount?.reduce(
            function (obj, item) {
              return {
                ...obj,
                [item.merchantId]: item,
              };
            },
            {},
          );
          yield put({
            type: 'updateState',
            payload: {
              trackingCodeCount,
            },
          });
          afterAction();
        }
        function* onFailure(data) {
          console.log('onFailure', data);
        }
        yield loading(serviceArgs, onSuccess, onFailure, onFailure);
      },
      { type: 'takeLatest' },
    ],
  },
});
