import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { useDispatch, useSelector } from 'react-redux';
import { GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE } from '../../../models/GamificationCampaignModel';
import { ArrayStringData, createAction } from '../../../utils';
// import './Filter.scss';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchEntitlementType = parsedSearch['entitlement_type'] || '';
  const searchLandingPage = parsedSearch['landing_page'] || '';
  // const searchPublishStatus = parsedSearch['status'] || '';
  const searchStatusIn = parsedSearch['status_in'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisablePeriod = parsedSearch['visible_period'] || '';
  const searchCreateAt = parsedSearch['create_at'] || '';
  const searchLastModified = parsedSearch['last_modified'] || '';

  const [entitlementType, setEntitlementType] = useState(searchEntitlementType);
  const [landingPage, setLandingPage] = useState(searchLandingPage);
  // const [status, setStatus] = useState(searchPublishStatus);
  const [statusIn, setStatusIn] = useState(ArrayStringData(searchStatusIn));
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visablePeriod, setVisablePeriod] = useState(searchVisablePeriod);
  const [createAt, setCreateAt] = useState(searchCreateAt);
  const [lastModified, setLastModified] = useState(searchLastModified);

  const content = [
    {
      title: 'Entitlement Type',
      data: [
        {
          pk: 'public',
          name: 'Public',
        },
      ].concat(
        Object.values(GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE).map((item) => {
          return {
            pk: item.value,
            name: item.label,
          };
        }),
      ),
      value: entitlementType,
      setValue: setEntitlementType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    // {
    //   title: 'Landing page',
    //   data: [
    //     {
    //       pk: 'true',
    //       name: 'On',
    //     },
    //     {
    //       pk: 'false',
    //       name: 'Off',
    //     },
    //   ],
    //   value: landingPage,
    //   setValue: setLandingPage,
    //   componment: FilterTypeEnum.singleChoice,
    //   className: 'fit-content-height-area',
    // },
    {
      displayTitle: 'Status',
      title: 'Status in',
      data: [
        {
          title: 'Published',
          name: 'Published',
          pk: 'true',
          key: 'true',
          children: [
            {
              title: 'Ative',
              name: 'Ative',
              pk: 'active',
              key: 'active',
            },
            {
              title: 'Expired',
              name: 'Expired',
              pk: 'expired',
              key: 'expired',
            },
            {
              title: 'Upcoming',
              name: 'Upcoming',
              pk: 'upcoming',
              key: 'upcoming',
            },
          ],
        },
        {
          title: 'Unpublished',
          name: 'Unpublished',
          pk: 'false',
          key: 'false',
          children: [
            {
              title: 'Inactive',
              name: 'Inactive',
              pk: 'inactive',
              key: 'inactive',
            },
          ],
        },
      ],
      value: statusIn,
      setValue: setStatusIn,
      componment: FilterTypeEnum.searchable2LevelChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      value: visablePeriod,
      setValue: setVisablePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Create at',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Last modified',
      value: lastModified,
      setValue: setLastModified,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
