import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import {
  LanguageConfig,
  EMAIL_REG,
  StoreType,
} from '../../../config/CustomEnums';
import {
  hasError,
  errorMessage,
} from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import { useHistory } from 'react-router-dom';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';
import {
  CustomNumberInput,
  CustomTitleLabel,
} from '../../../components/earning/CustomBaseComponments';
import SearchSemTagInput from '../../../components/campaign/campaignCreation/SearchSemTagInput';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';

export default function KeyVisualSection({
  language,
  languages,
  attributeTagList,
}) {
  const history = useHistory();
  const { register, watch, getValues, setValue, control, formState } =
    useFormContext();
  const { errors } = formState;

  const translations = watch('storeTranslations') || {};
  const watchTags = watch('attributeTags');

  const [promptConfig, setPromtConfig] = useState(false);

  return (
    <>
      <div className="first-section-title">Tag</div>
      <Controller
        control={control}
        name="attributeTags"
        render={() => (
          <>
            <CustomTitleLabel title={'Attribute tag (Optional)'} />
            <BaseMutipleSelectorV2
              hideTopSpace={true}
              searchPlaceholder="Search by key tag, attribute tag"
              namespace="attributeTag"
              addButton={{
                title: 'Add Attribute tag',
                action: () =>
                  setPromtConfig({
                    rightButton: {
                      text: `Go to create attribute tag`,
                      action: () => {
                        setPromtConfig(null);
                        history.push({
                          pathname: '/tags/create',
                          state: {
                            from: history.location,
                            title: 'Continue to create location?',
                            description:
                              'You can continue to create the location.',
                          },
                        });
                      },
                    },
                    title: `Go to create a attribute tag?`,
                    description: `You will leave the location creation process. After you create a new attribute tag, you can back to this page.`,
                  }),
                customClass: 'btn-back-button-common add-remove-botton-style',
              }}
              custom={{
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  const groupIgnoreCase = option?.tagKey?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    groupIgnoreCase?.includes(inputIgnoreCase)
                  );
                },
              }}
              data={{
                sourceData: attributeTagList,
                targetData: watchTags,
                targetChange: (value) => {
                  setValue('attributeTags', value, { shouldDirty: true });
                },
              }}
              groupKey="tagKey"
            />
          </>
        )}
      />
      <SearchSemTagInput />
      <BasePrompt
        show={!!promptConfig}
        closeAction={() => {
          setPromtConfig(null);
        }}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        title={promptConfig?.title}
        description={promptConfig?.description}
      />
    </>
  );
}
