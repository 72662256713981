// import { MISSION_TYPE } from '../../models/GamificationCampaignModel';
import { isArrayEmpty, getFieldValue } from '../../utils';

export const GamificationCampaignErrorHandleField = {
  name: {
    name: 'name',
    message: 'Please provide name and ensure the length not more than 200.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover Image.',
  },
  endDate: {
    name: 'endDate',
    message:
      'The end date & time cannot be on or before the start date and time.',
  },
  displayEndDate: {
    name: 'displayEndDate',
    message:
      'The end date & time cannot be on or before the start date and time.',
  },
  commonField: {
    message1: 'This field is required',
    message2: 'Please provide value and ensure the length not more than 200.',
    message3: 'Please input a number > 0.',
    message4: 'Please provide a image.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const errorList = [];
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const [
    translations,
    name,
    showMissionLabels,
    missionLabels,
    description,
    coverPhoto,
    coverPhotoForTaskList,
    tutorials,
    rewardInformations,
    otherInformations,
  ] = getValues([
    'translations',
    'translations.en.name',
    'showMissionLabels',
    'translations.en.missionLabels',
    'translations.en.shortDescription',
    'translations.en.coverPhoto',
    'translations.en.coverPhotoForTaskList',
    'translations.en.tutorials',
    'translations.en.rewardInformations',
    'translations.en.otherInformations',
  ]);
  const gamificationCampaign = getValues() || {};
  console.log('@GamificationCampaign validate2', gamificationCampaign);
  const errorList = [];
  if (!name || name === '' || name.length > 200) {
    errorList.push({
      name: 'translations.en.name',
      message: GamificationCampaignErrorHandleField.name.message,
    });
  }
  if (
    showMissionLabels &&
    Object.values(missionLabels || {}).filter(
      (item) =>
        !item.isDeleted &&
        (!item.title || item.title === '' || item.title.length > 20),
    ).length
  ) {
    errorList.push({
      name: 'translations.en.missionLabels',
      message: 'Please input less than 20 characters.',
    });
  }
  if (!description) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: GamificationCampaignErrorHandleField.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: GamificationCampaignErrorHandleField.coverPhoto.message,
    });
  }

  if (!coverPhotoForTaskList) {
    errorList.push({
      name: 'translations.en.coverPhotoForTaskList',
      message: GamificationCampaignErrorHandleField.coverPhoto.message,
    });
  }

  ['translations.en.tutorialTitle', 'translations.en.tutorialContent'].forEach(
    (fieldName) => {
      const fieldValue = getFieldValue(gamificationCampaign, fieldName);
      if (!fieldValue) {
        errorList.push({
          name: fieldName,
          message: GamificationCampaignErrorHandleField.commonField.message1,
        });
      }
    },
  );
  if (tutorials) {
    const temp = Object.keys(tutorials).map((key, index) => {
      const tutorial = tutorials[key] || {};
      if (tutorial.isDeleted) {
        return;
      }
      if (!tutorial.image) {
        errorList.push({
          name: `translations.en.tutorials.${key}.image`,
          message: GamificationCampaignErrorHandleField.commonField.message4,
        });
      }
      if (!tutorial.content) {
        errorList.push({
          name: `translations.en.tutorials.${key}.content`,
          message: GamificationCampaignErrorHandleField.commonField.message1,
        });
      }
    });
  }

  // if (rewardInformations) {
  //   const temp = Object.keys(rewardInformations).map((key, index) => {
  //     const rewardInformation = rewardInformations[key] || {};
  //     if (rewardInformation.isDeleted) {
  //       return;
  //     }
  //     if (!rewardInformation.image) {
  //       errorList.push({
  //         name: `translations.en.rewardInformations.${key}.image`,
  //         message: GamificationCampaignErrorHandleField.commonField.message4,
  //       });
  //     }
  //   });
  // }

  if (otherInformations) {
    const temp = Object.keys(otherInformations).map((key, index) => {
      const otherInformation = otherInformations[key] || {};
      if (otherInformation.isDeleted) {
        return;
      }
      // if (index > 2 && !otherInformation.image) {
      //   errorList.push({
      //     name: `translations.en.otherInformations.${key}.image`,
      //     message: GamificationCampaignErrorHandleField.commonField.message4,
      //   });
      // }
      if (otherInformation.title && !otherInformation.content) {
        errorList.push({
          name: `translations.en.otherInformations.${key}.content`,
          message: GamificationCampaignErrorHandleField.commonField.message1,
        });
      } else if (!otherInformation.title && otherInformation.content) {
        errorList.push({
          name: `translations.en.otherInformations.${key}.title`,
          message: GamificationCampaignErrorHandleField.commonField.message1,
        });
      }
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [translations, prizeCategories] = getValues([
    'translations',
    'translations.en.prizeCategories',
  ]);
  const gamificationCampaign = getValues() || {};
  console.log('@GamificationCampaign validate3', gamificationCampaign);
  const errorList = [];
  // if (
  //   Object.values(prizeCategories || {}).filter(
  //     (item) => !item.isDeleted && (!item || item === ''),
  //   ).length
  // ) {
  //   errorList.push({
  //     name: 'translations.en.prizeCategories',
  //     message: GamificationCampaignErrorHandleField.commonField.message1,
  //   });
  // }
  // ['translations.en.prizeTitle'].forEach((fieldName) => {
  //   const fieldValue = getFieldValue(gamificationCampaign, fieldName);
  //   if (!fieldValue || fieldValue === '' || fieldValue.length > 200) {
  //     errorList.push({
  //       name: fieldName,
  //       message: GamificationCampaignErrorHandleField.commonField.message2,
  //     });
  //   }
  // });
  [
    // 'translations.en.prizeContent',
    // 'translations.en.coverPhotoForPrize',
    // 'translations.en.prizeTermsAndConditionsTitle',
    // 'translations.en.prizeTermsAndConditionsContent',
    'stampCount',
    'stampCardResetLimit',
  ].forEach((fieldName) => {
    const fieldValue = getFieldValue(gamificationCampaign, fieldName);
    if (!fieldValue) {
      errorList.push({
        name: fieldName,
        message: GamificationCampaignErrorHandleField.commonField.message1,
      });
    } else {
      if (fieldName === 'stampCardResetLimit') {
        if (parseInt(fieldValue) <= 0) {
          errorList.push({
            name: fieldName,
            message: GamificationCampaignErrorHandleField.commonField.message3,
          });
        }
      }
    }
  });

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function fourthStepValidate({ getValues, setError }) {
  const errorList = [];
  const [
    // missionSectionTitle,
    // missionSectionDescription,
    missionCardTitle,
    missionCardSubtitle,
    isAlwaysActivePeriod,
    startDate,
    endDate,
    isAlwaysVisiblePeriod,
    displayStartDate,
    displayEndDate,
  ] = getValues([
    // 'translations.en.missionSectionTitle',
    // 'translations.en.missionSectionDescription',
    'translations.en.missionCardTitle',
    'translations.en.missionCardSubtitle',
    'isAlwaysActivePeriod',
    'startDate',
    'endDate',
    'isAlwaysVisiblePeriod',
    'displayStartDate',
    'displayEndDate',
  ]);
  const gamificationCampaign = getValues() || {};
  console.log('@GamificationCampaign validate4', gamificationCampaign);
  if (
    !(
      // missionSectionTitle ||
      // missionSectionDescription ||
      (missionCardTitle || missionCardSubtitle)
    )
  ) {
    errorList.push({
      name: 'translations.en.missionCardTitle',
      message: GamificationCampaignErrorHandleField.commonField.message1,
    });
  }

  if (!isAlwaysActivePeriod && startDate.getTime() >= endDate.getTime()) {
    errorList.push({
      name: 'endDate',
      message: GamificationCampaignErrorHandleField.endDate.message,
    });
  }
  if (
    !isAlwaysVisiblePeriod &&
    displayStartDate.getTime() >= displayEndDate.getTime()
  ) {
    errorList.push({
      name: 'displayEndDate',
      message: GamificationCampaignErrorHandleField.displayEndDate.message,
    });
  }

  [
    'translations.en.coverPhotoForMissionSection',
    'translations.en.mobilePhotoForMissionSection',
    'translations.en.missionSectionTitle',
    'translations.en.missionSectionDescription',
    // 'translations.en.missionCardTitle',
    // 'translations.en.missionCardSubtitle',
  ].forEach((fieldName) => {
    const fieldValue = getFieldValue(gamificationCampaign, fieldName);
    if (!fieldValue) {
      errorList.push({
        name: fieldName,
        message: 'This field is required',
      });
    }
  });

  console.log('errors3:', errorList);
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    case 3:
      const validStepFour = fourthStepValidate({ getValues, setError });
      return validStepFour;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      const validFour = fourthStepValidate({ getValues, setError });
      return validOne && validTwo && validThree && validFour;
  }
}
