import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TablePrompt from '../base/prompt/TablePrompt';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import Filter from '../../containers/engagement/goodieBags/Filter';

export default function GoodiebagTablePrompt(props) {
  const dispatch = useDispatch();
  const params = useParams();

  const onCancel = props.onCancel || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const title = props.title || 'Select Goodie bag';
  const table = props.table || {
    title: 'After checking, click confirm to complete the selection',
    fields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      {
        displayName: 'Entitlement method',
        fieldName: 'entitlementMethodDisplay',
      },
      { displayName: 'Reward type', fieldName: 'displayRewardType' },
      {
        displayName: 'Valid goodie bag',
        fieldName: 'validGoodiebagNumber',
      },
      {
        displayName: 'Per head limit',
        fieldName: 'perHeadLimit',
      },
      {
        displayName: 'Active Period',
        fieldName: 'activePeriodList',
        orderField: 'startDate',
      },
      {
        displayName: 'Visible Period',
        fieldName: 'visiblePeriodList',
        orderField: 'displayStartDate',
      },
      {
        displayName: 'Status',
        fieldName: 'displayStatus',
      },
    ],
  };

  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { pagedList, pageInfo } = useSelector((state) => ({
    pagedList: state.goodieBags.pagedList,
    pageInfo: state.goodieBags.pageInfo,
  }));

  const baseSearch = {
    status: true,
    excludeInOtherMission: params?.id || 0,
    needQuantity: true,
  };

  useEffect(() => {
    setDataList([]);
    setIsLoading(true);
    setIsLoadingMore(false);
    dispatch({ type: 'goodieBags/clearData' });
    if (props.show) {
      dispatch(
        createAction('goodieBags/getList')({
          ...baseSearch,
          sort: '-pk',
          afterAction: () => {
            setIsLoading(false);
          },
        }),
      );
    }
  }, [props.show]);

  useEffect(() => {
    setDataList([...dataList, ...pagedList]);
    if (isLoadingMore) {
      setIsLoading(false);
    }
  }, [pagedList]);

  return (
    <TablePrompt
      show={props.show}
      customClass="goodie-bag-table-prompt"
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={onConfirm}
      title={title}
      table={{
        ...table,
        dataList,
        isLoading,
        selectedData: props.selectedData || [],
        loadMore: (page, search) => {
          setIsLoading(true);
          setIsLoadingMore(true);
          dispatch(
            createAction('goodieBags/getList')({
              ...baseSearch,
              ...search,
              page: page + 1,
            }),
          );
        },
        hasNext: pageInfo.hasNextPage,
        filter: {
          filterComponent: Filter,
          filetrItems: [
            'Entitlement method',
            'Active Period',
            'Visible Period',
          ],
          keywordSearch: {
            keys: [
              {
                placeholder: 'Search by ID & name',
                search: 'search',
              },
            ],
          },
          searchFunc: (search) => {
            setIsLoading(true);
            dispatch(
              createAction('goodieBags/getList')({
                ...baseSearch,
                ...search,
                page: 1,
              }),
            );
            setDataList([]);
          },
        },
      }}
    />
  );
}
