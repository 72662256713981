import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import BaseForm from '../base/v2/BaseForm';
import ContentSections from '../base/ContentSections';
import { getError, createAction, isNumber } from '../../utils';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { SavedStatus, LanguageConfig } from '../../config/CustomEnums';
import BasePrompt from '../base/prompt/BasePrompt';
import PromptButton from '../base/prompt/BaseButtons';
import { BUTTON_TYPES } from '../base/prompt/BaseButtons';
import CoverPhotoSection from './CoverPhotoSection';
import { GAMIFICATION_CAMPAIGN_REWARD_TYPE } from '../../models/GamificationCampaignModel';
import CustomRadios from '../base/CustomRadios';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import {
  CustomTitleLabel,
  CustomNumberInput,
} from '../earning/CustomBaseComponments';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import AuthButton from '../base/AuthButton';
import { ApplySwitch } from '../base/LabelWithSwitch';
import { insertKeyToAllLanguages } from '../../utils';
import DeleteRowIcon from '../../assets/images/deleteIcon.png';
import CustomEditor from '../base/CustomEditor';
import { ImageButton } from '../base/CustomImageButton';

function PrizeSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch('translations') || {};
  const rewardType =
    watch('rewardType') || GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value;
  const applyCoverPhotoForPrizeToAllLanguage = watch(
    'applyCoverPhotoForPrizeToAllLanguage',
  );
  const coverPhotoForPrize = translations[language]?.coverPhotoForPrize || '';
  const applyMobilePhotoForPrizeToAllLanguage = watch(
    'applyMobilePhotoForPrizeToAllLanguage',
  );
  const mobilePhotoForPrize = translations[language]?.mobilePhotoForPrize || '';
  const prizeCategories = translations[language]?.prizeCategories;
  const prizeCategoriesKeys = watch('prizeCategoriesKeys') || [
    ...Array(1).keys(),
  ];

  return (
    <div className="create-gamification-campaign-prize-list-container">
      <div className="first-section-title">Prize List</div>
      <Controller
        control={control}
        name={`translations.${language}.pk`}
        render={() => () => {}}
      />
      <Controller
        control={control}
        name={`translations.${language}.prizeTitle`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Prize name (optional)"
              tips={
                rewardType ===
                GAMIFICATION_CAMPAIGN_REWARD_TYPE.GOODIE_BAG.value
                  ? 'If not inputted, will show the goodie bag name in frontend.'
                  : 'If not inputted, will show the coupon set name in frontend.'
              }
              extra={{ maxLength: 200 }}
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`translations.${language}.prizeTitle`, val, {
                  shouldDirty: true,
                });
              }}
              error={{
                id: `translations.${language}.prizeTitle`,
                error: hasError(errors, `translations.${language}.prizeTitle`),
                message: errorMessage(
                  errors,
                  `translations.${language}.prizeTitle`,
                ),
              }}
            />
          </>
        )}
      />
      <div className={'create-section-label create-section-label-bottom-space'}>
        Prize description (optional)
      </div>
      <label className="tips-message">
        {rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.GOODIE_BAG.value
          ? 'If not inputted, will show the goodie bag short description in frontend.'
          : 'If not inputted, will show the coupon set short description in frontend.'}
      </label>
      <Controller
        control={control}
        name={`translations.${language}.prizeContent`}
        render={({ field: { value } }) => (
          <>
            {/* <CustomEditor
              initialValue={value}
              onValueChange={(value) => {
                setValue(`translations.${language}.prizeContent`, value, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {}}
              errorMessage={errorMessage(
                errors,
                `translations.${language}.prizeContent`,
              )}
              error={hasError(errors, `translations.${language}.prizeContent`)}
            /> */}
            <textarea
              onChange={({ target }) => {
                setValue(
                  `translations.${language}.prizeContent`,
                  target.value,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              className="custom-textarea-remakrs"
              onFocus={() => {}}
              value={value}
              placeholder=""
            />
          </>
        )}
      />

      <div className="d-flex flex-column">
        <div
          className={'create-section-label create-section-label-bottom-space'}
        >
          Prize tag (optional)
        </div>
        {prizeCategoriesKeys
          ?.filter((key) => !prizeCategories?.[key]?.isDeleted)
          ?.map((key, index) => {
            return (
              <div key={`prize-category-${key}`}>
                <Controller
                  control={control}
                  name={`translations.${language}.prizeCategories.${key}.isDeleted`}
                  render={() => () => {}}
                />
                <Controller
                  control={control}
                  name={`translations.${language}.prizeCategories.${key}.title`}
                  render={({ field: { value } }) => {
                    return (
                      <div className="prize-category-container">
                        <CustomTitleLabel title={`#${index + 1}`} />
                        <input
                          type={'text'}
                          maxLength={200}
                          onChange={({ target }) =>
                            setValue(
                              `translations.${language}.prizeCategories.${key}.title`,
                              target.value,
                              {
                                shouldDirty: true,
                              },
                            )
                          }
                          value={value}
                          className={`custom-markdown-area-title custom-markdown-area-title-short`}
                        />
                        {/* <CustomTitleWithInput
                          title={`#${index + 1}`}
                          defaultValue={value}
                          useDefaultValue={false}
                          setValue={(val) => {
                            setValue(
                              `translations.${language}.prizeCategories.${key}.title`,
                              val,
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                        /> */}
                        {index > 0 ? (
                          <ImageButton
                            action={() => {
                              const temp = languages?.forEach((item) => {
                                setValue(
                                  `translations.${item.code}.prizeCategories.${key}.isDeleted`,
                                  true,
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              });
                            }}
                            image={DeleteRowIcon}
                            customClass="delete-icon"
                          />
                        ) : (
                          <ImageButton
                            action={() => {}}
                            image={DeleteRowIcon}
                            customClass="delete-icon hidden"
                          />
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            );
          })}
        <ErrorFieldMessage
          id={`translations.${language}.prizeCategories`}
          error={hasError(errors, `translations.${language}.prizeCategories`)}
          message={errorMessage(
            errors,
            `translations.${language}.prizeCategories`,
          )}
        />
      </div>
      <AuthButton
        title={'Add category'}
        customClass={'general-section-add-new btn-add-button-common'}
        action={() => {
          const lastKey = prizeCategoriesKeys[prizeCategoriesKeys.length - 1];
          setValue(
            'prizeCategoriesKeys',
            prizeCategoriesKeys.concat([parseInt(lastKey) + 1]),
            {
              shouldDirty: true,
            },
          );
        }}
      />

      <div className="cover-images-for-prize-list">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Prize image for desktop (optional)'}
          suggest={
            'If not inputted, will show the goodie bag cover image in frontend.  Suggested Image Size: 1920px*1440px At least: 488px*366px (Less than 2MB, support JPG, PNG and GIF only)'
          }
          minWidth={488}
          minHeight={366}
          photoKey={'coverPhotoForPrize'}
          aspect={480 / 360}
          applyToAllLanguage={applyCoverPhotoForPrizeToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english && !coverPhotoForPrize?.value
        // }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoForPrizeToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoForPrizeToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            languages.forEach((languageItem) => {
              setValue(
                `translations.${languageItem.code}.coverPhotoForPrize`,
                coverPhotoForPrize,
                {
                  shouldDirty: true,
                },
              );
            });
          }
        }}
      />
      <div className="cover-images-for-prize-list">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Prize image for mobile (optional)'}
          suggest={
            'If not inputted, will show the goodie bag cover image in frontend.  Suggested Image Size: 1125px*633px At least: 375px*211px (Less than 2MB, support JPG, PNG and GIF only)'
          }
          minWidth={375}
          minHeight={211}
          photoKey={'mobilePhotoForPrize'}
          aspect={375 / 211}
          applyToAllLanguage={applyMobilePhotoForPrizeToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english && !coverPhotoForPrize?.value
        // }
        show={language === LanguageConfig.english}
        checked={applyMobilePhotoForPrizeToAllLanguage}
        onChange={(value) => {
          setValue('applyMobilePhotoForPrizeToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            languages.forEach((languageItem) => {
              setValue(
                `translations.${languageItem.code}.mobilePhotoForPrize`,
                mobilePhotoForPrize,
                {
                  shouldDirty: true,
                },
              );
            });
          }
        }}
      />
    </div>
  );
}

function EditStampRewardContent({ onConfirm = () => {}, onClose = () => {} }) {
  const { formState, watch, setValue, getValues, setError, control, trigger } =
    useFormContext();
  const errors = formState.errors;

  const { couponTemplateList, goodieBagList, languageList } = useSelector(
    (state) => ({
      couponTemplateList: state.couponList.couponTemplateList,
      goodieBagList: state.goodieBags.allList,
      languageList: state.language.allList,
    }),
  );

  const translations = watch('translations') || {};
  const rewardType =
    watch('rewardType') || GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value;
  const couponTemplate = watch('couponTemplate');
  const goodieBag = watch('goodieBag');
  const quantity = watch('quantity');
  const rewardIcon = watch('rewardIcon');
  const rewardIconCollected = watch('rewardIconCollected');

  const buttonDisabled =
    !rewardType ||
    // !quantity ||
    (rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value &&
      !couponTemplate) ||
    (rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.GOODIE_BAG.value &&
      !goodieBag);

  return (
    <>
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex', marginTop: 12 }}>
        <CustomRadios
          options={Object.values(GAMIFICATION_CAMPAIGN_REWARD_TYPE)}
          default={rewardType}
          onChange={(value) => {
            setValue(`rewardType`, value, {
              shouldDirty: true,
            });
          }}
        />
      </div>
      {rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value ? (
        <>
          <CustomTitleWithDropDown
            title={'Select coupon'}
            source={couponTemplateList}
            defaultValue={{
              value: couponTemplate,
              label: couponTemplate?.label || couponTemplate?.name,
            }}
            setValue={(value) =>
              setValue(`couponTemplate`, value?.value, {
                shouldDirty: true,
              })
            }
            loadMoreAction={'couponList/getCurrentPageTemplateList'}
            filterAction={'couponList/getCurrentPageTemplateList'}
            defaultFilter={{
              getStock: true,
              noNeedBrand: true,
              all: true,
              pageSize: 20,
              status: 'active',
            }}
            labelContainPk={true}
          />
        </>
      ) : null}
      {rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.GOODIE_BAG.value ? (
        <>
          <CustomTitleWithDropDown
            title={'Select goodie bag'}
            source={goodieBagList}
            defaultValue={{
              value: goodieBag,
              label: goodieBag?.label || goodieBag?.name,
            }}
            setValue={(value) =>
              setValue(`goodieBag`, value?.value, {
                shouldDirty: true,
              })
            }
            loadMoreAction={'goodieBags/getList'}
            filterAction={'goodieBags/getList'}
            defaultFilter={{
              callFromGamificationCampaign: true,
              needQuantity: true,
              status: true,
              // type: 'CASH_VOUCHER,GIFT,BOTH',
            }}
          />
        </>
      ) : null}
      <CustomTitleLabel
        title={
          rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value
            ? 'Coupon set quantity'
            : 'Goodie bag quantity'
        }
      />
      <CustomNumberInput
        isNumberOnly={true}
        setValue={(value) => {
          setValue(`quantity`, value, {
            shouldDirty: true,
          });
        }}
        defaultValue={quantity}
        errorId="quantity"
        error={hasError(errors, 'quantity')}
        errorMessage={errorMessage(errors, 'quantity')}
      />
      <div className="cover-images-for-stamp-reward-icons">
        <CoverPhotoSection
          title={'Reward icon - before collected'}
          suggest={
            'If uploading an image, suggested lmage Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, supportIPG, PNG and GlF only.) lf uploading JSON, suggested aspect ratio of the animation is align with the image asset: 300 px*300 px (Language versions of this asset is required.)'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'rewardIcon'}
          value={rewardIcon}
          aspect={1}
          setCustomValue={(value) =>
            setValue(`rewardIcon`, value, {
              shouldDirty: true,
            })
          }
        />
        <CoverPhotoSection
          title={'Reward icon - collected'}
          suggest={
            'If uploading an image, suggested image Size: 300 px * 300 px. At least : 120 px * 120 px (Less than 2MB, supportIPG, PNG and GlF only.) lf uploading JSON, suggested aspect ratio of the animation is align with the image asset: 300 px*300 px (Language versions of this asset is required.)'
          }
          minWidth={120}
          minHeight={120}
          photoKey={'rewardIconCollected'}
          value={rewardIconCollected}
          aspect={1}
          setCustomValue={(value) =>
            setValue(`rewardIconCollected`, value, {
              shouldDirty: true,
            })
          }
        />
      </div>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: [
                <PrizeSection
                  language={language.code}
                  languages={languageList}
                />,
              ],
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <div className={`base-prompt-buttons`}>
        <PromptButton
          title={'Cancel'}
          action={() => onClose()}
          type={BUTTON_TYPES.secondary}
        />
        <PromptButton
          title={'Confirm'}
          disabled={buttonDisabled}
          action={async () => {
            const errorList = [];
            let isValid = true;
            const newStampReward = getValues();
            if (!newStampReward.rewardType) {
              newStampReward.rewardType =
                GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value;
            }
            if (!newStampReward.quantity) {
              errorList.push({
                name: 'quantity',
                message: 'Please provide a quantity.',
              });
            }
            errorList.forEach((error) => {
              setError(error.name, {
                type: 'manual',
                message: error.message,
              });
            });
            isValid = !errorList.length;
            console.log('@@68: ', isValid, newStampReward);
            if (isValid) {
              onConfirm(newStampReward);
              onClose();
            }
          }}
          type={BUTTON_TYPES.main}
        />
      </div>
    </>
  );
}

function EditStampRewardPrompt({
  show,
  stampReward,
  onConfirm = () => {},
  onClose = () => {},
}) {
  const [formHasSubmitted, setFormHasSubmitted] = useState(false);
  const [hasUpdatedDefaultValues, setHasUpdatedDefaultValues] = useState(false);

  useEffect(() => {
    if (show) {
      setFormHasSubmitted(false);
      setHasUpdatedDefaultValues(false);
    }
  }, [show]);

  const getBody = () => {
    return (
      <>
        <BaseForm
          defaultValues={stampReward}
          formHasSubmitted={formHasSubmitted}
          hasUpdatedDefaultValues={hasUpdatedDefaultValues}
          content={[
            <EditStampRewardContent onClose={onClose} onConfirm={onConfirm} />,
          ]}
          currentStep={0}
          caution={{
            detail: '',
            title: 'Create Stamp reward',
          }}
          hideHeader={true}
        />
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      customClass={'edit-stamp-reward-prompt'}
      closeAction={onClose}
      title={'Edit slot'}
      otherBody={getBody}
    />
  );
}

export default EditStampRewardPrompt;
