import { createModel } from './BaseModel';
import {
  getSearchSettings,
  updateSearchSettings,
} from '../services/SearchSettingApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { SESSION_KEYS, APIStatus } from '../config/CustomEnums';
import { convertPKToId } from '../utils';

export const SEARCH_SETTING_TYPE = {
  CAMPAIGN: 'Campaign search for goodie bags & deals',
  GAMIFICATION_MIXED: 'Gamification - mixed task search',
  MISSION_CAMPAIGN: 'Mission - redeem specific coupons task search',
  MISSION_CHECK_IN: 'Mission - check-in task search',
};

export const REVERT_SEARCH_SETTING_TYPE = {
  'Campaign search for goodie bags & deals': 'CAMPAIGN',
  'Gamification - mixed task search': 'GAMIFICATION_MIXED',
  'Mission - redeem specific coupons task search': 'MISSION_CAMPAIGN',
  'Mission - check-in task search': 'MISSION_CHECK_IN',
};

const parseType = (type) => {
  let displayType = '';
  if (type in SEARCH_SETTING_TYPE) {
    displayType = SEARCH_SETTING_TYPE[type];
  }
  return displayType;
};
export const revertParseType = (displayType) => {
  let type = '';
  if (displayType in REVERT_SEARCH_SETTING_TYPE) {
    type = REVERT_SEARCH_SETTING_TYPE[displayType];
  }
  return type;
};

const parseDetail = (data) => {
  return {
    ...data,
    searchSettingType: parseType(data.searchSettingType),
  };
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Items',
      fieldName: 'searchSettingType',
    },
  ],
  pagedList: [
    {
      pk: 3,
      searchSettingType: SEARCH_SETTING_TYPE.MISSION_CHECK_IN,
    },
    {
      pk: 2,
      searchSettingType: SEARCH_SETTING_TYPE.MISSION_CAMPAIGN,
    },
    // {
    //   pk: 2,
    //   searchSettingType: SEARCH_SETTING_TYPE.GAMIFICATION_MIXED,
    // },
    {
      pk: 1,
      searchSettingType: SEARCH_SETTING_TYPE.CAMPAIGN,
    },
  ],
  detail: {},
  totalCount: 4,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 1,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
});

export default createModel({
  namespace: 'searchSetting',
  params: {
    sessionKey: SESSION_KEYS.SEARCH_SETTING_SESSION_KEY,
    dataKey: SESSION_KEYS.SEARCH_SETTING_DATA_SESSION_KEY,
    initState: {},
    dataKey: 'searchSetting',
  },
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getOneDetail: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        const afterGet = payload?.afterAction || (() => {});
        const serviceArgs = [getSearchSettings, payload?.searchSettingType];
        function* onSuccess(data) {
          const detail = parseDetail(data.campaignSearchFieldSettings);
          afterGet(detail);
          yield all([
            put({
              type: 'updateState',
              payload: {
                detail: detail,
                hasUpdatedDefaultValues: true,
                apiStatus: APIStatus.success,
              },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateSearchSettings: [
      function* ({ payload }, { put, call }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            },
          });
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        const serviceArgs = [updateSearchSettings, payload];
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
