import React, { useState, useEffect } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction, checkHasPermission } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import EditMissionLocationPrompt from '../../../components/mission/EditMissionLocationPrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  DELETE_RELATED_SECTIONS,
  StatusTag,
} from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ImportResource } from '../../../models/DownloadImportModel';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CustomRadios from '../../../components/base/CustomRadios';
import { Row } from 'react-bootstrap';
import queryString from 'query-string';
import { MISSION_CHECK_IN_MODE_TYPE } from '../../../models/MissionModel';

function MissionLocationList({
  mission,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
  importedFile,
  user,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  // const hasLocaionPermission =
  //   [PermissionCodes.viewMission, PermissionCodes.changeMission].filter(
  //     (permissionItem) => checkHasPermission(user, permissionItem),
  //   ).length > 0;
  const hasChangeLocaionPermission = checkHasPermission(
    user,
    PermissionCodes.changeMission,
  );
  // const hasMissionLocationQrcodePermission =
  //   [
  //     // PermissionCodes.viewMissionLocationQrcode,
  //     PermissionCodes.changeMissionLocationQrcode,
  //   ].filter((permissionItem) => checkHasPermission(user, permissionItem))
  //     .length > 0;
  const displayQRCode = [
    MISSION_CHECK_IN_MODE_TYPE.QR_CODE.value,
    MISSION_CHECK_IN_MODE_TYPE.BOTH.value,
  ].includes(mission?.checkInMode);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [includeFilter] = useState({ isInclude: false });
  const [selectedMissonLocation, setSelectedMissonLocation] = useState();
  const [showEditMissonLocationPrompt, setShowEditMissonLocationPrompt] =
    useState(false);

  const queryList = () => {
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const status = querySearch['status'];
      const page = parseInt(stringPage);
      const queryData = {
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
        fromLocation: true,
        missionIn: params?.id,
      };
      if (status) {
        queryData.isPublished = status;
      }
      dispatch(createAction('missionLocation/getList')(queryData));
    }
  };

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeMission,
    },
  ];

  useEffect(() => {
    dispatch({ type: 'missionLocation/clearData' });
    queryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        tabs={[]}
        groupActions={groupActions}
        pageInfo={pageInfo}
        totalCount={totalCount}
        filter={{
          hasFilter: false,
        }}
        listContent={
          <BaseListContainer
            fields={[
              { displayName: 'ID', fieldName: 'locationPK' },
              {
                displayName: 'Location',
                fieldName: 'locationName',
                orderField: 'locationName',
                linked: true,
                linkedPathField: 'locationLink',
              },
              ...(displayQRCode
                ? [
                    {
                      displayName: 'QR code',
                      fieldName: 'qrCodeString',
                      hideDropdown: true,
                    },
                    {
                      displayName: 'QR code string',
                      fieldName: 'code',
                    },
                  ]
                : []),
              // {
              //   displayName: 'Display order',
              //   fieldName: 'displayPriority',
              //   orderField: 'displayPriority',
              // },
              {
                displayName: 'Featured',
                fieldName: 'displayIsFeatured',
                orderField: 'isFeatured',
              },
              { displayName: 'Status', fieldName: 'status' },
            ]}
            dataList={dataList}
            totalPage={totalPage}
            model={'missionLocation'}
            permissionGroup={PermissionCodes.mission}
            deleteInfo={{
              data: [],
              title: 'qr code',
              relatedName: '',
              onComfirm: {},
            }}
            // sortable
            // sortDisabled={!hasChangeLocaionPermission}
            sortable={false}
            sortDisabled={true}
            rankDisabled
            // onSortEnd={(oldIndex, newIndex) => {
            //   if (oldIndex === newIndex) return;
            //   const startOrder = dataList[0].order;
            //   const movedItem = dataList[oldIndex];
            //   dispatch(
            //     createAction('missionLocation/updateMissionLocation')({
            //       data: {
            //         id: movedItem.pk,
            //         displayPriority: startOrder + newIndex,
            //       },
            //       afterAction: () => history.push(location),
            //     }),
            //   );
            // }}
            // onOrderChangedByEdit={(pk, newOrder) => {
            //   dispatch(
            //     createAction('missionLocation/updateMissionLocation')({
            //       data: {
            //         id: pk,
            //         displayPriority: newOrder,
            //       },
            //       afterAction: () => history.push(location),
            //     }),
            //   );
            // }}
            // actions={['Edit']}
            actions={[]}
            specificActions={[
              {
                condition: (item) => {
                  return true;
                },
                buttons: [
                  {
                    name: 'Edit',
                    action: (item) => {
                      setSelectedMissonLocation(item);
                      setShowEditMissonLocationPrompt(true);
                    },
                    requires: [
                      PermissionCodes.changeMissionLocationQrcode,
                      PermissionCodes.changeMission,
                    ],
                  },
                ],
              },
            ]}
            hasOtherActions
            customActions={{
              deactive: (item) => {
                if (item.status === StatusTag.active) {
                  dispatch(
                    createAction('missionLocation/deactiveMissionLocation')({
                      pk: item.pk,
                      afterAction: () => history.push(location),
                    }),
                  );
                } else if (item.status === StatusTag.inactive) {
                  dispatch(
                    createAction('missionLocation/activeMissionLocation')({
                      pk: item.pk,
                      afterAction: () => history.push(location),
                    }),
                  );
                }
              },
              ...(displayQRCode
                ? {
                    export: (item) => {
                      dispatch(
                        createAction('missionLocation/downloadQRCode')({
                          code: item.code,
                        }),
                      );
                    },
                  }
                : {}),
            }}
          />
        }
      />
      <EditMissionLocationPrompt
        displayQRCode={displayQRCode}
        missionLocation={selectedMissonLocation}
        hasChangeLocaionPermission={hasChangeLocaionPermission}
        hasChangeMissionLocationQrcodePermission={checkHasPermission(
          user,
          PermissionCodes.changeMissionLocationQrcode,
        )}
        show={showEditMissonLocationPrompt}
        onClose={() => setShowEditMissonLocationPrompt(false)}
      />
      <DeletePrompt
        data={checkedList}
        title={'qr codes'}
        relatedName={''}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('missionLocation/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  mission: state.mission.detail,
  dataList: state.missionLocation.pagedList,
  pageInfo: state.missionLocation.pageInfo,
  totalPage: state.missionLocation.totalPage,
  totalCount: state.missionLocation.totalCount,
  checkedList: state.missionLocation.checkedList,
  importedFile: state.uploadFiles.importedFile,
  user: state.users,
});
export default connect(mapPropsToState)(MissionLocationList);
