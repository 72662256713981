import moment from 'moment';
import { createModel } from './BaseModel';
import {
  getGamificationCampaignList,
  getGamificationCampaignDetail,
  createGamificationCampaign,
  updateGamificationCampaign,
  deleteGamificationCampaign,
  publishGamificationCampaign,
  unPublishGamificationCampaign,
  createGamificationCampaignBatch,
  updateGamificationCampaignBatch,
  getStampRewardList,
  getLinkedMissions,
  getGamificationCampaignParticipationRecordsCount,
  getCustomerGamificationCampaignParticipationRecords,
  getCustomerOneGamificationCampaignParticipationRecord,
  getMissionLocationParticipationRecordsCount,
  getCustomerMissionLocationParticipationRecords,
  getCustomerOneMissionLocationParticipationRecord,
  getGamificationCampaignRewardParticipationRecordsCount,
  getCustomerGamificationCampaignRewardParticipationRecords,
  getCustomerOneGamificationCampaignRewardParticipationRecord,
} from '../services/GamificationCampaignApiHelper';
import { getCountryCodeList } from '../services/CountryCodeApiHelper';
import { getMissionList } from '../services/MissionApiHelper';
import { getEntitlements } from '../services/CampaignAPIHelper';
import { parseMission } from './MissionModel';
import { getImageImage } from './CreateCampaignModel';
import { getTermsAndConditionsTemplateDate } from './TermsAndConditionsModel';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  createAction,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  removeElementFromArray,
  removeFromSessionStorage,
  saveToSessionStorage,
  addDomainToImage,
} from '../utils';
import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
  PublishTagType,
  StatusTag,
} from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  TimeFormater,
  formatDate,
  checkCampaignIsExpired,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';

export const GAMIFICATION_CAMPAIGN_SESSION_KEY =
  SESSION_KEYS.CREATE_GAMIFICATION;

export const GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE = {
  GENERIC: { value: 'GENERIC', label: 'Generic link' },
  UNIQUE: { value: 'UNIQUE', label: 'Unique link' },
};

export const GAMIFICATION_CAMPAIGN_REWARD_TYPE = {
  COUPON: { value: 'COUPON', label: 'Coupon' },
  GOODIE_BAG: { value: 'GOODIE_BAG', label: 'Goodie bag' },
};

export const CARD_ORIENTATION_TYPE = {
  HORIZONTAL: { value: 'HORIZONTAL', label: 'Horizontal' },
  VERTICAL: { value: 'VERTICAL', label: 'Vertical' },
};

const stepNames = [
  'Core',
  'Content',
  'Stamp and Prize',
  'Mission and Properties',
  'Preview',
];

export const GAMIFICATION_CAMPAIGN_LIST_FIELDS = [
  { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
  { displayName: 'Name', fieldName: 'name', orderField: 'name' },
  { displayName: 'Entitlement type', fieldName: 'displayEntitlementType' },
  { displayName: 'No. of missions', fieldName: 'numberOfMissions' },
  { displayName: 'Stamp card capacity', fieldName: 'stampCount' },
  {
    displayName: 'Count of Join',
    fieldName: 'numberOfParticipatingCustomers',
  },
  {
    displayName: 'Count of Participation',
    fieldName: 'numberOfParticipatingTimes',
  },
  {
    displayName: 'Active Period',
    fieldName: 'activePeriodList',
    orderField: 'startDate',
  },
  {
    displayName: 'Visible Period',
    fieldName: 'visiblePeriodList',
    orderField: 'displayStartDate',
  },
  {
    displayName: 'Status',
    fieldName: 'status',
  },
];

export const saveGamificationCampaignToSessionStorage = (data) => {
  saveToSessionStorage(GAMIFICATION_CAMPAIGN_SESSION_KEY, data);
};

export const removeGamificationCampaignSessionStorage = () => {
  removeFromSessionStorage(GAMIFICATION_CAMPAIGN_SESSION_KEY);
};

const getGamificationCampaignStatus = (gamificationCampaign) => {
  if (gamificationCampaign.isPublished) {
    if (checkCampaignIsExpired(gamificationCampaign.endDate)) {
      return PublishTagType.expired;
    }
    return PublishTagType.published;
  } else {
    return PublishTagType.unPublished;
  }
};

export const getPeriodDetail = (data) => {
  if (!data) {
    return;
  }
  if (data.isAlwaysActivePeriod) {
    data.startDate = new Date();
    data.endDate = null;
  }
  const activePeriodDetail = `${formatDate(
    data.startDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.endDate, TimeFormater.dayMonthYearWeekTime)}`;

  if (data.isAlwaysVisiblePeriod) {
    data.displayStartDate = new Date();
    data.displayEndDate = null;
  }
  const visiblePeriodDetail = `${formatDate(
    data.displayStartDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.displayEndDate, TimeFormater.dayMonthYearWeekTime)}`;

  return {
    activePeriodDetail,
    visiblePeriodDetail,
  };
};

const getDisplayDate = (startDate, endDate) => {
  const startDisplay = formatDate(
    startDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  const endDisplay = formatDate(
    endDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  return `${startDisplay} - ${endDisplay}`;
};

const parseTranslations = (data, parseTranslation) => {
  const enTranslation = parseTranslation(data, data) || {};
  const translations = data?.translations?.edges?.map((item) => {
    const translation = parseTranslation(item.node, data) || {};
    return translation;
  });
  const reducedTranslation = translations?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});

  return {
    ...enTranslation,
    ...reducedTranslation,
  };
};

function parseLinkedMission(data) {
  if (!data || !data?.mission) {
    return;
  }
  const missionData = parseMission(data?.mission) || {};
  return missionData;
}

function stampRewardTranslationAssemble(node, allData) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;

  let prizeCategories = {};
  if (node.prizeCategories?.length) {
    for (let i = 0; i < node.prizeCategories.length; i++) {
      prizeCategories[i] = {
        title: node.prizeCategories[i],
      };
    }
  }

  const data = {
    ...node,
    pk: node.pk,
    prizeCategories,
  };
  return {
    [language]: data,
  };
}

function parseStampReward(data) {
  if (!data) {
    return;
  }

  const translations = parseTranslations(data, stampRewardTranslationAssemble);

  return {
    ...data,
    translations,
    prizeCategoriesKeys: [...Array(data.prizeCategories?.length || 1).keys()],
  };
}

function parseStampRewardList(dataList, quantity) {
  const newData = {};
  for (let i = 0; i < dataList?.length || 0; i++) {
    newData[(dataList[i].index || 'null').toString()] = dataList[i];
  }

  let stampRewards = [];
  for (let i = 1; i <= quantity; i++) {
    if (i.toString() in newData) {
      stampRewards.push(newData[i.toString()]);
    } else {
      stampRewards.push({
        index: i,
      });
    }
  }
  return stampRewards;
}

function parseEntitlement(data) {
  if (!data) {
    return {};
  }
  return {
    ...data,
    batchIndex: data?.batch?.index || 0,
    displayCreationDate: formatDate(
      data.creationDate,
      TimeFormater.monthDayYearTime,
    ),
  };
}

export function formatInformations(
  informationsData,
  language,
  defaultLength = null,
) {
  let informations = {};
  if (informationsData?.edges?.length) {
    for (let i = 0; i < informationsData?.edges?.length; i++) {
      let item = {};
      if (language === LanguageConfig.english) {
        item = informationsData?.edges[i]?.node;
      } else {
        item = informationsData?.edges[i]?.node?.translations?.edges?.filter(
          (item) => item.node.language === language,
        );
        item = item && item.length ? item[0].node : {};
      }
      informations[i] = {
        pk: item.pk || null,
        title: item.title || null,
        image: item.image || null,
        content: item.content || null,
      };
      if (language === LanguageConfig.english) {
        informations[i]['applyImageToAllLanguage'] =
          item.applyImageToAllLanguage || false;
      }
    }
  } else {
    if (defaultLength) {
      for (let i = 0; i < defaultLength; i++) {
        informations[i] = {
          pk: null,
          title: null,
          image: null,
          content: null,
        };
        if (language === LanguageConfig.english) {
          informations[i]['applyImageToAllLanguage'] = false;
        }
      }
    }
  }
  return informations;
}

function assemblePhotos(data, fieldName, count) {
  let detailPhotos = [];
  for (let i = 1; i <= count; i++) {
    const value = data[`${fieldName}${i}`];
    if (addDomainToImage(value)) {
      detailPhotos.push(addDomainToImage(value));
    }
  }
  return detailPhotos;
}

function translationAssemble(node, allData) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;

  let genericTermsAndConditionsTitle = node.genericTermsAndConditionsTitle;
  let genericTermsAndConditionsContent = node.genericTermsAndConditionsContent;

  if (
    allData?.isFollowGenericTermsAndConditionsTemplate &&
    allData?.genericTermsAndConditionsTemplate?.pk
  ) {
    genericTermsAndConditionsTitle =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[language]
        ?.title;
    genericTermsAndConditionsContent =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[language]
        ?.content;
  }

  const detailPhotos = assemblePhotos(node, 'detailPhoto', 4);
  const detailMobilePhotos = assemblePhotos(node, 'detailMobilePhoto', 4);

  const otherInformations = formatInformations(
    allData.otherInformations,
    language,
    3,
  );
  const tutorials = formatInformations(allData.tutorials, language, 1);
  const rewardInformations = formatInformations(
    allData.rewardInformations,
    language,
    1,
  );

  let missionLabels = {};
  if (node.missionLabels?.length) {
    for (let i = 0; i < node.missionLabels.length; i++) {
      missionLabels[i] = {
        title: node.missionLabels[i],
      };
    }
  }

  const data = {
    ...node,
    pk: node.pk,
    genericTermsAndConditionsTitle,
    genericTermsAndConditionsContent,
    detailPhotos,
    detailMobilePhotos,
    otherInformations,
    tutorials,
    rewardInformations,
    missionLabels: allData.showMissionLabels ? missionLabels : [],
  };
  return {
    [language]: data,
  };
}

const parseGamificationCampaign = (data) => {
  if (!data) {
    return;
  }
  if (Object.keys(data)?.length === 2) {
    // only have pk, name
    return {
      pk: data.pk,
      name: data.name,
      label: `[ID:${data.pk}] ${data.name}`,
    };
  }

  data.genericTermsAndConditionsTemplateDate =
    getTermsAndConditionsTemplateDate(data.genericTermsAndConditionsTemplate);
  const translations = parseTranslations(data, translationAssemble);

  const startDateWithFormat =
    formatDate(data.startDate, TimeFormater.dayMonthWeek) || '-';
  const endDateWithFormat =
    formatDate(data.endDate, TimeFormater.dayMonthWeek) || '-';
  const displayStartDateWithFormat =
    formatDate(data.displayStartDate, TimeFormater.dayMonthWeek) || '-';
  const displayEndDateWithFormat =
    formatDate(data.displayEndDate, TimeFormater.dayMonthWeek) || '-';
  const periodDetail = getPeriodDetail(data);

  const formatedData = {
    ...data,
    label: `[${data.pk}] ${data.name}`,
    translations,
    entitlementType: data.entitlementType,
    displayEntitlementType: data.isPublic
      ? 'Public'
      : GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE[data.entitlementType]?.label,
    activePeriod: data?.endDate
      ? `${formatDate(
          data?.startDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(data?.endDate, TimeFormater.dayMonthYearWeekTimeA)}`
      : 'All the time',
    visiblePeriod: data?.displayEndDate
      ? `${formatDate(
          data?.displayStartDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(
          data?.displayEndDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )}`
      : 'All the time',
    activePeriodList: `From ${startDateWithFormat} to ${endDateWithFormat}`,
    visiblePeriodList: `From ${displayStartDateWithFormat} to ${displayEndDateWithFormat}`,
    createAt: formatDate(data.creationDate, TimeFormater.dayMonthYearWeekTime),
    lastModified: formatDate(
      data.lastModifiedDate,
      TimeFormater.dayMonthYearWeekTime,
    ),
    displayStatus: data.isPublished ? 'Published' : 'Unpublished',
    ...periodDetail,
    isAlwaysActivePeriod: !data.endDate,
    isAlwaysVisiblePeriod: !data.displayEndDate,
    status: getGamificationCampaignStatus(data),
    channels: data.publishChannels?.edges?.map((item) => item.node),
    markets: data.targetMarkets?.edges?.map((item) => item.node),
    countryCodes: data.countryCodes?.edges?.map((item) => item.node),
    workingTeams: data.workingTeams?.edges?.map((item) => item.node),
    numberOfMissions: data.numberOfMissions
      ? `${data.numberOfActiveMissions || 0} / ${data.numberOfMissions}`
      : '-',
    numberOfParticipatingCustomers: `${
      (!data.isPublic && data.numberOfParticipatingCustomers) || '-'
    }`,
    numberOfParticipatingTimes: `${data.numberOfParticipatingTimes || '-'}`,
    genericTermsAndConditionsTemplate: getTermsAndConditionsTemplateDate(
      data.genericTermsAndConditionsTemplate,
    ),
    tutorialsKeys: [...Array(data.tutorials?.edges?.length || 1).keys()],
    otherInformationsKeys: [
      ...Array(data.otherInformations?.edges?.length || 3).keys(),
    ],
    rewardInformationsKeys: [
      ...Array(data.rewardInformations?.edges?.length || 1).keys(),
    ],
    missionLabelsKeys: [...Array(data.missionLabels?.length || 1).keys()],
  };
  return formatedData;
};

function parseOtherPhotoForAPI(otherPhotos, fieldName = 'detailPhoto') {
  let detailPhotos = {};
  if (otherPhotos && otherPhotos.length > 0) {
    otherPhotos.forEach((image, index) => {
      const detailPhotoKey = fieldName + (index + 1);
      detailPhotos[detailPhotoKey] = parsePhotoUrlForAPI(image);
    });
  }
  let detailPhotosLength = 4;
  [...Array(detailPhotosLength)].map((e, i) => {
    const detailPhotoKey = fieldName + (i + 1);
    detailPhotos[detailPhotoKey] = detailPhotos[detailPhotoKey] || null;
  });
  return detailPhotos;
}

const cleanGamificationCampaignTranslation = (translations, data) => {
  const cleanedTranslations = [];
  console.log('@107', translations);
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      ...parseOtherPhotoForAPI(translation.detailPhotos, 'detailPhoto'),
      ...parseOtherPhotoForAPI(
        translation.detailMobilePhotos,
        'detailMobilePhoto',
      ),
    };
    if (translation?.pk) {
      cleanedOneTranslation.id = translation?.pk || null;
    }

    [
      'name',
      'shortDescription',
      'genericTermsAndConditionsTitle',
      'genericTermsAndConditionsContent',
      'tutorialTitle',
      'tutorialContent',
      'rewardSectionTitle',
      'rewardSectionDescription',
      'missionSectionTitle',
      'missionSectionDescription',
      'missionCardTitle',
      'missionCardSubtitle',
    ].forEach((fieldName) => {
      cleanedOneTranslation[fieldName] = translation[fieldName] || null;
    });

    [
      'coverPhoto',
      'mobilePhoto',
      'coverPhotoForTaskList',
      'mobilePhotoForTaskList',
      'coverPhotoForMissionSection',
      'mobilePhotoForMissionSection',
    ].forEach((fieldName) => {
      cleanedOneTranslation[fieldName] =
        getImageImage(translation[fieldName]) || null;
    });

    const otherInformations = Object.keys(translation.otherInformations || {})
      .sort()
      .filter((key) => !translation.otherInformations[key].isDeleted)
      .map((key) => {
        const item = translation.otherInformations[key];
        return {
          id: item.pk || null,
          title: item.title || null,
          image: getImageImage(item.image) || null,
          content: item.content || null,
        };
      });
    cleanedOneTranslation['otherInformations'] = otherInformations;

    const tutorials = Object.keys(translation.tutorials || {})
      .sort()
      .filter((key) => !translation.tutorials[key].isDeleted)
      .map((key) => {
        const item = translation.tutorials[key];
        return {
          id: item.pk || null,
          image: getImageImage(item.image) || null,
          content: item.content || null,
        };
      });
    cleanedOneTranslation['tutorials'] = tutorials;

    const rewardInformations = Object.keys(translation.rewardInformations || {})
      .sort()
      .filter((key) => !translation.rewardInformations[key].isDeleted)
      .map((key) => {
        const item = translation.rewardInformations[key];
        return {
          id: item.pk || null,
          title: item.title || null,
          image: getImageImage(item.image) || null,
          content: item.content || null,
        };
      });
    cleanedOneTranslation['rewardInformations'] = rewardInformations;

    const missionLabels = Object.keys(translation.missionLabels || {})
      .sort()
      .filter((key) => !translation.missionLabels[key].isDeleted)
      .map((key) => {
        const item = translation.missionLabels[key];
        return item.title || '';
      });
    cleanedOneTranslation['missionLabels'] = data.showMissionLabels
      ? missionLabels
      : [];

    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

export function cleanInformations(informations, translations, fieldName) {
  const cleanedInformations = Object.keys(informations || {})
    .sort()
    .filter((key) => !informations[key].isDeleted)
    .map((key) => {
      const item = informations[key];
      const data = {
        id: item.pk || null,
        applyImageToAllLanguage: item.applyImageToAllLanguage || false,
        image: getImageImage(item.image) || null,
        content: item.content || null,
        translations: [],
      };
      if (fieldName !== 'tutorials') {
        data['title'] = item.title || null;
      }
      return data;
    });
  for (let i = 0; i < cleanedInformations?.length; i++) {
    let informationTranslations = [];
    for (let y = 0; y < translations.length; y++) {
      if (translations[y][fieldName]) {
        informationTranslations.push({
          language: translations[y]['language'],
          ...translations[y][fieldName][i],
        });
      }
    }
    cleanedInformations[i]['translations'] = informationTranslations;
  }
  for (let y = 0; y < translations?.length; y++) {
    delete translations[y][fieldName];
  }
  return cleanedInformations;
}

const cleanStampRewardTranslation = (translations, data) => {
  const cleanedTranslations = [];
  console.log('@107', translations);
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
    };
    if (translation?.pk) {
      cleanedOneTranslation.id = translation?.pk || null;
    }

    ['prizeTitle', 'prizeContent'].forEach((fieldName) => {
      cleanedOneTranslation[fieldName] = translation[fieldName] || null;
    });

    ['coverPhotoForPrize', 'mobilePhotoForPrize'].forEach((fieldName) => {
      cleanedOneTranslation[fieldName] =
        getImageImage(translation[fieldName]) || null;
    });

    const prizeCategories = Object.keys(translation.prizeCategories || {})
      .sort()
      .filter((key) => !translation.prizeCategories[key].isDeleted)
      .map((key) => {
        const item = translation.prizeCategories[key];
        return item.title || '';
      });
    cleanedOneTranslation['prizeCategories'] = prizeCategories;

    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

function cleanStampRewards(stampRewards) {
  return stampRewards
    ?.map((data, index) => {
      const enData = (data.translations || {})[LanguageConfig.english] || {};
      const translations =
        cleanStampRewardTranslation(data.translations || {}, data) || [];

      const prizeCategories = Object.keys(enData.prizeCategories || {})
        .sort()
        .filter((key) => !enData.prizeCategories[key].isDeleted)
        .map((key) => {
          const item = enData.prizeCategories[key];
          return item.title || '';
        });

      return {
        id: data.pk,
        index: index + 1,
        rewardType: data.rewardType,
        couponTemplate: data.couponTemplate?.pk,
        goodieBag: data.goodieBag?.pk,
        quantity: data.quantity && parseInt(data.quantity),
        rewardIcon: getImageImage(data.rewardIcon) || null,
        rewardIconCollected: getImageImage(data.rewardIconCollected) || null,
        prizeTitle: enData.prizeTitle || null,
        prizeContent: enData.prizeContent || null,
        prizeCategories,
        applyCoverPhotoForPrizeToAllLanguage:
          data.applyCoverPhotoForPrizeToAllLanguage,
        coverPhotoForPrize: getImageImage(enData['coverPhotoForPrize']) || null,
        applyMobilePhotoForPrizeToAllLanguage:
          data.applyMobilePhotoForPrizeToAllLanguage,
        mobilePhotoForPrize:
          getImageImage(enData['mobilePhotoForPrize']) || null,
        translations,
      };
    })
    ?.filter((data) => data.quantity);
}

const cleanGamificationCampaign = (data) => {
  const enData = data.translations[LanguageConfig.english];
  const translations = cleanGamificationCampaignTranslation(
    data.translations,
    data,
  );

  const otherInformations = cleanInformations(
    enData.otherInformations || {},
    translations,
    'otherInformations',
  );
  const tutorials = cleanInformations(
    enData.tutorials || {},
    translations,
    'tutorials',
  );
  const rewardInformations = cleanInformations(
    enData.rewardInformations || {},
    translations,
    'rewardInformations',
  );

  const missionLabels = Object.keys(enData.missionLabels || {})
    .sort()
    .filter((key) => !enData.missionLabels[key].isDeleted)
    .map((key) => {
      const item = enData.missionLabels[key];
      return item.title || '';
    });

  const payload = {
    isPublic: data.isPublic,
    // isRequireLandingPage: data.isRequireLandingPage,
    isRequireLandingPage: !data.isPublic,
    missionCardOrientation:
      data.missionCardOrientation || CARD_ORIENTATION_TYPE.HORIZONTAL.value,

    missions: data.missions?.map((item) => item.pk),
    publishChannels: data.channels?.map((item) => item.pk),
    targetMarkets: data.markets?.map((item) => item.pk),
    countryCodes: data.countryCodes?.map((item) => item.pk),
    workingTeams: data.workingTeams?.map((item) => item.pk),

    ...parseOtherPhotoForAPI(enData.detailPhotos, 'detailPhoto'),
    ...parseOtherPhotoForAPI(enData.detailMobilePhotos, 'detailMobilePhoto'),

    translations,
    otherInformations,
    tutorials,
    rewardInformations,
    stampRewards: cleanStampRewards(data.stampRewards),
    genericTermsAndConditionsTemplate:
      data.genericTermsAndConditionsTemplate?.pk || null,
    isFollowGenericTermsAndConditionsTemplate:
      data.genericTermsAndConditionsTemplate
        ? data.isFollowGenericTermsAndConditionsTemplate
        : false,
    showMissionLabels: data.showMissionLabels,
    missionLabels: data.showMissionLabels ? missionLabels : [],
  };

  [
    'name',
    'shortDescription',
    'genericTermsAndConditionsTitle',
    'genericTermsAndConditionsContent',
    'tutorialTitle',
    'tutorialContent',
    'rewardSectionTitle',
    'rewardSectionDescription',
    'missionSectionTitle',
    'missionSectionDescription',
    'missionCardTitle',
    'missionCardSubtitle',
  ].forEach((fieldName) => {
    payload[fieldName] = enData[fieldName] || null;
  });

  [
    'coverPhoto',
    'mobilePhoto',
    'coverPhotoForTaskList',
    'mobilePhotoForTaskList',
    'coverPhotoForMissionSection',
    'mobilePhotoForMissionSection',
  ].forEach((fieldName) => {
    payload[fieldName] = getImageImage(enData[fieldName]) || null;
  });

  [
    'stampIcon',
    'stampIconCollected',
    'rewardIcon',
    'rewardIconCollected',
  ].forEach((fieldName) => {
    payload[fieldName] = getImageImage(data[fieldName]) || null;
  });

  [
    'themeColor',
    'stampCount',
    'stampCardResetLimit',
    'entitlementType',
    'startDate',
    'endDate',
    'displayStartDate',
    'displayEndDate',
  ].forEach((fieldName) => {
    payload[fieldName] = data[fieldName] === undefined ? null : data[fieldName];
  });

  [
    'applyCoverPhotoToAllLanguage',
    'applyCoverPhotoForTaskListToAllLanguage',
    'applyDetailPhotosToAllLanguage',
    'applyCoverPhotoForMissionSectionToAllLanguage',
  ].forEach((fieldName) => {
    payload[fieldName] = data[fieldName] || false;
  });

  if (data.isAlwaysActivePeriod) {
    payload.startDate = new Date();
    payload.endDate = null;
  }
  if (data.isAlwaysVisiblePeriod) {
    payload.displayStartDate = new Date();
    payload.displayEndDate = null;
  }

  if (data?.pk) {
    payload.id = data?.pk;
  }
  console.log('@453', payload);
  return payload;
};

const parseGamificationCampaignList = (data) =>
  data?.gamificationCampaigns?.edges?.map((item) =>
    parseGamificationCampaign(item?.node),
  );

const parseGamificationCampaignParticipationRecord = (item) => {
  let data = {
    ...item,
    name: `[ID:${item.gamificationCampaign?.pk}]${item.gamificationCampaign?.name}`,
    acquireDate: formatDate(
      item.creationDate,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
    gamificationCampaign: {
      ...item.gamificationCampaign,
      displayStartDate: moment(item.gamificationCampaign.displayStartDate),
      displayEndDate: item.gamificationCampaign.displayEndDate
        ? moment(item.gamificationCampaign.displayEndDate)
        : null,
      startDate: moment(item.gamificationCampaign.startDate),
      endDate: item.gamificationCampaign.endDate
        ? moment(item.gamificationCampaign.endDate)
        : null,
      absoluteStartDate: item.gamificationCampaign.absoluteStartDate
        ? moment(item.gamificationCampaign.absoluteStartDate)
        : null,
      absoluteExpiryDate: item.gamificationCampaign.absoluteExpiryDate
        ? moment(item.gamificationCampaign.absoluteExpiryDate)
        : null,
    },
    trackingCode: item.trackingCode?.trackingCode,
  };
  return data;
};

const parseMissionLocationParticipationRecord = (item) => {
  let data = {
    ...item,
    name: `[ID:${item.gamificationCampaign?.pk}]${item.gamificationCampaign?.name}`,
    acquireDate: formatDate(
      item.creationDate,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
    gamificationCampaign: {
      ...item.gamificationCampaign,
      displayStartDate: moment(item.gamificationCampaign.displayStartDate),
      displayEndDate: item.gamificationCampaign.displayEndDate
        ? moment(item.gamificationCampaign.displayEndDate)
        : null,
      startDate: moment(item.gamificationCampaign.startDate),
      endDate: item.gamificationCampaign.endDate
        ? moment(item.gamificationCampaign.endDate)
        : null,
      absoluteStartDate: item.gamificationCampaign.absoluteStartDate
        ? moment(item.gamificationCampaign.absoluteStartDate)
        : null,
      absoluteExpiryDate: item.gamificationCampaign.absoluteExpiryDate
        ? moment(item.gamificationCampaign.absoluteExpiryDate)
        : null,
    },
    location: item.missionLocation?.location,
    displayLocation: `[ID:${item.missionLocation?.location?.pk}]${item.missionLocation?.location.name}`,
  };
  return data;
};

const parseGamificationCampaignRewardParticipationRecord = (item) => {
  let data = {
    ...item,
    name: `[ID:${item.gamificationCampaign?.pk}]${item.gamificationCampaign?.name}`,
    acquireDate: formatDate(
      item.creationDate,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
    gamificationCampaign: {
      ...item.gamificationCampaign,
      displayStartDate: moment(item.gamificationCampaign.displayStartDate),
      displayEndDate: item.gamificationCampaign.displayEndDate
        ? moment(item.gamificationCampaign.displayEndDate)
        : null,
      startDate: moment(item.gamificationCampaign.startDate),
      endDate: item.gamificationCampaign.endDate
        ? moment(item.gamificationCampaign.endDate)
        : null,
      absoluteStartDate: item.gamificationCampaign.absoluteStartDate
        ? moment(item.gamificationCampaign.absoluteStartDate)
        : null,
      absoluteExpiryDate: item.gamificationCampaign.absoluteExpiryDate
        ? moment(item.gamificationCampaign.absoluteExpiryDate)
        : null,
    },
  };
  return data;
};

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  detail: {
    entitlementType: GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value,
    tutorialsKeys: [...Array(1).keys()],
    otherInformationsKeys: [...Array(3).keys()],
    rewardInformationsKeys: [...Array(1).keys()],
    // prizeCategoriesKeys: [...Array(1).keys()],
    missionCardOrientation: CARD_ORIENTATION_TYPE.HORIZONTAL.value,
  },
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  apiStatus: APIStatus.none,
  createStatus: APIStatus.none,
});

export default createModel({
  namespace: 'gamificationCampaign',
  params: {
    dataKey: 'gamificationCampaigns',
    listAPI: getGamificationCampaignList,
    pkNode: 'GamificationCampaignNode',
    detailAPI: getGamificationCampaignDetail,
    parse: parseGamificationCampaignList,
    parseDetail: (data) => parseGamificationCampaign(data.gamificationCampaign),
    deleteAPI: deleteGamificationCampaign,
    objectKey: 'gamificationCampaigns',
  },
  states: getInitialState(),
  reducers: {
    updateGamificationCampaign(state, { payload }) {
      console.log('updateGamificationCampaign: ', payload);
      const detail = {
        ...state.detail,
        ...payload,
      };
      return { ...state, detail: detail };
    },

    clearData(state, { payload }) {
      const newState = {
        ...state,
        ...getInitialState(),
      };
      return newState;
    },

    loadGamificationCampaignFromCookie(state, { payload }) {
      const gamificationCampaign = getObjectFromSessionStorage(
        GAMIFICATION_CAMPAIGN_SESSION_KEY,
      );
      console.log('@147', gamificationCampaign);
      if (!gamificationCampaign) {
        return {
          ...state,
          detail: getInitialState().detail,
          hasUpdatedDefaultValues: true,
        };
      }
      return {
        ...state,
        detail: {
          ...gamificationCampaign,
        },
        hasUpdatedDefaultValues: true,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },
  },
  effects: {
    getOneGamificationCampaign: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const id = convertPKToId('GamificationCampaignNode', payload.id);
        const serviceArgs = [
          getGamificationCampaignDetail,
          id,
          payload.callFromEntitlement,
        ];
        function* onSuccess(data) {
          const detail = parseGamificationCampaign(data.gamificationCampaign);

          afterAction(detail?.entitlementType);
          if (payload.getDetailOnly) {
            yield put({
              type: 'updateState',
              payload: {
                detail: detail,
                hasUpdatedDefaultValues: true,
                selectedAllListLoading: true,
              },
            });
          } else {
            const puts = [
              put({
                type: 'updateState',
                payload: {
                  detail: detail,
                  hasUpdatedDefaultValues: true,
                  selectedAllListLoading: false,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  gamificationCampaignIn: [payload.id],
                  pageSize: 1000,
                  page: 1,
                  stateName: 'detail',
                  dataKey: 'countryCodes',
                  updateKey: 'countryCodes',
                  updateApi: getCountryCodeList,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  getAll: true,
                  isAll: true,
                  gamificationCampaignIn: [payload.id],
                  pageSize: 1000,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'missions',
                  dataKey: 'gamificationCampaignMissions',
                  updateApi: getLinkedMissions,
                  parseDetail: parseLinkedMission,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  getAll: true,
                  isAll: true,
                  gamificationCampaignIn: [payload.id],
                  pageSize: 100,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'stampRewards',
                  dataKey: 'stampRewards',
                  updateApi: getStampRewardList,
                  parseDetail: parseStampReward,
                  filterCondition: (item) => item.quantity,
                  parseList: (dataList) => {
                    return parseStampRewardList(
                      dataList || [],
                      detail.stampCount,
                    );
                  },
                },
              }),
            ];
            if (payload.fromDetail) {
              puts.push(
                put({
                  type: 'updateProperty',
                  payload: {
                    gamificationCampaignId: payload.id,
                    pageSize: 100,
                    page: 1,
                    stateName: 'detail',
                    dataKey: 'entitlements',
                    updateKey: 'entitlements',
                    updateApi: getEntitlements,
                    parseDetail: parseEntitlement,
                  },
                }),
              );
            }
            yield all(puts);
          }
        }

        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateGamificationCampaign: [
      function* ({ payload }, { all, select, put }) {
        const { isPublished, data, afterAction, isOptionD } = payload;
        console.log('@@1034: ', payload.data);

        const serviceArgs = [
          data?.pk ? updateGamificationCampaign : createGamificationCampaign,
          {
            ...cleanGamificationCampaign(data),
            isPublished: isPublished,
          },
        ];

        console.log('@@803: ', data);
        yield put(
          createAction('updateState')({
            createStatus: APIStatus.calling,
          }),
        );

        function* onSuccess(successData) {
          removeGamificationCampaignSessionStorage();
          const gamificationCampaign =
            successData?.[
              `${data?.pk ? 'update' : 'create'}GamificationCampaign`
            ]?.node;

          if (!gamificationCampaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateGamificationCampaign',
              payload: {
                id: gamificationCampaign.id,
                pk: gamificationCampaign.pk,
              },
            }),
          ]);
          // if (isPublished) {
          //   yield put({
          //     type: 'publish',
          //     payload: {
          //       gamificationCampaignPk: gamificationCampaign.pk,
          //       isPublished: !isPublished,
          //     },
          //   });
          // } else {
          //   yield put({
          //     type: 'publish',
          //     payload: {
          //       gamificationCampaignPk: gamificationCampaign.pk,
          //       isPublished: isPublished,
          //     },
          //   });
          // }

          afterAction &&
            typeof afterAction === 'function' &&
            afterAction(gamificationCampaign.pk);
        }

        function* onError(err) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    publish: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const gamificationCampaignPk = payload.gamificationCampaignPk;
        let serviceArgs = [
          unPublishGamificationCampaign,
          { id: gamificationCampaignPk },
        ];
        console.log('@@309: ', payload);
        if (!payload.isPublished) {
          serviceArgs = [
            publishGamificationCampaign,
            {
              id: gamificationCampaignPk,
            },
          ];
        }

        function* onSuccess(data) {
          console.log('@@309: ', payload.isPublished);
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.success,
              },
            }),
            put({
              type: 'updateGamificationCampaign',
              payload: { isPublished: payload.isPublished },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    generateEntitlementUniqueLink: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const gamificationCampaignPk = payload.gamificationCampaignPk;
        let serviceArgs = [
          createGamificationCampaignBatch,
          { id: gamificationCampaignPk, ...payload.data },
        ];
        console.log('@@309: ', payload);

        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.success,
              },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateEntitlementUniqueLink: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const gamificationCampaignBatchPk = payload.gamificationCampaignBatchPk;
        let serviceArgs = [
          updateGamificationCampaignBatch,
          { batchId: gamificationCampaignBatchPk, ...payload.data },
        ];
        console.log('@@309: ', payload);

        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.success,
              },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    getGamificationCampaignParticipationRecordsCount: [
      function* ({ payload }, { put }) {
        console.log('@@1378', payload);
        const serviceArgs = [
          getGamificationCampaignParticipationRecordsCount,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.gamificationCampaignParticipationRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              recordCount: totalCount,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerGamificationCampaignParticipationRecords: [
      function* ({ payload }, { put, select }) {
        const pageSize = 20;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );

        const serviceArgs = [
          getCustomerGamificationCampaignParticipationRecords,
          pageCursor,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.gamificationCampaignParticipationRecords?.totalCount;
          const pageInfo =
            data?.gamificationCampaignParticipationRecords?.pageInfo;
          let formatData =
            data?.gamificationCampaignParticipationRecords?.edges?.map((item) =>
              parseGamificationCampaignParticipationRecord(item.node),
            );
          const source = yield select(
            (state) =>
              state.gamificationCampaign.customerParticipationRecords?.data,
          );
          if (payload.isLoadMore && payload.page > 1) {
            formatData = [...(source || []), ...formatData];
          }

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecords: {
                totalCount: totalCount,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                  hasNextPage: pageInfo.hasNextPage,
                },
                totalPage: Math.ceil(totalCount / pageSize),
                data: formatData,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerOneGamificationCampaignParticipationRecord: [
      function* ({ payload }, { put, select }) {
        const id = convertPKToId(
          'GamificationCampaignParticipationRecordNode',
          payload.id,
        );

        const serviceArgs = [
          getCustomerOneGamificationCampaignParticipationRecord,
          id,
        ];

        function* onSuccess(data) {
          let formatData = parseGamificationCampaignParticipationRecord(
            data?.gamificationCampaignParticipationRecord,
          );

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecord: formatData,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMissionLocationParticipationRecordsCount: [
      function* ({ payload }, { put }) {
        console.log('@@1378', payload);
        const serviceArgs = [
          getMissionLocationParticipationRecordsCount,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.missionLocationParticipationRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              recordCount: totalCount,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerMissionLocationParticipationRecords: [
      function* ({ payload }, { put, select }) {
        const pageSize = 20;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );

        const serviceArgs = [
          getCustomerMissionLocationParticipationRecords,
          pageCursor,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.missionLocationParticipationRecords?.totalCount;
          const pageInfo = data?.missionLocationParticipationRecords?.pageInfo;
          let formatData =
            data?.missionLocationParticipationRecords?.edges?.map((item) =>
              parseMissionLocationParticipationRecord(item.node),
            );
          const source = yield select(
            (state) =>
              state.gamificationCampaign.customerParticipationRecords?.data,
          );
          if (payload.isLoadMore && payload.page > 1) {
            formatData = [...(source || []), ...formatData];
          }

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecords: {
                totalCount: totalCount,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                  hasNextPage: pageInfo.hasNextPage,
                },
                totalPage: Math.ceil(totalCount / pageSize),
                data: formatData,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerOneMissionLocationParticipationRecord: [
      function* ({ payload }, { put, select }) {
        const id = convertPKToId(
          'MissionLocationParticipationRecordNode',
          payload.id,
        );

        const serviceArgs = [
          getCustomerOneMissionLocationParticipationRecord,
          id,
        ];

        function* onSuccess(data) {
          let formatData = parseMissionLocationParticipationRecord(
            data?.gamificationCampaignParticipationRecord,
          );

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecord: formatData,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getGamificationCampaignRewardParticipationRecordsCount: [
      function* ({ payload }, { put }) {
        console.log('@@1378', payload);
        const serviceArgs = [
          getGamificationCampaignRewardParticipationRecordsCount,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.gamificationCampaignRewardParticipationRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              recordCount: totalCount,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerGamificationCampaignRewardParticipationRecords: [
      function* ({ payload }, { put, select }) {
        const pageSize = 20;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );

        const serviceArgs = [
          getCustomerGamificationCampaignRewardParticipationRecords,
          pageCursor,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount =
            data?.gamificationCampaignRewardParticipationRecords?.totalCount;
          const pageInfo =
            data?.gamificationCampaignRewardParticipationRecords?.pageInfo;
          let formatData =
            data?.gamificationCampaignRewardParticipationRecords?.edges?.map(
              (item) =>
                parseGamificationCampaignRewardParticipationRecord(item.node),
            );
          const source = yield select(
            (state) =>
              state.gamificationCampaign.customerParticipationRecords?.data,
          );
          if (payload.isLoadMore && payload.page > 1) {
            formatData = [...(source || []), ...formatData];
          }

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecords: {
                totalCount: totalCount,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                  hasNextPage: pageInfo.hasNextPage,
                },
                totalPage: Math.ceil(totalCount / pageSize),
                data: formatData,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerOneGamificationCampaignRewardParticipationRecord: [
      function* ({ payload }, { put, select }) {
        const id = convertPKToId(
          'GamificationCampaignRewardParticipationRecordNode',
          payload.id,
        );

        const serviceArgs = [
          getCustomerOneGamificationCampaignRewardParticipationRecord,
          id,
        ];

        function* onSuccess(data) {
          let formatData = parseGamificationCampaignRewardParticipationRecord(
            data?.gamificationCampaignRewardParticipationRecord,
          );

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecord: formatData,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
