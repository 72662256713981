import { BaseHelper } from './BaseGraphQLAPIHelper';

const TRACKING_CODE_NODE = `
  id
  pk
  merchant {
    pk
    name
  }
  trackingCode
  encodeTrackingCode
  qrcodeImage
  isFollowGoodieBagActivePeriod
  startDate
  endDate
  isForcedInactive
  creationDate
  lastModifiedDate
`;

export const getTrackingCodesList = (afterCursor = '', payload) => {
  console.log('@@25: ', payload);
  let searchString = 'first: 20';
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  searchString += `, orderBy: "-lastModifiedDate"`;
  if (payload.goodieBagId) {
    searchString += `, goodieBag: ${payload.goodieBagId}`;
  }
  if (payload.entitlementId) {
    searchString += `, entitlement: ${payload.entitlementId}`;
  }
  if (payload.gamificationCampaignId) {
    searchString += `, gamificationCampaign: ${payload.gamificationCampaignId}`;
  }

  if (payload.search) {
    searchString += `, search: "${payload.search}"`;
  }
  if (payload.status) {
    searchString += `, statusIn: "${payload.status}"`;
  }
  if (payload.activeTimeRange) {
    searchString += `, activeTimeRange: "${payload.activeTimeRange}"`;
  }
  if (payload.inActiveTimeRange) {
    searchString += `, inactiveTimeRange: "${payload.inActiveTimeRange}"`;
  }

  const query = `{
    entitlementTrackingCodes(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${TRACKING_CODE_NODE}
          isCheckGps
          locationCount
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTrackingCodeDetail = (id, payload) => {
  const query = `
  {
    entitlementTrackingCode(id: "${id}") {
      ${TRACKING_CODE_NODE}
      goodieBag {
        displayStartDate
        displayEndDate
      }
      isCheckGps
      locations {
        edges {
          node {
            pk
            mapAddress
            latitude
            longitude
            radius
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createEntitlementTrackingCode = (inputBody) => {
  const query = `
  mutation CreateEntitlementTrackingCode($input: CreateEntitlementTrackingCodeInput!) {
    createEntitlementTrackingCode(input: $input) {
      success
      errors {
        index
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateEntitlementTrackingCode = (inputBody) => {
  const query = `
  mutation UpdateEntitlementTrackingCode($input: UpdateEntitlementTrackingCodeInput!) {
    updateEntitlementTrackingCode(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTrackingCodeCount = (payload) => {
  let searchString = `goodieBag: ${payload.goodieBagId}, merchants: [${payload.merchants}]`;
  const query = `{
    entitlementTrackingCodeCount(${searchString}) {
      merchantId
      total
      active
      inactive
      expired
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const checkTrackingCodeExists = (payload) => {
  let searchString = `index: ${payload.index}, goodieBag: ${payload.goodieBagId}, code: "${payload.code}"`;
  const query = `{
    entitlementTrackingCodeExists(${searchString}) {
      codeIndex
      exists
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};
