import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Image, Button } from 'react-bootstrap';
import PromptButton, { BUTTON_TYPES } from '../base/prompt/BaseButtons';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import './CreateTrackingCodePrompt.scss';
import BasePrompt from '../base/prompt/BasePrompt';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import CustomSwitchButton from '../base/CustomSwitchButton';
import {
  TimeFormater,
  formatDate,
  getCurrentDay,
} from '../../utils/TimeFormatUtil';

export function TrackingCodeActivePeriod({
  index,
  source,
  onChange,
  goodiebag,
  errors,
}) {
  console.log('@@26: ', errors, index);
  const hasError = (field) => errors?.[index]?.indexOf(field) >= 0;
  const [followGoodieBag, setFollowGoodieBag] = useState();

  useEffect(() => {
    setFollowGoodieBag(source?.isFollowGoodieBag);
  }, [source?.isFollowGoodieBag]);
  return source ? (
    <>
      <CustomTitleLabel
        className="valid-period-follow-title"
        title={`Active period follows the VISIBLE PERIOD of the ${goodiebag.labelName}`}
      />
      <CustomSwitchButton
        defaultChecked={source?.isFollowGoodieBag}
        className="create-section-label-bottom-space"
        onChange={(value) => {
          setFollowGoodieBag(value);
          onChange(index, 'isFollowGoodieBag', value);
        }}
      />

      <label className="tips-message valid-period-display">
        {goodiebag.labelName} VISIBLE PERIOD:{' '}
        {goodiebag?.endDate
          ? `${formatDate(
              goodiebag?.startDate,
              TimeFormater.dayMonthYearWeekTimeAWithComma,
            )}
      -
      ${formatDate(
        goodiebag?.endDate,
        TimeFormater.dayMonthYearWeekTimeAWithComma,
      )}`
          : 'All the time'}
      </label>
      {followGoodieBag ? null : (
        <div className="valid-period-area">
          <div className="second-section">
            <label className="create-section-label">Active start</label>

            <CustomDateTimeSelect
              defaultTime={source?.startDate}
              error={hasError('startDate')}
              onTimeChange={(datetime) => {
                //   setValue(effectiveDateKey, datetime, {
                //     shouldDirty: true,
                //   });
                onChange(index, 'startDate', datetime);
              }}
            />
          </div>
          <div className="second-section">
            <label className="create-section-label">Active end</label>
            <CustomDateTimeSelect
              defaultTime={source?.endDate}
              error={hasError('endDate') || hasError('goodiebagEndDate')}
              onTimeChange={(datetime) => {
                onChange(index, 'endDate', datetime);
              }}
              seconds={59}
              // disabledTime={true}
            />
            <ErrorFieldMessage
              error={
                hasError('startDate') ||
                hasError('endDate') ||
                hasError('goodiebagEndDate')
              }
              message={
                hasError('startDate') || hasError('goodiebagEndDate')
                  ? `The active period must be within the ${goodiebag.labelName.toLowerCase()} visible period.`
                  : 'The end date must be later than start date'
              }
            />
          </div>
        </div>
      )}
    </>
  ) : null;
}

function TrackingCodeItem({ index, source, goodiebag, onChange, errors }) {
  const hasError = (field) => errors?.[index]?.indexOf(field) >= 0;
  // const [followGoodieBag, setFollowGoodieBag] = useState(
  //   source?.isFollowGoodieBag,
  // );
  return (
    <div
      className={`create-code-view ${
        source?.pk ? '' : 'create-code-view-border'
      }`}
    >
      <CustomTitleLabel title={'Tracking code'} />
      <label className="tips-message">
        Please only input 20 max. letters & numbers. 
      </label>

      <input
        className={`custom-markdown-area-title  custom-markdown-area-title-short ${
          hasError('name') || hasError('nameUsed') ? 'error-field' : ''
        }`}
        defaultValue={source?.name}
        maxLength={20}
        onChange={(e) => {
          onChange(index, 'name', e.target.value);
        }}
        disabled={source?.pk}
      />
      <ErrorFieldMessage
        error={hasError('name') || hasError('nameUsed')}
        message={
          hasError('name')
            ? 'Please input valid code.'
            : 'This tracking code has been used.'
        }
      />
      <TrackingCodeActivePeriod
        index={index}
        source={source}
        goodiebag={goodiebag}
        onChange={onChange}
        errors={errors}
      />
      {/* <CustomTitleLabel
        className="valid-period-follow-title"
        title={`Active period follows the VISIBLE PERIOD of the ${goodiebag.labelName}`}
      />
      <CustomSwitchButton
        defaultChecked={source?.isFollowGoodieBag}
        className="create-section-label-bottom-space"
        onChange={(value) => {
          setFollowGoodieBag(value);
          onChange(index, 'isFollowGoodieBag', value);
        }}
      />

      <label className="tips-message valid-period-display">
        {goodiebag.labelName} VISIBLE PERIOD:{' '}
        {goodiebag?.endDate
          ? `${formatDate(
              goodiebag?.startDate,
              TimeFormater.dayMonthYearWeekTimeAWithComma,
            )}
          -
          ${formatDate(
            goodiebag?.endDate,
            TimeFormater.dayMonthYearWeekTimeAWithComma,
          )}`
          : 'All the time'}
      </label>
      {followGoodieBag ? null : (
        <div className="valid-period-area">
          <div className="second-section">
            <label className="create-section-label">Active start</label>

            <CustomDateTimeSelect
              defaultTime={source?.startDate}
              error={hasError('startDate')}
              onTimeChange={(datetime) => {
                //   setValue(effectiveDateKey, datetime, {
                //     shouldDirty: true,
                //   });
                onChange(index, 'startDate', datetime);
              }}
            />
          </div>
          <div className="second-section">
            <label className="create-section-label">Active end</label>
            <CustomDateTimeSelect
              defaultTime={source?.endDate}
              error={hasError('endDate') || hasError('goodiebagEndDate')}
              onTimeChange={(datetime) => {
                onChange(index, 'endDate', datetime);
              }}
              seconds={59}
              // disabledTime={true}
            />
            <ErrorFieldMessage
              error={
                hasError('startDate') ||
                hasError('endDate') ||
                hasError('goodiebagEndDate')
              }
              message={
                hasError('startDate') || hasError('goodiebagEndDate')
                  ? `The active period must be within the ${goodiebag.labelName.toLowerCase()} visible period.`
                  : 'The end date must be later than start date'
              }
            />
          </div>
        </div>
      )} */}
    </div>
  );
}

export default function CreateTrackingCodePrompt(props) {
  const closeAction = props.onClose || (() => {});
  const customClass = props.customClass || '';

  const data = props.data;

  const history = useHistory();
  const location = useLocation();

  // const [show, setShow] = useState(props.show);
  const [codeList, setCodeList] = useState();
  const [confirmDisabled, setConfirmDisabled] = useState(!!data.code?.name);

  const [errors, setErrors] = useState();
  const [existsCodes, setExistsCodes] = useState();

  const handleClose = () => {
    // setShow(false);
    setCodeList([]);
    setErrors();
    closeAction();
  };

  useEffect(() => {
    if (props.show) {
      setCodeList([
        data?.code?.name
          ? data.code
          : {
              ...data.code,
              startDate: data.goodiebagStartDate,
              endDate: data.goodiebagEndDate,
              isFollowGoodieBag: true,
            },
      ]);
    }
    // setShow(props.show);
  }, [props.show]);

  const body = (data) => {
    return (
      <div className="tracking-code-container">
        {data.gamificationCampaignId ? null : (
          <label className="caution-text">
            [ID: {data.merchantId}] {data?.merchantName}
          </label>
        )}

        {codeList?.map((item, index) => {
          return (
            <TrackingCodeItem
              index={index}
              source={item}
              onChange={(index, name, value) => {
                // if (name === 'name') {
                //   if (value) {
                //     dispatch(
                //       'entitlementTrackingCodes/checkTrackingCodeExists',
                //       {
                //         index,
                //         goodieBag: data.goodiebagId,
                //         code: value,
                //       },
                //     );
                //   } else {
                //     setExistsCodes(
                //       removeElementFromArray(existsCodes || [], index),
                //     );
                //   }
                // }

                const source = codeList;
                source[index] = { ...source[index], [name]: value };
                setConfirmDisabled(
                  source.filter((item) => !!item.name).length <= 0,
                );
                setCodeList(source);
              }}
              goodiebag={{
                labelName: data.gamificationCampaignId
                  ? 'Gamification campaign'
                  : 'Goodie bag',
                startDate: data.goodiebagStartDate,
                endDate: data.goodiebagEndDate,
              }}
              errors={errors}
            />
          );
        })}

        {codeList?.[0]?.pk ? null : (
          <PromptButton
            title={'Add more'}
            className="add-more"
            type={BUTTON_TYPES.secondary}
            action={() => {
              setCodeList([
                ...codeList,
                {
                  startDate: data.goodiebagStartDate,
                  endDate: data.goodiebagEndDate,
                  isFollowGoodieBag: true,
                },
              ]);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <BasePrompt
      show={props.show}
      //   customClass={'tracking-code-form'}
      closeAction={handleClose}
      rightButton={{
        text: 'Confirm',
        action: () => {
          setConfirmDisabled(true);
          const errors = {};
          const formatData = [];
          codeList.forEach((item, index) => {
            const indexError = [];

            var re = new RegExp(/^[A-Za-z0-9]+$/);
            if (
              !re.test(item.name.trim()) ||
              (re.test(item.name.trim()) && item.name.trim().length > 20)
            ) {
              indexError.push('name');
            }

            const start = item.startDate || getCurrentDay();
            const end = item.endDate || getCurrentDay();

            if (!item.isFollowGoodieBag) {
              if (end <= start) {
                indexError.push('endDate');
              } else {
                if (
                  data.goodiebagEndDate &&
                  start < new Date(data.goodiebagStartDate)
                ) {
                  indexError.push('startDate');
                }
                if (
                  data.goodiebagEndDate &&
                  end > new Date(data.goodiebagEndDate)
                ) {
                  indexError.push('goodiebagEndDate');
                }
              }
            }

            if (indexError.length > 0) {
              // if (indexError.indexOf('name') >= 0) {
              errors[index] = indexError;
            }

            formatData.push({
              ...item,
              merchantId: data.merchantId,
              startDate: item.isFollowGoodieBag
                ? data.goodiebagStartDate
                : start,
              endDate: item.isFollowGoodieBag ? data.goodiebagEndDate : end,
            });
          });

          if (Object.keys(errors).length > 0 && formatData[0]?.pk) {
            setErrors(errors);
            setConfirmDisabled(false);
            return;
          }

          props.onConfirm({
            data: formatData,
            merchantData: {
              goodiebagId: data.goodiebagId,
              merchantId: data.merchantId,
              gamificationCampaignId: data.gamificationCampaignId,
            },
            errors,
            afterAction: (errors) => {
              if (Object.keys(errors || {}).length > 0) {
                setErrors(errors);
              } else {
                // window.location.reload();
                handleClose();
                history.push(location);
              }
              setConfirmDisabled(false);
            },
          });

          // if (Object.keys(errors).length <= 0) {
          //   props.onConfirm({
          //     data: formatData,
          //     merchantData: {
          //       goodiebagId: data.goodiebagId,
          //       merchantId: data.merchantId,
          //     },
          //     afterAction: (errors) => {
          //       if (Object.keys(errors || {}).length > 0) {
          //         setErrors(errors);
          //       } else {
          //         window.location.reload();
          //         handleClose();
          //       }
          //     },
          //   });
          // } else {
          //   setErrors(errors);
          // }
        },
        disabled: confirmDisabled,
      }}
      leftButton={{
        text: 'Cancel',
        action: handleClose,
      }}
      title={props.title}
      otherBody={() => body(data)}
    />
  );
}
