import React, { useEffect, useState } from 'react';
import './GamificationCampaignEntitlement.scss';
import queryString from 'query-string';
import ContentSections from '../../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../../components/base/BottomStepComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../../config/PermissionCodes';
import CustomListComponent from '../../../../components/base/CustomListComponent';
import AuthButton from '../../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../../components/base/CustomBreadcrumb';

import {
  checkHasPermission,
  createAction,
  getHashKeyString,
} from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import BaseTabListContainer from '../../../base/BaseTabListContainer';
import BaseListContainer from '../../../base/BaseListContainer';
import { FORTUNEBAG_OPTIONS_BUTTON_TYPE } from '../../../../models/FortuneBagEntitlementModel';
import { GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE } from '../../../../models/GamificationCampaignModel';
import { gamificationCampaignEntitlementBreads } from '../../../../config/CustomBreadConfig';
import { downloadZipFiles } from '../../../merchants/stores/QRCodeDownloadUtil';
import { OptionButton } from '../../goodieBags/GoodieBagEntitlement/GoodieBagEntitlement';
import EntitlementTrackingCodeExportPrompt from '../../../../components/goodieBags/EntitlementTrackingCodeExportPrompt';
import CreateTrackingCodePrompt from '../../../../components/goodieBags/CreateTrackingCodePrompt';
import GenerateUniqueLinkPrompt from '../../../../components/gamificationCampaign/GenerateUniqueLinkPrompt';
import { Image } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../../../assets/images/campaign/copy_icon.svg';
import { ToastType } from '../../../../models/NavBarModel';
import { ImportResource } from '../../../../models/DownloadImportModel';
import BaseSimpleTable from '../../../../components/base/BaseSimpleTable';
import CountComponent from '../../../../components/base/CountComponent';
import ListFilterComponent from '../../../../components/base/ListFilterComponent';

const WECHAT_APP_ID = process.env.REACT_APP_WECHAT_APP_ID;

function MerchantTable({
  entitlementOptionDatas,
  setSelectedBatch = () => {},
  setShowEditUniqueLinkPrompt = () => {},
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users);

  let header = [
    {
      displayName: 'Batch no.',
      fieldName: 'batchNo',
    },
    {
      displayName: 'No. of links',
      fieldName: 'fortuneBagQuantity',
    },
    {
      displayName: 'Remarks',
      fieldName: 'remarks',
      fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.EDIT,
    },
    {
      displayName: 'Create time',
      fieldName: 'displayCreationDate',
    },
    {
      displayName: 'Unique link list',
      fieldName: 'fortuneBagUrl',
      fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
    },
  ];

  if (entitlementOptionDatas?.length <= 0) {
    if (
      !checkHasPermission(
        user,
        PermissionCodes.changeGamificationCampaignEntitlement,
      )
    ) {
      return <></>;
    }
    return (
      <>
        <div className={`loading-area`}>
          <div>
            Please click the “Generate link” button at top right corner to
            create entitlement links for the gamification campaign.
          </div>
        </div>
      </>
    );
  }

  // utils
  const fieldForDisplay = (field) => {
    if (typeof field !== 'string') {
      return field;
    }

    const regex = '([^\\/]+.csv$)';
    const found = field.match(regex);
    return found ? found[0] : field;
  };

  return (
    <>
      <table className="goodie-bag-merchant-table">
        <thead>
          <tr>
            {header.map((headerContent, index) => {
              return <th>{headerContent.displayName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {entitlementOptionDatas
            .sort(function (a, b) {
              return b.batchNo - a.batchNo;
            })
            .map((fieldContent, index) => {
              return (
                <tr>
                  {header.map((headerContent) => {
                    const fieldString = fieldContent[headerContent.fieldName];
                    const fieldButtonType = headerContent.fieldButton;
                    let hasPermission = true;
                    if (
                      [
                        FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
                        FORTUNEBAG_OPTIONS_BUTTON_TYPE.EDIT,
                      ].includes(fieldButtonType)
                    ) {
                      if (
                        !checkHasPermission(
                          user,
                          PermissionCodes.changeGamificationCampaignEntitlement,
                        )
                      ) {
                        hasPermission = false;
                      }
                    }
                    if (
                      [FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD].includes(
                        fieldButtonType,
                      )
                    ) {
                      if (
                        !checkHasPermission(user, PermissionCodes.addExportjob)
                      ) {
                        hasPermission = false;
                      }
                    }
                    const customAction = () => {
                      if (
                        fieldButtonType ===
                        FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD
                      ) {
                        // dispatch({
                        //   type: 'adminActivityLog/createAdministratorActivityLog',
                        //   payload: {
                        //     activityType:
                        //       AdminActivityType.DOWNLOAD_ENTITLEMENT_SIGN_LIST
                        //         .pk,
                        //     objectId: fieldContent.entitlementId,
                        //   },
                        // });
                        // downloadZipFiles(fieldString);
                        dispatch({
                          type: 'downloadAndImport/createDownloadTask',
                          payload: {
                            from: ImportResource.gamificationCampaignUniqueLink,
                            related: {
                              entitlement: fieldContent.entitlementId,
                            },
                          },
                        });
                      } else if (
                        fieldButtonType === FORTUNEBAG_OPTIONS_BUTTON_TYPE.EDIT
                      ) {
                        setSelectedBatch(fieldContent?.batch);
                        setShowEditUniqueLinkPrompt(true);
                      }
                    };
                    return (
                      <td>
                        {fieldButtonType ===
                        FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD
                          ? null
                          : fieldForDisplay(fieldString)}
                        {fieldButtonType && hasPermission ? (
                          <OptionButton
                            type={fieldButtonType}
                            content={fieldString}
                            toastMessage={null}
                            customAction={customAction}
                          />
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

function NoTrackingCodePage() {
  const user = useSelector((state) => state.users);

  if (
    !checkHasPermission(
      user,
      PermissionCodes.changeGamificationCampaignEntitlement,
    )
  ) {
    return <></>;
  }
  return (
    <>
      <div className={`loading-area`}>
        <div>
          Please click the “Add tracking code” button at top right corner to
          create tracking codes of entitlement links for the gamification
          campaign.
        </div>
      </div>
    </>
  );
}

function TrackingCodeTable({
  formatedDataList,
  setChangeTrackingCodes,
  setShowExportTrackingCodeView,
}) {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pageInfo, totalPage, totalCount, entitlementDetail } = useSelector(
    (state) => ({
      pageInfo: state.entitlementTrackingCodes.pageInfo,
      totalPage: state.entitlementTrackingCodes.totalPage,
      totalCount: state.entitlementTrackingCodes.totalCount,
      entitlementDetail: state.fortuneBagEntitlement.entitlementDetail,
    }),
  );

  const permissionGroup = PermissionCodes.gamificationCampaignEntitlement;

  return (
    <>
      <div className="all-list-scrollable-area entitlement-info-area">
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-buttons">
            <AuthButton
              customClass="btn-back-button-common btn-download"
              title="Export"
              action={() => {
                setShowExportTrackingCodeView(true);
              }}
              requires={PermissionCodes.viewGamificationCampaignEntitlement}
            />
            <AuthButton
              title="Add tracking code"
              action={() => {
                console.log('add');
                setChangeTrackingCodes({ code: {} });
              }}
              requires={PermissionCodes.changeGamificationCampaignEntitlement}
            />
          </div>
        </div>

        <div id="tabindex" className="all-list-component-action-header">
          <div className="all-list-component-action-header-div">
            <label>
              Gamification campaign VISIBLE PERIOD:{' '}
              {entitlementDetail?.gamificationCampaign?.visiblePeriod}
            </label>
            <CountComponent
              start={pageInfo?.startCursor}
              end={pageInfo?.endCursor}
              total={totalCount}
            />
          </div>
        </div>

        <div className="goodie-bag-mini-program">
          <label className="goodie-bag-mini-program-title">
            App ID (HKTB mini program):
          </label>
          <label className="goodie-bag-mini-program-id">{WECHAT_APP_ID}</label>
          <AuthButton
            className="lebel-right-button"
            onClick={(e) => {
              copy(WECHAT_APP_ID);
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: 'App ID copied',
                  },
                },
              });
            }}
            variant="link"
          >
            <Image src={CopyIcon} />
          </AuthButton>
        </div>
        <div className="all-list-search-container">
          <ListFilterComponent
            searchPlaceholder={'Search by Tracking code'}
            onSearch={(key) => {
              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  ...{ search: key, page: 1 },
                }),
              });
            }}
          />
        </div>
      </div>

      <div className="all-list-table-content all-list-table-content-simple">
        <BaseSimpleTable
          customTableClassName="entitlement-tracking-code-simple-table"
          fields={[
            {
              displayName: 'Tracking code',
              fieldName: 'trackingCode',
              // linkUrl: (id) => `${id}/`,
            },
            {
              displayName: 'QR code',
              fieldName: 'qrcodeImage',
            },
            {
              displayName: 'Active start',
              fieldName: 'displayStartDate',
            },
            {
              displayName: 'Active end',
              fieldName: 'displayEndDate',
            },
            {
              displayName:
                'Generic URL - Distribute from email or SMS, and open in web browser',
              fieldName: 'genericURL',
              copyToast: ToastType.merchantContentCopied,
            },
            {
              displayName:
                'Generic URL - Distribute from mini program, and open in mini program',
              fieldName: 'wechatGenericURL',
              copyToast: ToastType.merchantContentCopied,
            },
            {
              displayName: 'Status',
              fieldName: 'status',
            },
            {
              displayName: 'Action',
              fieldName: 'Action',
            },
          ]}
          dataList={formatedDataList}
          totalCount={totalCount}
          customActions={[
            {
              name: 'Edit active period',
              action: (item) => {
                console.log('edit', item);
                setChangeTrackingCodes({
                  code: {
                    pk: item.pk,
                    name: item.trackingCode,
                    isFollowGoodieBag: item.isFollowGoodieBagActivePeriod,
                    startDate: item.startDate,
                    endDate: item.endDate,
                  },
                });
              },
              requires: `change_${permissionGroup}`,
            },
            {
              name: 'Activate',
              action: (item) => {
                dispatch(
                  createAction('entitlementTrackingCodes/updateTrackingCode')({
                    data: {
                      id: item.pk,
                      isForcedInactive: !item.isForcedInactive,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              },
              requires: `change_${permissionGroup}`,
            },
          ]}
          customNoDataPage={<NoTrackingCodePage />}
        />
      </div>
    </>
  );
}

export default function GamificationCampaignEntitlement() {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    gamificationCampaignDetail,
    entitlementOptionDatas,
    entitlementDetail,
    dataList,
    totalCount,
  } = useSelector((state) => ({
    gamificationCampaignDetail: state.gamificationCampaign.detail,
    entitlementOptionDatas: state.fortuneBagEntitlement.entitlementOptionDatas,
    entitlementDetail: state.fortuneBagEntitlement.entitlementDetail,
    dataList: state.entitlementTrackingCodes.pagedList,
    totalCount: state.entitlementTrackingCodes.totalCount,
  }));

  const [method, setMethod] = useState();
  const [showGenerateUniqueLinkPrompt, setShowGenerateUniqueLinkPrompt] =
    useState(false);
  const [selectedBatch, setSelectedBatch] = useState();
  const [showEditUniqueLinkPrompt, setShowEditUniqueLinkPrompt] =
    useState(false);
  const [showExportTrackingCodeView, setShowExportTrackingCodeView] =
    useState(false);
  const [formatedDataList, setFormatedDataList] = useState([]);
  const [changeTrackingCodes, setChangeTrackingCodes] = useState();

  useEffect(() => {
    if (history.location?.state?.gamificationCampaignEntitlementType) {
      setMethod(history.location?.state?.gamificationCampaignEntitlementType);
    }
    if (!history.location?.state?.gamificationCampaignEntitlementType) {
      dispatch(
        createAction('gamificationCampaign/getOneGamificationCampaign')({
          id,
          getDetailOnly: true,
          callFromEntitlement: true,
          afterAction: (gamificationCampaignEntitlementType) => {
            setMethod(gamificationCampaignEntitlementType);
          },
        }),
      );
    }
  }, []);

  const getTrackingCodeListData = () => {
    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchKey = querySearch['search'] || '';
    const stringPage = querySearch['page'] || 1;
    const page = parseInt(stringPage);
    dispatch(
      createAction('entitlementTrackingCodes/getList')({
        gamificationCampaignId: id,
        page,
        search: searchKey,
      }),
    );
  };

  useEffect(() => {
    dispatch({ type: 'fortuneBagEntitlement/clearData' });
    if (method) {
      if (method !== GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value) {
        dispatch(
          createAction('fortuneBagEntitlement/getAllEntitlements')({
            gamificationCampaignId: id,
          }),
        );
      } else {
        dispatch(
          createAction('fortuneBagEntitlement/getFirstEntitlement')({
            gamificationCampaignId: id,
          }),
        );

        dispatch({ type: 'entitlementTrackingCodes/clearData' });
        getTrackingCodeListData();
      }
    }
  }, [method]);

  useEffect(() => {
    if (method === GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value) {
      getTrackingCodeListData();
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (entitlementDetail && dataList) {
      setFormatedDataList(
        dataList.map((item) => {
          return {
            ...item,
            genericURL: `${entitlementDetail.staticLink}${item.genericURL}`,
            wechatGenericURL: `${
              entitlementDetail.wechatStaticLink
            }${encodeURIComponent(item.wechatGenericURL)}`,
          };
        }),
      );
    } else if (dataList) {
      setFormatedDataList(dataList);
    }
  }, [entitlementDetail, dataList]);

  const firstTabName = () => {
    let tab = 'Unique link';
    if (method === GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value) {
      tab = 'Generic link';
    }
    return tab;
  };

  const getTabs = () => {
    const getSections = () => {
      let sections = [];
      if (method === GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value) {
        sections.push(
          <TrackingCodeTable
            formatedDataList={formatedDataList}
            setChangeTrackingCodes={(value) => {
              setChangeTrackingCodes(value);
            }}
            setShowExportTrackingCodeView={(value) => {
              setShowExportTrackingCodeView(value);
            }}
          />,
        );
      } else {
        sections.push(
          <MerchantTable
            entitlementOptionDatas={entitlementOptionDatas}
            setSelectedBatch={setSelectedBatch}
            setShowEditUniqueLinkPrompt={setShowEditUniqueLinkPrompt}
          />,
        );
      }

      return sections;
    };

    let tabs = [];
    tabs.push({
      name: firstTabName(),
      content: (
        <>
          <div className="scroll-container-common entitlement-content-section">
            <ContentSections sections={getSections()} hidePreview={true} />
            <SaveAndBackButtons
              saveTempText={''}
              saveText={''}
              backAction={() => history.goBack()}
              saveAction={() => {}}
              saveTempAction={() => {}}
            />
          </div>
        </>
      ),
    });
    return tabs;
  };

  const getButtons = () => {
    const buttons = [];
    if (method !== GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value) {
      buttons.push(
        <AuthButton
          title="Generate link"
          action={() => {
            setShowGenerateUniqueLinkPrompt(true);
          }}
          requires={PermissionCodes.changeGamificationCampaignEntitlement}
        />,
      );
    }
    return buttons;
  };

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Entitlement',
        }}
        defaultActiveKey={firstTabName()}
        hideTab={false}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={gamificationCampaignEntitlementBreads(
              id,
              history.location?.state?.gamificationCampaignName ||
                gamificationCampaignDetail?.name,
            )}
          />
        }
        buttons={getButtons()}
        tabs={getTabs()}
      />
      <GenerateUniqueLinkPrompt
        gamificationCampaignId={id}
        show={showGenerateUniqueLinkPrompt}
        onClose={() => setShowGenerateUniqueLinkPrompt(false)}
        title={'Generate unique links'}
        rightButtonText={'Generate'}
      />
      <GenerateUniqueLinkPrompt
        gamificationCampaignId={id}
        selectedBatch={selectedBatch}
        show={showEditUniqueLinkPrompt}
        onClose={() => setShowEditUniqueLinkPrompt(false)}
        isEdit={true}
        title={'Edit remarks'}
        rightButtonText={'Done'}
      />
      <EntitlementTrackingCodeExportPrompt
        show={showExportTrackingCodeView}
        exportTotalCount={totalCount}
        closeAction={() => {
          setShowExportTrackingCodeView(false);
        }}
        exportAction={(exportType) => {
          const queryString = require('query-string');
          const querySearch = queryString.parse(location.search);
          const searchKey = querySearch['search'] || '';
          dispatch({
            type: 'downloadAndImport/createDownloadTask',
            payload: {
              from: ImportResource.entitlementTrackingCode,
              related: {
                filterConditions: JSON.stringify({
                  gamification_campaign: id,
                  search: searchKey,
                }),
                gamificationCampaign: id,
                gamificationCampaignName:
                  history.location?.state?.gamificationCampaignName ||
                  gamificationCampaignDetail?.name,
                exportTypes: exportType.join(','),
              },
            },
          });
          setShowExportTrackingCodeView(false);
        }}
      />
      <CreateTrackingCodePrompt
        show={!!changeTrackingCodes}
        onClose={() => setChangeTrackingCodes()}
        onConfirm={(payload) => {
          dispatch(
            createAction('entitlementTrackingCodes/createUpdateTrackingCodes')(
              payload,
            ),
          );
          // setChangeTrackingCodes();
        }}
        title={
          changeTrackingCodes?.code?.pk
            ? 'Edit active period'
            : 'Add tracking code'
        }
        data={{
          gamificationCampaignId: id,
          goodiebagStartDate:
            entitlementDetail?.gamificationCampaign?.displayStartDate,
          goodiebagEndDate:
            entitlementDetail?.gamificationCampaign?.displayEndDate,
          code: changeTrackingCodes?.code,
          // code: {
          //   name: '',
          //   isFollowGoodieBagPeriod: false,
          //   goodiebagStartDate: '',
          //   goodiebagEndDate: '',
          //   startDate: '',
          //   endDate: '',
          // },
        }}
      />
    </>
  );
}
