import React from 'react';
import StoreHours from './StoreHours';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import DeleteIcon from '../../../assets/images/deleteIcon.png';

const defaultHour = {
  days: [],
  isClose: false,
  openHour: '12:00:00',
  closeHour: '12:00:00',
};

export default function HolidayHours() {
  const { watch, setValue } = useFormContext();
  const watchHolidayHours = watch('holidayHours') || {};
  const params = useParams();
  const { id } = params;
  if (id && !watchHolidayHours?.isClose && !watchHolidayHours) {
    return <div />;
  }

  const openHourExist = watchHolidayHours.openHour || watchHolidayHours.pk;

  const addButton = (
    <div hidden={openHourExist} className={'add-hours-button-common'}>
      <Button
        hidden={openHourExist}
        className="btn-back-button-common add-hours-button"
        onClick={() => {
          setValue('holidayHours', defaultHour, {
            shouldDirty: true,
          });
        }}
      >
        Add new hour
      </Button>
    </div>
  );

  const holidayHour = (
    <>
      <label className="create-section-label create-section-label-bottom-space undefined">
        Public holiday business hours (optional)
      </label>
      <div className="store-hours-area-container">
        {addButton}
        {openHourExist ? (
          <>
            <div className="week-hour-title-content holiday-hours-delete">
              <Image
                src={DeleteIcon}
                className="content-delete-button"
                style={{ alignSelf: 'center', marginLeft: 'auto' }}
                onClick={() => {
                  setValue(
                    'holidayHours',
                    {},
                    {
                      shouldDirty: true,
                    },
                  );
                }}
              />
            </div>
            <StoreHours
              closeStyle="holiday-hours-close"
              hour={watchHolidayHours}
              switchChange={(value) => {
                setValue(
                  'holidayHours',
                  { ...watchHolidayHours, isClose: value },
                  {
                    shouldDirty: false,
                  },
                );
              }}
              openHourChange={(date) => {
                setValue(
                  'holidayHours',
                  { ...watchHolidayHours, openHour: date },
                  {
                    shouldDirty: false,
                  },
                );
              }}
              closeHourChange={(date) => {
                setValue(
                  'holidayHours',
                  { ...watchHolidayHours, closeHour: date },
                  {
                    shouldDirty: false,
                  },
                );
              }}
            />
          </>
        ) : null}
      </div>
    </>
  );
  return holidayHour;
}
