import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  checkHasPermission,
  createAction,
  getHashKeyString,
} from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import { missionDetailBreads } from '../../../config/CustomBreadConfig.js';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import {
  StepFourContentSection,
  StepFourPropertiesSection,
} from '../../../components/mission/CreateMissionStepFour';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import PreviewButton from '../../../components/mission/PreviewButton';
import MissionLocationList from './MissionLocationList.js';
import MissionLocationCodeExportPrompt from '../../../components/mission/MissionLocationCodeExportPrompt';
import { ImportResource } from '../../../models/DownloadImportModel';

import './MissionDetail.scss';
import {
  MISSION_CHECK_IN_MODE_TYPE,
  MISSION_TYPE,
} from '../../../models/MissionModel.js';

const queryString = require('query-string');

const tabConfig = {
  overview: 'Detail',
  list: 'Location list',
};

const MissionDetail = ({
  detail = {},
  dispatch,
  languages,
  selectedAllListLoading,
  missionLocationTotalCount,
  user,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  const [activeTab, setActiveTab] = useState(
    getHashKeyString(history.location.hash) || tabConfig.overview,
  );
  const [showExportCodeView, setShowExportCodeView] = useState(false);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('mission/getOneMission')({ id }));
    return () => {
      dispatch(createAction('mission/clearData')());
    };
  }, []);

  const tabs = [
    {
      name: tabConfig.overview,
      content:
        detail.pk && !selectedAllListLoading ? (
          <div className="scroll-container-common">
            <StepFourContentSection data={detail} />
            <ContentSections
              sections={[<StepFourPropertiesSection data={detail} />]}
              hidePreview={true}
            />
            <SaveAndBackButtons
              saveTempText={''}
              saveText={''}
              backAction={() => history.push('/mission')}
              saveAction={() => {}}
              saveTempAction={() => {}}
              extraButtons={[
                detail?.isPublished ? (
                  <PreviewButton mission={detail} fromDetail={true} />
                ) : null,
              ]}
            />
          </div>
        ) : (
          <Loading />
        ),
    },
  ];
  const hasSeeMissionLocationListPermission =
    [
      PermissionCodes.viewMission,
      PermissionCodes.changeMission,
      // PermissionCodes.viewMissionLocationQrcode,
      // PermissionCodes.changeMissionLocationQrcode,
    ].filter((val) => checkHasPermission(user, val)).length > 0;
  if (
    detail?.missionType?.value === MISSION_TYPE.CHECK_IN.value &&
    hasSeeMissionLocationListPermission
  ) {
    tabs.push({
      name: tabConfig.list,
      content: <MissionLocationList />,
    });
  }

  const getButtons = () => {
    const buttons = [];
    if (
      detail?.missionType?.value === MISSION_TYPE.CHECK_IN.value &&
      [
        MISSION_CHECK_IN_MODE_TYPE.QR_CODE.value,
        MISSION_CHECK_IN_MODE_TYPE.BOTH.value,
      ].includes(detail?.checkInMode)
    ) {
      buttons.push(
        <AuthButton
          title={'Export QR code'}
          customClass="btn-back-button-common btn-download"
          action={() => {
            setShowExportCodeView(true);
          }}
          requires={[
            PermissionCodes.viewMission,
            PermissionCodes.changeMission,
          ]}
        />,
      );
    }
    buttons.push(
      <AuthButton
        title="Edit"
        action={() => {
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeMission}
      />,
    );

    return buttons;
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={missionDetailBreads(id, detail?.name)}
          />
        }
        defaultActiveKey={activeTab}
        onTabChange={(key) => {
          let search = '';
          if (key !== tabConfig.overview) {
            search = queryString.stringify({
              page: 1,
              // sort: 'displayPriority',
              sort: '-pk',
            });
          }
          history.push({
            pathname: `/mission/${id}/`,
            hash: key,
            search: search,
          });
          setActiveTab(key);
        }}
        buttons={getButtons()}
        tabs={tabs}
      />
      <MissionLocationCodeExportPrompt
        show={showExportCodeView}
        mission={detail}
        closeAction={() => {
          setShowExportCodeView(false);
        }}
        exportTotalCount={missionLocationTotalCount}
        exportAction={(exportType) => {
          const querySearch = queryString.parse(location.search);
          const searchKey = querySearch['search'] || '';
          dispatch({
            type: 'downloadAndImport/createDownloadTask',
            payload: {
              from: ImportResource.missionLocationCode,
              related: {
                filterConditions: JSON.stringify({
                  mission: detail?.pk,
                  search: searchKey,
                }),
                mission: detail?.pk,
                missionName: detail?.name,
                exportTypes: exportType.join(','),
              },
            },
          });
          setShowExportCodeView(false);
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.mission.detail,
  languages: state.language.allList,
  selectedAllListLoading: state.mission.selectedAllListLoading,
  missionLocationTotalCount: state.missionLocation.totalCount,
  user: state.users,
});
export default connect(mapPropsToState)(MissionDetail);
