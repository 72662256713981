import React, { useEffect, useMemo, useRef, useState } from 'react';

import TablePrompt from '../base/prompt/TablePrompt';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import Filter from '../../containers/merchants/locations/Filter';

export default function LocationTablePrompt(props) {
  const dispatch = useDispatch();

  const onCancel = props.onCancel || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const title = props.title || 'Select location';
  const table = props.table || {
    title: 'After checking, click confirm to complete the selection',
    fields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      {
        displayName: 'District',
        fieldName: 'displayDistrict',
      },
      { displayName: 'Address', fieldName: 'address' },
      {
        displayName: 'Status',
        fieldName: 'status',
      },
    ],
  };

  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { pagedList, pageInfo } = useSelector((state) => ({
    pagedList: state.storeModel.pagedStoreList,
    pageInfo: state.storeModel.pageInfo,
  }));

  const baseSearch = {
    // type: 'LOCATION',
  };

  useEffect(() => {
    setDataList([]);
    setIsLoading(true);
    setIsLoadingMore(false);
    dispatch({
      type: 'storeModel/clearData',
      payload: {
        pagedList: [],
        pageInfo: {},
      },
    });
    if (props.show) {
      dispatch(
        createAction('storeModel/getPagedStoreList')({
          ...baseSearch,
          sort: '-pk',
          fromLocation: true,
          afterAction: () => {
            setIsLoading(false);
          },
        }),
      );
    }
  }, [props.show]);

  useEffect(() => {
    setDataList([...dataList, ...pagedList]);
    if (isLoadingMore) {
      setIsLoading(false);
    }
  }, [pagedList]);

  return (
    <TablePrompt
      show={props.show}
      customClass="location-table-prompt"
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={onConfirm}
      title={title}
      table={{
        ...table,
        dataList,
        isLoading,
        selectedData: props.selectedData || [],
        loadMore: (page, search) => {
          setIsLoading(true);
          setIsLoadingMore(true);
          dispatch(
            createAction('storeModel/getPagedStoreList')({
              ...baseSearch,
              ...search,
              fromLocation: true,
              page: page + 1,
            }),
          );
        },
        hasNext: pageInfo.hasNextPage,
        orderField: '-pk',
        filter: {
          filterComponent: Filter,
          filetrItems: ['District', 'attribute_tags', 'Status'],
          keywordSearch: {
            keys: [
              {
                placeholder: 'Search by district',
                search: 'districtIcontains',
              },
              {
                placeholder: 'Search by attribute tag',
                search: 'attributeTagIcontains',
              },
              {
                placeholder: 'Search by name',
                search: 'search',
              },
            ],
          },
          searchFunc: (search) => {
            setIsLoading(true);
            dispatch(
              createAction('storeModel/getPagedStoreList')({
                ...baseSearch,
                ...search,
                fromLocation: true,
                page: 1,
              }),
            );
            setDataList([]);
          },
        },
      }}
    />
  );
}
