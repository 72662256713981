import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import {
  LanguageConfig,
  TermsAndConditionsType,
} from '../../config/CustomEnums';
import { createAction, insertKeyToTranslations } from '../../utils';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
// import DeleteRowIcon from '../../assets/images/deleteIcon.png';
import DeleteRowIcon from '../../assets/images/earning/deletex1.png';
import AuthButton from '../base/AuthButton';
import { ImageButton } from '../base/CustomImageButton';
import './MissionLabelsSection.scss';

export function MissionLabelsSection({
  language,
  languages,
  translationField = 'translations',
  customTitle = 'Mission text labels',
}) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch(translationField) || {};
  const showMissionLabels = watch('showMissionLabels');
  const missionLabels = translations[language]?.missionLabels || {};
  // const missionLabelsKeys = Object.keys(missionLabels).length
  //   ? Object.keys(missionLabels)
  //   : [...Array(1).keys()];
  const missionLabelsKeys = watch('missionLabelsKeys') || [...Array(1).keys()];

  return (
    <>
      <Controller
        control={control}
        name="showMissionLabels"
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithSwitch
              title={customTitle}
              defaultValue={value}
              setValue={(value) => {
                setValue('showMissionLabels', value, { shouldDirty: true });
              }}
            />
          </>
        )}
      />
      {showMissionLabels ? (
        <>
          <div className="d-flex flex-column">
            {missionLabelsKeys
              ?.filter((key) => !missionLabels?.[key]?.isDeleted)
              ?.map((key, index) => {
                return (
                  <div key={`mission-label-${key}`}>
                    <Controller
                      control={control}
                      name={`${translationField}.${language}.missionLabels.${key}.isDeleted`}
                      render={() => () => {}}
                    />
                    <Controller
                      control={control}
                      name={`${translationField}.${language}.missionLabels.${key}.title`}
                      render={({ field: { value } }) => {
                        return (
                          <div className="mission-label-container">
                            <CustomTitleLabel title={`${index + 1}`} />
                            <input
                              type={'text'}
                              maxLength={200}
                              onChange={({ target }) =>
                                setValue(
                                  `${translationField}.${language}.missionLabels.${key}.title`,
                                  target.value,
                                  {
                                    shouldDirty: true,
                                  },
                                )
                              }
                              value={value}
                              className={`custom-markdown-area-title custom-markdown-area-title-short`}
                            />
                            {index > 0 ? (
                              <ImageButton
                                action={() => {
                                  // setValue(
                                  //   `${translationField}.${language}.missionLabels.${key}.isDeleted`,
                                  //   true,
                                  //   {
                                  //     shouldDirty: true,
                                  //   },
                                  // );
                                  const temp = languages?.forEach((item) => {
                                    setValue(
                                      `${translationField}.${
                                        item.code || item
                                      }.missionLabels.${key}.isDeleted`,
                                      true,
                                      {
                                        shouldDirty: true,
                                      },
                                    );
                                  });
                                }}
                                image={DeleteRowIcon}
                                customClass="delete-icon"
                              />
                            ) : (
                              <ImageButton
                                action={() => {}}
                                image={DeleteRowIcon}
                                customClass="delete-icon hidden"
                              />
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                );
              })}
            <ErrorFieldMessage
              id={`${translationField}.${language}.missionLabels`}
              error={hasError(
                errors,
                `${translationField}.${language}.missionLabels`,
              )}
              message={errorMessage(
                errors,
                `${translationField}.${language}.missionLabels`,
              )}
            />
          </div>
          <AuthButton
            title={'Add text label'}
            customClass={
              'general-section-add-new btn-add-button-common mission-label-add-btn'
            }
            action={() => {
              const lastKey = missionLabelsKeys[missionLabelsKeys.length - 1];
              // setValue(
              //   `${translationField}.${language}.missionLabels`,
              //   {
              //     ...missionLabels,
              //     [parseInt(lastKey) + 1]: {
              //       isDeleted: false,
              //       title: null,
              //     },
              //   },
              //   {
              //     shouldDirty: true,
              //   },
              // );
              setValue(
                'missionLabelsKeys',
                missionLabelsKeys.concat([parseInt(lastKey) + 1]),
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        </>
      ) : null}
    </>
  );
}

export function CampaignMissionLabelsSection({
  language,
  languages,
  translationField = 'translations',
}) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch(translationField) || {};
  const showMissionLabels = watch('showMissionLabels');
  const missionLabels = translations[language]?.missionLabels || {};
  // const missionLabelsKeys = Object.keys(missionLabels).length
  //   ? Object.keys(missionLabels)
  //   : [...Array(1).keys()];
  const missionLabelsKeys = watch('missionLabelsKeys') || [...Array(1).keys()];
  // console.log(
  //   '???222',
  //   language,
  //   getValues(),
  //   getValues([translationField, `${translationField}.${language}`]),
  // );

  return (
    <>
      <Controller
        control={control}
        name="showMissionLabels"
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithSwitch
              title={'Mission text labels'}
              defaultValue={value}
              setValue={(value) => {
                setValue('showMissionLabels', value, { shouldDirty: true });
              }}
            />
          </>
        )}
      />
      {showMissionLabels ? (
        <>
          <div className="d-flex flex-column">
            {missionLabelsKeys
              ?.filter((key) => !missionLabels?.[key]?.isDeleted)
              ?.map((key, index) => {
                const title = watch(
                  `${translationField}.${language}.missionLabels.${key}.title`,
                );
                return (
                  <div key={`mission-label-${key}`}>
                    <div className="mission-label-container">
                      <CustomTitleLabel title={`${index + 1}`} />
                      <input
                        type={'text'}
                        maxLength={200}
                        onChange={({ target }) => {
                          // insertKeyToTranslations({
                          //   language,
                          //   key: `missionLabels.${key}.title`,
                          //   value: target.value,
                          //   setValue,
                          //   getValues,
                          // });
                          const [translations] =
                            getValues(['translations']) || {};
                          const newTransaltion = {
                            ...translations,
                            [language]: {
                              ...(translations?.[language] || {}),
                              missionLabels: {
                                ...((translations?.[language] || {})
                                  .missionLabels || {}),
                                [key]: {
                                  ...(((translations?.[language] || {})
                                    .missionLabels || {})[key] || {}),
                                  title: target.value,
                                },
                              },
                            },
                          };
                          console.log(
                            'newTransaltion language:',
                            newTransaltion,
                          );
                          setValue('translations', newTransaltion, {
                            shouldDirty: true,
                          });
                        }}
                        value={title}
                        className={`custom-markdown-area-title custom-markdown-area-title-short`}
                      />
                      {index > 0 ? (
                        <ImageButton
                          action={() => {
                            // setValue(
                            //   `${translationField}.${language}.missionLabels.${key}.isDeleted`,
                            //   true,
                            //   {
                            //     shouldDirty: true,
                            //   },
                            // );
                            const temp = languages?.forEach((item) => {
                              // insertKeyToTranslations({
                              //   language,
                              //   key: `missionLabels.${key}.isDeleted`,
                              //   value: true,
                              //   setValue,
                              //   getValues,
                              // });
                              const languageValue = item.code || item;
                              const [translations] =
                                getValues(['translations']) || {};
                              const newTransaltion = {
                                ...translations,
                                [languageValue]: {
                                  ...(translations?.[languageValue] || {}),
                                  missionLabels: {
                                    ...((translations?.[languageValue] || {})
                                      .missionLabels || {}),
                                    [key]: {
                                      ...(((translations?.[languageValue] || {})
                                        .missionLabels || {})[key] || {}),
                                      isDeleted: true,
                                    },
                                  },
                                },
                              };
                              console.log(
                                'newTransaltion language:',
                                newTransaltion,
                              );
                              setValue('translations', newTransaltion, {
                                shouldDirty: true,
                              });
                            });
                          }}
                          image={DeleteRowIcon}
                          customClass="delete-icon"
                        />
                      ) : (
                        <ImageButton
                          action={() => {}}
                          image={DeleteRowIcon}
                          customClass="delete-icon hidden"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            <ErrorFieldMessage
              id={`${translationField}.${language}.missionLabels`}
              error={hasError(
                errors,
                `${translationField}.${language}.missionLabels`,
              )}
              message={errorMessage(
                errors,
                `${translationField}.${language}.missionLabels`,
              )}
            />
          </div>
          <AuthButton
            title={'Add text label'}
            customClass={
              'general-section-add-new btn-add-button-common mission-label-add-btn'
            }
            action={() => {
              const lastKey = missionLabelsKeys[missionLabelsKeys.length - 1];
              // setValue(
              //   `${translationField}.${language}.missionLabels`,
              //   {
              //     ...missionLabels,
              //     [parseInt(lastKey) + 1]: {
              //       isDeleted: false,
              //       title: null,
              //     },
              //   },
              //   {
              //     shouldDirty: true,
              //   },
              // );
              setValue(
                'missionLabelsKeys',
                missionLabelsKeys.concat([parseInt(lastKey) + 1]),
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        </>
      ) : null}
    </>
  );
}
