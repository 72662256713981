import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  LanguageConfig,
  EMAIL_REG,
  StoreType,
} from '../../../config/CustomEnums';
import {
  hasError,
  errorMessage,
} from '../../../components/base/ErrorFieldMessage';
import {
  insertKeyToTranslations,
  createAction,
  insertKeyToAllLanguages,
} from '../../../utils';
import i18n from '../../../I18n';
import { useHistory } from 'react-router-dom';
import ImageUploader from '../../../components/base/ImageUploader';
import LabelWithSwitch, {
  ApplySwitch,
} from '../../../components/base/LabelWithSwitch';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';

export default function KeyVisualSection({ language, languages, focusChange }) {
  const { register, watch, getValues, setValue, control, formState } =
    useFormContext();
  const { errors } = formState;

  const translations = watch('storeTranslations') || {};
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const coverPhoto = translations[language]?.coverPhoto || '';
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );
  const detailPhotos = translations[language]?.detailPhotos || [];

  return (
    <>
      <div className="first-section-title">
        {i18n.t('key_visual', { locale: 'en' })}
      </div>
      <div className={'create-section-label create-section-label-bottom-space'}>
        Cover photo
      </div>
      <div className="second-section_description">
        Suggested Image Size: 1902 px * 1428 px At least : 634 px * 476 px(Less
        than 2MB, support JPG, PNG and GIF only)
      </div>
      <Controller
        control={control}
        name={`storeTranslations.${language}.coverPhoto`}
        rules={{ required: StoreErrorHandleFields.coverPhoto.required }}
        render={({ field: { value } }) => (
          <ImageUploader
            minWidth={634}
            minHeight={476}
            images={value ? [value] : []}
            disabled={
              applyCoverPhotoToAllLanguage &&
              language &&
              language !== LanguageConfig.english
            }
            onImageStateChange={(newState) => {
              const applyLanguages = applyCoverPhotoToAllLanguage
                ? languages
                : [language];
              applyLanguages.forEach((item) => {
                setValue(
                  `storeTranslations.${item?.code || item}.coverPhoto`,
                  newState?.[0] || null,
                  {
                    shouldDirty: true,
                  },
                );
              });
            }}
            aspect={634 / 476}
            maxImageNum={1}
            language={language}
            uploadImageClicked={() => {}}
            errorMessage={
              hasError(errors, `storeTranslations.${language}.coverPhoto`)
                ? errorMessage(
                    errors,
                    `storeTranslations.${language}.coverPhoto`,
                  )
                : ''
            }
          />
        )}
      />
      <ApplySwitch
        disabled={
          language === LanguageConfig.english &&
          !(coverPhoto?.value || coverPhoto)
        }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            languages.forEach((item) => {
              setValue(
                `storeTranslations.${item?.code || item}.coverPhoto`,
                coverPhoto,
                {
                  shouldDirty: true,
                },
              );
            });
          }
        }}
      />
      <div className={'create-section-label create-section-label-bottom-space'}>
        Other photos (max. 9) (optional)
      </div>
      <div className="second-section_description">
        Suggested Image Size: 1902 px * 1428 px At least : 634 px * 476 px(Less
        than 2MB, support JPG, PNG and GIF only)
      </div>
      <Controller
        control={control}
        name={`storeTranslations.${language}.detailPhotos`}
        render={({ field: { value } }) => (
          <ImageUploader
            minWidth={634}
            minHeight={476}
            images={value}
            disabled={
              applyDetailPhotosToAllLanguage &&
              language &&
              language !== LanguageConfig.english
            }
            onImageStateChange={(newState) => {
              const applyLanguages = applyDetailPhotosToAllLanguage
                ? languages
                : [language];
              applyLanguages.forEach((item) => {
                setValue(
                  `storeTranslations.${item?.code || item}.detailPhotos`,
                  newState || [],
                  {
                    shouldDirty: true,
                  },
                );
              });
            }}
            aspect={634 / 476}
            maxImageNum={9}
            language={language}
            uploadImageClicked={() => {}}
            errorMessage={
              hasError(errors, `storeTranslations.${language}.detailPhotos`)
                ? errorMessage(
                    errors,
                    `storeTranslations.${language}.detailPhotos`,
                  )
                : ''
            }
          />
        )}
      />
      <ApplySwitch
        disabled={language === LanguageConfig.english && !detailPhotos?.length}
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            languages.forEach((item) => {
              setValue(
                `storeTranslations.${item?.code || item}.detailPhotos`,
                detailPhotos,
                {
                  shouldDirty: true,
                },
              );
            });
          }
        }}
      />
    </>
  );
}
