import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
} from '../../../models/StoreModel';
import { SavedStatus, StoreType } from '../../../config/CustomEnums';
import { createLocationBreads } from '../../../config/CustomBreadConfig';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateStoreStepOne from '../stores/CreateStoreStepOne';
import Loading from '../../../components/base/Loading';
import '../stores/CreateStore.scss';

function CreateLocation() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const {
    store,
    formHasSubmitted,
    saved,
    currentStep,
    stepConfig,
    hasUpdatedDefaultValues,
  } = useSelector((state) => ({
    store: state.storeModel.oneStore,
    formHasSubmitted: state.storeModel.formHasSubmitted,
    saved: state.storeModel.saved,
    currentStep: state.storeModel.currentStep,
    stepConfig: state.storeModel.locationStepConfig,
    hasUpdatedDefaultValues: state.storeModel.hasUpdatedDefaultValues,
  }));

  const stepSet = [
    <CreateStoreStepOne
      hideContinueButtons={true}
      fromLocationList={true}
      storeType={StoreType.LOCATION}
    />,
  ];
  const goToLocationList = () =>
    history.push({ pathname: '/locations', search: '?sort=-pk' });

  useEffect(() => {
    if (id) {
      dispatch(createAction('storeModel/getOneStore')({ id }));
    } else {
      dispatch({ type: 'storeModel/loadLocationDataFromCookie' });
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });
    dispatch({ type: 'attributeTag/getAllList' });
    return () => {
      dispatch({ type: 'storeModel/clearData' });
      dispatch({ type: 'attributeTag/clearData' });
    };
  }, []);

  useEffect(() => {
    if (id && saved === SavedStatus.savedWithSuccess) {
      goToLocationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  if (!store?.id && id && store?.storeType !== StoreType.LOCATION) {
    return <Loading customClass={'common-full-height '} />;
  }
  if (store.isFollowBrandAttribute === undefined) {
    store.isFollowBrandAttribute = false;
  }
  store.storeType = StoreType.LOCATION;

  return (
    <BaseForm
      defaultValues={store}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues(), true);
        } else {
          removeDataFromSessionStorage(true);
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: goToLocationList,
        onClose: goToLocationList,
      }}
      nextStepConfig={{
        title: `Successfully ${id ? 'Updated' : 'Created'}!`,
        description: `Location is successfully ${id ? 'updated' : 'created'}.`,
        steps: null,
        buttons: [
          {
            text: 'Back to location list',
            action: () =>
              history.push({ pathname: '/locations', search: '?sort=-pk' }),
          },
        ],
      }}
      content={stepSet}
      currentStep={0}
      breadcrumb={
        <CustomBreadcrumb
          breadcrumbs={createLocationBreads(id, id ? store.storeName : '')}
        />
      }
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: id ? store.storeName : 'Create Location',
      }}
    />
  );
}
export default CreateLocation;
