import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import BasePrompt from '../base/prompt/BasePrompt';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { createAction } from '../../utils/index.js';
import './GenerateUniqueLinkPrompt.scss';

function GenerateUniqueLinkPrompt({
  gamificationCampaignId,
  selectedBatch = null,
  show,
  onClose = () => {},
  isEdit = false,
  title,
  rightButtonText,
}) {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState();
  const [remarks, setRemarks] = useState();

  useEffect(() => {
    if (show) {
      setAmount();
      setRemarks(selectedBatch?.remarks || '');
    }
  }, [show]);

  const getBody = () => {
    return (
      <div className="gamification-campaign-generate-unique-link-content">
        {isEdit ? null : (
          <>
            <CustomTitleWithInput
              title="No. of links to be generated"
              customClass="create-mission-input"
              type="number"
              defaultValue={amount}
              useDefaultValue={false}
              setValue={(val) => {
                setAmount(val);
              }}
            />
          </>
        )}
        <label
          className={`create-section-label create-section-label-bottom-space `}
        >
          Remarks
        </label>
        <textarea
          onChange={({ target }) => {
            setRemarks(target.value);
          }}
          className="custom-textarea-remakrs"
          onFocus={() => {}}
          value={remarks}
          placeholder="Please input remarks"
        />
      </div>
    );
  };

  return (
    <BasePrompt
      show={show}
      customClass={'gamification-campaign-generate-unique-link-prompt'}
      closeAction={onClose}
      rightButton={{
        text: rightButtonText,
        action: () => {
          if (isEdit) {
            dispatch({
              type: 'gamificationCampaign/updateEntitlementUniqueLink',
              payload: {
                gamificationCampaignBatchPk: selectedBatch?.pk,
                data: {
                  remarks,
                },
                afterAction: () => {
                  onClose();
                  dispatch(
                    createAction('fortuneBagEntitlement/getAllEntitlements')({
                      gamificationCampaignId,
                    }),
                  );
                },
              },
            });
          } else {
            dispatch({
              type: 'gamificationCampaign/generateEntitlementUniqueLink',
              payload: {
                gamificationCampaignPk: gamificationCampaignId,
                data: {
                  amount,
                  remarks,
                },
                afterAction: () => {
                  onClose();
                  dispatch(
                    createAction('downloadAndImport/getSystemTasks')({}),
                  );
                },
              },
            });
          }
        },
        disabled: !isEdit && !amount,
      }}
      leftButton={{
        text: 'Cancel',
        action: onClose,
      }}
      title={title}
      otherBody={getBody}
    />
  );
}

export default GenerateUniqueLinkPrompt;
