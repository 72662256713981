import { useRef, useEffect, useState } from 'react';
import { ImageDomain, LanguageConfig } from '../config/CustomEnums';
import i18n from '../I18n';
import { IMAGE_TYPES, ERROR_TYPE } from '../models/UploadFilesModel';
import copy from 'copy-to-clipboard';
import { ToastType } from '../models/NavBarModel';
import { PermissionCodes } from '../config/PermissionCodes';
import _ from 'lodash';

export const GOOGLE_API_KEY =
  process.env.GOOGLE_API_KEY || 'AIzaSyB6UcOzZRR8w3TATqj2QDXV5Afhugfrmm8';

export const ERROR_MESSAGE = {
  MISS_FIELD: 'Missing field.',
  INVAILD_URL: `Please enter a correct formatted URL. Starts with " http:// " or " https:// " .`,
  REQUIRED_FIELD: 'Required fields',
};

export const deleteMessage = (items, related) =>
  `${
    items.length > 1
      ? 'These ' +
        items.slice(0, items.length - 1).join(', ') +
        ' and ' +
        items.slice(items.length - 1) +
        ' are'
      : 'This ' + items.join(', ') + ' is'
  } associated with some ${
    related.length > 1
      ? related.slice(0, related.length - 1).join(', ') +
        ' and ' +
        related.slice(related.length - 1)
      : related.join(', ')
  }, once deleted, it will affect other content. Are you sure to delete?`;

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));
export const createNumberArray = (numberSize) =>
  Array.from(Array(numberSize).keys());

export const anchorElementWithId = (id) => {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  window.scrollTo(0, element.offsetTop - 124);
};

export const getFileNameFromUrl = (url) => {
  const mediaPath = 'media/';
  if (!url || url.lastIndexOf(mediaPath) === -1) {
    return url;
  }
  const start = url.lastIndexOf(mediaPath) + mediaPath.length;
  if (url.lastIndexOf('?') === -1) {
    return url.substring(start);
  }

  const end = url.indexOf('?');
  return url.substring(start, end);
};

export function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && (image.value || Object.keys(image).includes('value'))) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl) || null;
}

export const detectIsSafari = () => {
  return window.navigator.userAgent?.indexOf('Safari') !== -1;
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const getImageUrl = (data) => {
  if (!data) {
    return '';
  }
  if (data.type !== IMAGE_TYPES.TYPE_URL) {
    return '';
  }
  return data.value;
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const addDomainToImage = (imageName) => {
  if (!imageName) {
    return imageName;
  }
  if (imageName.indexOf('http') > -1) {
    return imageName;
  }
  return `${ImageDomain}${imageName}`;
};

export const gotoOutWeb = (link, callback) => {
  if (typeof callback === 'function') {
    callback();
  }
  window.open(link, '_blank');
};

export const getHashKeyString = (hash) => {
  if (!hash) {
    return '';
  }
  const hashKey = hash.slice(1, hash.length);
  return decodeURI(hashKey);
};

export const convertNumberToCursor = (index) => {
  const numberString = `arrayconnection:${index}`;
  return btoa(numberString);
};
export const convertPKToId = (nodeName, PK) => {
  if (!PK) {
    return PK;
  }
  const idString = `${nodeName}:${PK}`;
  return btoa(idString);
};

export const convertCursorToNumber = (base64) => {
  if (Number.isInteger(base64)) {
    return base64;
  }
  const decodedString = atob(base64);
  const numberString = decodedString.substring(
    decodedString.lastIndexOf(':') + 1,
  );
  return parseInt(numberString);
};

export const saveToSessionStorage = (key, object) => {
  window.sessionStorage.setItem(key, JSON.stringify(object));
};

export const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getObjectFromSessionStorage = (key) => {
  const allcookies = sessionStorage.getItem(key);
  if (allcookies) {
    return JSON.parse(allcookies);
  }
  return allcookies;
};

export const addToSessionStorage = (key, object) => {
  console.log('save cookies:', object);

  const originalCookie = getObjectFromSessionStorage(key);
  const newCookie = { ...originalCookie, ...object };
  window.sessionStorage.setItem(key, JSON.stringify(newCookie));
};

const tokenRequiredAction = [
  'brand/getBrand',
  'brand/getCurrentPageBrands',
  'brand/updateBrand',
  'brand/delete',
  'brand/duplicate',
  'brand/createBrand',
  'couponList/getCouponTemplateList',
  'couponList/getCurrentPageTemplateList',
  'singleCoupon/getCurrentPageSingleCouponList',
  'singleCoupon/getAllSingleCoupons',
  'singleCoupon/delete',
  'singleCoupon/deactiveSingleCoupon',
  'singleCoupon/activeSingleCoupon',
  'singleCoupon/deactiveCoupons',
  'singleCoupon/activeCoupons',
  'singleCoupon/grantSingleCoupon',
  'singleCoupon/manualUseCoupon',
  'couponList/deleteCouponSet',
  'couponList/delete',
  'couponList/duplicate',
  'couponList/updateCouponSetActiveStatus',
  'couponList/getCouponSet',
  'createCoupon/createCouponTemplate',
  'createCoupon/createCouponTemplateTranslation',
  'createCoupon/generateCoupon',
  'campaignList/getCampaignList',
  'customerList/getCustomerGroups',
  'customerList/getAllCustomers',
  'customerList/getPagedCustomers',
  'customerList/getOneCustomer',
  'customerList/updateCustomer',
  'customerList/getCustomerActivityLog',
  'earningRuleList/getEarningRuleListWithTypes',
  'earningRuleList/delete',
  'uploadFiles/uploadCroppedImage',
  'uploadFiles/uploadFile',
  'campaignList/publish',
  'campaignList/duplicate',
  'createCampaign/createOrUpdateCampaign',
  'createCampaign/createCamapigncategory',
  'createCampaign/getOneCampaign',
  'createCampaign/duplicateCampaign',
  'createCampaign/getAndLinkCouponDetail',
  'createEarningRules/createOrUpdateEarningRule',
  'createEarningRules/getOneEarningRule',
  'storeModel/getAllStores',
  'createCampaign/getAndLinkEarningRuleDetail',
  'segments/deleteSegments',
  'segments/getSegmentsList',
  'levels/getLevelList',
  'segments/createOrUpdateSegment',
  'segments/getOneSegment',
  'downloadAndImport/getSystemTasks',
  'downloadAndImport/importFile',
  'downloadAndImport/createDownloadTask',
  'downloadAndImport/downloadFile',
  'downloadAndImport/updateTask',
  'downloadAndImport/cancelTask',
  'couponTransactions/getCurrentPageCouponTransactions',
  'couponTransactions/getSingleCouponTransactionRecord',
  'dashboard/getDashboardData',
  'dashboard/getTop10CouponRedeemData',
  'transactions/getCurrentPageTransactions',
  'transactions/getTransaction',
  'transactions/approveTransaction',
  'transactions/rejectTransaction',
  'transactions/recallTransaction',
  'transactions/deleteTransaction',
  'createTransaction/createTransaction',
  'pointTransactionList/getCurrentPagePointRecords',
  'pointTransactionList/getSinglePointRecord',
  'admin/getPagedAdmins',
  'admin/delete',
  'admin/updateAdminStatus',
  'admin/duplicate',
  'admin/getOneAdmin',
  'admin/updateOrCreate',
  'adminGroup/getPagedAdminGroups',
  'adminGroup/delete',
  'adminGroup/duplicate',
  'adminGroup/getOneAdminGroup',
  'adminGroup/updateOrCreate',
  'admin/getAllPermissions',
  'admin/getAllAdmins',
  'customerGroup/getGroupList',
  'customerGroup/getCurrentPageList',
  'customerGroup/getCustomerGroup',
  'customerGroup/delete',
  'customerGroup/duplicate',
  'customerGroup/createCustomerGroup',
  'customerGroup/updateCustomerGroup',
  'overview/getCustomersData',
  'overview/getCouponsData',
  'overview/getCampaignsData',
  'messageList/getCurrentPageMessages',
  'messageList/getMessage',
  'messageList/delete',
  'messageList/duplicate',
  'createMessage/createMessage',
  'createMessage/createAndUpdateMessageTranslation',
  'createMessage/testMessageSend',
  'createMessage/updateMessage',
  'campaignCategoryList/getCampaignCategoryList',
  'createCampaignCategory/getCategory',
  'createCampaignCategory/createCampaignCategory',
  'createCampaignCategory/updateCategoryActiveStatus',
  'createCampaignCategory/updateCategoryOrder',
  'createCampaignCategory/deleteCategories',
  'createCampaignCategory/duplicateCategory',
  'storeModel/getPagedStoreList',
  'storeModel/getOneStore',
  'storeModel/delete',
  'storeModel/createOrUpdate',
  'storeModel/getDistrictList',
  'storeCategoryList/getStoreCategoryList',
  'createStoreCategory/getCategory',
  'storeCategoryList/getAllStoreSubcategoryList',
  'bannerList/getPageBannerList',
  'bannerList/duplicate',
  'bannerList/createOrUpdateBanner',
  'bannerList/delete',
  'bannerList/getOneBanner',
  'targetMarket/getList',
  'targetMarket/createOrUpdate',
  'targetMarket/delete',
  'logoLabel/getList',
  'offerTypeLabel/getList',
  'attributeTag/getList',
  'channel/getList',
  'channel/createOrUpdate',
  'channel/delete',
  'surveyForm/getList',
  'surveyForm/createOrUpdate',
  'surveyForm/delete',
  'language/getList',
  'language/createOrUpdate',
  'language/delete',
  'attributeTagKey/getList',
  'attributeTagKey/createOrUpdate',
  'attributeTagKey/delete',
  'paymentSettlementList/getList',
  'paymentSettlementList/getListByName',
  'paymentSettlementList/getListByMerchant',
  'paymentSettlementList/updatePaymentSettlementReportStatus',
  'interestPreference/getOneDetail',
  'interestPreference/getList',
  'generalAlert/getList',
  'generalAlert/getOneDetail',
  'customerList/getInterestPreferences',
];

export const createAction = (type) => (payload) => {
  // if (
  //   tokenRequiredAction.includes(type) &&
  //   !((payload || {}).fromRefresh || false)
  // ) {
  //   const newPayload = payload || {};
  //   newPayload.action = type;
  //   return { type: 'users/refreshAccessToken', payload: newPayload };
  // }
  if (type) {
    return { type, payload: payload || {} };
  }
};

export const removeElementFromArray = (array, value) => {
  return array.filter(function (ele) {
    return ele !== value;
  });
};

export const removeElementFromArrayByPK = (array, value) => {
  return array.filter(function (ele) {
    return ele.pk !== value.pk;
  });
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useCompare = (val: any) => {
  const prevVal = usePrevious(val);
  // console.log('@@174: ', prevVal, val);
  // console.log(
  //   '@@341: ',
  //   JSON.stringify(prevVal),
  //   JSON.stringify(val),
  //   _.isEqual(JSON.stringify(prevVal), JSON.stringify(val)),
  // );
  return prevVal && !_.isEqual(JSON.stringify(prevVal), JSON.stringify(val));
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const getError = (field, errorFields, errorHandle) => {
  return {
    id: field,
    error: errorFields?.fields?.includes(field),
    message: errorFields?.messages?.map((item) => {
      if (item.field === field) {
        if (item.errorType === 'required')
          return errorHandle[item.errorType][item.field];
        else return errorHandle['others'][item.field][item.errorType];
      }
    }),
  };
};

export const enLocaleNumberFormatter = (num) => {
  if (!num) {
    return 0;
  }
  const stringNumber = num.toString();
  const commaIndex = stringNumber.indexOf('.');
  if (commaIndex === -1) {
    return i18n.toNumber(stringNumber, { precision: 0 });
  }
  const stringNeedFormatting = stringNumber.substring(0, commaIndex);
  const stringEnd = stringNumber.substring(commaIndex);
  const stringFormated = i18n.toNumber(stringNeedFormatting, { precision: 0 });
  return `${stringFormated}${stringEnd}`;
};

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeUndefinedFieldFromDict = (data) => {
  let validData = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        validData[key] = data[key];
      }
    });
  }

  return validData;
};

export const rewritePermission = (requires) => {
  return requires?.indexOf('delete') === 0 || requires?.indexOf('add') === 0
    ? `change_${requires.split('_')[1]}`
    : requires;
};

export const checkHasPermission = (user, requires) => {
  if (user?.isSuperuser) return true;

  const newRequires = rewritePermission(requires);

  if (user?.userPermissions?.includes(newRequires) || !requires) return true;

  return false;
};

export const ArrayStringData = (toSplitIntData) => {
  return toSplitIntData
    .split(',')
    .filter(function (ele) {
      return ele !== '';
    })
    .map((el) => {
      let number = Number(el);
      return number === 0 ? number : number || el;
    });
};

export const reducer = (accumulator, currentValue) =>
  accumulator + currentValue;

export const copyCampaignLink = (dispatch, id, language = 'en') => {
  const domain = process.env.REACT_APP_FRONTEND_LINK;
  copy(`${domain}/${language}/campaign-detail?campaignId=${id}`);
  dispatch({
    type: 'navBars/updateState',
    payload: {
      saveDiscardToastShowing: {
        value: true,
        type: ToastType.copied,
      },
    },
  });
};

export const extraQueryParameterString = (parameterKey, parameterValue) =>
  `, ${parameterKey}: ${parameterValue}`;

export const isValidHttpUrl = (string) => {
  if (!string) {
    return false;
  }
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

var he = require('he');
export const stripedHtml = (htmlString) =>
  htmlString ? htmlString?.replace(/<[^>]+>/g, '') : '';
export const decodedStripedHtml = (htmlString) =>
  he.decode(stripedHtml(htmlString));

export const insertKeyToTranslations = ({
  language,
  key,
  value,
  setValue,
  getValues,
}) => {
  // console.log('newTransaltion language:', language, key);
  // setValue(`translations.${language}.${key}`, value, {
  //   shouldDirty: true,
  // });
  const [translations] = getValues(['translations']) || {};
  const newTransaltion = {
    ...translations,
    [language]: {
      ...(translations?.[language] || {}),
      [key]: value,
    },
  };
  console.log('newTransaltion language:', newTransaltion);
  setValue('translations', newTransaltion, { shouldDirty: true });
};

export const insertKeyToAllLanguages = ({
  languages = [],
  key,
  value,
  setValue,
  getValues,
}) => {
  languages.forEach((item) => {
    insertKeyToTranslations({
      key,
      value,
      setValue,
      getValues,
      language: item.code || item,
    });
  });
};

export const arrayGroupBy = (items, key) =>
  items.reduce((result, item) => {
    if (!item) {
      return result;
    }

    return {
      ...result,
      [item?.[key]]: [...(result?.[item[key]] || []), item],
    };
  }, {});

export const checkUrlIsValid = (value) => {
  const validUrlReg =
    /\bhttps?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const result = validUrlReg.test(value);
  return result;
};

export const useDeviceDetect = (maxWidth = 1024) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return width <= maxWidth;
};

export const isArrayEmpty = (data) => {
  return !data || data?.length === 0;
};

export const isJsonFile = (value) => {
  if (Array.isArray(value)) return false;
  if (!(typeof value === 'string' || value instanceof String)) return false;
  return value?.toLowerCase().endsWith('.json') || false;
};

export function getNumberWithOrdinal(num) {
  let j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return num + 'st';
  }
  if (j === 2 && k !== 12) {
    return num + 'nd';
  }
  if (j === 3 && k !== 13) {
    return num + 'rd';
  }
  return num + 'th';
}

export function getFieldValue(data, fieldName) {
  let fieldValue = { ...data };
  const names = fieldName.split('.');
  for (let i = 0; i < names.length; i++) {
    fieldValue = fieldValue[names[i]];
  }
  return fieldValue;
}

export const filterWorkingTeams = (
  userIsSuperuser,
  userWorkingTeamIds,
  watchWorkingTeams,
) => {
  if (userIsSuperuser) {
    if (!watchWorkingTeams || watchWorkingTeams?.length <= 0) {
      return '';
    } else {
      return `${watchWorkingTeams.map((item) => item.pk).join()},null`;
    }
  }

  if (watchWorkingTeams?.length <= 0) {
    if (userWorkingTeamIds?.length > 0) {
      return `${userWorkingTeamIds.map((val) => val && val.pk).join()},null`;
    } else {
      return 'null';
    }
  } else {
    if (userWorkingTeamIds?.length > 0) {
      return `${userWorkingTeamIds
        .filter(
          (val) =>
            watchWorkingTeams?.filter((item) => item.pk === val.pk).length > 0,
        )
        .map((val) => val && val.pk)
        .join()},null`;
    } else {
      return 'null';
    }
  }
};
