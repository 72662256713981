import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

export class InfiniteScrollOverride extends InfiniteScroll {
  static propTypes = {
    children: PropTypes.node.isRequired,
    element: PropTypes.node,
    hasMore: PropTypes.bool,
    initialLoad: PropTypes.bool,
    isReverse: PropTypes.bool,
    loader: PropTypes.node,
    loadMore: PropTypes.func.isRequired,
    pageStart: PropTypes.number,
    ref: PropTypes.func,
    getScrollParent: PropTypes.func,
    threshold: PropTypes.number,
    useCapture: PropTypes.bool,
    useWindow: PropTypes.bool,
    isLoading: PropTypes.bool,
    isResetPage: PropTypes.bool,
  };

  static defaultProps = {
    element: 'div',
    hasMore: false,
    initialLoad: true,
    pageStart: 0,
    ref: null,
    threshold: 250,
    useWindow: true,
    isReverse: false,
    useCapture: false,
    loader: null,
    getScrollParent: null,
    isLoading: false,
    isResetPage: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    super.componentDidUpdate();

    if (this.props.isResetPage) {
      this.pageLoaded = this.props.pageStart;
    }
  }

  /**
   * We are overriding the getParentElement function to use a custom element as the scrollable element
   *
   * @param {any} el the scroller domNode
   * @returns {any} the parentNode to base the scroll calulations on
   *
   * @memberOf InfiniteScrollOverride
   */
  getParentElement(el) {
    //return el;
    return document.querySelector('.table-scroll-area'); // change it to your scrollable parent container element
  }

  scrollListener() {
    const el = this.scrollComponent;
    const scrollEl = window;
    const parentNode = this.getParentElement(el);

    let offset;
    if (this.props.useWindow) {
      const doc =
        document.documentElement || document.body.parentNode || document.body;
      const scrollTop =
        scrollEl.pageYOffset !== undefined
          ? scrollEl.pageYOffset
          : doc.scrollTop;
      if (this.props.isReverse) {
        offset = scrollTop;
      } else {
        offset = this.calculateOffset(el, scrollTop);
      }
    } else if (this.props.isReverse) {
      offset = parentNode.scrollTop;
    } else {
      offset = el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight;
    }

    // Here we make sure the element is visible as well as checking the offset
    if (
      offset < Number(this.props.threshold) &&
      el &&
      el.offsetParent !== null &&
      !this.props.isLoading
    ) {
      this.detachScrollListener();
      this.beforeScrollHeight = parentNode.scrollHeight;
      this.beforeScrollTop = parentNode.scrollTop;
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (typeof this.props.loadMore === 'function') {
        this.props.loadMore((this.pageLoaded += 1));
        this.loadMore = true;
      }
    }
  }

  render() {
    return super.render();
  }
}
