import React, { useState } from 'react';
import './PublishMissionPrompt.scss';
import { useHistory } from 'react-router-dom';
import NextStepPrompt from '../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';

function PublishMissionPrompt(props) {
  const defaultShow = props.show || false;
  const isPublish = props.isPublish;
  const isSave = props.isSave;

  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  if (!show && defaultShow) {
    setShow(true);
  }

  const getTitle = () => {
    if (isSave) {
      return 'Successfully Saved!';
    }
    if (isPublish) {
      return 'Successfully Published!';
    }
    return 'Successfully UnPublished!';
  };

  const getDescription = () => {
    if (isSave) {
      return `Mission is successfully saved.
      You can publish by edit the status.`;
    }
    if (isPublish) {
      return `Mission is successfully published.`;
    }
    return `Mission is successfully unpublished.
    You can publish again by edit the status.`;
  };

  const description = getDescription();
  const title = getTitle();
  const buttons = [
    {
      text: 'Back to mission listing page',
      action: () =>
        history.push({
          pathname: '/mission',
        }),
      requires: PermissionCodes.changeCampaign,
    },
    {
      text: 'Continue edit',
      action: () => {
        window.location.href = `/mission/${props.continueId}/edit/`;
      },
    },
  ];

  return (
    <NextStepPrompt
      show={show}
      title={title}
      description={`${description}`}
      steps={null}
      buttons={buttons}
    />
  );
}

export default PublishMissionPrompt;
