import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import { GOOGLE_API_KEY } from '../../utils';

class CustomMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cricleRadius: props.radius / 16,
    };
  }

  mapClick = (data) => {
    this.props.onClick && this.props.onClick(data);
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.lat !== this.props.lat ||
      nextProps.lng !== this.props.lng ||
      nextProps.radius !== this.props.radius
    );
    // return false;
  }

  // handleZoomChanged() {
  //   // onZoomChanged(this.getZoom()); //current zoom
  //   console.log('@@30: ', this.getZoom());
  //   this.setState({ cricleRadius: this.props.radius / this.getZoom() });
  // }

  render() {
    console.log('radius-1: ', this.props.radius);
    // cricle reference: https://tomchentw.github.io/react-google-maps/#circle
    // map options(defaultOptions): https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const GoogleMapExample = withScriptjs(
      withGoogleMap((props) => {
        console.log('radius-2: ', props.radius);
        return (
          <GoogleMap
            defaultCenter={{
              lat: parseFloat(props.lat) || 22.3193,
              lng: parseFloat(props.lng) || 114.1694,
            }}
            defaultZoom={18}
            onClick={(e) => {
              // this.setState({
              //   lat: e.latLng.lat().toFixed(6),
              //   lng: e.latLng.lng().toFixed(6),
              // });
              props.onClick && props.onClick(e);
            }}
            onZoomChanged={props.handleZoomChanged}
            editable={false}
            defaultOptions={{
              fullscreenControl: false,
              // gestureHandling: 'none', //default is 'auto'
              mapTypeControl: false,
              zoomControl: true,
              streetViewControl: false,
            }}
          >
            <Marker
              position={{
                lat: parseFloat(props.lat),
                lng: parseFloat(props.lng),
              }}
            />
            <Circle
              defaultCenter={{
                lat: parseFloat(props.lat),
                lng: parseFloat(props.lng),
              }}
              radius={props.radius}
              options={{
                strokeColor: '#ff0000',
                // fillColor: 'transparent',
              }}
              onDblClick={(event) => {
                console.log('onDblClick: ', event.latLng.lat());
                props.onClick && props.onClick(event);
              }}
              // editable={true}
              // draggable={true}
            />
          </GoogleMap>
        );
      }),
    );
    return (
      <div>
        <GoogleMapExample
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`}
          loadingElement={
            <div style={{ height: `${this.props.height || 500}px` }} />
          }
          containerElement={
            <div
              style={{
                height: `${this.props.height || 500}px`,
                ...this.props.style?.container,
              }}
            />
          }
          mapElement={
            <div style={{ height: `${this.props.height || 500}px` }} />
          }
          onClick={this.mapClick}
          handleZoomChanged={this.handleZoomChanged}
          lat={this.props.lat}
          lng={this.props.lng}
          radius={this.props.radius}
        ></GoogleMapExample>
      </div>
    );
  }
}

export default CustomMap;
