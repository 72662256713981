import moment from 'moment';
import { createModel } from './BaseModel';
import {
  getGoodieBagsList,
  getGoodieBagDetail,
  checkCouponOutOfStockFortuneBag,
  createGoodieBag,
  updateGoodieBag,
  publishGoodieBag,
  deleteGoodieBag,
  getGoodieBagCouponCampaigns,
  getGoodieBagCampaignSlots,
  getGoodieBagParticipationRecordsCount,
  getCustomerGoodieBagParticipationRecords,
  getCustomerOneGoodieBagParticipationRecord,
  extendGoodieBagRecordExpiryDate,
} from '../services/GoodieBagsApiHelper';
import {
  getCampaignList,
  getCampaignAllListWithCouponStock,
  getEntitlements,
} from '../services/CampaignAPIHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  createAction,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  removeElementFromArray,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  APIStatus,
  CampaignType,
  LanguageConfig,
  SESSION_KEYS,
} from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';
import { TimeFormater, formatDate } from '../utils/TimeFormatUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { getImageImage } from './CreateCampaignModel';
import { getTermsAndConditionsTemplateDate } from './TermsAndConditionsModel';
import { getCountryCodeList } from '../services/CountryCodeApiHelper';
import { getBrands } from '../services/BrandAPIHelper';
import { parseBrand } from './BrandModel';

export const GOODIE_BAG_SESSION_KEY = SESSION_KEYS.CREATE_GOODIE_BAG;

export const GOODIE_BAG_ENTITLEMENT_METHOD_TYPE = {
  optionA: 'OPTION_A',
  optionB: 'OPTION_B',
  optionC: 'OPTION_C',
  optionD: 'OPTION_D',
};

export const GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY = {
  OPTION_A: { name: 'Option A - Signed URL', title: 'Signed URL (Option A)' },
  OPTION_B: { name: 'Option B - Static URL', title: 'Static URL (Option B)' },
  OPTION_C: { name: 'Option C - Unique URL', title: 'Unique URL (Option C)' },
  OPTION_D: {
    name: 'Option D - Generic URL',
    title: 'Generic URL (Option D)',
  },
};

const stepNames = ['Core', 'Content', 'Properties', 'Preview'];

const GOODIE_BAG_TYPE = {
  CASH_VOUCHER: { name: 'Cash voucher only', value: 'CASH_VOUCHER', pk: 1 },
  GIFT: { name: 'Goodie Bag Coupon only', value: 'GIFT', pk: 2 },
  BOTH: {
    name: 'Both cash voucher and goodie bag coupon',
    value: 'BOTH',
    pk: 3,
  },
  FORTUNE_BAG: {
    name: 'Goodie bag offer (Direct assign)',
    value: 'FORTUNE_BAG',
    pk: 4,
  },
};

export const GOODIE_BAG_REWARD_TYPE_OPTIONS = {
  cashVoucher: {
    label: 'Cash voucher only',
    value: GOODIE_BAG_TYPE.CASH_VOUCHER.value,
  },
  gift: { label: 'Goodie Bag Coupon only', value: GOODIE_BAG_TYPE.GIFT.value },
  both: {
    label: 'Both cash voucher and goodie bag coupon',
    value: GOODIE_BAG_TYPE.BOTH.value,
  },
  fortuneBag: {
    label: 'Goodie bag offer (Direct assign) ',
    value: GOODIE_BAG_TYPE.FORTUNE_BAG.value,
  },
};

export const COUPON_ACQUIRED_PERIOD_TYPE_CONFIG = {
  allTime: 'ALL_TIME',
  absolute: 'ABSOLUTE',
  relative: 'RELATIVE',
};

export const COUPON_ACQUIRED_PERIOD_OPTIONS = [
  { label: 'Same as active period', value: 'ALL_TIME' },
  { label: 'Specific period', value: 'ABSOLUTE' },
  { label: 'Number of days since acquiring', value: 'RELATIVE' },
];

export const GOODIE_BAG_LIST_FIELDS = [
  { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
  { displayName: 'Name', fieldName: 'name', orderField: 'name' },
  { displayName: 'Cover photo', fieldName: 'coverPhoto' },
  { displayName: 'Entitlement method', fieldName: 'entitlementMethodDisplay' },
  { displayName: 'Reward type', fieldName: 'displayRewardType' },
  { displayName: 'Goodie bag group', fieldName: 'displayGroups' },
  {
    displayName: 'Active Period',
    fieldName: 'activePeriodList',
    orderField: 'startDate',
  },
  {
    displayName: 'Visible Period',
    fieldName: 'visiblePeriodList',
    orderField: 'displayStartDate',
  },
  {
    displayName: 'Coupon Acquired Period',
    fieldName: 'acquiredPeriodList',
    orderField: 'absoluteStartDate',
  },
  {
    displayName: 'Goodie bag Acquired / Created',
    fieldName: 'goodieBagAcquiredCreated',
  },
  {
    displayName: 'Valid goodie bag',
    fieldName: 'validGoodiebagNumber',
  },
  {
    displayName: 'Create at',
    fieldName: 'createAt',
    orderField: 'creationDate',
  },
  {
    displayName: 'Last modified',
    fieldName: 'lastModified',
    orderField: 'lastModifiedDate',
  },
  {
    displayName: 'Status',
    fieldName: 'displayStatus',
  },
];

export const checkIsOptionD = (merchants) =>
  (merchants || []).filter((item) => item.handleOption.value === 'OPTION_D')
    .length > 0;

export const getGoodieBagPerviewPropertiesFields = (
  rewardType,
  isDetail,
  extraComponment,
) => {
  return [
    [
      rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? {
            title:
              'Linked Goodie bag offer (Direct assign) (with number of coupon can be acquired per entitlement)',
            // field: 'goodieBagCouponCampaigns',
            componment: extraComponment?.linkedFortuneBagCampaigns,
          }
        : {
            title: 'Link to coupon campaign',
            // field: 'goodieBagCouponCampaigns',
            field: 'slotCampaigns',
            titleField: 'slotNames',
          },
    ],
    ...[
      isDetail
        ? [
            {
              title: 'Goodie bag group',
              field: 'groups',
            },
          ]
        : [],
    ],
    [
      {
        title: 'Active period',
        field: 'activePeriodDetail',
      },
    ],
    [
      {
        title: 'Visible period',
        field: 'visiblePeriodDetail',
      },
    ],
    ...[
      rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? [
            {
              title: 'Coupon acquire period of a goodie bag',
              field: 'acquiredPeriodDetail',
            },
          ]
        : [],
    ],
    ...[
      [
        GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
        GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
      ].includes(rewardType)
        ? [
            {
              title: 'Credits',
              field: 'assignCreditPerLink',
            },
          ]
        : [],
      [
        GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value,
        GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
      ].includes(rewardType)
        ? [
            {
              title: 'Quota',
              field: 'assignQuotaPerLink',
            },
          ]
        : [],
    ],
    [
      {
        title: 'Overall limit',
        field: 'overallLimit',
      },
    ],
    [
      {
        title: 'Remaining goodie bag link',
        field: 'remainingGoodiebagLinks',
      },
    ],
    ...(rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
      ? []
      : [
          [
            {
              title: 'Number of valid goodie bag (Remaining)',
              field: 'validGoodiebagLinks',
            },
          ],
          [
            {
              title: 'Number of empty goodie bag (Remaining)',
              field: 'emptyGoodiebagLinks',
            },
          ],
        ]),
    [
      {
        title: 'Per head limit',
        field: 'perHeadLimit',
      },
    ],
  ];
};

export const saveGoodieBagToSessionStorage = (data) => {
  saveToSessionStorage(GOODIE_BAG_SESSION_KEY, data);
};

export const removeGoodieBagSessionStorage = () => {
  removeFromSessionStorage(GOODIE_BAG_SESSION_KEY);
};

function translationAssemble(node, allData) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;

  const ageRestrictedPrimaryButtonNames =
    node.ageRestrictedPrimaryButtonName?.split('\n');
  const ageRestrictedSecondaryButtonNames =
    node.ageRestrictedSecondaryButtonName?.split('\n');
  const data = {
    pk: node.pk,
    name: node.name,
    shortDescription: node.shortDescription,
    shortDescriptionInCampaignListingPage:
      node.shortDescriptionInCampaignListingPage,
    coverPhoto: node.coverPhoto,
    coverPhotoForCampaignListingPage: node.coverPhotoForCampaignListingPage,
    mobilePhoto: node.mobilePhoto,
    mobilePhotoForCampaignListingPage: node.mobilePhotoForCampaignListingPage,
    genericTermsAndConditionsTitle: node.genericTermsAndConditionsTitle,
    genericTermsAndConditionsContent: node.genericTermsAndConditionsContent,
    cashVoucherName: node.creditName,
    limitedOfferName: node.quotaName,

    ageRestrictedConditionsTitle: node.ageRestrictedTermsAndConditionsTitle,
    ageRestrictedConditionsContent: node.ageRestrictedTermsAndConditionsContent,
    primaryButton1: ageRestrictedPrimaryButtonNames?.[0] || '',
    primaryButton2: ageRestrictedPrimaryButtonNames?.[1] || '',
    secondaryButton1: ageRestrictedSecondaryButtonNames?.[0] || '',
    secondaryButton2: ageRestrictedSecondaryButtonNames?.[1] || '',
  };

  if (
    allData?.isFollowGenericTermsAndConditionsTemplate &&
    allData?.genericTermsAndConditionsTemplate?.pk
  ) {
    data.genericTermsAndConditionsTitle =
      allData?.genericTermsAndConditionsTemplate?.translations?.[
        language
      ]?.title;
    data.genericTermsAndConditionsContent =
      allData?.genericTermsAndConditionsTemplate?.translations?.[
        language
      ]?.content;
  }
  data.indicatorText = allData?.showColorIndicator ? node.indicatorText : null;
  return {
    [language]: data,
  };
}

const parseTranslations = (data) => {
  const enTranslation = translationAssemble(data, data) || {};
  const translations = data?.translations?.edges?.map((item) => {
    const translation = translationAssemble(item.node, data) || {};
    return translation;
  });
  const reducedTranslation = translations?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});

  return {
    ...enTranslation,
    ...reducedTranslation,
  };
};

export const getPeriodDetail = (data) => {
  if (!data) {
    return;
  }
  if (data.isAlwaysActivePeriod) {
    data.startDate = new Date();
    data.endDate = null;
  }
  const activePeriodDetail = `${formatDate(
    data.startDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.endDate, TimeFormater.dayMonthYearWeekTime)}`;

  if (data.isAlwaysVisiblePeriod) {
    data.displayStartDate = new Date();
    data.displayEndDate = null;
  }
  const visiblePeriodDetail = `${formatDate(
    data.displayStartDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.displayEndDate, TimeFormater.dayMonthYearWeekTime)}`;

  let acquiredPeriodDetail = '';
  switch (data.couponAcquirePeriodType) {
    case 'ABSOLUTE':
      acquiredPeriodDetail = `${formatDate(
        data.absoluteStartDate,
        TimeFormater.dayMonthYearWeekTime,
      )} - ${formatDate(
        data.absoluteExpiryDate,
        TimeFormater.dayMonthYearWeekTime,
      )}`;
      break;
    case 'RELATIVE':
      acquiredPeriodDetail = `${data.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
      break;
    default:
      acquiredPeriodDetail = activePeriodDetail;
      break;
  }

  return {
    activePeriodDetail,
    visiblePeriodDetail,
    acquiredPeriodDetail,
  };
};

const getDisplayDate = (startDate, endDate) => {
  const startDisplay = formatDate(
    startDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  const endDisplay = formatDate(
    endDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  return `${startDisplay} - ${endDisplay}`;
};

const getDisplayActiveDate = (data) => {
  if (data.couponAcquirePeriodType === 'ABSOLUTE') {
    return getDisplayDate(data.absoluteStartDate, data.absoluteExpiryDate);
  }
  if (data.couponAcquirePeriodType === 'RELATIVE') {
    return `${data.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  }
  if (!data.endDate) {
    return `All time valid`;
  }
  return getDisplayDate(data.startDate, data.endDate);
};

const handleOptionPk = (option) => {
  switch (option) {
    case 'OPTION_D':
      return 4;
    case 'OPTION_C':
      return 3;
    case 'OPTION_B':
      return 2;
    default:
      return 1;
  }
};

export const parseGoodieBagFromCampaign = (data) => {
  if (!data) {
    return {};
  }
  let displayVisiblePeriod = '';
  if (!data.displayEndDate) {
    displayVisiblePeriod = 'All-time visible';
  } else {
    displayVisiblePeriod = getDisplayDate(
      data.displayStartDate,
      data.displayEndDate,
    );
  }

  return {
    ...data,
    nameOnly: data.name,
    name: `[${data.pk}] ${data.name}`,
    displayActivePeriod: getDisplayActiveDate(data),
    displayVisiblePeriod: displayVisiblePeriod,
  };
};

export const parseGoodieBag = (data) => {
  if (!data) {
    return;
  }
  if (Object.keys(data)?.length === 2) {
    // only have pk, name
    return {
      pk: data.pk,

      // name: `[${data.pk}] ${data.name}`,

      name: data.name,
      label: `[ID:${data.pk}] ${data.name}`,
    };
  }

  data.genericTermsAndConditionsTemplate = getTermsAndConditionsTemplateDate(
    data.genericTermsAndConditionsTemplate,
  );

  const goodieBagMerchants = [].concat.apply(
    [],
    data?.batches?.edges?.map((batch) =>
      batch.node?.fortuneBagBrands?.edges?.map((item) => item.node),
    ),
  );
  const merchantsTotalCount = data?.batches?.edges?.reduce(
    (perviousValue, item) =>
      perviousValue + (item.node?.fortuneBagBrands?.totalCount || 0),
    0,
  );

  const batchesGoodieBag = {};
  let overallAmount = null;
  (data.batches?.edges || []).forEach((batch) => {
    if (!data.isPublished) {
      overallAmount = batch.node?.fortuneBagQuantity;
    }
    batchesGoodieBag[batch.node?.index] = {
      ...batch.node,
      targetMerchants: batch.node?.entitlements?.edges?.map((entitlement) => ({
        ...entitlement.node,
      })),
    };
  });

  const translations = parseTranslations(data);

  const startDateWithFormat =
    formatDate(data.startDate, TimeFormater.dayMonthWeek) || '-';
  const endDateWithFormat =
    formatDate(data.endDate, TimeFormater.dayMonthWeek) || '-';
  const displayStartDateWithFormat =
    formatDate(data.displayStartDate, TimeFormater.dayMonthWeek) || '-';
  const displayEndDateWithFormat =
    formatDate(data.displayEndDate, TimeFormater.dayMonthWeek) || '-';
  const absoluteStartDateWithFormat =
    formatDate(data.absoluteStartDate, TimeFormater.dayMonthWeek) || '-';
  const absoluteExpiryDateWithFormat =
    formatDate(data.absoluteExpiryDate, TimeFormater.dayMonthWeek) || '-';

  const periodDetail = getPeriodDetail(data);

  let acquiredPeriodList = '';
  switch (data.couponAcquirePeriodType) {
    case 'ABSOLUTE':
      acquiredPeriodList = `From ${absoluteStartDateWithFormat} to ${absoluteExpiryDateWithFormat}`;
      break;
    case 'RELATIVE':
      acquiredPeriodList = `${data.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
      break;
    default:
      acquiredPeriodList = `From ${startDateWithFormat} to ${endDateWithFormat}`;
      break;
  }

  return {
    ...data,
    label: `[${data.pk}] ${data.name}`,
    entitlementMethodDisplay:
      GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY?.[data?.entitlementMethod]
        ?.name,
    handleOptionTitle: {
      name: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY?.[
        data?.entitlementMethod
      ]?.title,
      value: data?.entitlementMethod,
      pk: handleOptionPk(data?.entitlementMethod),
    },

    translations,
    activePeriod: data?.endDate
      ? `${formatDate(
          data?.startDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(data?.endDate, TimeFormater.dayMonthYearWeekTimeA)}`
      : 'All the time',
    visiblePeriod: data?.displayEndDate
      ? `${formatDate(
          data?.displayStartDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(
          data?.displayEndDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )}`
      : 'All the time',
    activePeriodList: `From ${startDateWithFormat} to ${endDateWithFormat}`,
    visiblePeriodList: `From ${displayStartDateWithFormat} to ${displayEndDateWithFormat}`,
    acquiredPeriodList,
    goodieBagAcquiredCreated: `${data.quantityOfAcquired || 0}/${
      data.quantityOfCreated || 0
    }`,
    createAt: formatDate(data.creationDate, TimeFormater.dayMonthYearWeekTime),
    lastModified: formatDate(
      data.lastModifiedDate,
      TimeFormater.dayMonthYearWeekTime,
    ),
    displayStatus: data.isPublished ? 'Published' : 'Unpublished',
    ...periodDetail,
    isAlwaysActivePeriod: !data.endDate,
    isAlwaysVisiblePeriod: !data.displayEndDate,
    batchesGoodieBag,
    otaCount: merchantsTotalCount,
    ota: goodieBagMerchants?.map((item) => ({
      ...item,
      handleOption: {
        name: item.handleOption?.toLowerCase().replace('_', ' '),
        value: item.handleOption,
      },
    })),
    quantityOfCreated: data.isPublished ? data.quantityOfCreated : 0,
    overallAmount,

    countryCodes: data?.countryCodes?.edges?.map((item) => item.node),
    groups: data?.groups?.edges?.map((item) => item.node),
    displayGroups: data?.groups?.edges
      ?.map((item) => `[ID: ${item?.node?.pk}] ${item?.node?.name}`)
      .join(','),
    isAgeRestricted: data?.isAgeRestricted,
    handleRewardType: GOODIE_BAG_TYPE[data?.rewardType] || {},
    displayRewardType: GOODIE_BAG_TYPE[data?.rewardType]?.name || '-',
    validGoodiebagNumber:
      data.isPublished &&
      data?.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? Math.min(
            data.quantityOfCreated - data.quantityOfAcquired,
            data.minCampaignStock,
          )
        : '-',
    couponLabel: {
      label: data?.couponLabel?.name,
      value: data?.couponLabel,
    },
    checkGPS: data?.isCheckGps,
    locations: data?.locations?.edges?.map((item) => ({
      id: item.node.pk,
      mapAddress: item.node.mapAddress,
      latitude: item.node.latitude,
      longitude: item.node.longitude,
      radius: item.node.radius,
    })),
  };
};

const cleanGoodieBagTranslation = (translations, data) => {
  const cleanedTranslations = [];
  console.log('@107', translations);
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      name: translation.name,
      shortDescription: translation.shortDescription,
      shortDescriptionInCampaignListingPage:
        translation.shortDescriptionInCampaignListingPage || '',
      coverPhoto: getImageImage(translation.coverPhoto) || null,
      coverPhotoForCampaignListingPage:
        getImageImage(translation.coverPhotoForCampaignListingPage) || null,
      mobilePhoto: getImageImage(translation.mobilePhoto) || null,
      mobilePhotoForCampaignListingPage:
        getImageImage(translation.mobilePhotoForCampaignListingPage) || null,
      genericTermsAndConditionsTitle:
        translation.genericTermsAndConditionsTitle || '',
      genericTermsAndConditionsContent:
        translation.genericTermsAndConditionsContent || '',
      creditName: translation.cashVoucherName,
      quotaName: translation.limitedOfferName,

      ageRestrictedTermsAndConditionsTitle:
        translation.ageRestrictedConditionsTitle,
      ageRestrictedTermsAndConditionsContent:
        translation.ageRestrictedConditionsContent,
      ageRestrictedPrimaryButtonName: `${translation.primaryButton1 || ''}${
        translation.primaryButton1 && translation.primaryButton2 ? '\n' : ''
      }${translation.primaryButton2}`,
      ageRestrictedSecondaryButtonName: `${translation.secondaryButton1 || ''}${
        translation.secondaryButton1 && translation.secondaryButton2 ? '\n' : ''
      }${translation.secondaryButton2}`,

      indicatorText: data?.showColorIndicator
        ? translation.indicatorText
        : null,
    };

    if (translation?.pk) {
      cleanedOneTranslation.id = translation?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  console.log('@314', cleanedTranslations);
  return cleanedTranslations;
};

export const getGoodieBagCouponCampaignsList = (data) => {
  const cashVoucherCampaigns = [].concat.apply(
    [],
    Object.values(data.slotCashCampaigns || {})?.map((item) =>
      item?.map((item) => item?.pk),
    ) || [],
  );

  const giftCampaigns = [].concat.apply(
    [],
    Object.values(data.slotGiftCampaigns || {})?.map((item) =>
      item?.map((item) => item?.pk),
    ) || [],
  );

  const fortuneBagCampaigns = [].concat.apply(
    [],
    Object.values(data.slotFortuneBagCampaigns || {})?.map((item) =>
      item?.map((item) => item?.pk),
    ) || [],
  );
  let campaignList = [];

  switch (data.rewardType) {
    case GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value:
      campaignList = [...cashVoucherCampaigns, ...giftCampaigns];
      break;
    case GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value:
      campaignList = giftCampaigns;
      break;
    case GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value:
      campaignList = fortuneBagCampaigns;
      break;
    default:
      campaignList = cashVoucherCampaigns;
      break;
  }
  // campaignList = campaignList.filter((item) => item?.length > 0);
  return campaignList;
};

const cleanGoodiebagCampaignSlot = (data, originalData) => {
  let campaignSlotList = [];
  const oldCashCampaignsLength = Object.keys(data.slotCashCampaigns).filter(
    (key) => key.indexOf('new') !== 0,
  ).length;

  const campaigns =
    data.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
      ? [data.slotFortuneBagCampaigns || {}]
      : data.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
      ? [data.slotCashCampaigns || {}, data.slotGiftCampaigns || {}]
      : data.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
      ? [data.slotGiftCampaigns || {}]
      : [data.slotCashCampaigns || {}];

  campaigns.forEach((slotCampaigns) =>
    Object.keys(slotCampaigns).forEach((key) => {
      const isNew = key.indexOf('new') === 0;
      const typeIndexPk = isNew
        ? [
            key.indexOf('Cash') > 0
              ? GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
              : key.indexOf('Gift') > 0
              ? GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
              : GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value,
            parseInt(
              key
                .replace('newCash', '')
                .replace('newGift', '')
                .replace('newFortune', ''),
            ),
            null,
          ]
        : key.split('-');
      // console.log('@523: ', data.slotNames[key]);
      campaignSlotList.push({
        id: typeIndexPk[2] || parseInt(typeIndexPk[2]),
        name: data.slotNames ? data.slotNames[key]?.en?.name : 'slot name',
        label: data.slotNames ? data.slotNames[key]?.en?.label : '',
        rewardType: typeIndexPk[0],
        index:
          isNew &&
          typeIndexPk[0] !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
            ? oldCashCampaignsLength + typeIndexPk[1]
            : typeIndexPk[1],
        isLanding: data.isSetLanding && key === data.landingSlot,
        linkCampaigns: slotCampaigns[key]
          ? slotCampaigns[key].map((val) => val.pk)
          : [],
        ...(typeIndexPk[0] === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
          ? {}
          : {
              assignRewardPerLink:
                (data.assignReward[key] &&
                  data.assignReward[key] !== 'unlimited') ||
                data.assignReward[key] === 0
                  ? parseInt(data.assignReward[key])
                  : null,
              translations: Object.keys(data.slotNames[key] || {})
                .filter((langauge) => langauge !== LanguageConfig.english)
                .map((language) => ({
                  id: originalData?.[key]?.[language]?.pk,
                  language,
                  name: data.slotNames[key][language]?.name,
                  label: data.slotNames[key][language]?.label,
                })),
            }),
      });
    }),
  );

  return campaignSlotList;
};

const cleanGoodieBag = (data) => {
  const enData = data.translations[LanguageConfig.english];
  const payload = {
    name: enData.name,
    shortDescription: enData.shortDescription,
    shortDescriptionInCampaignListingPage:
      enData.shortDescriptionInCampaignListingPage || '',
    coverPhoto: getImageImage(enData.coverPhoto) || null,
    coverPhotoForCampaignListingPage:
      getImageImage(enData.coverPhotoForCampaignListingPage) || null,
    mobilePhoto: getImageImage(enData.mobilePhoto) || null,
    mobilePhotoForCampaignListingPage:
      getImageImage(enData.mobilePhotoForCampaignListingPage) || null,
    isFollowGenericTermsAndConditionsTemplate:
      data.isFollowGenericTermsAndConditionsTemplate,
    genericTermsAndConditionsTemplate:
      data.genericTermsAndConditionsTemplate?.pk || null,
    genericTermsAndConditionsTitle: enData.genericTermsAndConditionsTitle || '',
    genericTermsAndConditionsContent:
      enData.genericTermsAndConditionsContent || '',
    startDate: data.startDate,
    endDate: data.endDate,
    displayStartDate: data.displayStartDate,
    displayEndDate: data.displayEndDate,
    absoluteStartDate: data.absoluteStartDate,
    absoluteExpiryDate: data.absoluteExpiryDate,
    couponAcquirePeriodType: data.couponAcquirePeriodType,
    numberOfDaysToExpireAfterAcquisition: parseInt(
      data.numberOfDaysToExpireAfterAcquisition,
    ),
    isAgeRestricted: data.isAgeRestricted,
    ageRestrictedTermsAndConditionsTitle: enData.ageRestrictedConditionsTitle,
    ageRestrictedTermsAndConditionsContent:
      enData.ageRestrictedConditionsContent,
    ageRestrictedPrimaryButtonName: `${enData.primaryButton1 || ''}${
      enData.primaryButton1 && enData.primaryButton2 ? '\n' : ''
    }${enData.primaryButton2}`,
    ageRestrictedSecondaryButtonName: `${enData.secondaryButton1 || ''}${
      enData.secondaryButton1 && enData.secondaryButton2 ? '\n' : ''
    }${enData.secondaryButton2}`,

    showColorIndicator: data.showColorIndicator,
    indicatorColor: data.showColorIndicator ? data.indicatorColor : null,
    indicatorText: data.showColorIndicator ? enData.indicatorText : null,

    // assignCreditPerLink: parseInt(data.assignCreditPerLink),
    // assignQuotaPerLink: parseInt(data.assignQuotaPerLink),
    overallAmount: parseInt(data.overallAmount) || null,
    rewardType:
      data.rewardType || GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
    goodieBagBrands: data.isPublished
      ? data.batchOta?.map((item) => item.pk)
      : data.ota?.map((item) => item.pk),
    translations: cleanGoodieBagTranslation(data.translations, data),

    showCampaignCategoryFilter:
      data.showCampaignCategoryFilter === undefined
        ? true
        : data.showCampaignCategoryFilter,
    showBrandFilter:
      data.showBrandFilter === undefined ? true : data.showBrandFilter,
    showOfferTypeLabelFilter:
      data.showOfferTypeLabelFilter === undefined
        ? true
        : data.showOfferTypeLabelFilter,
    showCreditRangeFilter:
      data.showCreditRangeFilter === undefined
        ? true
        : data.showCreditRangeFilter,

    // creditName: enData.cashVoucherName,
    // quotaName: enData.limitedOfferName,

    skipCheckLimit: data.skipCheckLimit || false,
    countryCodes: data.countryCodes?.map((item) => item.pk),
    couponLabel: data.couponLabel?.value?.pk || null,
    perHeadLimit: data?.perHeadLimit || null,
    showLocationFilter:
      data?.showLocationFilter === undefined ? true : data?.showLocationFilter,
    hideOutOfStockCampaign:
      data?.hideOutOfStockCampaign === undefined
        ? false
        : data?.hideOutOfStockCampaign,

    // clientMutationId: '',
  };

  // payload.couponCampaigns = getGoodieBagCouponCampaignsList(data);

  if (payload.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value) {
    payload.assignCreditPerLink = null;
  }
  if (payload.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value) {
    payload.assignQuotaPerLink = null;
  }

  if (data.isAlwaysActivePeriod) {
    payload.startDate = new Date();
    payload.endDate = null;
  }
  if (data.isAlwaysVisiblePeriod) {
    payload.displayStartDate = new Date();
    payload.displayEndDate = null;
  }
  switch (data.couponAcquirePeriodType) {
    case COUPON_ACQUIRED_PERIOD_TYPE_CONFIG.absolute:
      payload.numberOfDaysToExpireAfterAcquisition = null;
      break;
    case COUPON_ACQUIRED_PERIOD_TYPE_CONFIG.relative:
      payload.absoluteStartDate = null;
      payload.absoluteExpiryDate = null;
      break;
    case COUPON_ACQUIRED_PERIOD_TYPE_CONFIG.allTime:
    default:
      payload.couponAcquirePeriodType = COUPON_ACQUIRED_PERIOD_OPTIONS[0].value;
      payload.absoluteStartDate = null;
      payload.absoluteExpiryDate = null;
      payload.numberOfDaysToExpireAfterAcquisition = null;
      break;
  }

  if (data?.pk) {
    payload.id = data?.pk;
  }
  console.log('@453', payload);
  return payload;
};

const parseGoodieBagsList = (data) =>
  data?.goodieBags?.edges?.map((item) => parseGoodieBag(item?.node));

const parseAllCouponCampaign = (data) => {
  if (!data) {
    return;
  }
  const goodieBagCouponCampaigns = data.goodieBagCouponCampaigns?.edges?.map(
    (item) => ({
      pk: item?.node?.pk,
      name: item?.node?.name,
      type: item?.node?.type,
      title: `[${item?.node?.pk}] ${item?.node?.name}`,
      relatedBrand: item?.node?.brand,
    }),
  );
  const goodieBagCashVoucherCampaign = goodieBagCouponCampaigns?.filter(
    (item) => item.type === CampaignType.cashVoucherCampaign,
  );

  const goodieBagGiftCampaign = goodieBagCouponCampaigns?.filter(
    (item) => item.type === CampaignType.giftCampaign,
  );
  return {
    goodieBagCouponCampaigns,
    goodieBagCashVoucherCampaign,
    goodieBagGiftCampaign,
    originalGoodieBagCouponCampaigns: goodieBagCouponCampaigns,
  };
};

const parseGoodiebagParticipationRecord = (item) => {
  let data = {
    ...item,
    name: `[ID:${item.goodieBag?.pk}]${item.goodieBag?.name}`,
    acquireDate: formatDate(
      item.creationDate,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
    creditUsedTotal: item.totalCredit
      ? `${item.usedCredit} / ${item.totalCredit}`
      : 'N/A',
    quotaUsedTotal: item.totalQuota
      ? `${item.usedQuota} / ${item.totalQuota}`
      : 'N/A',
    goodieBag: {
      ...item.goodieBag,
      displayStartDate: moment(item.goodieBag.displayStartDate),
      displayEndDate: item.goodieBag.displayEndDate
        ? moment(item.goodieBag.displayEndDate)
        : null,
      startDate: moment(item.goodieBag.startDate),
      endDate: item.goodieBag.endDate ? moment(item.goodieBag.endDate) : null,
      absoluteStartDate: item.goodieBag.absoluteStartDate
        ? moment(item.goodieBag.absoluteStartDate)
        : null,
      absoluteExpiryDate: item.goodieBag.absoluteExpiryDate
        ? moment(item.goodieBag.absoluteExpiryDate)
        : null,
    },
    trackingCode: item.trackingCode?.trackingCode,
  };

  if (item.couponOverview) {
    data.customerMembershipId = item.customer.membershipId;
    data.displayName = item.goodieBag?.name;
    data.goodieBagType = GOODIE_BAG_TYPE[item.goodieBag.rewardType].name;
    data.deadline = item.expiredDate
      ? formatDate(
          item.expiredDate,
          TimeFormater.yearMonthDayTimeSecondsWithBlank,
        )
      : 'All time';
  }
  return data;
};

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  detail: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  apiStatus: APIStatus.none,
  createStatus: APIStatus.none,
  goodiebagCampaignSlots: [],
});

export default createModel({
  namespace: 'goodieBags',
  params: {
    dataKey: 'goodieBags',
    listAPI: getGoodieBagsList,
    pkNode: 'GoodieBagNode',
    detailAPI: getGoodieBagDetail,
    parse: parseGoodieBagsList,
    parseDetail: (data) => parseGoodieBag(data.goodieBag),
    deleteAPI: deleteGoodieBag,
    objectKey: 'goodieBags',
  },
  states: getInitialState(),
  reducers: {
    updateGoodieBag(state, { payload }) {
      console.log('updateGoodieBag: ', payload);
      const detail = {
        ...state.detail,
        ...payload,
      };
      return { ...state, detail: detail };
    },

    clearData(state, { payload }) {
      const newState = {
        ...state,
        ...getInitialState(),
      };
      console.log('@170', newState);
      return newState;
    },

    loadGoodieBagFromCookie(state, { payload }) {
      const goodieBag = getObjectFromSessionStorage(GOODIE_BAG_SESSION_KEY);
      console.log('@147', goodieBag);
      if (!goodieBag) {
        return {
          ...state,
          detail: {
            rewardType: GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
            slotCashCampaigns: { newCash01: [] },
            slotGiftCampaigns: { newGift01: [] },
            slotFortuneBagCampaigns: { newFortune01: [] },
            translations: {
              [LanguageConfig.english]: {
                ageRestrictedConditionsTitle:
                  '<p>Important notice</p><p>重要告示</p>',
                ageRestrictedConditionsContent:
                  '<p>Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.</p><p>根據香港法律，不得在業務過程中，向未成年人售賣或供應令人醺醉的酒類。謹此聲明本人已年滿18歲。</p>',
                primaryButton1: 'I am aged 18 or above',
                primaryButton2: '本人年滿18歲',
                secondaryButton1: 'Leave',
                secondaryButton2: '離開',
              },
              [LanguageConfig.traditionalChinese]: {
                ageRestrictedConditionsTitle:
                  '<p>Important notice</p><p>重要告示</p>',
                ageRestrictedConditionsContent:
                  '<p>Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.</p><p>根據香港法律，不得在業務過程中，向未成年人售賣或供應令人醺醉的酒類。謹此聲明本人已年滿18歲。</p>',
                primaryButton1: 'I am aged 18 or above',
                primaryButton2: '本人年滿18歲',
                secondaryButton1: 'Leave',
                secondaryButton2: '離開',
              },
              [LanguageConfig.simplifiedChinese]: {
                ageRestrictedConditionsTitle:
                  '<p>Important notice</p><p>重要告示</p>',
                ageRestrictedConditionsContent:
                  '<p>Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.</p><p>根据香港法律，不得在业务过程中，向未成年人售卖或供应令人醺醉的酒类。谨此声明本人已年满18岁。</p>',
                primaryButton1: 'I am aged 18 or above',
                primaryButton2: '本人年满18岁',
                secondaryButton1: 'Leave',
                secondaryButton2: '离开',
              },
            },
          },
          hasUpdatedDefaultValues: true,
        };
      }
      return {
        ...state,
        detail: {
          ...goodieBag,
        },
        hasUpdatedDefaultValues: true,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },
  },
  effects: {
    getOneGoodieBag: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const id = convertPKToId('GoodieBagNode', payload.id);
        const serviceArgs = [
          getGoodieBagDetail,
          id,
          null,
          payload.callFromEntitlement,
        ];
        function* onSuccess(data) {
          // yield put({
          //   type: 'updateState',
          //   payload: {
          //     detail: parseGoodieBag(data.goodieBag),
          //     selectedAllListLoading: true,
          //   },
          // });

          // yield put({
          //   type: 'getGoodieBagSlots',
          //   payload: {
          //     id: payload.id,
          //   },
          // });

          const detail = parseGoodieBag(data.goodieBag);

          afterAction(detail?.entitlementMethod);
          if (payload.getDetailOnly) {
            yield put({
              type: 'updateState',
              payload: {
                detail: detail,
                hasUpdatedDefaultValues: true,
                selectedAllListLoading: true,
              },
            });
          } else {
            yield all([
              put({
                type: 'updateState',
                payload: {
                  detail: detail,
                  hasUpdatedDefaultValues: true,
                  selectedAllListLoading: true,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  goodieBagIn: [payload.id],
                  pageSize: 1000,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'countryCodes',
                  dataKey: 'countryCodes',
                  updateApi: getCountryCodeList,
                },
              }),
              // put({
              //   type: 'getAllCouponCampaign',
              //   payload: {
              //     id: payload.id,
              //     data: data?.goodieBag,
              //     page: 1,
              //   },
              // }),
              put({
                type: 'getGoodieBagSlots',
                payload: {
                  id: payload.id,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  params: {
                    goodieBagIn: [payload.id],
                  },
                  isAll: true,
                  pageSize: 100,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'ota',
                  dataKey: 'brands',
                  updateApi: getBrands,
                  parseDetail: parseBrand,
                },
              }),
              put({
                type: 'updateProperty',
                payload: {
                  goodieBagId: payload.id,
                  isAll: true,
                  pageSize: 100,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'entitlements',
                  dataKey: 'entitlements',
                  updateApi: getEntitlements,
                },
              }),
            ]);
          }
        }

        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    getGoodieBagSlots: [
      function* ({ payload }, { all, select, put }) {
        const page = payload?.page || 1;
        const pageCount = payload.pageCount || 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);

        if (!payload.id) {
          yield put({
            type: 'updateState',
            payload: {
              goodiebagCampaignSlots: [],
            },
          });
          return;
        }

        const serviceArgs = [getGoodieBagCampaignSlots, pageCursor, payload];

        function* onSuccess(data) {
          let slotNames = {};
          let assignReward = {};
          let slotCashCampaigns = {};
          let slotGiftCampaigns = {};
          let slotFortuneBagCampaigns = {};

          let sourceGoodiebagCampaignSlots = [];
          let originalGoodieBagCouponCampaigns = [];

          if (payload.isSelector) {
            sourceGoodiebagCampaignSlots =
              data.goodieBagCampaignSlots?.edges?.map((item) => ({
                pk: item.node.pk,
                rewardType: item.node.rewardType,
                index: item.node.index,
                name: item.node.name || '  ',
                label: `[${item.node.pk}] ${item.node.name || ''}`,
              }));

            const stateGoodiebagCampaignSlots = yield select(
              (state) => state.goodieBags.goodiebagCampaignSlots,
            );

            yield put({
              type: 'updateState',
              payload: {
                goodiebagCampaignSlots:
                  page > 1
                    ? [
                        ...stateGoodiebagCampaignSlots,
                        ...sourceGoodiebagCampaignSlots,
                      ]
                    : sourceGoodiebagCampaignSlots,
              },
            });

            return;
          }

          let needLoadMoreSlot = [];
          let landingSlot = null;
          for (let i = 0; i < data.goodieBagCampaignSlots?.edges?.length; i++) {
            const item = data.goodieBagCampaignSlots?.edges?.[i];
            if (!item.node) {
              continue;
            }

            const key = `${item.node.rewardType}-${item.node.index}-${item.node.pk}`;
            sourceGoodiebagCampaignSlots.push({
              pk: item.node.pk,
              rewardType: item.node.rewardType,
              index: item.node.index,
              name: item.node.name,
            });
            landingSlot = item.node.isLanding ? key : landingSlot;

            let nameTranslations = {};
            item.node.translations.edges.forEach(
              (val) =>
                (nameTranslations[val.node.language] = {
                  pk: val.node.pk,
                  name: val.node.name,
                  label: val.node.label,
                }),
            );

            if (
              item.node.rewardType ===
              GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
            ) {
              slotCashCampaigns[key] = [];
            } else if (
              item.node.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
            ) {
              slotGiftCampaigns[key] = [];
            } else if (
              item.node.rewardType ===
              GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
            ) {
              slotFortuneBagCampaigns[key] = [];
            }

            needLoadMoreSlot.push(key);

            yield put({
              type: 'getSlotAllCampaigns',
              payload: {
                id: item.node.pk,
                index: key,
                page: 1,
                type: item.node.rewardType,
              },
            });
            // }

            slotNames[key] = {
              en: {
                name: item.node.name,
                label: item.node.label,
              },
              ...nameTranslations,
            };

            assignReward[key] = item.node.assignRewardPerLink;
          }

          console.log('@@791-2: ', slotNames);
          const detail = yield select((state) => state.goodieBags.detail);
          yield put({
            type: 'updateState',
            payload: {
              detail: {
                ...detail,
                landingSlot,
                isSetLanding: !!landingSlot,
                slotCashCampaigns,
                slotGiftCampaigns,
                slotFortuneBagCampaigns,
                slotNames,
                originalSlotNames: slotNames,
                assignReward,
                originalGoodieBagCouponCampaigns:
                  originalGoodieBagCouponCampaigns.filter(
                    (v, i, a) => a.findIndex((v2) => v2.pk === v.pk) === i,
                  ),
              },
              hasUpdatedDefaultValues: true,
              selectedAllListLoading: needLoadMoreSlot.length > 0,
              needLoadMoreSlot,
            },
          });
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    getSlotAllCampaigns: [
      function* ({ payload }, { all, select, put }) {
        console.log('@@973: ', payload);
        if (!payload?.id) {
          return;
        }

        const pageSize = 500;
        const pageCursor = convertNumberToCursor(
          ((payload.page || 1) - 1) * pageSize - 1,
        );
        console.log('@@974: ', pageCursor);
        const serviceArgs = [
          getCampaignAllListWithCouponStock,
          pageCursor,
          {
            // ...payload,
            isAll: true,
            others: {
              // ...payload.others,
              goodieBagCampaignSlot: payload.id,
            },
            pageSize,
          },
        ];

        function* onSuccess(data) {
          console.log('@@662', data, data.campaignsWithCouponStock?.edges?.[0]);
          const startCursor =
            convertCursorToNumber(
              data?.campaignsWithCouponStock?.pageInfo?.startCursor || '',
            ) || 1;

          const slotCampaignListName =
            payload.type === GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
              ? 'slotCashCampaigns'
              : payload.type === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
              ? 'slotGiftCampaigns'
              : 'slotFortuneBagCampaigns';
          const campaignList =
            data.campaignsWithCouponStock?.edges?.map((val) => ({
              pk: val?.node?.pk,
              name: val?.node?.name,
              type: val?.node?.type,
              title: `[${val?.node?.pk}] ${val?.node?.name}`,
              goodieBagReward: val?.node?.goodieBagReward,
              relatedBrand: val?.node?.brand,
              couponTemplateID: val?.node?.couponCampaignTypeCouponTemplate?.pk,
              couponCampaignInfo: {
                quantityOfGenerate:
                  val?.node?.couponCampaignTypeCouponTemplate
                    ?.totalNubmerOfGeneratedCoupons,
                quantityOfRemaining:
                  val?.node?.couponCampaignTypeCouponTemplate?.stock,
              },
              startDate: val?.node?.startDate,
              endDate: val?.node?.endDate,
              isPublished: val?.node.isPublished,
            })) || [];
          // console.log(
          //   '@@1134: ',
          //   slotCampaignListName,
          //   campaignList,
          //   data.campaignsWithCouponStock,
          // );
          const { detail, needLoadMoreSlot } = yield select((state) => ({
            detail: state.goodieBags.detail,
            needLoadMoreSlot: state.goodieBags.needLoadMoreSlot,
          }));
          const origianlCampaignList = detail[slotCampaignListName] || {};

          const newSlotCampaignsData = {
            ...origianlCampaignList,
            [payload.index]: [
              ...(origianlCampaignList?.[payload.index] || []),
              ...campaignList,
            ],
          };

          const updateDetail = {
            ...detail,
            [slotCampaignListName]: newSlotCampaignsData,
            originalGoodieBagCouponCampaigns: [
              ...(detail.originalGoodieBagCouponCampaigns || []),
              ...campaignList,
            ].filter((v, i, a) => a.findIndex((v2) => v2.pk === v.pk) === i),
          };

          if (data?.campaignsWithCouponStock?.pageInfo?.hasNextPage) {
            yield all([
              put({
                type: 'updateState',
                payload: {
                  detail: updateDetail,
                },
              }),
              put({
                type: 'getSlotAllCampaigns',
                payload: {
                  id: payload.id,
                  index: payload.index,
                  page: parseInt(startCursor / pageSize + 2),
                },
              }),
            ]);
          } else {
            const newNeedLoadMoreSlot = removeElementFromArray(
              needLoadMoreSlot,
              payload.index,
            );

            yield put({
              type: 'updateState',
              payload: {
                detail: updateDetail,
                hasUpdatedDefaultValues: newNeedLoadMoreSlot.length <= 0,
                needLoadMoreSlot: newNeedLoadMoreSlot,
                selectedAllListLoading: newNeedLoadMoreSlot.length > 0,
              },
            });
          }
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
    ],
    getAllCouponCampaign: [
      function* ({ payload }, { all, select, put }) {
        if (!payload?.id || !payload?.page) {
          return;
        }
        console.log('@@656', payload);
        const pageSize = 1000;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );
        const serviceArgs = [
          getGoodieBagCouponCampaigns,
          payload.id,
          pageCursor,
        ];
        function* onSuccess(data) {
          console.log('@@662', data);
          const totalCount = data?.campaigns?.totalCount || 0;
          const endCursor =
            convertCursorToNumber(data?.campaigns?.pageInfo?.endCursor || '') ||
            0;
          const newData = {
            ...payload.data,
            goodieBagCouponCampaigns: {
              ...payload.data?.goodieBagCouponCampaigns,
              edges: [
                ...(payload.data?.goodieBagCouponCampaigns?.edges || []),
                ...data?.campaigns?.edges,
              ],
            },
          };
          if (endCursor && endCursor + 1 < totalCount) {
            yield put({
              type: 'getAllCouponCampaign',
              payload: {
                id: payload.id,
                data: newData,
                page: payload.page + 1,
              },
            });
            return;
          }
          const couponCampaignDetail = parseAllCouponCampaign(newData);

          yield all([
            put({
              type: 'updateGoodieBag',
              payload: couponCampaignDetail,
            }),
          ]);

          yield put({
            type: 'updateProperty',
            payload: {
              goodieBagIn: [payload.id],
              pageSize: 1000,
              page: 1,
              stateName: 'detail',
              dataKey: 'countryCodes',
              updateKey: 'countryCodes',
              updateApi: getCountryCodeList,
            },
          });
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
    ],
    checkExistsCouponOutOfStockFortuneBag: [
      function* ({ payload }, { all, select, put }) {
        const serviceArgs = [
          checkCouponOutOfStockFortuneBag,
          payload?.sendEmail ? true : false,
        ];
        function* onSuccess(data) {
          console.log('@checkExistsCouponOutOfStockFortuneBag', data);
          yield all([
            put({
              type: 'updateState',
              payload: {
                existsCouponOutOfStock:
                  data.checkCouponOutOfStockFortuneBag.existsCouponOutOfStock,
              },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateGoodieBag: [
      function* ({ payload }, { all, select, put }) {
        const { isPublished, data, afterAction, isOptionD } = payload;
        console.log('@@1034: ', payload.data);

        const originalSlotNames = yield select(
          (state) => state.goodieBags.detail.originalSlotNames,
        );

        const campaignSlots = cleanGoodiebagCampaignSlot(
          data,
          originalSlotNames,
        );
        const serviceArgs = [
          data?.pk ? updateGoodieBag : createGoodieBag,
          {
            ...cleanGoodieBag(data),
            campaignSlots,
            campaignSlotsToDelete: data.CampaignSlotsToDelete?.filter(
              (key) => key.indexOf('new') !== 0,
            ).map((key) => key.split('-').at(-1)),
            isCheckGps: isOptionD ? false : data.checkGPS,
            locations: data.locations,
            toDeleteLocations: data.toDeleteLocations || [],
          },
        ];

        console.log('@@803: ', data);
        console.log('@@803-campaignSlots: ', campaignSlots);
        yield put(
          createAction('updateState')({
            createStatus: APIStatus.calling,
          }),
        );

        function* onSuccess(successData) {
          removeGoodieBagSessionStorage();
          const goodieBag =
            successData?.[`${data?.pk ? 'update' : 'create'}GoodieBag`]?.node;

          if (!goodieBag) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          const shouldPublish =
            isPublished === true &&
            ((!isOptionD &&
              data.overallAmount &&
              parseInt(data.overallAmount) > 0) ||
              (isOptionD &&
                (data.batchOta?.length > 0 ||
                  (data.overallAmount && parseInt(data.overallAmount) > 0))));
          console.log(
            '@@1617: ',
            isOptionD,
            data.batchOta?.length,
            isOptionD && data.batchOta?.length > 0,
          );
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateGoodieBag',
              payload: { id: goodieBag.id, pk: goodieBag.pk },
            }),
            shouldPublish &&
              put({
                type: 'publish',
                payload: {
                  ...payload,
                  goodieBagPk: goodieBag?.pk,
                  isPublished: !payload.isPublished,
                  otaAllocationNum: payload.otaAllocatedNum,
                },
              }),
          ]);

          afterAction &&
            typeof afterAction === 'function' &&
            afterAction(goodieBag.pk);
        }

        function* onError(err) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    publish: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const goodieBagPk = payload.goodieBagPk;
        let serviceArgs = [
          publishGoodieBag,
          { id: goodieBagPk, otaAllocationNum: payload.otaAllocatedNum },
        ];
        console.log('@@526: ', payload);

        function* onSuccess(data) {
          console.log('@@309: ', payload.isPublished);
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.success,
              },
            }),
            put({
              type: 'updateGoodieBag',
              payload: { isPublished: payload.isPublished },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    getGoodieBagParticipationRecordsCount: [
      function* ({ payload }, { put }) {
        console.log('@@1378', payload);
        const serviceArgs = [
          getGoodieBagParticipationRecordsCount,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount = data?.goodieBagParticipationRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              recordCount: totalCount,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerGoodieBagParticipationRecords: [
      function* ({ payload }, { put, select }) {
        const pageSize = 20;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );

        const serviceArgs = [
          getCustomerGoodieBagParticipationRecords,
          pageCursor,
          payload?.filter,
        ];

        function* onSuccess(data) {
          const totalCount = data?.goodieBagParticipationRecords?.totalCount;
          const pageInfo = data?.goodieBagParticipationRecords?.pageInfo;
          let formatData = data?.goodieBagParticipationRecords?.edges?.map(
            (item) => parseGoodiebagParticipationRecord(item.node),
          );
          const source = yield select(
            (state) => state.goodieBags.customerParticipationRecords?.data,
          );
          if (payload.isLoadMore && payload.page > 1) {
            formatData = [...(source || []), ...formatData];
          }

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecords: {
                totalCount: totalCount,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                  hasNextPage: pageInfo.hasNextPage,
                },
                totalPage: Math.ceil(totalCount / pageSize),
                data: formatData,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerOneGoodieBagParticipationRecord: [
      function* ({ payload }, { put, select }) {
        const id = convertPKToId(
          'GoodieBagParticipationRecordNode',
          payload.id,
        );

        const serviceArgs = [getCustomerOneGoodieBagParticipationRecord, id];

        function* onSuccess(data) {
          let formatData = parseGoodiebagParticipationRecord(
            data?.goodieBagParticipationRecord,
          );

          yield put({
            type: 'updateState',
            payload: {
              customerParticipationRecord: formatData,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    extendGoodieBagRecordExpiryDate: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const data = payload.data;
        let serviceArgs = [extendGoodieBagRecordExpiryDate, data];

        function* onSuccess(data) {
          afterAction();
        }
        function* onError(response) {
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
