import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateMissionStepOne from '../../../components/mission/CreateMissionStepOne';
import CreateMissionStepTwo from '../../../components/mission/CreateMissionStepTwo';
import CreateMissionStepThree from '../../../components/mission/CreateMissionStepThree';
import CreateMissionStepFour from '../../../components/mission/CreateMissionStepFour';
import { createAction } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';
import {
  saveMissionToSessionStorage,
  removeMissionSessionStorage,
  MISSION_SESSION_KEY,
} from '../../../models/MissionModel';
import { APIStatus } from '../../../config/CustomEnums';
import '../gamificationCampaign/CreateGamificationCampaign.scss';

function CreateMission({
  missionModel,
  stepConfig,
  currentStep = 0,
  mission = {},
  formHasSubmitted,
  hasUpdatedDefaultValues,
  createStatus,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const loadData = (data) => {
    if (!params.id) {
      dispatch({
        type: 'mission/loadMissionFromCookie',
        payload: data,
      });
      return;
    } else {
      dispatch(
        createAction('mission/getOneMission')({
          id: params.id,
          ...data,
        }),
      );
    }
  };

  const clearData = () => {
    dispatch({ type: 'mission/clearData' });
  };

  useEffect(() => {
    clearData();
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    loadData();

    return () => {
      clearData();
    };
  }, []);

  const stepSet = [
    <CreateMissionStepOne />,
    <CreateMissionStepTwo />,
    <CreateMissionStepThree />,
    <CreateMissionStepFour />,
  ];

  const toMissionList = () => {
    history.push({
      pathname: `/mission`,
    });
  };
  console.log('@@93: ', mission);
  return (
    <BaseForm
      defaultValues={mission}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveMissionToSessionStorage(getValues());
        } else {
          removeMissionSessionStorage();
        }
      }}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: toMissionList,
        onClose: toMissionList,
      }}
      showFinishPop={createStatus === APIStatus.success}
      sessionDataKey={MISSION_SESSION_KEY}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={
        <CustomBreadcrumb
          name={params?.id ? mission?.name : 'Create Mission'}
        />
      }
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: params?.id ? mission?.name : 'Create Mission',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  missionModel: state.mission,
  stepConfig: state.mission.stepConfig,
  currentStep: state.mission.currentStep,
  mission: state.mission.detail,
  formHasSubmitted: state.mission.formHasSubmitted,
  hasUpdatedDefaultValues: state.mission.hasUpdatedDefaultValues,
  createStatus: state.mission.createStatus,
});

export default connect(mapPropsToState)(CreateMission);
