import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import arrayMove from 'array-move';
import ContentSections from '../base/ContentSections';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import BasePrompt from '../base/prompt/BasePrompt';
import PromptButton from '../base/prompt/BaseButtons';
import { BUTTON_TYPES } from '../base/prompt/BaseButtons';
import FilterableDropdown from '../base/FilterableDropdown';
import CustomRadios from '../base/CustomRadios';
import AuthButton from '../base/AuthButton';
import SortableComponent from '../base/SortableComponent';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import {
  CustomTitleLabel,
  CustomNumberInput,
  CustomNumberInputWithUnit,
  CustomNumberMutipleInputWithUnit,
} from '../earning/CustomBaseComponments';
import CampaignTablePrompt from '../campaign/CampaignTablePrompt';
import GoodiebagTablePrompt from '../campaign/GoodiebagTablePrompt';
import LocationTablePrompt from '../campaign/LocationTablePrompt';
import CreateStoreStepOne from '../../containers/merchants/stores/CreateStoreStepOne';
import OutOfStockSection from './OutOfStockSection';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { validate } from './CreateMissionValidate';
import './CreateMissionStepThree.scss';
import { PermissionCodes } from '../../config/PermissionCodes';
import {
  MISSION_TYPE,
  MISSION_CHECK_IN_MODE_TYPE,
} from '../../models/MissionModel';
import { StoreType, LanguageConfig } from '../../config/CustomEnums';
import { isArrayEmpty, filterWorkingTeams } from '../../utils';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

function CreateLocation({ closeAction, successAction }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { store } = useSelector((state) => ({
    store: state.storeModel.oneStore,
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: store,
  });
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => {
            console.log(data);
          })}
        >
          <CreateStoreStepOne
            hideContinueButtons={true}
            storeType={StoreType.LOCATION}
          />
          <div className={`base-prompt-buttons`}>
            <PromptButton
              title={'Cancel'}
              action={() => closeAction()}
              type={BUTTON_TYPES.secondary}
            />
            <PromptButton
              title={'Done'}
              disabled={buttonDisabled}
              action={async () => {
                const isValid = await methods.trigger([
                  'storeTranslations.en.name',
                  'storeTranslations.en.coverPhoto',
                  'storeTranslations.en.address',
                  'weekHours',
                  'district',
                  'emailAddress',
                  'location',
                ]);
                console.log(
                  '@@68: ',
                  isValid,
                  methods.getValues(),
                  methods.errors,
                );
                // dispatch({
                //   type: 'storeModel/stepChange',
                //   payload: { currentStep: 0, isValid },
                // });
                if (isValid) {
                  setButtonDisabled(true);
                  const storeData = methods.getValues();
                  dispatch({
                    type: 'storeModel/createOrUpdate',
                    payload: {
                      data: storeData,
                      afterAction: (data) => {
                        successAction(data);
                        setButtonDisabled(false);
                        closeAction();
                      },
                      failAction: () => {
                        setButtonDisabled(false);
                      },
                    },
                  });
                }
              }}
              type={BUTTON_TYPES.main}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
}

const formatCampaigns = (items) => {
  return items.map((item) => {
    return {
      ...item,
      rows: [
        `Available Coupon: ${item.couponStock || 0}`,
        `Coupon Used / Acquired/Generated (eWallet|Physical): ${item.couponUsedAndAcquired}`,
        `Active Period: ${item.displayActivePeriod}`,
        `Visible Period: ${item.displayVisiblePeriod}`,
      ]
        .concat(
          item.isCheckGps
            ? [
                `Location restriction (acquire coupons): ${
                  item.isCheckGps ? 'Yes' : 'No'
                }`,
              ]
            : [],
        )
        .concat(
          item.couponIsCheckGps
            ? [
                `Location restriction (use coupons): ${
                  item.couponIsCheckGps ? 'Yes' : 'No'
                }`,
              ]
            : [],
        ),
    };
  });
};

const formatGoodieBags = (items) => {
  return items.map((item) => {
    return {
      ...item,
      rows: [
        `Valid goodie bag: ${item.minCampaignStock || '-'}`,
        `Per head limit: ${item.perHeadLimit || '-'}`,
        `Active Period: ${item.activePeriodList}`,
        `Visible Period: ${item.visiblePeriodList}`,
      ],
    };
  });
};

const formatMissionLocations = (items, id) => {
  return items.map((item) => {
    return {
      ...item,
      pk: item.locationForMissoin?.pk || item.pk,
      locationForMissoin: item.locationForMissoin || item,
      label: item.name,
      rows: [
        `Related mission:
        <ul>${(item.missions || [])
          .map((missionItem) =>
            missionItem.pk === (id && parseInt(id))
              ? ''
              : `<li>${missionItem.label}</li>`,
          )
          .join('')}</ul>
        `,
      ],
    };
  });
};

function EarningRuleSection({ userWorkingTeamIds, userIsSuperuser }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();

  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;
  const watchWorkingTeams = watch('workingTeams');
  const missionType = watch('missionType');
  const checkInMode = watch('checkInMode');
  const campaigns = watch('campaigns') || [];
  const goodieBags = watch('goodieBags') || [];
  const missionLocations = watch('missionLocations') || [];

  const [showSelectCampaignTable, setShowSelectCampaignTable] = useState(false);
  const [showSelectGoodieBagTable, setShowSelectGoodieBagTable] =
    useState(false);
  const [showSelectLocationTable, setShowSelectLocationTable] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState();
  const [addLocationPrompt, setAddLocationPrompt] = useState();

  function createLocationSuccessAction(data) {
    if (data?.createStore?.node?.pk) {
      const location = data?.createStore?.node;
      const formatedNode = {
        ...location,
        location: location,
        locationForMissoin: location,
        locationPK: location?.pk,
        locationName: location?.name,
        locationLink: `/locations/${location?.pk}`,
      };
      setValue(
        'missionLocations',
        missionLocations.concat(
          formatMissionLocations([formatedNode], param?.id),
        ),
        {
          shouldDirty: true,
        },
      );
    }
  }

  return (
    <>
      <label className="create-section-title">Earning Rules</label>
      <CustomTitleLabel title={'Select rule'} />
      <FilterableDropdown
        defaultValue={missionType}
        // options={Object.values(MISSION_TYPE)}
        options={[
          {
            value: 'SPECIAL_CAMPAIGN',
            label: 'Redeem specific coupons',
          },
          { value: 'CHECK_IN', label: 'Check-in' },
        ]}
        selectChange={(item) => {
          setValue('missionType', item, { shouldDirty: true });
          if (item.value === MISSION_TYPE.CHECK_IN.value) {
            setValue('checkInMode', MISSION_CHECK_IN_MODE_TYPE.GPS.value, {
              shouldDirty: true,
            });
          }
        }}
        needFilter={false}
        needDelete={false}
      />
      {hasError(errors, 'missionType') ? (
        <label id="missionType" className={`error-field-message-style`}>
          {errorMessage(errors, 'missionType')}
        </label>
      ) : null}
      {missionType?.value === MISSION_TYPE.SPECIAL_CAMPAIGN.value ? (
        <>
          <div style={{ display: 'flex', gap: '12px' }}>
            <AuthButton
              title={'Add campaign'}
              requires={PermissionCodes.viewCampaign}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                setShowSelectCampaignTable(true);
              }}
            />
          </div>
          <SortableComponent
            title="Selected campaign"
            tips=""
            customRowClass="selected-campaigns-row"
            data={formatCampaigns(campaigns) || []}
            setData={(value) => {
              setValue('campaigns', value, {
                shouldDirty: true,
              });
            }}
            disabledSort={true}
            displayDropDownArrow={true}
            hideClearAll={false}
          />
          <div style={{ display: 'flex', gap: '12px' }}>
            <AuthButton
              title={'Create campaign'}
              requires={PermissionCodes.changeCampaign}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                setAddPromptConfig({
                  button: {
                    text: 'create campaign',
                    actionPath: '/campaigns/create',
                  },
                  title: 'Go to create Campaign?',
                  description: `Go to create campaign.`,
                });
              }}
            />
          </div>
        </>
      ) : null}

      {missionType?.value === MISSION_TYPE.SPECIAL_GOODIE_BAG.value ? (
        <>
          <div style={{ display: 'flex', gap: '12px' }}>
            <AuthButton
              title={'Add goodie bag'}
              requires={PermissionCodes.viewGoodieBag}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                setShowSelectGoodieBagTable(true);
              }}
            />
          </div>
          <SortableComponent
            title="Selected goodie bag"
            tips=""
            data={formatGoodieBags(goodieBags) || []}
            setData={(value) => {
              setValue('goodieBags', value, {
                shouldDirty: true,
              });
            }}
            disabledSort={true}
            displayDropDownArrow={true}
            hideClearAll={false}
          />
          <div style={{ display: 'flex', gap: '12px' }}>
            <AuthButton
              title={'Create goodie bag'}
              requires={PermissionCodes.changeGoodieBag}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                setAddPromptConfig({
                  button: {
                    text: 'create goodie bag',
                    actionPath: '/goodie_bags/create',
                  },
                  title: 'Go to create Goodie Bag?',
                  description: `Go to create goodie bag.`,
                });
              }}
            />
          </div>
        </>
      ) : null}

      {missionType?.value === MISSION_TYPE.CHECK_IN.value ? (
        <>
          <CustomTitleLabel title="Mode" />
          <div style={{ display: 'flex', marginTop: 8 }}>
            <CustomRadios
              options={Object.values(MISSION_CHECK_IN_MODE_TYPE)}
              default={checkInMode}
              onChange={(value) => {
                setValue('checkInMode', value, {
                  shouldDirty: true,
                });
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <AuthButton
              title={'Add existing location'}
              requires={PermissionCodes.viewStore}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                setShowSelectLocationTable(true);
              }}
            />
            <AuthButton
              title={'Create new location'}
              requires={PermissionCodes.changeStore}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                dispatch({ type: 'storeModel/clearData' });
                setAddLocationPrompt(true);
              }}
            />
          </div>
          <SortableComponent
            title="Selected location"
            // tips="Drag cards to set order"
            data={formatMissionLocations(missionLocations, param?.id) || []}
            // onSortEnd={(oldIndex, newIndex) => {
            //   setValue(
            //     'missionLocations',
            //     arrayMove(missionLocations, oldIndex, newIndex),
            //     {
            //       shouldDirty: true,
            //     },
            //   );
            // }}
            setData={(value) => {
              setValue('missionLocations', value, {
                shouldDirty: true,
              });
            }}
            disabledSort={true}
            displayDropDownArrow={false}
            displayDropDown={
              checkInMode !== MISSION_CHECK_IN_MODE_TYPE.GPS.value
            }
            hideClearAll={true}
          />
        </>
      ) : null}

      <CampaignTablePrompt
        show={showSelectCampaignTable}
        selectedData={campaigns}
        onClose={() => setShowSelectCampaignTable(false)}
        baseSearch={{
          working_team: filterWorkingTeams(
            userIsSuperuser,
            userWorkingTeamIds,
            watchWorkingTeams,
          ),
        }}
        onConfirm={(selectedData, selectedDataRemoved) => {
          const removedPKs = selectedDataRemoved?.map((item) => item.pk) || [];
          const values = [
            ...campaigns.filter((item) => !removedPKs.includes(item.pk)),
            ...selectedData,
          ].sort((a, b) => a.pk - b.pk);
          setValue(
            'campaigns',
            formatCampaigns(
              values.filter((item, pos) => {
                return values.map((item) => item.pk).indexOf(item.pk) == pos;
              }),
            ),
            {
              shouldDirty: true,
            },
          );
          setShowSelectCampaignTable(false);
        }}
      />
      <GoodiebagTablePrompt
        show={showSelectGoodieBagTable}
        selectedData={goodieBags}
        onClose={() => setShowSelectGoodieBagTable(false)}
        onConfirm={(selectedData, selectedDataRemoved) => {
          const removedPKs = selectedDataRemoved?.map((item) => item.pk) || [];
          const values = [
            ...goodieBags.filter((item) => !removedPKs.includes(item.pk)),
            ...selectedData,
          ].sort((a, b) => a.pk - b.pk);
          setValue(
            'goodieBags',
            formatGoodieBags(
              values.filter((item, pos) => {
                return values.map((item) => item.pk).indexOf(item.pk) == pos;
              }),
            ),
            {
              shouldDirty: true,
            },
          );
          setShowSelectGoodieBagTable(false);
        }}
      />
      <LocationTablePrompt
        show={showSelectLocationTable}
        selectedData={formatMissionLocations(missionLocations, param?.id)}
        onClose={() => setShowSelectLocationTable(false)}
        onConfirm={(selectedData, selectedDataRemoved) => {
          const removedPKs = selectedDataRemoved?.map((item) => item.pk) || [];
          const values = formatMissionLocations(
            [
              ...missionLocations.filter(
                (item) => !removedPKs.includes(item.location?.pk || item.pk),
              ),
              ...selectedData,
            ].sort((a, b) => a.pk - b.pk),
            param?.id,
          );
          setValue(
            'missionLocations',
            values.filter((item, pos) => {
              return (
                values
                  .map((item) => item.location?.pk || item.pk)
                  .indexOf(item.location?.pk || item.pk) == pos
              );
            }),
            {
              shouldDirty: true,
            },
          );
          setShowSelectLocationTable(false);
        }}
      />

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig.button.text,
                action: () => {
                  setAddPromptConfig(null);
                  history.push({
                    pathname: addPromptConfig.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'Continue to Create Mission',
                      content: 'You can continue to create the mission.',
                    },
                  });
                },
              }
            : {}
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />

      <BasePrompt
        show={!!addLocationPrompt}
        dialogClassName="create-location-dialog"
        closeAction={() => {
          setAddLocationPrompt(null);
        }}
        title={'Create new location'}
        otherBody={() => {
          return (
            <CreateLocation
              closeAction={() => setAddLocationPrompt(null)}
              successAction={createLocationSuccessAction}
            />
          );
        }}
      />
    </>
  );
}

const StatementWordingLanguages = [
  { label: 'EN', value: LanguageConfig.english },
  { label: 'TC', value: LanguageConfig.traditionalChinese },
  { label: 'SC', value: LanguageConfig.simplifiedChinese },
];

function LimitSection() {
  const { watch, setValue, formState, control } = useFormContext();
  const { errors } = formState;

  const showStatementWordingForStampLimit = watch(
    'showStatementWordingForStampLimit',
  );

  return (
    <>
      <label className="create-section-title">Limit</label>
      <Controller
        control={control}
        name="perActionStampRewardQuantity"
        render={({ field: { value } }) => (
          <>
            <CustomTitleLabel title={'Reward stamp quantity per action'} />
            <CustomNumberInput
              setValue={(value) => {
                setValue('perActionStampRewardQuantity', value, {
                  shouldDirty: true,
                });
              }}
              defaultValue={value}
              errorId="perActionStampRewardQuantity"
              error={hasError(errors, 'perActionStampRewardQuantity')}
              errorMessage={errorMessage(
                errors,
                'perActionStampRewardQuantity',
              )}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name="showStatementWordingForStampLimit"
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithSwitch
              title={'Show stamp limit statement on mission card '}
              defaultValue={value}
              setValue={(value) => {
                setValue('showStatementWordingForStampLimit', value, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />
      {showStatementWordingForStampLimit ? (
        <>
          {StatementWordingLanguages.map((statementWordingLanguage) => {
            const language = statementWordingLanguage.value;
            return (
              <>
                <Controller
                  control={control}
                  name={`translations.${language}.statementWordingForStampLimit`}
                  render={({ field: { value } }) => {
                    return (
                      <CustomTitleWithInput
                        title={`Stamp limit statement (${statementWordingLanguage.label})`}
                        defaultValue={value}
                        useDefaultValue={false}
                        setValue={(val) => {
                          setValue(
                            `translations.${language}.statementWordingForStampLimit`,
                            val,
                            {
                              shouldDirty: true,
                            },
                          );
                        }}
                        error={{
                          id: `translations.${language}.statementWordingForStampLimit`,
                          error: hasError(
                            errors,
                            `translations.${language}.statementWordingForStampLimit`,
                          ),
                          message: errorMessage(
                            errors,
                            `translations.${language}.statementWordingForStampLimit`,
                          ),
                        }}
                      />
                    );
                  }}
                />
              </>
            );
          })}
        </>
      ) : null}
      <Controller
        control={control}
        name="perHeadLimit"
        render={({ field: { value } }) => (
          <>
            <CustomTitleLabel title={'Per head limit (optional)'} />
            <label className="tips-message">
              Max. no. of stamps can be earned for this mission per head
            </label>
            <CustomNumberInputWithUnit
              setValue={(value) => {
                setValue('perHeadLimit', value, { shouldDirty: true });
              }}
              defaultValue={value}
              unit="stamps"
              errorId="perHeadLimit"
              error={hasError(errors, 'perHeadLimit')}
              errorMessage={errorMessage(errors, 'perHeadLimit')}
            />
          </>
        )}
      />
    </>
  );
}

function TagSection({ attributeTagList }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { watch, setValue, formState, control } = useFormContext();
  const { errors } = formState;
  const attributeTags = watch('attributeTags');

  const [addPromptConfig, setAddPromptConfig] = useState();

  const config = {
    title: 'Attribute tag / Sub category (optional)',
    namespace: 'attributeTag',
    data: attributeTagList,
    selectedList: attributeTags,
    setValue: (option) => {
      setValue('attributeTags', option, { shouldDirty: true });
    },
    addButton: {
      title: 'Add Attribute tag',
      action: () => {
        setAddPromptConfig({
          button: {
            text: 'create attribute tag',
            actionPath: '/tags/create',
          },
          title: 'Go to create attribute tag?',
          description: `Go to create attribute tag.`,
        });
      },
      customClass: 'general-section-add-new btn-add-button-common',
    },
    requires: PermissionCodes.changeAttributeTags,
    groupKey: 'tagKey',
    errorIdKey: 'attributeTags',
    searchPlaceholder: 'Search by ID , name',
    custom: {
      customFilter: (inputValue, option) => {
        const inputIgnoreCase = inputValue?.toLowerCase();
        const optionIgnoreCase = option?.name?.toLowerCase();
        return (
          optionIgnoreCase?.includes(inputIgnoreCase) || option.pk == inputValue
        );
      },
    },
  };
  return (
    <>
      <label className="create-section-title">Tag</label>
      <BaseMutipleSelectorV2
        title={config.title}
        namespace={config.namespace}
        data={{
          sourceData: config.data,
          targetData: config.selectedList,
          targetChange: (value) => {
            config.setValue(value);
          },
        }}
        addButton={config.addButton}
        error={{
          error: hasError(errors, config.errorIdKey),
          id: config.errorIdKey,
          message: errorMessage(errors, config.errorIdKey),
        }}
        requires={config.requires}
        groupKey={config.groupKey}
        custom={config.custom}
        searchPlaceholder={config.searchPlaceholder}
      />

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig.button.text,
                action: () => {
                  setAddPromptConfig(null);
                  history.push({
                    pathname: addPromptConfig.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'Continue to Create Mission',
                      content: 'You can continue to create the mission.',
                    },
                  });
                },
              }
            : {}
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

function ActivePeriodSection() {
  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;
  const isFollowGamificationCampaignActivePeriod = watch(
    'isFollowGamificationCampaignActivePeriod',
  );
  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <>
      <label className="create-section-title">Active Period</label>

      <label className="create-section-label create-section-label-bottom-space">
        Follow the active period from the linked Gamification campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isFollowGamificationCampaignActivePeriod}
        onChange={(value) => {
          setValue('isFollowGamificationCampaignActivePeriod', value, {
            shouldDirty: true,
          });
        }}
      />

      {isFollowGamificationCampaignActivePeriod ? null : (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            It is an all-time active campaign
          </label>
          <CustomSwitchButton
            defaultChecked={isAlwaysActivePeriod}
            onChange={(value) => {
              setValue('isAlwaysActivePeriod', value, { shouldDirty: true });
            }}
          />
          {!isAlwaysActivePeriod ? (
            <div>
              <label className="create-section-label create-section-label-bottom-space">
                Start date
              </label>
              <CustomDateTimeSelect
                disabled={isAlwaysActivePeriod}
                defaultTime={watchStartDate}
                onTimeChange={(date) => {
                  setValue('startDate', date, { shouldDirty: true });
                }}
              />
              <label className="create-section-label create-section-label-bottom-space">
                End date
              </label>
              <CustomDateTimeSelect
                error={hasError(errors, 'endDate')}
                defaultTime={watchEndDate}
                disabled={isAlwaysActivePeriod}
                onTimeChange={(date) => {
                  setValue('endDate', date, { shouldDirty: true });
                }}
                seconds={59}
              />
              <ErrorFieldMessage
                id={`endDate`}
                error={hasError(errors, 'endDate')}
                message={errorMessage(errors, 'endDate')}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

function VisiblePeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isFollowGamificationCampaignVisiblePeriod = watch(
    'isFollowGamificationCampaignVisiblePeriod',
  );
  const isAlwaysVisiblePeriod = watch('isAlwaysVisiblePeriod');
  const watchDisplayStartDate = watch('displayStartDate');
  const watchDisplayEndDate = watch('displayEndDate');

  return (
    <>
      <label className="create-section-title">Visible Period</label>

      <label className="create-section-label create-section-label-bottom-space">
        Follow the visible period from the linked Gamification campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isFollowGamificationCampaignVisiblePeriod}
        onChange={(value) => {
          setValue('isFollowGamificationCampaignVisiblePeriod', value, {
            shouldDirty: true,
          });
        }}
      />

      {isFollowGamificationCampaignVisiblePeriod ? null : (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            It is an all-time visible campaign
          </label>
          <CustomSwitchButton
            defaultChecked={isAlwaysVisiblePeriod}
            onChange={(value) => {
              setValue('isAlwaysVisiblePeriod', value, { shouldDirty: true });
            }}
          />
          {!isAlwaysVisiblePeriod ? (
            <div>
              <label className="create-section-label create-section-label-bottom-space">
                Start date
              </label>
              <CustomDateTimeSelect
                disabled={isAlwaysVisiblePeriod}
                defaultTime={watchDisplayStartDate}
                onTimeChange={(date) => {
                  setValue('displayStartDate', date, { shouldDirty: true });
                }}
              />
              <label className="create-section-label create-section-label-bottom-space">
                End date
              </label>
              <CustomDateTimeSelect
                error={hasError(errors, 'displayEndDate')}
                defaultTime={watchDisplayEndDate}
                disabled={isAlwaysVisiblePeriod}
                onTimeChange={(date) => {
                  setValue('displayEndDate', date, { shouldDirty: true });
                }}
                seconds={59}
              />
              <ErrorFieldMessage
                id={`displayEndDate`}
                error={hasError(errors, 'displayEndDate')}
                message={errorMessage(errors, 'displayEndDate')}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

function CreateMissionStepThree({
  attributeTagList,
  userWorkingTeamIds,
  userIsSuperuser,
}) {
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors } = useFormContext();

  const missionType = watch('missionType');

  useEffect(() => {
    dispatch({ type: 'attributeTag/getAllList' });
  }, []);

  const getSections = () => {
    let sections = [
      <EarningRuleSection
        userWorkingTeamIds={userWorkingTeamIds}
        userIsSuperuser={userIsSuperuser}
      />,
      <LimitSection />,
      <TagSection attributeTagList={attributeTagList} />,
      <ActivePeriodSection />,
      <VisiblePeriodSection />,
    ];
    if (missionType?.value === MISSION_TYPE.SPECIAL_CAMPAIGN.value) {
      sections = sections.concat([<OutOfStockSection />]);
    }
    return sections;
  };
  const sections = getSections();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'mission/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });
  };

  return (
    <div className="create-mission-step-three">
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  attributeTagList: state.attributeTag.notPagedAllList,
  userWorkingTeamIds: state.users.workingTeams,
  userIsSuperuser: state.users.isSuperuser,
});

export default connect(mapPropsToState)(CreateMissionStepThree);
