import React, { useState, useEffect } from 'react';
import ContentGeneralSection from './ContentGeneralSection';
import { useFormContext } from 'react-hook-form';
import {
  LanguageConfig,
  SavedStatus,
  StoreType,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { ContinueWithBackButtons } from '../../../components/base/BottomStepComponent';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import { saveDataToSessionStorage } from '../../../models/StoreModel';
import { useHistory, useParams } from 'react-router-dom';
import KeyVisualSection from './KeyVisualSection';
import DetailSection from './DetailSection';
import BusinessHours from './BusinessHours';
import TagSection from './TagSection';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import './CreateStoreStepOne.scss';

const getTabs = (
  language,
  languages,
  setActiveSection,
  storeType,
  attributeTagList,
) => {
  const tabs = [
    <ContentGeneralSection
      language={language}
      languages={languages}
      focusChange={setActiveSection}
    />,
  ];
  if (storeType === StoreType.LOCATION) {
    tabs.push(
      <KeyVisualSection
        language={language}
        languages={languages}
        focusChange={setActiveSection}
      />,
    );
  }
  tabs.push(
    <DetailSection language={language} focusChange={setActiveSection} />,
    <BusinessHours
      language={language}
      focusChange={setActiveSection}
      storeType={storeType}
    />,
  );
  if (storeType === StoreType.LOCATION && language === LanguageConfig.english) {
    tabs.push(
      <TagSection language={language} attributeTagList={attributeTagList} />,
    );
  }
  return tabs;
};

function CreateStoreStepOne({
  languages,
  store,
  saved,
  hideContinueButtons,
  fromLocationList,
  storeType,
  attributeTagList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [activeSection, setActiveSection] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { setValue, getValues, trigger, setError } = useFormContext();
  console.log('@Store', store, getValues());

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    if (!fromLocationList) {
      setValue('storeType', storeType, { shouldDirty: true });
    }
  }, [dispatch]);

  return (
    <div className="store-create-step-one">
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(
              item.code,
              languages,
              setActiveSection,
              storeType,
              attributeTagList,
            ),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
        activeSection={activeSection}
      />
      {hideContinueButtons ? null : (
        <ContinueWithBackButtons
          continueAction={async () => {
            const isValid = await trigger([
              'storeTranslations.en.name',
              'brand',
              'storeTranslations.en.address',
              'weekHours',
              'district',
              'emailAddress',
              'location',
            ]);
            console.log('@@68: ', isValid, getValues());
            dispatch({
              type: 'storeModel/stepChange',
              payload: { currentStep: 0, isValid },
            });
            saveDataToSessionStorage(getValues());
          }}
          backAction={() => {
            history.push({
              pathname: '/stores',
              search: '?sort=displayPriority',
            });
          }}
        />
      )}
      {fromLocationList ? (
        <>
          <SaveAndBackButtons
            backAction={() =>
              history.push({ pathname: '/locations', search: '?sort=-pk' })
            }
            saveTempText={params.id ? 'Delete Location' : null}
            saveTempAction={() => {
              dispatch(
                createAction('storeModel/updateState')({
                  checkedList: [store],
                }),
              );
              setShowDeletePrompt(true);
            }}
            saveText={params.id ? 'Update' : 'Save'}
            saveAction={async () => {
              if (saved === SavedStatus.saving) {
                return;
              }
              const isValid = await trigger([
                'storeTranslations.en.name',
                // 'brand',
                // 'storeTranslations.en.brandName',
                // 'storeTranslations.en.brandIcon',
                'storeTranslations.en.coverPhoto',
                'storeTranslations.en.address',
                'weekHours',
                'district',
                'emailAddress',
                'location',
              ]);
              const errorList = [];
              const [showMissionLabels, missionLabels] = getValues([
                'showMissionLabels',
                'storeTranslations.en.missionLabels',
              ]);
              if (
                showMissionLabels &&
                Object.values(missionLabels || {}).filter(
                  (item) =>
                    !item.isDeleted &&
                    (!item.title ||
                      item.title === '' ||
                      item.title.length > 20),
                ).length
              ) {
                errorList.push({
                  name: 'storeTranslations.en.missionLabels',
                  message: 'Please input less than 20 characters.',
                });
              }
              errorList.forEach((error) => {
                setError(error.name, {
                  type: 'manual',
                  message: error.message,
                });
              });
              console.log('@@68: ', isValid, errorList.length, getValues());
              // dispatch({
              //   type: 'storeModel/stepChange',
              //   payload: { currentStep: 0, isValid },
              // });
              if (isValid && !errorList.length) {
                const storeData = getValues();
                if (!params.id) {
                  delete storeData.pk;
                }
                dispatch({
                  type: 'storeModel/createOrUpdate',
                  payload: {
                    data: storeData,
                    afterAction: () => {},
                    failAction: () => {},
                  },
                });
              }
            }}
            save={{ disabled: saved === SavedStatus.saving }}
          />
          <DeletePrompt
            data={store}
            title={'locations'}
            relatedName={''}
            // relatedSections={DELETE_RELATED_SECTIONS.STORE}
            show={showDeletePrompt}
            onClose={() => setShowDeletePrompt(false)}
            onConfirm={() => {
              setShowDeletePrompt(false);
              dispatch(
                createAction('storeModel/delete')({
                  afterAction: () => {
                    history.push({
                      pathname: '/locations',
                      search: '?sort=-pk',
                    });
                  },
                }),
              );
            }}
          />
        </>
      ) : null}
    </div>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  store: state.storeModel.oneStore,
  saved: state.storeModel.saved,
  attributeTagList: state.attributeTag.notPagedAllList,
});

export default connect(mapPropsToState)(CreateStoreStepOne);
