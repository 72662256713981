import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext, Controller } from 'react-hook-form';
import { validate } from './CreateGamificationCampaignValidate';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomEditor from '../base/CustomEditor';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import {
  LanguageConfig,
  TermsAndConditionsType,
} from '../../config/CustomEnums';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import MarkDownAreaWithTC from '../base/MarkDownAreaWithTC';
import defaultOtherInformationImage1 from '../../assets/images/gamificationCampaign/other-information-1.png';
import defaultOtherInformationImage2 from '../../assets/images/gamificationCampaign/other-information-2.png';
import defaultOtherInformationImage3 from '../../assets/images/gamificationCampaign/other-information-3.png';
import DeleteRowIcon from '../../assets/images/deleteIcon.png';
import CoverPhotoSection from './CoverPhotoSection';
import TutorialSection from './TutorialSection';
import AuthButton from '../base/AuthButton';
import { ImageButton } from '../base/CustomImageButton';
import { ApplySwitch } from '../base/LabelWithSwitch';
import { insertKeyToAllLanguages } from '../../utils';
import { MissionLabelsSection } from '../mission/MissionLabelsSection';

function GeneralSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  // const { genericTermsAndConditionsList } = useSelector((state) => ({
  //   genericTermsAndConditionsList: state.termsAndConditions?.genericList || [],
  // }));

  const translations = watch('translations') || {};
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const coverPhoto = translations[language]?.coverPhoto || '';
  const mobilePhoto = translations[language]?.mobilePhoto || '';
  const applyCoverPhotoForTaskListToAllLanguage = watch(
    'applyCoverPhotoForTaskListToAllLanguage',
  );
  const coverPhotoForTaskList =
    translations[language]?.coverPhotoForTaskList || '';
  const mobilePhotoForTaskList =
    translations[language]?.mobilePhotoForTaskList || '';
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );
  const detailPhotos = translations[language]?.detailPhotos || [];
  const detailMobilePhotos = translations[language]?.detailMobilePhotos || [];

  return (
    <div>
      <div className="first-section-title">General</div>
      <Controller
        control={control}
        name={`translations.${language}.pk`}
        render={() => () => {}}
      />
      <Controller
        control={control}
        name={`translations.${language}.name`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Name"
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`translations.${language}.name`, val, {
                  shouldDirty: true,
                });
              }}
              error={{
                id: `translations.${language}.name`,
                error: hasError(errors, `translations.${language}.name`),
                message: errorMessage(errors, `translations.${language}.name`),
              }}
            />
          </>
        )}
      />
      <MissionLabelsSection
        translationField={'translations'}
        language={language}
        languages={languages}
        customTitle={'Gamification text labels'}
      />
      <div className={'create-section-label create-section-label-bottom-space'}>
        Short description
      </div>
      <Controller
        control={control}
        name={`translations.${language}.shortDescription`}
        render={({ field: { value } }) => (
          <>
            <CustomEditor
              initialValue={value}
              onValueChange={(value) => {
                setValue(`translations.${language}.shortDescription`, value, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {}}
              errorMessage={errorMessage(
                errors,
                `translations.${language}.shortDescription`,
              )}
              error={hasError(
                errors,
                `translations.${language}.shortDescription`,
              )}
            />
          </>
        )}
      />

      <CustomTitleLabel
        title="Cover image on gamification campaign list"
        className="display-as-block"
      />
      <div className="cover-images-for-list">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Desktop'}
          suggest={
            'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={480}
          minHeight={270}
          photoKey={'coverPhoto'}
          aspect={480 / 270}
          applyToAllLanguage={applyCoverPhotoToAllLanguage}
        />
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Mobile (optional)'}
          suggest={
            'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={480}
          minHeight={270}
          photoKey={'mobilePhoto'}
          aspect={480 / 270}
          applyToAllLanguage={applyCoverPhotoToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english &&
        //   !coverPhoto?.value &&
        //   !mobilePhoto?.value
        // }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
            insertKeyToAllLanguages({
              languages: languages,
              key: 'mobilePhoto',
              value: mobilePhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      <CustomTitleLabel
        title="Cover image on gamification campaign detail page"
        className="display-as-block"
      />
      <div className="cover-images-for-task-list">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Desktop'}
          suggest={
            'Suggested Image Size: 1920px*500px. At least : 1024px*266px (Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={1024}
          minHeight={266}
          photoKey={'coverPhotoForTaskList'}
          aspect={1024 / 266}
          applyToAllLanguage={applyCoverPhotoForTaskListToAllLanguage}
        />
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Mobile (optional)'}
          suggest={
            'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={480}
          minHeight={270}
          photoKey={'mobilePhotoForTaskList'}
          aspect={480 / 270}
          applyToAllLanguage={applyCoverPhotoForTaskListToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english &&
        //   !coverPhotoForTaskList?.value &&
        //   !mobilePhotoForTaskList?.value
        // }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoForTaskListToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoForTaskListToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languages,
              key: 'coverPhotoForTaskList',
              value: coverPhotoForTaskList,
              setValue,
              getValues,
            });
            insertKeyToAllLanguages({
              languages: languages,
              key: 'mobilePhotoForTaskList',
              value: mobilePhotoForTaskList,
              setValue,
              getValues,
            });
          }
        }}
      />
      <CustomTitleLabel
        title="Other photos (max. 4) (optional)"
        className="display-as-block"
      />
      <div className="cover-images-for-detail-photos">
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Desktop'}
          suggest={
            'Suggested Image Size: 1920px*500px. At least : 1024px*266px (Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={1024}
          minHeight={266}
          photoKey={'detailPhotos'}
          aspect={1024 / 266}
          maxImageNum={4}
          applyToAllLanguage={applyDetailPhotosToAllLanguage}
        />
        <CoverPhotoSection
          languages={languages}
          language={language}
          title={'Mobile'}
          suggest={
            'Suggested Image Size: 1920px*1080px. At least : 480px*270px(Less than 2MB, support JPG, PNG and GIF only.)'
          }
          minWidth={480}
          minHeight={270}
          photoKey={'detailMobilePhotos'}
          aspect={480 / 270}
          maxImageNum={4}
          applyToAllLanguage={applyDetailPhotosToAllLanguage}
        />
      </div>
      <ApplySwitch
        // disabled={
        //   language === LanguageConfig.english &&
        //   !detailPhotos &&
        //   !detailMobilePhotos
        // }
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languages,
              key: 'detailPhotos',
              value: detailPhotos,
              setValue,
              getValues,
            });
            insertKeyToAllLanguages({
              languages: languages,
              key: 'detailMobilePhotos',
              value: detailMobilePhotos,
              setValue,
              getValues,
            });
          }
        }}
      />
    </div>
  );
}

function RewardInformationSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const translations = watch('translations') || {};
  const rewardInformations = translations[language].rewardInformations;
  const enRewardInformations = translations['en'].rewardInformations;
  const rewardInformationsKeys = watch('rewardInformationsKeys') || [];

  return (
    <div>
      <div className="first-section-title">Rewards (optional)</div>
      <div className="d-flex flex-column reward-section-fields-container">
        <CustomTitleLabel title={'Reward section header (optional)'} />
        <Controller
          control={control}
          name={`translations.${language}.rewardSectionTitle`}
          render={({ field: { value } }) => (
            <>
              <input
                type="text"
                onChange={({ target }) => {
                  setValue(
                    `translations.${language}.rewardSectionTitle`,
                    target.value,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                defaultValue={value}
                className={`custom-markdown-area-title custom-markdown-area-title-short`}
                placeholder="Please input title"
              />
              <ErrorFieldMessage
                id={'translations.en.rewardSectionTitle'}
                error={hasError(errors, 'translations.en.rewardSectionTitle')}
                message={errorMessage(
                  errors,
                  'translations.en.rewardSectionTitle',
                )}
              />
            </>
          )}
        />
        <Controller
          control={control}
          name={`translations.${language}.rewardSectionDescription`}
          render={({ field: { value } }) => (
            <>
              <input
                type="text"
                onChange={({ target }) => {
                  setValue(
                    `translations.${language}.rewardSectionDescription`,
                    target.value,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                defaultValue={value}
                className={`custom-markdown-area-title custom-markdown-area-title-short`}
                placeholder="Please input description"
              />
              <ErrorFieldMessage
                id={'translations.en.rewardSectionDescription'}
                error={hasError(
                  errors,
                  'translations.en.rewardSectionDescription',
                )}
                message={errorMessage(
                  errors,
                  'translations.en.rewardSectionDescription',
                )}
              />
            </>
          )}
        />
      </div>
      <div className="reward-information-container">
        {rewardInformationsKeys
          ?.filter((key) => !rewardInformations?.[key]?.isDeleted)
          ?.map((key, index) => {
            const applyImageToAllLanguage =
              enRewardInformations?.[key]?.applyImageToAllLanguage || false;
            const imageToApply = enRewardInformations?.[key]?.image;
            return (
              <>
                <div
                  key={`reward-info-${key}`}
                  className="reward-information-item-container"
                >
                  {index > 0 ? (
                    <ImageButton
                      action={() => {
                        const temp = languages?.forEach((item) => {
                          setValue(
                            `translations.${item.code}.rewardInformations.${key}.isDeleted`,
                            true,
                            {
                              shouldDirty: true,
                            },
                          );
                        });
                      }}
                      image={DeleteRowIcon}
                      customClass="delete-icon"
                    />
                  ) : null}
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.pk`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.isDeleted`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.title`}
                    render={({ field: { value } }) => {
                      return (
                        <CustomTitleWithInput
                          title={`Reward ${index + 1} title (optional)`}
                          defaultValue={value}
                          useDefaultValue={false}
                          setValue={(val) => {
                            setValue(
                              `translations.${language}.rewardInformations.${key}.title`,
                              val,
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.image`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <CoverPhotoSection
                            languages={languages}
                            language={language}
                            title={`Reward ${index + 1} image (optional)`}
                            suggest={
                              'Suggested Image Size: 1920px*1440. At least : 480px*360px(Less than 2MB, support JPG, PNG and GIF only.)'
                            }
                            minWidth={480}
                            minHeight={360}
                            aspect={480 / 360}
                            value={value}
                            setCustomValue={(val) => {
                              const applyLanguages = applyImageToAllLanguage
                                ? languages
                                : [language];
                              applyLanguages.forEach((item) => {
                                setValue(
                                  `translations.${
                                    item.code || item
                                  }.rewardInformations.${key}.image`,
                                  val,
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              });
                            }}
                            applyToAllLanguage={applyImageToAllLanguage}
                          />
                          <ErrorFieldMessage
                            id={`translations.${language}.rewardInformations.${key}.image`}
                            error={hasError(
                              errors,
                              `translations.${language}.rewardInformations.${key}.image`,
                            )}
                            message={errorMessage(
                              errors,
                              `translations.${language}.rewardInformations.${key}.image`,
                            )}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.applyImageToAllLanguage`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <ApplySwitch
                            // disabled={
                            //   language === LanguageConfig.english &&
                            //   !coverPhoto?.value &&
                            //   !mobilePhoto?.value
                            // }
                            show={language === LanguageConfig.english}
                            checked={value}
                            onChange={(applyValue) => {
                              setValue(
                                `translations.${language}.rewardInformations.${key}.applyImageToAllLanguage`,
                                applyValue,
                                {
                                  shouldDirty: true,
                                },
                              );
                              if (applyValue) {
                                languages.forEach((item) => {
                                  setValue(
                                    `translations.${
                                      item.code || item
                                    }.rewardInformations.${key}.image`,
                                    imageToApply,
                                    {
                                      shouldDirty: true,
                                    },
                                  );
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.rewardInformations.${key}.content`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <div
                            className={
                              'create-section-label create-section-label-bottom-space'
                            }
                          >
                            Reward {index + 1} content (optional)
                          </div>
                          <CustomEditor
                            initialValue={value}
                            onValueChange={(val) => {
                              setValue(
                                `translations.${language}.rewardInformations.${key}.content`,
                                val,
                                {
                                  shouldDirty: true,
                                },
                              );
                            }}
                            toolBars={[
                              ['heading', 'bold', 'italic'],
                              ['quote'],
                              ['ul', 'ol'],
                              ['image', 'link', 'table'],
                            ]}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </>
            );
          })}
        <div>
          <AuthButton
            title={'Add a new reward'}
            customClass={'general-section-add-new btn-add-button-common'}
            action={() => {
              const lastKey =
                rewardInformationsKeys[rewardInformationsKeys.length - 1];
              setValue(
                'rewardInformationsKeys',
                rewardInformationsKeys.concat([parseInt(lastKey) + 1]),
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

function OtherInformationSection({ language, languages }) {
  const { formState, watch, setValue, getValues, control } = useFormContext();
  const errors = formState.errors;

  const { genericTermsAndConditionsList } = useSelector((state) => ({
    genericTermsAndConditionsList: state.termsAndConditions?.genericList || [],
  }));

  const translations = watch('translations') || {};
  const otherInformations = translations[language].otherInformations;
  const enOtherInformations = translations['en'].otherInformations;
  const otherInformationsKeys = watch('otherInformationsKeys') || [];

  const otherInformationDefaultImages = [
    defaultOtherInformationImage1,
    defaultOtherInformationImage2,
    defaultOtherInformationImage3,
  ];

  return (
    <div>
      <div className="first-section-title">Other Information (optional)</div>
      <div className="other-information-container">
        {otherInformationsKeys
          ?.filter((key) => !otherInformations?.[key]?.isDeleted)
          ?.map((key, index) => {
            const applyImageToAllLanguage =
              enOtherInformations?.[key]?.applyImageToAllLanguage || false;
            const imageToApply = enOtherInformations?.[key]?.image;
            return (
              <>
                <div
                  key={`other-info-${key}`}
                  className="other-information-item-container"
                >
                  {index > 2 ? (
                    <ImageButton
                      action={() => {
                        const temp = languages?.forEach((item) => {
                          setValue(
                            `translations.${item.code}.otherInformations.${key}.isDeleted`,
                            true,
                            {
                              shouldDirty: true,
                            },
                          );
                        });
                      }}
                      image={DeleteRowIcon}
                      customClass="delete-icon"
                    />
                  ) : null}
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.pk`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.isDeleted`}
                    render={() => () => {}}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.title`}
                    render={({ field: { value } }) => {
                      return (
                        <CustomTitleWithInput
                          title={`Other information ${
                            index + 1
                          } title (optional)`}
                          defaultValue={value}
                          useDefaultValue={false}
                          setValue={(val) => {
                            setValue(
                              `translations.${language}.otherInformations.${key}.title`,
                              val,
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          error={{
                            id: `translations.${language}.otherInformations.${key}.title`,
                            error: hasError(
                              errors,
                              `translations.${language}.otherInformations.${key}.title`,
                            ),
                            message: errorMessage(
                              errors,
                              `translations.${language}.otherInformations.${key}.title`,
                            ),
                          }}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.image`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <CoverPhotoSection
                            languages={languages}
                            language={language}
                            title={`Other information ${
                              index + 1
                            } image (optional)`}
                            suggest={
                              'Suggested Image Size: 1920px*1440. At least : 480px*360px(Less than 2MB, support JPG, PNG and GIF only.)'
                            }
                            minWidth={480}
                            minHeight={360}
                            aspect={480 / 360}
                            sideDefaultImage={
                              otherInformationDefaultImages[index]
                            }
                            value={value}
                            setCustomValue={(val) => {
                              const applyLanguages = applyImageToAllLanguage
                                ? languages
                                : [language];
                              applyLanguages.forEach((item) => {
                                setValue(
                                  `translations.${
                                    item.code || item
                                  }.otherInformations.${key}.image`,
                                  val,
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              });
                            }}
                            applyToAllLanguage={applyImageToAllLanguage}
                          />
                          <ErrorFieldMessage
                            id={`translations.${language}.otherInformations.${key}.image`}
                            error={hasError(
                              errors,
                              `translations.${language}.otherInformations.${key}.image`,
                            )}
                            message={errorMessage(
                              errors,
                              `translations.${language}.otherInformations.${key}.image`,
                            )}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.applyImageToAllLanguage`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <ApplySwitch
                            // disabled={
                            //   language === LanguageConfig.english &&
                            //   !coverPhoto?.value &&
                            //   !mobilePhoto?.value
                            // }
                            show={language === LanguageConfig.english}
                            checked={value}
                            onChange={(applyValue) => {
                              setValue(
                                `translations.${language}.otherInformations.${key}.applyImageToAllLanguage`,
                                applyValue,
                                {
                                  shouldDirty: true,
                                },
                              );
                              if (applyValue) {
                                languages.forEach((item) => {
                                  setValue(
                                    `translations.${
                                      item.code || item
                                    }.otherInformations.${key}.image`,
                                    imageToApply,
                                    {
                                      shouldDirty: true,
                                    },
                                  );
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`translations.${language}.otherInformations.${key}.content`}
                    render={({ field: { value } }) => {
                      return (
                        <>
                          <div
                            className={
                              'create-section-label create-section-label-bottom-space'
                            }
                          >
                            Other information {index + 1} content (optional)
                          </div>
                          <CustomEditor
                            initialValue={value}
                            onValueChange={(val) => {
                              setValue(
                                `translations.${language}.otherInformations.${key}.content`,
                                val,
                                {
                                  shouldDirty: true,
                                },
                              );
                            }}
                            toolBars={[
                              ['heading', 'bold', 'italic'],
                              ['quote'],
                              ['ul', 'ol'],
                              ['image', 'link', 'table'],
                            ]}
                          />
                          <ErrorFieldMessage
                            id={`translations.${language}.otherInformations.${key}.content`}
                            error={hasError(
                              errors,
                              `translations.${language}.otherInformations.${key}.content`,
                            )}
                            message={errorMessage(
                              errors,
                              `translations.${language}.otherInformations.${key}.content`,
                            )}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </>
            );
          })}
        <div>
          <AuthButton
            title={'Add other information'}
            customClass={'general-section-add-new btn-add-button-common'}
            action={() => {
              const lastKey =
                otherInformationsKeys[otherInformationsKeys.length - 1];
              setValue(
                'otherInformationsKeys',
                otherInformationsKeys.concat([parseInt(lastKey) + 1]),
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        </div>
      </div>

      <MarkDownAreaWithTC
        languages={languages}
        language={language}
        hideTC={false}
        linkToTCTemplate="genericTermsAndConditionsTemplate"
        followTemplateKey="isFollowGenericTermsAndConditionsTemplate"
        getTCListKey="getGenericList"
        defaultFilterTC={{ type: TermsAndConditionsType.generic }}
        TCList={genericTermsAndConditionsList}
        // areaTitle="Generic Terms & Conditions (optional)"
        titleKey="genericTermsAndConditionsTitle"
        titleName="Generic Terms & Conditions section title"
        contentKey="genericTermsAndConditionsContent"
        contentName="Generic Terms & Conditions section content"
      />
    </div>
  );
}

function CreateGamificationCampaignStepTwo({ languageList }) {
  const { getValues, setValue, clearErrors, setError, watch } =
    useFormContext();
  console.log('@@284: ', getValues());
  const params = useParams();
  const dispatch = useDispatch();

  const getTabs = (language) => {
    return [
      <GeneralSection language={language} languages={languageList} />,
      <TutorialSection language={language} languages={languageList} />,
      <RewardInformationSection language={language} languages={languageList} />,
      <OtherInformationSection language={language} languages={languageList} />,
    ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'gamificationCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(CreateGamificationCampaignStepTwo);
