import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { CampaignType } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import ActivePeriodSection from './ActivePeriodSection';
import CouponCampaignSection, {
  FortuneBagPerHeadSection,
  PerHeadSection,
} from './CouponCampaignSection';
import PropertiesGeneralSection from './PropertiesGeneralSection';
import VisiblePeriodSection from './VisiblePeriodSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { isShowCouponRelated } from '../../../models/CreateCampaignModel';
import MultipleMapLocationSelectors from '../../base/MultipleMapLocationSelectors';

function CreateCampaignStepThree({ couponAcquired, sourcePerHeadLimit }) {
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors } = useFormContext();
  const campaignType = watch('campaignType');
  const isCouponRelated = isShowCouponRelated(campaignType);
  const getSections = () => {
    if (campaignType === CampaignType.fortuneBagCampaign) {
      return [<ActivePeriodSection />, <FortuneBagPerHeadSection />];
    }
    const sections = [<PropertiesGeneralSection />];
    sections.push(<ActivePeriodSection />);
    if (campaignType !== CampaignType.fortuneBagForGoodieBagCampaign) {
      sections.push(<VisiblePeriodSection />);
      if (isCouponRelated) {
        sections.push(
          <MultipleMapLocationSelectors
            title={'Restrict acquire location by user GPS info'}
            tips={
              'If turn on, restrict the location where campaign can be acquired'
            }
          />,
          <CouponCampaignSection />,
          <PerHeadSection />,
        );
      }
    } else {
      sections.push(<FortuneBagPerHeadSection />);
    }

    return sections;
  };

  const sections = getSections();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
      couponAcquired,
      sourcePerHeadLimit,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  couponAcquired: state.createCampaign.couponAcquired,
  sourcePerHeadLimit: state.createCampaign.campaign?.perHeadLimit,
});

export default connect(mapPropsToState)(CreateCampaignStepThree);
