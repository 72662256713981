import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { useDispatch, useSelector } from 'react-redux';
import { MISSION_TYPE } from '../../../models/MissionModel';
import { ArrayStringData, createAction } from '../../../utils';
import LabelWithToolTips from '../../../components/base/LabelWithToolTips';

// import './Filter.scss';

function Filter({
  backAction = () => {},
  inPrompt = false,
  filetrItems = [],
  defaultSearch = {},
}) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { attributeTags, workingTeamList, gamificationList } = useSelector(
    (state) => ({
      attributeTags: state.attributeTag.notPagedAllList,
      workingTeamList: state.workingTeam.notPagedAllList,
      gamificationList: state.gamificationCampaign.notPagedAllList,
    }),
  );

  const parsedSearch = queryString.parse(location.search);
  const searchType =
    parsedSearch['type'] || (inPrompt && defaultSearch.type) || '';
  const searchPublishStatus =
    parsedSearch['status'] || (inPrompt && defaultSearch.status) || '';
  const searchActivePeriod =
    parsedSearch['active_period'] ||
    (inPrompt && defaultSearch.active_period) ||
    '';
  const searchVisablePeriod =
    parsedSearch['visible_period'] ||
    (inPrompt && defaultSearch.visible_period) ||
    '';
  const searchCreateAt = parsedSearch['create_at'] || '';
  const searchLastModified = parsedSearch['last_modified'] || '';
  const searchWorkingTeam =
    parsedSearch['working_team'] ||
    (inPrompt && defaultSearch.working_team) ||
    '';
  const searchAttributeTag =
    parsedSearch['tags'] || (inPrompt && defaultSearch.tags) || '';
  const searchGamification =
    parsedSearch['linked_gamification'] ||
    (inPrompt && defaultSearch.linked_gamification) ||
    '';

  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(searchPublishStatus);
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visablePeriod, setVisablePeriod] = useState(searchVisablePeriod);
  const [createAt, setCreateAt] = useState(searchCreateAt);
  const [lastModified, setLastModified] = useState(searchLastModified);
  const [workingTeam, setWorkingTeam] = useState(
    ArrayStringData(searchWorkingTeam),
  );
  const [searchWorkingTeamData, setSearchWorkingTeamData] = useState();
  const [tags, setTags] = useState(ArrayStringData(searchAttributeTag));
  const [sourceTagsData, setSourceTagsData] = useState();
  const [tagsData, setTagsData] = useState();
  const [gamification, setGamification] = useState(
    ArrayStringData(searchGamification),
  );
  const [searchGamificationData, setSearchGamificationData] = useState();

  useEffect(() => {
    if (filetrItems.indexOf('Working team') >= 0) {
      dispatch({ type: 'workingTeam/getAllList', payload: {} });
    }
    if (filetrItems.indexOf('tags') >= 0) {
      dispatch({
        type: 'attributeTag/getAllList',
        payload: { linkedCampaign: true },
      });
    }
    if (filetrItems.indexOf('gamification') < 0) {
      dispatch({
        type: 'gamificationCampaign/getAllList',
        payload: { isSimplify: true },
      });
    }

    return () => {
      dispatch({ type: 'attributeTag/clearData' });
      dispatch({ type: 'workingTeam/clearData' });
      if (filetrItems.indexOf('gamification') < 0) {
        dispatch({ type: 'gamificationCampaign/clearData' });
      }
    };
  }, []);

  useEffect(() => {
    console.log('attributeTags: ', attributeTags);
    const treeData = [];
    let others = {};
    attributeTags.forEach((item) => {
      let parent = item.categories?.edges?.[0]?.node;
      const parentPK = item.categories?.edges?.[0]?.node?.pk || -1;
      if (!parent) {
        parent = {
          pk: -1,
          title: (
            <LabelWithToolTips
              title={'Other attribute tags'}
              className="list-tree-title"
            />
          ),
          name: 'Other attribute tags',
        };

        if (others.title) {
          others.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
        } else {
          others = {
            ...parent,
            key: `group-${parentPK}`,
            children: [
              {
                ...item,
                title: (
                  <LabelWithToolTips
                    title={item.title}
                    className="list-tree-sub-title"
                  />
                ),
                key: item.pk,
              },
            ],
          };
        }
      } else {
        parent.title = (
          <LabelWithToolTips
            title={`[ID:${parent.pk}] ${parent.name}`}
            className="list-tree-title"
          />
        );

        const exsitGroup = treeData.find((group) => group.pk == parentPK);
        if (exsitGroup) {
          exsitGroup.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
          return;
        }

        treeData.push({
          ...parent,
          key: `group-${parentPK}`,
          children: [
            {
              ...item,
              title: (
                <LabelWithToolTips
                  title={item.title}
                  className="list-tree-sub-title"
                />
              ),
              key: item.pk,
            },
          ],
        });
      }
    });
    setTagsData([others].concat(treeData));
    setSourceTagsData([others].concat(treeData));
  }, [attributeTags]);

  let content = [
    {
      title: 'Type',
      data: Object.values(MISSION_TYPE).map((item) => {
        return {
          pk: item.value,
          name: item.label,
        };
      }),
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      value: visablePeriod,
      setValue: setVisablePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Create at',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Last modified',
      value: lastModified,
      setValue: setLastModified,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Status',
      data: inPrompt
        ? [
            { name: 'Published', pk: 'true' },
            { name: 'Unpublished', pk: 'false' },
          ]
        : [
            { name: 'Published', pk: 'true' },
            { name: 'Unpublished', pk: 'false' },
            { name: 'Expired', pk: 'inactive' },
          ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
  ];

  const workingTeamsSection = {
    title: 'Working team',
    data: !!searchWorkingTeamData
      ? searchWorkingTeamData
      : [{ pk: 'null', name: 'All admin users' }, ...workingTeamList],
    value: workingTeam,
    setValue: setWorkingTeam,
    componment: FilterTypeEnum.searchableChoice,
    placeholder: 'Search by ID, Name',
    renderDisplayName: (item) =>
      `${item.pk === 'null' ? '' : `[ID:${item.pk}] `}${item.name}`,
    filterAction: (searchKey) => {
      const newData = [
        { pk: 'null', name: 'All admin users' },
        ...workingTeamList,
      ]?.filter((item) => {
        const name = `${item.pk} ${item.name?.toLowerCase() || ''}`; //item.name?.toLowerCase() || '';
        return name.includes(searchKey?.toLowerCase() || '');
      });
      setSearchWorkingTeamData(newData);
    },
  };
  const attributeTagsSection = {
    title: 'tags',
    displayTitle: 'Attribute tag',
    data: tagsData,
    value: tags,
    setValue: setTags,
    componment: FilterTypeEnum.searchable2LevelChoice,
    placeholder: 'Search by ID, Name',
    filterAction: (searchKey) => {
      const newData = sourceTagsData
        ?.filter((item) => {
          const name = item.title.props.title?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            // item.pk === searchKey ||
            item.children.filter((val) =>
              val.title.props.title
                .toLowerCase()
                .includes(searchKey?.toLowerCase() || ''),
            ).length > 0
          );
        })
        ?.map((item) => {
          const name = item.title.props.title?.toLowerCase() || '';
          const categoryMatched = name.includes(searchKey?.toLowerCase() || '');

          if (categoryMatched) {
            return item;
          }

          return {
            ...item,
            children: item.children.filter((val) =>
              val.title.props.title
                .toLowerCase()
                .includes(searchKey?.toLowerCase() || ''),
            ),
          };
        });
      setTagsData(newData);
    },
  };
  const gamificationSection = {
    title: 'Linked gamification',
    data: !!searchGamificationData ? searchGamificationData : gamificationList,
    value: gamification,
    setValue: setGamification,
    componment: FilterTypeEnum.searchableChoice,
    placeholder: 'Search by ID, Name',
    renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    filterAction: (searchKey) => {
      const newData = gamificationList?.filter((item) => {
        const name = `${item.pk} ${item.name?.toLowerCase() || ''}`;
        return name.includes(searchKey?.toLowerCase() || '');
      });
      setSearchGamificationData(newData);
    },
  };
  // if (filetrItems.includes('Working team')) {
  //   content.splice(0, 0, workingTeamsSection);
  //   if (filetrItems.includes('tags')) {
  //     content.splice(2, 0, attributeTagsSection);
  //   }
  // }
  if (filetrItems.includes('tags')) {
    content.splice(1, 0, attributeTagsSection);
  }
  if (!filetrItems.includes('gamification')) {
    content.splice(1, 0, gamificationSection);
  }

  return (
    <BaseFilter
      content={
        filetrItems?.length > 0
          ? content.filter((item) => filetrItems.indexOf(item.title) >= 0)
          : content
      }
      backAction={backAction}
      inPrompt={inPrompt}
    />
  );
}

export default Filter;
