import React, { useEffect, useMemo, useRef, useState } from 'react';
import './TablePrompt.scss';
import BasePrompt from './BasePrompt';
import BaseSimpleTable from '../BaseSimpleTable';
import { InfiniteScrollOverride } from '../../override/InfiniteScrollOverride';
import { useDispatch, useSelector } from 'react-redux';
import { createAction, removeElementFromArrayByPK } from '../../../utils';

import InfiniteScroll from 'react-infinite-scroller';
import { Image } from 'react-bootstrap';
import NoDataPage from '../NoDataPage';
import CustomQRCode from '../CustomQRCode';
import CustomStatusTag, { CustomStatusTagFromText } from '../CustomStatusTag';
import ListFilterComponent from '../ListFilterComponent';
import { ImageTextButton } from '../CustomImageButton';
import filterImage from '../../../assets/images/32filter.svg';
import loading from '../../../assets/images/loading.gif';
import CustomEditableRank from '../CustomEditableRank';

function TablePrompt(props) {
  const onCancel = props.onCancel || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const title = props.title;
  const subTitle = props.subTitle;
  const table = props.table;
  const tableOrderField = props.table.orderField || '-pk';

  const scrollParentRef = useRef();
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPage, setIsResetPage] = useState(false);

  const [selected, setSelected] = useState(table.selectedData || []);
  const [selectedRemoved, setSelectedRemoved] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [searchKey, setSearchKey] = useState({ sort: tableOrderField });
  const [searchFilter, setSearchFilter] = useState({});
  const [filterCount, setFilterCount] = useState();

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (!props.show) {
      setSearchKey({ sort: tableOrderField });
      setSearchFilter({});
      setFilterCount();

      //   set;
    } else {
      setSelected(table.selectedData || []);
    }
  }, [props.show]);

  useEffect(() => {
    setIsResetPage(false);
    setDataList(props.table.dataList);
    if (selectAll) {
      setSelected(props.table.dataList);
    }
  }, [props.table.dataList]);

  useEffect(() => {
    // console.log('??? table isLoading change', props.table.isLoading);
    setIsLoading(props.table.isLoading);
  }, [props.table.isLoading]);

  useEffect(() => {
    setButtonDisabled(selected.length <= 0);
  }, [selected]);

  //   const totalCustomWidth = useMemo(() => {
  //     const hasWidthFields = table.fields.filter((item) => item.width);

  //     return [
  //       hasWidthFields.reduce(
  //         (accumulator, current) => accumulator + (current.width || 0),
  //         0,
  //       ),
  //       hasWidthFields.length,
  //     ];
  //   }, [table.fields]);

  const renderRowData = (item, rowIndex) => {
    return (
      <>
        {table.fields.map((field, index) => {
          switch (field.fieldName) {
            case 'status':
            case 'displayStatus':
              return (
                <td
                  key={`td-${rowIndex}-${index}`}
                  className="simple-list-status-field"
                >
                  {CustomStatusTagFromText(item[field.fieldName])}
                </td>
              );

            default:
              return (
                <td key={`td-${rowIndex}-${index}`}>
                  {item[field.fieldName] || '-'}
                </td>
              );
          }
        })}
      </>
    );
  };

  const rank = useMemo(() => {
    if (!searchKey.sort || !searchKey.sort?.includes('-')) {
      return false;
    }

    return true;
  }, [searchKey.sort]);

  const tableContent = () => {
    return (
      <>
        {showFilter ? (
          <table.filter.filterComponent
            backAction={(filterSearch) => {
              setShowFilter(false);
              console.log('@@114: ', filterSearch);
              setFilterCount(Object.keys(filterSearch).length);

              setSearchFilter(filterSearch);
              table.filter.searchFunc({ ...searchKey, ...filterSearch });
              setIsResetPage(true);
            }}
            inPrompt={true}
            filetrItems={table.filter.filetrItems || []}
            defaultSearch={searchFilter}
          />
        ) : null}
        <div className={`table-area`}>
          <label className="table-title">{table.title}</label>

          {/* <table className={`base-simple-table-header `}>
          <colgroup>
            <col width={`35px`} />
            {table.fields.map((item, index) => {
              console.log(
                '@@121: ',
                item.displayName,
                item.width,
                totalCustomWidth,
              );

              return (
                <col
                  width={
                    item.width
                      ? `${item.width}px`
                      : `${
                          (1000 - 35 - totalCustomWidth[0] - 14) /
                          (table.fields.length - totalCustomWidth[1])
                        }px`
                  }
                />
              );
            })}
            <col width={'14px'} />
          </colgroup>
          <thead>
            <tr>
              <td colSpan={table.fields.length + 2}>filter</td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              {table.fields.map((item, index) => {
                return <td key={`th-${index}`}>{item.displayName}</td>;
              })}
              <td></td>
            </tr>
          </thead>
        </table> */}
          <div
            ref={scrollParentRef}
            className="base-simple-table-div table-scroll-area"
            //   style={{ height: '700px', overflow: 'auto' }}
          >
            <table className={`base-simple-table `}>
              {/* <colgroup>
              <col width={'35px'} />
              {table.fields.map((item, index) => {
                const width = item.width
                  ? `${item.width}px`
                  : `${
                      (1000 - 35 - totalCustomWidth[0]) /
                      (table.fields.length - 1)
                    }px`;
                return <col width={`${width}`} />;
              })}
            </colgroup> */}
              <thead>
                <tr>
                  <td colSpan={table.fields.length + 2}>
                    <div>
                      <div
                        className={`all-list-filter-container`}
                        // style={showFilter}
                      >
                        <ImageTextButton
                          action={() => setShowFilter(!showFilter)}
                          image={filterImage}
                          title={'Filter'}
                        />
                        {filterCount > 0 ? (
                          <label className="filter-badge-icon">
                            {filterCount}
                          </label>
                        ) : null}
                      </div>
                      {table.filter.keywordSearch.keys.map((item) => (
                        <ListFilterComponent
                          // defaultValue={searchKey}
                          searchPlaceholder={item.placeholder}
                          onKeywordChange={(value) => {
                            setSearchKey({
                              ...searchKey,
                              [item.search]: value,
                            });
                          }}
                          onSearch={(key) => {
                            table.filter.searchFunc({
                              ...searchKey,
                              ...searchFilter,
                            });
                            setIsResetPage(true);
                          }}
                        />
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => {
                        if (!selectAll) {
                          setSelected(dataList);
                        } else {
                          setSelected([]);
                        }
                        setSelectAll(!selectAll);
                      }}
                    />
                  </td>
                  {table.fields.map((item, index) => {
                    return (
                      <td key={`th-${index}`} align="center">
                        {index === 0 ? (
                          <CustomEditableRank
                            rank={rank}
                            show={true}
                            title={item.displayName}
                            // extraAreaStyle={'base-rank-container'}
                            // extraRankStyle={extraRankStyle}
                            onClick={(rank) => {
                              setSearchKey({
                                ...searchKey,
                                sort: rank
                                  ? `-${tableOrderField}`
                                  : tableOrderField,
                              });
                              table.filter.searchFunc({
                                ...searchKey,
                                sort: rank
                                  ? `-${tableOrderField}`
                                  : tableOrderField,
                                ...searchFilter,
                              });
                            }}
                          />
                        ) : (
                          item.displayName
                        )}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <InfiniteScrollOverride
                pageStart={1}
                loadMore={(page) => {
                  console.log('@@loadmore: ', page);
                  // setIsLoading(true);
                  if (!isLoading) {
                    setIsLoading(true);
                    console.log('@@loadmore-1: ', page);
                    // table.loadMore(page, { ...searchFilter, ...searchKey });
                  }
                  table.loadMore(page - 1, { ...searchFilter, ...searchKey });
                }}
                hasMore={table.hasNext}
                isLoading={isLoading}
                loader={
                  <img
                    src={loading}
                    style={{ width: '40px', height: '40px' }}
                  />
                }
                threshold={1}
                element={'tbody'}
                useWindow={false}
                getScrollParent={() => scrollParentRef}
                isResetPage={isResetPage}
              >
                {/* <tr>
                <td height={'74px'}> </td>
                {table.fields.map((item, index) => {
                  return <td></td>;
                })}
              </tr> */}

                {dataList?.length > 0 ? (
                  dataList.map((item, rowIndex) => {
                    const checked =
                      selected
                        .map((selectedItem) => selectedItem.pk)
                        .indexOf(item.pk) >= 0;
                    return (
                      <tr key={`tr-${rowIndex}`}>
                        <td align="center">
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={(event) => {
                              if (checked) {
                                const removedSelected =
                                  removeElementFromArrayByPK(selected, item);

                                setSelected(removedSelected);
                                setSelectedRemoved([...selectedRemoved, item]);
                              } else {
                                setSelected([...selected, item]);
                              }
                            }}
                          />
                        </td>
                        {renderRowData(item, rowIndex)}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={table.fields.length + 1}>
                      <NoDataPage />
                    </td>
                  </tr>
                )}
              </InfiniteScrollOverride>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <BasePrompt
      show={props.show}
      customClass={`table-prompt ${props.customClass}`}
      closeAction={props.onClose}
      leftButton={{
        text: 'Cancel',
        action: () => onCancel(true),
      }}
      rightButton={{
        text: 'Confirm',
        action: () => {
          console.log('@@286: ', selected);
          setButtonDisabled(true);
          onConfirm(selected, selectedRemoved);
        },
        disabled: buttonDisabled,
      }}
      title={title}
      description={subTitle}
      otherBody={tableContent}
    />
  );
}

export default TablePrompt;
