import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { LanguageConfig } from '../../config/CustomEnums';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { createAction } from '../../utils';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import Loading from '../base/Loading';
import { APIStatus } from '../../config/CustomEnums';

const INTEREST_PREFERENCE_ERRORS = {
  name: {
    required: 'Please provide title',
  },
};

const CreateInterestPreferenceStepOne = ({
  languages,
  dispatch,
  apiStatus,
}) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const {
    getValues,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const validate = async () => {
    clearErrors([`translations.en.name`]);
    const [name] = getValues(['translations.en.name']);

    if (!name) {
      setError(
        'translations.en.name',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    return name;
  };

  const renderLanguageTab = (language) => {
    const watchName = watch(`translations.${language}.name`);

    const watchTips = watch(`translations.${language}.tips`);

    return [
      <div>
        <div className="first-section-title">General</div>
        <Controller
          control={control}
          name={`translations.${language}.name`}
          render={() => (
            <CustomTitleWithInput
              title="Name"
              defaultValue={watchName}
              setValue={(value) => {
                setValue(`translations.${language}.name`, value, {
                  shouldDirty: true,
                });
              }}
              error={
                hasError(errors, `translations.${language}.name`)
                  ? {
                      id: `translations.${language}.name`,
                      error: hasError(errors, `translations.${language}.name`),
                      message: 'please input a name',
                    }
                  : {}
              }
            />
          )}
        />
      </div>,
    ];
  };
  if (apiStatus === APIStatus.calling) {
    return <Loading customClass={'common-full-height'} />;
  }
  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          let isValid = await validate();

          console.log('@create 801', getValues(), isValid);

          if (isValid) {
            const data = getValues();
            if (id) {
              dispatch(
                createAction('interestPreference/updateInterestPreference')({
                  data,
                }),
              );
            } else {
              dispatch(
                createAction('interestPreference/createInterestPreference')({
                  data,
                }),
              );
            }
          }
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  languages: state.language.allList,
  apiStatus: state.interestPreference.apiStatus,
});

export default connect(mapPropsToState)(CreateInterestPreferenceStepOne);
