export const NavBarNames = {
  customer: 'Customer',
  segments: 'Segment',
  customerGroup: 'Customer group',
  interestPreference: 'Interest Preference',
  levels: 'Levels',
  records: 'Records',
  campaigns: 'Campaigns',
  campaignGroups: 'Fortune Bag groups',
  goodieBags: 'Goodie Bags',
  goodieBagGroups: 'Goodie Bag groups',
  gamificationCampaign: 'Gamification campaign',
  mission: 'Mission',
  campaignCategory: 'Campaign categories',
  generalAlert: 'General Alert',
  message: 'Messages',
  earnRules: 'Earning rules',
  coupons: 'Coupons',
  couponLabel: 'Coupon label',
  rewards: 'Rewards',
  brands: 'Brands',
  stores: 'Stores',
  storeCodes: 'Store code List',
  locations: 'Locations',
  storeCategory: 'Store Categories',
  translations: 'Translations',
  transactions: 'Transactions',
  pointRecords: 'Point records',
  couponRecords: 'Coupon records',
  others: 'Webview content',
  admin: 'Administrators',
  adminGroup: 'Administrators group',
  dashboard: 'Dashboard',
  banners: 'Banners',
  productCategory: 'Product categories',
  featuredCampaign: 'Featured campaigns',
  featuredProduct: 'Featured products',
  // tags: 'Attribute tags',
  logoLabel: 'Logo label',
  targetMarket: 'Target markets',
  channel: 'Channels',
  surveyForm: 'Survey form',
  langauge: 'Languages',
  offerTypeLabel: 'Offer type labels',

  tags: 'Tag',
  tourCard: 'Tour card',
  termsAndConditions: 'Terms & Conditions',
  paymentSettlement: 'Payment settlement',
  manageRecaptcha: 'Manage reCAPTCHA',
  manageSearchFunction: 'Manage search function',

  dashboardOverview: 'Overview',
  dashboardCustomer: 'Customer ',
  dashboardCampaign: 'Campaign',
  dashboardCoupon: 'Coupons ',
  dashboardGoodiebag: 'Goodie bag',

  otpChannel: 'OTP channel',
  checkOtp: 'Check OTP',
  checkMemberId: 'Check Member ID',

  simpleDashboard: 'Simple Dashboard',

  workingTeam: 'Working Team',
  adminActivityLog: 'Admin activity log',
  rateLimit: 'Rate Limit Control',

  manualUseCoupon: 'Manual use coupon',
};
