import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import MapLocationSelector, { checkLocationValid } from './MapLocationSelector';
import CustomTitleWithSwitch from './CustomTitleWithSwitch';
import { ImageTextButton } from './CustomImageButton';
import deleteIcon from '../../assets/images/delete_icon.svg';
import PromptButton, { BUTTON_TYPES } from './prompt/BaseButtons';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTitleWithRadio } from '../earning/CustomBaseComponments';
import NumberInputArea from './NumberInputArea';
import ErrorFieldMessage from './ErrorFieldMessage';
import { COUPON_LOCATION_SET } from '../../config/CustomEnums';

export const checkMultipleLocationValid = (
  checkGPS,
  locations,
  required = true,
) => {
  const allErrors = [];
  if (checkGPS) {
    const e = {};
    (locations || []).forEach((item, index) => {
      const { valid, errors } = checkLocationValid(item, required);

      if (!valid) {
        e[index + 1] = errors;
        // allErrors.push({
        //   name: `locations`,
        //   message: JSON.stringify({ [index + 1]: errors }),
        // });
      }
    });
    if (Object.keys(e).length > 0) {
      allErrors.push({
        name: `locations`,
        message: JSON.stringify(e),
      });
    }
  }

  return allErrors;
};

function MultipleMapLocationSelectors({ title, tips, setLocationSet }) {
  const { watch, setValue, control, formState, getValues } = useFormContext();
  const { errors } = formState;
  console.log('@@12: ', errors);

  const locations = watch('locations');
  const checkGPS = watch('checkGPS');
  const locationSet = watch('locationSet');
  const toDeleteLocations = watch('toDeleteLocations');

  return (
    <>
      <label className="create-section-title">Location Restriction</label>

      <Controller
        control={control}
        name="checkGPS"
        render={({ field: { value } }) => (
          <CustomTitleWithSwitch
            title={title}
            defaultValue={value}
            setValue={(value) => {
              setValue('checkGPS', value);
              if (value && (!locations || locations?.length <= 0)) {
                setValue(
                  `locations`,
                  [
                    {
                      mapAddress: '',
                      latitude: null,
                      longitude: null,
                      radius: 100,
                    },
                  ],
                  {
                    shouldDirty: true,
                  },
                );
              }
              if (setLocationSet) {
                if (!locationSet) {
                  setValue('locationSet', COUPON_LOCATION_SET.BY_STORES, {
                    shouldDirty: true,
                  });
                }
              } else {
                setValue('locationSet', COUPON_LOCATION_SET.CUSTOMIZED, {
                  shouldDirty: true,
                });
              }
            }}
            tips={tips}
          />
        )}
      />

      {checkGPS && setLocationSet ? (
        <>
          <Controller
            control={control}
            name={`locationSet`}
            render={({ field: { value } }) => (
              <CustomTitleWithRadio
                title={'Set location point'}
                options={[
                  {
                    label: 'Follow applicable stores’ location',
                    value: COUPON_LOCATION_SET.BY_STORES,
                  },
                  {
                    label: 'Manually Select Location',
                    value: COUPON_LOCATION_SET.CUSTOMIZED,
                  },
                ]}
                defaultValue={value}
                setValue={(value) => {
                  setValue('locationSet', value, { shouldDirty: true });
                  if (
                    value === COUPON_LOCATION_SET.CUSTOMIZED &&
                    (!locations || locations?.length <= 0)
                  ) {
                    setValue(
                      `locations`,
                      [
                        {
                          mapAddress: '',
                          latitude: null,
                          longitude: null,
                          radius: 100,
                        },
                      ],
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              />
            )}
          />
          {locationSet === COUPON_LOCATION_SET.BY_STORES ? (
            <>
              <ErrorFieldMessage
                id={'locationSet'}
                error={setLocationSet.error}
                message={`There are ${setLocationSet.storeCount} applicable stores selected in this coupon. There may be performance issue if the applicate store number over 10.`}
              />
              {setLocationSet?.notHaveLocationStores?.length ? (
                <div>
                  <label
                    className={`error-field-message-style`}
                    style={{ display: 'block' }}
                  >
                    The below store(s) have not had their location's latitude
                    and longitude input.:
                  </label>
                  {setLocationSet?.notHaveLocationStores.map((store) => {
                    return (
                      <div className={`error-field-message-style`}>
                        [ID: {store.pk}] {store.name}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      {checkGPS ? (
        locationSet === COUPON_LOCATION_SET.BY_STORES ? (
          <Controller
            control={control}
            name={`radius`}
            render={({ field: { value } }) => (
              <NumberInputArea
                labelText={
                  'Radius (Calculate a circular eligible zone per store’s location)'
                }
                // labelClass = "",
                customStyle={{ display: 'block' }}
                type="meters"
                valueChange={(value) =>
                  setValue('radius', value, { shouldDirty: true })
                }
                defaultValue={value}
                errors={errors}
                errorFieldName={'radius'}
                // index = null
              />
            )}
          />
        ) : (
          <>
            {locations?.map((item, index) => (
              <div className="one-location-area">
                <Controller
                  control={control}
                  name={`location.${index + 1}`}
                  // rules={{
                  //   validate: {
                  //     inner: (data) => {
                  //       const { valid, errors } = checkLocationValid(data);
                  //       console.log(
                  //         '@@MultipleMapLocationSelectors-108: ',
                  //         valid,
                  //         errors,
                  //       );
                  //       if (valid) {
                  //         return true;
                  //       }

                  //       return JSON.stringify(errors);
                  //     },
                  //   },
                  // }}
                  render={() => (
                    <div className="one-map">
                      <MapLocationSelector
                        data={item}
                        setData={(value) => {
                          const updatedArr = Array.from(locations);
                          updatedArr[index] = value;

                          setValue(`locations`, updatedArr, {
                            shouldDirty: true,
                          });
                        }}
                        errors={
                          errors?.locations
                            ? JSON.parse(errors?.locations?.message)?.[
                                `${index + 1}`
                              ]
                            : {}
                        }
                      />
                    </div>
                  )}
                />
                <ImageTextButton
                  action={() => {
                    const toDeleteLocation = locations[index];
                    const removedArr = Array.from(locations);
                    removedArr.splice(index, 1);
                    setValue('locations', removedArr, { shouldDirty: true });
                    if (toDeleteLocation.id) {
                      setValue(
                        'toDeleteLocations',
                        [...(toDeleteLocations || []), toDeleteLocation.id],
                        { shouldDirty: true },
                      );
                    }
                  }}
                  image={deleteIcon}
                  title={'Delete'}
                  customClass={
                    locations?.length > 1
                      ? 'map-delete-button'
                      : 'map-delete-button hidden'
                  }
                />
              </div>
            ))}

            <PromptButton
              title={'Add location'}
              className="add-location-more"
              type={BUTTON_TYPES.secondary}
              action={() => {
                setValue(
                  'locations',
                  [
                    ...locations,
                    {
                      mapAddress: '',
                      latitude: null,
                      longitude: null,
                      radius: 100,
                    },
                  ],
                  { shouldDirty: true },
                );
              }}
            />
          </>
        )
      ) : null}
    </>
  );
}

const mapPropsToState = (state) => ({
  appliableStoreList: state.storeModel.appliableStoreList || [],
});

export default connect(mapPropsToState)(MultipleMapLocationSelectors);
