import React, { useState, useEffect } from 'react';
import '../stores/StoreList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ImportResource } from '../../../models/DownloadImportModel';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CustomRadios from '../../../components/base/CustomRadios';
import { Row } from 'react-bootstrap';
import queryString from 'query-string';

function LocationList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
    importedFile,
  } = useSelector((state) => ({
    listFields: state.storeModel.locationListDisplayFields,
    dataList: state.storeModel.pagedStoreList,
    pageInfo: state.storeModel.pageInfo,
    totalPage: state.storeModel.totalPage,
    totalCount: state.storeModel.totalCount,
    checkedList: state.storeModel.checkedList,
    importedFile: state.uploadFiles.importedFile,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [sortDisabled, setSortDisabled] = useState(false);
  const [includeFilter] = useState({ isInclude: false });
  const [promptConfig, setPromptConfig] = useState();

  const queryList = () => {
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const status = querySearch['status'];
      const page = parseInt(stringPage);
      const queryData = {
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
        fromLocation: true,
      };
      if (status) {
        queryData.isPublished = status;
      }
      dispatch(createAction('storeModel/getPagedStoreList')(queryData));
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);

    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const exportData = {};
    if (parsedSearch.attribute_tags) {
      exportData.attribute_tags = parsedSearch.attribute_tags.split(',');
    }
    // if (parsedSearch.brands) {
    //   exportData.brands = parsedSearch.brands.split(',');
    // }
    if (parsedSearch.status) {
      exportData.is_published =
        parsedSearch.status === 'Published' ? true : false;
    }
    if (parsedSearch.search) {
      exportData.search = parsedSearch.search;
    }
    const related = !includeFilter.isInclude
      ? {}
      : {
          filterConditions: JSON.stringify(exportData),
        };
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: { from: ImportResource.store, related: related },
    });
    includeFilter.isInclude = false;
  };

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.store,
          file: importedFile,
        }),
      );
    }
  }, [dispatch, importedFile]);

  const buttons = [
    // <ListButtonsGroup
    //   hideExtraButtonWidth={500}
    //   extraButtons={[
    //     <AuthButton
    //       customClass="btn-back-button-common btn-download"
    //       title="Export .csv"
    //       action={() => setPromptConfig(exportConfig)}
    //       requires={PermissionCodes.addExportjob}
    //     />,
    //     <AuthButton
    //       customClass="btn-back-button-common btn-download"
    //       title={'Import .csv'}
    //       action={handleImportClick}
    //       requires={PermissionCodes.addImportjob}
    //     />,
    //   ]}
    //   extraPopContent={[
    //     {
    //       requires: PermissionCodes.addExportjob,
    //       action: () => setPromptConfig(exportConfig),
    //       content: 'Export .csv',
    //     },
    //     {
    //       requires: PermissionCodes.addImportjob,
    //       action: handleImportClick,
    //       content: 'Import .csv',
    //     },
    //   ]}
    // />,
    // <input
    //   type="file"
    //   ref={hiddenFileInput}
    //   onChange={handleChange}
    //   style={{ display: 'none' }}
    //   accept=".csv"
    // />,
    <AuthButton
      title="Create Location"
      action={() => {
        history.push('/locations/create');
      }}
      requires={PermissionCodes.addLocation}
    />,
  ];

  // const buttons = [];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteLocation,
    },
  ];

  const doChanges = (data) => {
    dispatch({
      type: 'storeModel/createOrUpdate',
      payload: { data, afterAction: () => queryList() },
    });
  };

  const exportConfig = {
    title: 'Export Store (.csv)',
    closeAction: () => {
      setPromptConfig(null);
      includeFilter.isInclude = false;
    },
    leftButton: {
      text: 'Cancel',
      action: () => {
        setPromptConfig(null);
        includeFilter.isInclude = false;
      },
    },
    rightButton: {
      text: 'Export',
      action: () => {
        exportCSVAction();
        setPromptConfig(null);
      },
      requires: PermissionCodes.addExportjob,
    },
    otherBody: () => {
      return <div>{exportFilterChoice}</div>;
    },
  };

  const exportFilterChoice = (
    <>
      <label className={`create-section-label landing-title-margin-bottom `}>
        {'Select the export range'}
      </label>
      <Row style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}>
        <CustomRadios
          onChange={(value) => {
            console.log('CustomRadios:', value);
            includeFilter.isInclude = value;
          }}
          default={includeFilter.isInclude}
          options={[
            {
              label: 'All items',
              value: false,
            },
            {
              label: 'All filtered items from the list',
              value: true,
            },
          ]}
        />
      </Row>
    </>
  );

  const tabs = [
    {
      name: 'List of Locations',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
            // filetrItems: ['District', 'attribute_tags', 'Status'],
            filetrItems: ['District', 'Status'],
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeModel'}
              permissionGroup={PermissionCodes.location}
              deleteInfo={{
                data: [],
                title: 'location',
                relatedName: '',
                onComfirm: {},
              }}
              // sortable
              // sortDisabled={sortDisabled}
              // rankDisabled
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              hasOtherActions
              customActions={{
                deactive: (item) => {
                  doChanges({ id: item.pk, isPublished: !item.isPublished });
                },
              }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });
    queryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.locations,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeletePrompt
        data={checkedList}
        title={'locations'}
        relatedName={''}
        // relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('storeModel/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={!!promptConfig}
        title={promptConfig?.title}
        description={promptConfig?.description}
        closeAction={promptConfig?.closeAction}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        otherBody={promptConfig?.otherBody}
      />
    </>
  );
}

export default LocationList;
