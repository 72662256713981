import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import ManageSearchContent from '../../../components/manageSearch/ManageSearchContent';
import { revertParseType } from '../../../models/SearchSettingModel';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import Loading from '../../../components/base/Loading';

const ManageSearch = ({
  searchSettingsData,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  apiStatus,
}) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { searchSettingType } = params;

  const queryString = require('query-string');

  const clearData = () => {
    dispatch({ type: 'searchSetting/clearData' });
  };

  useEffect(() => {
    clearData();
    if (revertParseType(searchSettingType)) {
      dispatch(
        createAction('searchSetting/getOneDetail')({
          searchSettingType: revertParseType(searchSettingType),
        }),
      );
    } else {
      history.push({ pathname: '/manage_search' });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchSettingType]);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({ pathname: '/manage_search' });
    }
  }, [formHasSubmitted, apiStatus, history]);

  return (
    <>
      <BaseForm
        defaultValues={searchSettingsData}
        formHasSubmitted={formHasSubmitted}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.SEARCH_SETTING_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.SEARCH_SETTING_SESSION_KEY);
          }
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
              }),
            });
          },
          onCancel: () => history.push({ pathname: '/manage_search' }),
          onClose: () => history.push({ pathname: '/manage_search' }),
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={searchSettingType ? [<ManageSearchContent />] : [<Loading />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={searchSettingType || 'Manage search function'}
          />
        }
        caution={{
          title: searchSettingType || 'Manage search function',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  searchSettingsData: state.searchSetting.detail,
  formHasSubmitted: state.searchSetting.formHasSubmitted,
  hasUpdatedDefaultValues: state.searchSetting.hasUpdatedDefaultValues,
  apiStatus: state.searchSetting.apiStatus,
});
export default connect(mapPropsToState)(ManageSearch);
