import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';

function OutOfStockSection() {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      control={control}
      name="hideOutOfStockCampaign"
      render={({ field: { value } }) => (
        <>
          <label className="create-section-title">
            Out-of-stock coupon campaign
          </label>
          <CustomTitleWithSwitch
            title={'Hide the campaign which is out-of-stock'}
            tips={
              'If on, the frontend campaign list will hide all the out-of-stock campaign. If off, the out-of-stock campaign will show as dimmed.'
            }
            defaultValue={value}
            setValue={(value) => {
              setValue('hideOutOfStockCampaign', value, { shouldDirty: true });
            }}
          />
        </>
      )}
    />
  );
}

export default OutOfStockSection;
