import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { Button, Image } from 'react-bootstrap';
import './WeekHours.scss';
import DeleteIcon from '../../../assets/images/deleteIcon.png';
import StoreHours from './StoreHours';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';
import { weekdays } from '../../../models/StoreModel';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';

const defaultNewHour = {
  days: [],
  isClose: false,
  openHour: '12:00:00',
  closeHour: '12:00:00',
};

const getSelectDays = (weekHours) => {
  const days = [];
  weekHours.forEach((weekHour) => {
    days.push(...weekHour.days);
  });
  return days;
};

const getLeftWeekDays = (selectWeekdays) => {
  return weekdays.filter((weekDay) => !selectWeekdays.includes(weekDay));
};

const switchNewHour = (hours, value, hour, index, key, setValue) => {
  const newData = hours.map((weekHour, oldIndex) => {
    if (index === oldIndex) {
      return { ...hour, [key]: value };
    }
    return weekHour;
  });
  setValue('weekHours', newData, {
    shouldDirty: false,
  });
};

const DashLine = ({ index }) => {
  if (index === 0) {
    return <></>;
  }
  return (
    <div className="content-condition-area dash-top">
      <div className="content-condition-dash"></div>
      <label>And</label>
    </div>
  );
};

export default function DetailSection() {
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchWeekHours = watch('weekHours') || [];
  const selectWeekdays = getSelectDays(watchWeekHours);

  const allHoursSelect = selectWeekdays.length === weekdays.length;
  const weekHoursApproach = watchWeekHours.map((hour, index) => {
    const cleanData =
      weekdays.filter(
        (item) =>
          [].concat
            .apply([], Object.values(selectWeekdays))
            .map((selected) => selected.pk)
            .indexOf(item.pk) < 0,
      ) || [];
    cleanData.push(...hour.days);

    const leftWeekDays = getLeftWeekDays(selectWeekdays);
    return (
      <>
        <DashLine index={index} />

        <BaseMutipleSelectorV2
          custom={{
            customTitle: (
              <div className="week-hour-title-content">
                <label className="create-section-title-without-upper">
                  Hour #{index + 1}
                </label>
                <Image
                  src={DeleteIcon}
                  className="content-delete-button"
                  onClick={() => {
                    const newHours = watchWeekHours.filter(
                      (weekHour, oldIndex) => index !== oldIndex,
                    );
                    setValue('weekHours', newHours, { shouldDirty: true });
                  }}
                />
              </div>
            ),
          }}
          data={{
            sourceData: cleanData,
            targetData: hour.days,
            targetChange: (value) => {
              const newData = watchWeekHours.map((weekHour, oldIndex) => {
                if (index === oldIndex) {
                  value.sort((a, b) => a.key - b.key);
                  return { ...hour, days: value };
                }
                return weekHour;
              });
              setValue('weekHours', newData, {
                shouldDirty: false,
              });
            },
          }}
        />
        <StoreHours
          hour={hour}
          switchChange={(value) => {
            switchNewHour(
              watchWeekHours,
              value,
              hour,
              index,
              'isClose',
              setValue,
            );
          }}
          openHourChange={(value) => {
            switchNewHour(
              watchWeekHours,
              value,
              hour,
              index,
              'openHour',
              setValue,
            );
          }}
          closeHourChange={(value) => {
            switchNewHour(
              watchWeekHours,
              value,
              hour,
              index,
              'closeHour',
              setValue,
            );
          }}
        />
      </>
    );
  });
  const addButton = (
    <div hidden={allHoursSelect} className={'add-hours-button-common'}>
      <Button
        hidden={allHoursSelect}
        className="btn-back-button-common add-hours-button"
        onClick={() => {
          setValue('weekHours', [...watchWeekHours, defaultNewHour], {
            shouldDirty: true,
          });
        }}
      >
        Add new hour
      </Button>
    </div>
  );

  return (
    <>
      <label class="create-section-label create-section-label-bottom-space undefined">
        Days in a week business hour (optional)
      </label>
      <div
        className={`store-hours-area-container ${
          hasError(errors, 'weekHours') ? 'error-hour-container' : ''
        }`}
      >
        <Controller
          control={control}
          name={'weekHours'}
          rules={{
            validate: {
              lessThanOne: (value) => {
                if (!value?.length) {
                  return true;
                }
                return (
                  !!value?.every((workHour) => workHour.days.length > 0) ||
                  StoreErrorHandleFields.weekHours.required
                );
              },
            },
          }}
          render={() => weekHoursApproach}
        />
        {addButton}
        <ReactHookFormErrorMessage errors={errors} id="weekHours" />
      </div>
    </>
  );
}
