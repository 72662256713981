import React, { useState, useEffect } from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import CreateCategoryPrompt from './CreateCategoryPrompt';
import { CampaignType } from '../../../config/CustomEnums';
import './PropertiesGeneralSection.scss';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  CampaignErrorHandleField,
  firstError,
} from './CreateCampaignHandleError';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ContinueCreate } from '../CampaignUrlConfig';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import CreateOfferTypePrompt from './CreateOfferTypePrompt';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../../base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { isGoodieBagType } from '../../../models/CreateCampaignModel';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
} from '../../earning/CustomBaseComponments';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import SearchSemTagInput from './SearchSemTagInput';
import deleteImage from '../../../assets/images/earning/deletex1.png';
import { removeElementFromArray } from '../../../utils';
import { Button, Image } from 'react-bootstrap';

function onlyUnique(value, index, self) {
  console.log('@@176-27: ', value, index);
  // return self.filter(value.pk).length <= 1;
  return self.indexOf(value) === index;
}

function PropertiesGeneralSection({
  categories,
  offerTypeLabelList,
  attributeTagList,
}) {
  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;
  const campaignCategories = watch('categories') || [];
  const campaignType = watch('campaignType');
  const offerTypeLabel = watch('offerTypeLabel');
  const attributeTags = watch('attributeTags');
  const isFeatured = watch('isFeatured');
  const showInWechatHomepage = watch('showInWechatHomepage');
  const isStarred = watch('isStarred');
  const goodieBagReward = watch('goodieBagReward');
  const goodieBag = watch('goodieBag');
  const isPublished = watch('isPublished');

  const showLowStock = watch('showLowStock');
  const showOutOfStock = watch('showOutOfStock');

  const isEvent = watch('isEvent');
  const eventName = watch('eventName');

  const comma = ',';
  const searchTags = watch('searchTags') || [];
  const semTags = watch('semTags') || [];

  const params = useParams();
  const { id } = params;
  console.log('attributeTags', attributeTags);
  console.log('campaignCategories:', campaignCategories);

  console.log('errosList:', errors);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showCreateOfferType, setShowCreateOfferType] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const [addPromptConfig, setAddPromptConfig] = useState();

  useEffect(() => {
    firstError(`${CampaignErrorHandleField.customerGroup.name}`, []);
    dispatch({ type: 'attributeTag/getAllList' });
    dispatch({
      type: 'campaignCategoryList/getAllList',
      payload: { status: true },
    });
  }, []);

  const campaignCategoryConfig = {
    title: 'Main category',
    namespace: 'campaignCategoryList',
    data: categories,
    selectedList: campaignCategories,
    setValue: (option) => {
      setValue('categories', option, { shouldDirty: true });
    },
    addButton: {
      title: 'Add campaign category',
      action: () => {
        setShowCreateCategory(true);
      },
      customClass: 'general-section-add-new btn-add-button-common',
    },
    requires: PermissionCodes.addCampaignCategory,
    errorIdKey: 'categories',
    custom: {
      customItem: (value) => {
        return (
          <>
            <label>
              [ID:{value.pk}] {value.name}
            </label>
            {value.attributeTagKey && value.attributeTagKey !== '-' ? (
              <>
                <br />
                <label className="category-tag">
                  Linked to Tag {value.displayAttributeTagKey}{' '}
                </label>{' '}
              </>
            ) : null}
          </>
        );
      },
      customFilter: (inputValue, option) => {
        const inputIgnoreCase = inputValue?.toLowerCase();
        const optionIgnoreCase = option?.name?.toLowerCase();
        return (
          optionIgnoreCase?.includes(inputIgnoreCase) || option.pk == inputValue
        );
      },
    },
    searchPlaceholder: 'Search by ID , name',
  };

  const attributeTagConfig = {
    title: 'Attribute tag / Sub category (optional)',
    namespace: 'attributeTag',
    data: attributeTagList,
    selectedList: attributeTags,
    setValue: (option) => {
      setValue('attributeTags', option, { shouldDirty: true });
    },
    addButton: {
      title: 'Add Attribute tag',
      action: () => {
        setAddPromptConfig({
          button: {
            text: 'create attribute tag',
            actionPath: '/tags/create',
          },
          title: 'Go to create attribute tag?',
          description: `Go to create attribute tag.`,
        });
      },
      customClass: 'general-section-add-new btn-add-button-common',
    },
    requires: PermissionCodes.changeAttributeTags,
    groupKey: 'tagKey',
    errorIdKey: 'attributeTags',
    searchPlaceholder: 'Search by ID , name',
    custom: {
      customFilter: (inputValue, option) => {
        const inputIgnoreCase = inputValue?.toLowerCase();
        const optionIgnoreCase = option?.name?.toLowerCase();
        return (
          optionIgnoreCase?.includes(inputIgnoreCase) || option.pk == inputValue
        );
      },
    },
  };

  const getSelectorWithConfig = (config) => {
    return (
      <>
        <BaseMutipleSelectorV2
          title={config.title}
          namespace={config.namespace}
          data={{
            sourceData: config.data,
            targetData: config.selectedList,
            targetChange: (value) => {
              config.setValue(value);
            },
          }}
          addButton={config.addButton}
          error={{
            error: hasError(errors, config.errorIdKey),
            id: config.errorIdKey,
            message: errorMessage(errors, config.errorIdKey),
          }}
          requires={config.requires}
          groupKey={config.groupKey}
          custom={config.custom}
          searchPlaceholder={config.searchPlaceholder}
        />
      </>
    );
  };

  return (
    <>
      {campaignType !== CampaignType.fortuneBagCampaign ? (
        <label className="create-section-title">General</label>
      ) : null}

      {campaignType === CampaignType.cashVoucherCampaign ? (
        <CustomNumberInputWithUnit
          title="Required credit for cash voucher"
          setValue={(value) => {
            setValue('goodieBagReward', value, { shouldDirty: true });
          }}
          disabled={isPublished}
          defaultValue={goodieBagReward}
          unit="credit(s)"
          error={hasError(errors, 'goodieBagReward')}
          errorMessage={errorMessage(errors, 'goodieBagReward')}
        />
      ) : null}

      {/* <span id={`${CampaignErrorHandleField.customerGroup.name}`} /> */}
      {campaignType !== CampaignType.fortuneBagForGoodieBagCampaign ? (
        <CustomTitleWithDropDown
          title={'Offer type label (optional)'}
          source={offerTypeLabelList}
          defaultValue={offerTypeLabel}
          setValue={(value) =>
            setValue('offerTypeLabel', value, { shouldDirty: true })
          }
          loadMoreAction={'offerTypeLabel/getList'}
          filterAction={'offerTypeLabel/getList'}
          addButtonInfo={{
            action: () => {
              dispatch({
                type: 'offerTypeLabel/updateState',
                payload: { createdNew: null },
              });
              setShowCreateOfferType(true);
            },
            title: 'Add offer type label',
            requires: PermissionCodes.offerTypeLabel,
            customClass:
              'general-section-add-new-brand btn-add-button-common offer-type-button',
          }}
          errors={[]}
          errorName={'offerTypeLabel'}
        />
      ) : null}

      {getSelectorWithConfig(campaignCategoryConfig)}
      {getSelectorWithConfig(attributeTagConfig)}

      <div className="category-frontend">
        <CustomTitleLabel title="Will be shown in frontend filter level 1" />
        {[
          ...new Map(
            attributeTags
              ?.map((item) => item.tagKey)
              ?.map((item) => [item['pk'], item]),
          )?.values(),
        ]
          .filter(
            (item) =>
              item.showInWechatCampaignFilterLevel1 ||
              item.showInGoodieBagCampaignFilterLevel1,
          )
          ?.map((key) => {
            return (
              <ul>
                [ID: {key.pk}] {key.name}
                {attributeTags
                  ?.filter((item) => item.tagKey.pk === key.pk)
                  ?.map((tag) => (
                    <li>
                      [ID: {tag.pk}] {tag.name}
                    </li>
                  ))}
              </ul>
            );
          })}
      </div>

      <div className="category-frontend">
        <CustomTitleLabel
          title={`Will be shown in frontend filter, under "Category" `}
        />
        {categories
          .filter((item) => {
            const attributeTagHasBeenSelect = attributeTags
              ?.map((tag) => tag?.tagKey?.pk)
              ?.includes(item?.attributeTagKey?.pk);
            return (
              (!attributeTagHasBeenSelect ||
                (attributeTagHasBeenSelect &&
                  !item?.attributeTagKey?.showInGoodieBagCampaignFilterLevel1 &&
                  !item?.attributeTagKey?.showInWechatCampaignFilterLevel1)) &&
              campaignCategories?.map((v) => v.pk)?.includes(item.pk)
            );
          })
          ?.map((category) => {
            return (
              <ul>
                [ID: {category.pk}] {category.name}
                {category.attributeTags?.edges
                  ?.filter((item) =>
                    attributeTags?.map((v) => v.pk)?.includes(item?.node.pk),
                  )
                  ?.map((tag) => (
                    <li>
                      [ID: {tag.node.pk}] {tag.node.name}
                    </li>
                  ))}
              </ul>
            );
          })}
      </div>

      {<SearchSemTagInput />}

      {campaignType !== CampaignType.fortuneBagForGoodieBagCampaign ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            {isGoodieBagType(campaignType)
              ? 'Apply to e-Solution Goodie bag - cash voucher / goodie bag coupon sorting order'
              : 'This is a featured campaign.'}
          </label>
          <label className="tips-message">
            In frontend, under the default “Featured” sorting{' '}
            {isGoodieBagType(campaignType) ? 'in Goodie bag list' : ''}, if
            toggle on, the campaign displays randomly in the upper part of the
            list; if toggle off, it displays randomly at the bottom part of the
            list.
          </label>
          <CustomSwitchButton
            defaultChecked={isFeatured}
            onChange={(value) => {
              setValue('isFeatured', value, { shouldDirty: true });
            }}
          />
          {!isGoodieBagType(campaignType) ? (
            <CustomTitleWithSwitch
              title={'Show in WeChat homepage'}
              defaultValue={showInWechatHomepage}
              tips={`If on, this campaign will be displayed in WeChat homepage campaign list; If off, only it
        will only be displayed in WeChat “Top Offers” section campaign list.`}
              setValue={(value) => {
                setValue('showInWechatHomepage', value, { shouldDirty: true });
              }}
            />
          ) : null}
        </>
      ) : null}

      {campaignType !== CampaignType.generalMessageCampaign ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            This is a starred campaign.
          </label>
          <label className="tips-message">
            If toggle on, a star label will be shown on the campaign in
            frontend. The limit setting of starred campaign is available in
            listing page.
          </label>
          <CustomSwitchButton
            defaultChecked={isStarred}
            onChange={(value) => {
              setValue('isStarred', value, { shouldDirty: true });
            }}
          />
        </>
      ) : null}

      {campaignType !== CampaignType.fortuneBagForGoodieBagCampaign ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            Show low stock label (optional)
          </label>
          <label className="tips-message">
            Default OFF. If ON, it will display "Low stock" label in Frontend.
          </label>
          <CustomSwitchButton
            defaultChecked={showLowStock}
            onChange={(value) => {
              setValue('showLowStock', value, { shouldDirty: true });
              if (value) {
                setValue('showOutOfStock', false, { shouldDirty: true });
              }
            }}
            disabled={showOutOfStock}
          />

          <label className="create-section-label create-section-label-bottom-space">
            Show out of stock label (optional)
          </label>
          <label className="tips-message">
            Default OFF. If ON, it will display "Out of stock" label in
            Frontend.
          </label>
          <CustomSwitchButton
            defaultChecked={showOutOfStock}
            onChange={(value) => {
              setValue('showOutOfStock', value, { shouldDirty: true });
              if (value) {
                setValue('showLowStock', false, { shouldDirty: true });
              }
            }}
            disabled={showLowStock}
          />

          <label className="create-section-label create-section-label-bottom-space">
            Is age restricted
          </label>
          <label className="tips-message">
            Front-end users will display an 'Age Declaration' prompt when they
            enter the campaign detail page.
          </label>
          <Controller
            control={control}
            name={'inAgeRestricted'}
            render={({ field: { value } }) => (
              <CustomSwitchButton
                defaultChecked={value}
                onChange={(value) => {
                  setValue('inAgeRestricted', value, { shouldDirty: true });
                }}
              />
            )}
          />

          {campaignType === CampaignType.couponsCampaign ? (
            <>
              <label className="create-section-label create-section-label-bottom-space">
                Confirmation popup required for coupon download
              </label>
              <label className="tips-message">
                If on, when acquire coupon, it will display a confirmation popup
                in Frontend containing information about how many coupons can be
                acquired.
              </label>
              <Controller
                control={control}
                name={'showCouponDownloadConfirmation'}
                render={({ field: { value } }) => (
                  <CustomSwitchButton
                    defaultChecked={value}
                    onChange={(value) => {
                      setValue('showCouponDownloadConfirmation', value, {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
            </>
          ) : null}

          {campaignType !== CampaignType.generalMessageCampaign ? (
            <>
              <label className="create-section-label create-section-label-bottom-space">
                In event
              </label>
              <CustomSwitchButton
                defaultChecked={isEvent}
                onChange={(value) => {
                  setValue('isEvent', value, { shouldDirty: true });
                }}
              />
              {isEvent ? (
                <CustomTitleWithInput
                  title="Event name"
                  error={{
                    error: hasError(errors, 'eventName'),
                    message: 'This field is required',
                  }}
                  defaultValue={eventName}
                  setValue={(value) => {
                    setValue('eventName', value);
                  }}
                  type={'text'}
                  extra={{
                    maxLength: 50,
                  }}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig.button.text,
                action: () => {
                  setAddPromptConfig(null);
                  history.push({
                    pathname: addPromptConfig.button.actionPath,
                    state: { from: history.location, ...ContinueCreate },
                  });
                },
              }
            : {}
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />

      <CreateOfferTypePrompt
        show={showCreateOfferType}
        onClose={(newItem) => {
          if (newItem) {
            setValue('offerTypeLabel', newItem, { shouldDirty: true });
          }
          setShowCreateOfferType(false);
        }}
      />

      <CreateCategoryPrompt
        show={showCreateCategory}
        onClose={() => setShowCreateCategory(false)}
        successAction={(data) => {
          if (data) {
            setValue(
              'categories',
              [...campaignCategories, { name: data.name, value: data }],
              {
                shouldDirty: true,
              },
            );
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  categories: state.campaignCategoryList.notPagedAllList,
  groups: state.customerGroup.currentPageGroupList,
  segments: state.segments.segmentList,
  offerTypeLabelList: state.offerTypeLabel.allList,
  attributeTagList: state.attributeTag.notPagedAllList,
});

export default connect(mapPropsToState)(PropertiesGeneralSection);
