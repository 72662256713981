import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_MESSAGE } from '../../../utils';

import BasePrompt from '../../base/prompt/BasePrompt';

import { CustomNumberInputWithUnit } from '../../earning/CustomBaseComponments';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';

function StarCamapignSettingPrompt(props) {
  const show = props.show || false;
  const onClose = props.onClose || (() => {});

  const tipTarget = useRef(null);

  const dispatch = useDispatch();
  const { starPerheadLimit } = useSelector((state) => ({
    starPerheadLimit: state.campaignList.starPerheadLimit,
  }));

  const [starLimit, setStarLimit] = useState(starPerheadLimit || 0);

  const [tipsText, setTipsText] = useState();

  useEffect(() => {
    if (show) {
      dispatch({ type: 'campaignList/getStarLimit' });
    }
  }, [show]);

  useEffect(() => {
    setStarLimit(starPerheadLimit || 0);
  }, [starPerheadLimit]);

  useEffect(() => {
    console.log('88-0', tipTarget.current);
  }, [tipTarget]);

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      rightButton={{
        text: 'Confirm',
        action: () => {
          dispatch({
            type: 'campaignList/setStarLimit',
            payload: { limit: parseInt(starLimit) },
          });

          onClose();
        },
        disabled:
          starLimit === undefined || starLimit === null || starLimit === '',
      }}
      leftButton={{
        text: `Cancel`,
        action: () => {
          setStarLimit(starPerheadLimit || 0);
          onClose();
        },
      }}
      title={'Starred campaign limit settings (universal setting)'}
      description={
        'Set the maximum number of starred coupon for each user that can owned simultaneously. \nThis setting will apply to ALL starred campaigns instantly after "Confirm" is clicked.'
      }
      otherBody={() => {
        return (
          <>
            <CustomNumberInputWithUnit
              title={'Per head owned coupon limit for starred campaign'}
              setValue={(value) => {
                setStarLimit(value);
              }}
              defaultValue={starLimit}
              unit={'number of coupons'}
              errorId={'starLimit'}
              error={
                starLimit === undefined ||
                starLimit === null ||
                starLimit === ''
              }
              errorMessage={ERROR_MESSAGE.MISS_FIELD}
              tips={
                'The number is “0” by default, “0” means hide the statement in My Wallet.'
              }
              tipsAtBottom={true}
              // others={{ value: starLimit || starPerheadLimit }}
            />
            <button
              onClick={(e) => {
                console.log(
                  '@@88: ',
                  tipTarget,
                  tipTarget?.current?.selectionStart,
                  tipTarget?.current?.value,
                );
                // setTipsText(`${tipsText} {value}`);
                const cursorPosition = tipTarget?.current?.selectionStart;
                const sourceValue = tipTarget?.current?.value;

                const newValue =
                  sourceValue?.slice(0, cursorPosition) +
                  ' {value} ' +
                  sourceValue?.slice(cursorPosition);
                tipTarget.current.value = newValue;
                setTipsText(newValue);
              }}
            >
              insert value
            </button>
            tips: {tipsText}
            {/* <CustomTitleWithInput
              ref={(el) => console.log('88-1: ', el)}
              title="tips"
              multipleLines
              // defaultValue={tipsText}
              value={tipsText}
              setValue={(value) => {
                setTipsText(value);
              }}
            /> */}
            <textarea
              ref={tipTarget}
              onChange={(e) => {
                console.log('@@88-3');
                setTipsText(e.value);
              }}
            />
            {/* <CustomTitleWithInput
          title="tips"
          multipleLines
          value={value}
          setValue={(value) => {
            setValue(`translations.${language}.tips`, value);
          }}
        /> */}
          </>
        );
      }}
    />
  );
}

export default StarCamapignSettingPrompt;
