import React, { useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import ContentSections from '../base/ContentSections';
import {
  SaveAndBackButtons,
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import ListButtonsGroup from '../base/ListButtonsGroup';
import { useHistory, useParams } from 'react-router-dom';
import {
  createAction,
  isArrayEmpty,
  removeFromSessionStorage,
  saveToSessionStorage,
  checkHasPermission,
} from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';
import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
} from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { validate } from './CreateMissionValidate';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import PublishMissionPrompt from './PublishMissionPrompt';
import { getImageUrl } from '../../models/CreateCampaignModel';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  MISSION_CHECK_IN_MODE_TYPE,
  MISSION_TYPE,
} from '../../models/MissionModel';
import PreviewButton from './PreviewButton';

const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

export function StepFourContentSection({ data }) {
  const { languageList } = useSelector((state) => ({
    languageList: state.language.allList,
  }));

  const { getValues } = useFormContext() || {};
  const mission = data || getValues() || {};
  const translations = mission.translations || {};

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach((valueKey) => {
      switch (valueKey) {
        case 'coverPhoto':
        case 'mobilePhoto': {
          const url = getImageUrl(translations?.[language]?.[valueKey]);
          if (!url || url === '') {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        case 'detailPhotos': {
          data[valueKey] = translations?.[language]?.[valueKey]?.map((item) => {
            const url = getImageUrl(item);
            if (!url || url === '') {
              return null;
            }
            return url;
          });
          break;
        }
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });

    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'name',
      'shortDescription',
      'coverPhoto',
      'mobilePhoto',
      'detailPhotos',
      'tutorialTitle',
      'tutorialContent',
    ]);

    return (
      <>
        <MembershipInfoCard
          title={i18n.t('content', { locale: 'en' })}
          data={data}
          fields={[
            [
              {
                title: 'Title',
                field: 'name',
              },
            ],
            [
              {
                title: 'Short Description',
                field: 'shortDescription',
                isHtml: true,
              },
            ],
            [
              {
                title: 'Cover image',
                field: 'coverPhoto',
              },
            ],
            [
              {
                title: 'Cover image for mobile',
                field: 'mobilePhoto',
              },
            ],
            [
              {
                title: 'Image',
                field: 'detailPhotos',
              },
            ],
            [
              {
                title: 'Tutorial title',
                field: 'tutorialTitle',
              },
            ],
            [
              {
                title: 'Tutorial description',
                field: 'tutorialContent',
                isHtml: true,
              },
            ],
          ]}
        />
      </>
    );
  };

  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: languageList?.map((item) => {
          console.log('@221', item);
          return {
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName || item.name,
          };
        }),
      }}
      activeSection={LanguageConfig.english}
      contentStyle="campaign-detail-language-content-sections"
    />
  );
}

export function StepFourPropertiesSection({ data }) {
  const { getValues } = useFormContext() || {};
  const mission = data || getValues() || {};
  const missionType = MISSION_TYPE[mission.missionType] || mission.missionType;
  const checkInMode =
    MISSION_CHECK_IN_MODE_TYPE[mission.checkInMode] || mission.checkInMode;

  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);

  const getAttributeTags = (attributeTags) => {
    attributeTags =
      attributeTags &&
      attributeTags?.map((data, index) => `[ID:tag_${data.pk}] ${data.name}`);
    return (
      <>
        <div className="list-new-line">
          {attributeTags?.slice(0, 10).join('\r\n')}
        </div>

        {attributeTags?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(attributeTags);
              setRelatedPromptTitle('Attribute tag');
            }}
          >
            See more
          </button>
        ) : null}
      </>
    );
  };

  const getCampaigns = (campaigns) => {
    campaigns =
      campaigns &&
      campaigns?.map((data, index) => `[ID:${data.pk}] ${data.name}`);
    return (
      <>
        <div className="list-new-line">
          {campaigns?.slice(0, 10).join('\r\n')}
        </div>

        {campaigns?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(campaigns);
              setRelatedPromptTitle('Campaign');
            }}
          >
            See more
          </button>
        ) : null}
      </>
    );
  };

  const getGoodieBags = (goodieBags) => {
    goodieBags =
      goodieBags &&
      goodieBags?.map((data, index) => `[ID:${data.pk}] ${data.name}`);
    return (
      <>
        <div className="list-new-line">
          {goodieBags?.slice(0, 10).join('\r\n')}
        </div>

        {goodieBags?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(goodieBags);
              setRelatedPromptTitle('Goodie Bag');
            }}
          >
            See more
          </button>
        ) : null}
      </>
    );
  };

  const getLocations = (missionLocations) => {
    missionLocations =
      missionLocations &&
      missionLocations?.map(
        (data, index) =>
          `[ID:${data.locationForMissoin?.pk}] ${data.locationForMissoin?.name}`,
      );
    return (
      <>
        <div className="list-new-line">
          {missionLocations?.slice(0, 10).join('\r\n')}
        </div>

        {missionLocations?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(missionLocations);
              setRelatedPromptTitle('Location');
            }}
          >
            See more
          </button>
        ) : null}
      </>
    );
  };

  const profileField = (
    field,
    value,
    show = true,
    showValue = true,
    customValue = null,
    customClass = '',
    customeContentClass = '',
  ) => {
    return (
      <>
        {show ? (
          <>
            <label
              className={`create-section-label create-section-label-bottom-space ${customClass}`}
            >
              {field}
            </label>
            {showValue
              ? customValue || (
                  <label
                    className={`create-section-field-input profile-section-label-bottom ${customeContentClass}`}
                  >
                    {value || '-'}
                  </label>
                )
              : null}
          </>
        ) : null}
      </>
    );
  };

  const displayFieldContentList = [
    {
      field: 'Select rule',
      value: missionType?.label,
    },
    {
      field: 'Mode',
      value: checkInMode?.label,
      show: missionType?.value === MISSION_TYPE.CHECK_IN.value,
    },
    {
      field: 'Selected campaign',
      customValue: getCampaigns(mission.campaigns),
      show: missionType?.value === MISSION_TYPE.SPECIAL_CAMPAIGN.value,
    },
    {
      field: 'Selected goodie bag',
      customValue: getGoodieBags(mission.goodieBags),
      show: missionType?.value === MISSION_TYPE.SPECIAL_GOODIE_BAG.value,
    },
    {
      field: 'Selected location',
      customValue: getLocations(mission.missionLocations),
      show: missionType?.value === MISSION_TYPE.CHECK_IN.value,
    },
    {
      field: 'Limit',
      showValue: false,
    },
    {
      field: 'Reward stamp quantity per action',
      value: mission.perActionStampRewardQuantity,
    },
    {
      field: 'Per head limit (optional)',
      value: `${mission.perHeadLimit} stamps`,
    },
    {
      field: 'Attribute tag / Sub category',
      customValue: getAttributeTags(mission.attributeTags),
    },
    {
      field: 'Active period',
      value: mission.isFollowGamificationCampaignActivePeriod
        ? mission.linkedGamificationCampaign
          ? getReadablePeriod(
              mission.startDate,
              mission.endDate,
              mission.isAlwaysActivePeriod,
            ) + ' (follow gamification campaign)'
          : 'Follow gamification campaign (not yet linked)'
        : getReadablePeriod(
            mission.startDate,
            mission.endDate,
            mission.isAlwaysActivePeriod,
          ),
    },
    {
      field: 'Visible period',
      value: mission.isFollowGamificationCampaignVisiblePeriod
        ? mission.linkedGamificationCampaign
          ? getReadablePeriod(
              mission.displayStartDate,
              mission.displayEndDate,
              mission.isAlwaysVisiblePeriod,
            ) + ' (follow gamification campaign)'
          : 'Follow gamification campaign (not yet linked)'
        : getReadablePeriod(
            mission.displayStartDate,
            mission.displayEndDate,
            mission.isAlwaysVisiblePeriod,
          ),
    },
  ];

  return (
    <>
      <label className="create-section-title">Properties</label>
      {displayFieldContentList.map((item, index) => {
        if (item.field)
          return profileField(
            item.field,
            item.value,
            item.show,
            item.showValue,
            item.customValue,
            item.customClass,
            item.customeContentClass,
          );
      })}
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description=""
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container terms-and-conditions-related-items-container">
            {relatedPromptKey?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      />
    </>
  );
}

function CreateMissionStepFour() {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);

  const dispatch = useDispatch();
  const history = useHistory();

  const { createStatus, originalMission, user } = useSelector((state) => ({
    createStatus: state.mission.createStatus,
    originalMission: state.mission.detail,
    user: state.users,
  }));

  const mission = getValues() || {};
  console.log('CreateMissionStepFour:', mission);

  useEffect(() => {
    saveToSessionStorage(SESSION_KEYS.CREATE_MISSION, mission);
    return () => {
      removeFromSessionStorage(SESSION_KEYS.CREATE_MISSION);
    };
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(!history.location?.state?.from);
    }

    if (
      createStatus === APIStatus.success ||
      createStatus === APIStatus.failed
    ) {
      setSaveDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const sections = [<StepFourPropertiesSection />];

  const saveMission = (isPublish, afterAction) => {
    dispatch(
      createAction('mission/createOrUpdateMission')({
        data: {
          ...mission,
          isPublished: isPublish,
        },
        afterAction,
      }),
    );
  };

  const saveTempAction = (afterAction) => {
    setSaveDisabled(true);
    if (mission.isPublished) {
      setActionType(ActionType.save);
      saveMission(true, afterAction);
    } else {
      setActionType(ActionType.save);
      dispatch(
        createAction('mission/createOrUpdateMission')({
          data: mission,
          afterAction: afterAction,
        }),
      );
    }
  };

  const saveAction = (isPublished) => {
    setActionType(isPublished ? ActionType.publish : ActionType.none);
    setSaveDisabled(true);
    saveMission(isPublished);
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'mission/stepChange',
      payload: { isBack: isBack, step: 3, isValid },
    });
  };

  const getButtons = () => {
    const buttons = [];
    let hasPublishRelatedPermission = false;
    if (!mission.isPublished) {
      hasPublishRelatedPermission = checkHasPermission(
        user,
        PermissionCodes.publishMission,
      );
    } else {
      hasPublishRelatedPermission = checkHasPermission(
        user,
        PermissionCodes.unPublishMission,
      );
    }

    if (!params.id && !mission.pk) {
      buttons.push(
        <SaveTempButton
          text="Save only"
          action={saveTempAction}
          disabled={saveDisabled}
        />,
      );
    }
    if (params.id || mission.pk) {
      if (mission.isPublished) {
        buttons.push(<PreviewButton mission={mission} />);
      }
      const ComponentInstance = !hasPublishRelatedPermission
        ? SaveButton
        : SaveTempButton;
      buttons.push(
        <ComponentInstance
          text="Update"
          action={() => {
            saveTempAction();
          }}
          disabled={saveDisabled}
        />,
      );
    }
    if (hasPublishRelatedPermission) {
      if (!mission.isPublished) {
        buttons.push(
          <SaveButton
            text="Save and publish"
            action={() => {
              saveAction(true);
            }}
            disabled={saveDisabled}
          />,
        );
      } else {
        buttons.push(
          <SaveButton
            text="UnPublished"
            action={() => {
              saveAction(false);
            }}
            disabled={saveDisabled}
          />,
        );
      }
    }
    return buttons;
  };
  const bottomButtons = getButtons();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <StepFourContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={bottomButtons}
          />,
        ]}
      />

      <PublishMissionPrompt
        isSave={actionType === ActionType.save}
        isPublish={!mission.isPublished}
        show={showPublishPrompt}
        continueId={originalMission.pk}
      />
    </>
  );
}

export default CreateMissionStepFour;
