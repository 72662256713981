import { MISSION_TYPE } from '../../models/MissionModel';
import { isArrayEmpty, getFieldValue } from '../../utils';
import { LanguageConfig } from '../../config/CustomEnums';

export const MissionErrorHandleField = {
  name: {
    name: 'name',
    message: 'Please provide name and ensure the length not more than 200.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description', //and ensure the length not more than 200.
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover Image.',
  },
  endDate: {
    name: 'endDate',
    message:
      'The end date & time cannot be on or before the start date and time.',
  },
  displayEndDate: {
    name: 'displayEndDate',
    message:
      'The end date & time cannot be on or before the start date and time.',
  },
  commonField: {
    message1: 'This field is required',
    message2: 'Please provide value and ensure the length not more than 200.',
    message3: 'Please input a number > 0.',
    message4: 'Please provide a image.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const errorList = [];
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const [translations, name, description, coverPhoto, tutorials] = getValues([
    'translations',
    'translations.en.name',
    'translations.en.shortDescription',
    'translations.en.coverPhoto',
    'translations.en.tutorials',
  ]);
  const mission = getValues() || {};
  console.log('@72', name, description, coverPhoto, mission);
  const errorList = [];
  if (!name || name === '' || name.length > 200) {
    errorList.push({
      name: 'translations.en.name',
      message: MissionErrorHandleField.name.message,
    });
  }
  if (!description) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: MissionErrorHandleField.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: MissionErrorHandleField.coverPhoto.message,
    });
  }
  ['translations.en.tutorialTitle', 'translations.en.tutorialContent'].forEach(
    (fieldName) => {
      const fieldValue = getFieldValue(mission, fieldName);
      if (!fieldValue) {
        errorList.push({
          name: fieldName,
          message: MissionErrorHandleField.commonField.message1,
        });
      }
    },
  );
  if (tutorials) {
    const temp = Object.keys(tutorials).map((key, index) => {
      const tutorial = tutorials[key] || {};
      if (tutorial.isDeleted) {
        return;
      }
      if (!tutorial.image) {
        errorList.push({
          name: `translations.en.tutorials.${key}.image`,
          message: MissionErrorHandleField.commonField.message4,
        });
      }
      if (!tutorial.content) {
        errorList.push({
          name: `translations.en.tutorials.${key}.content`,
          message: MissionErrorHandleField.commonField.message1,
        });
      }
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const errorList = [];
  const [
    perActionStampRewardQuantity,
    perHeadLimit,
    isFollowGamificationCampaignActivePeriod,
    isAlwaysActivePeriod,
    startDate,
    endDate,
    isFollowGamificationCampaignVisiblePeriod,
    isAlwaysVisiblePeriod,
    displayStartDate,
    displayEndDate,
    showStatementWordingForStampLimit,
    enStatementWordingForStampLimit,
    tcStatementWordingForStampLimit,
    scStatementWordingForStampLimit,
  ] = getValues([
    'perActionStampRewardQuantity',
    'perHeadLimit',
    'isFollowGamificationCampaignActivePeriod',
    'isAlwaysActivePeriod',
    'startDate',
    'endDate',
    'isFollowGamificationCampaignVisiblePeriod',
    'isAlwaysVisiblePeriod',
    'displayStartDate',
    'displayEndDate',
    'showStatementWordingForStampLimit',
    'translations.en.statementWordingForStampLimit',
    'translations.zh-Hant.statementWordingForStampLimit',
    'translations.zh-Hans.statementWordingForStampLimit',
  ]);
  const mission = getValues() || {};
  if (perActionStampRewardQuantity !== null && perHeadLimit !== null) {
    if (parseInt(perActionStampRewardQuantity) > parseInt(perHeadLimit)) {
      errorList.push({
        name: 'perHeadLimit',
        message:
          'Per head limit should be larger / equal to the reward stamp quantity per action.',
      });
    }
  }
  if (
    !isAlwaysActivePeriod &&
    !isFollowGamificationCampaignActivePeriod &&
    startDate.getTime() >= endDate.getTime()
  ) {
    errorList.push({
      name: 'endDate',
      message: MissionErrorHandleField.endDate.message,
    });
  }
  if (
    !isAlwaysVisiblePeriod &&
    !isFollowGamificationCampaignVisiblePeriod &&
    displayStartDate.getTime() >= displayEndDate.getTime()
  ) {
    errorList.push({
      name: 'displayEndDate',
      message: MissionErrorHandleField.displayEndDate.message,
    });
  }

  ['missionType', 'perActionStampRewardQuantity'].forEach((field_name) => {
    if (!mission[field_name]) {
      errorList.push({
        name: field_name,
        message: 'This field is required',
      });
    }
  });

  if (showStatementWordingForStampLimit) {
    if (
      !enStatementWordingForStampLimit ||
      !enStatementWordingForStampLimit.includes('{X stamp(s)}')
    ) {
      errorList.push({
        name: 'translations.en.statementWordingForStampLimit',
        message:
          '“{X stamp(s)}“ (reward stamp quantity per action) cannot be deleted.',
      });
    }
    if (
      !tcStatementWordingForStampLimit ||
      !tcStatementWordingForStampLimit.includes('{X個印花}')
    ) {
      errorList.push({
        name: 'translations.zh-Hant.statementWordingForStampLimit',
        message:
          '“{X個印花}“ (reward stamp quantity per action) cannot be deleted.',
      });
    }
    if (
      !scStatementWordingForStampLimit ||
      !scStatementWordingForStampLimit.includes('{X个印花}')
    ) {
      errorList.push({
        name: 'translations.zh-Hans.statementWordingForStampLimit',
        message:
          '“{X个印花}“ (reward stamp quantity per action) cannot be deleted.',
      });
    }
  }

  console.log('errors3:', errorList);
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
