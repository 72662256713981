import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { APIStatus } from '../../config/CustomEnums';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import BasePrompt from '../base/prompt/BasePrompt';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import CustomRadios from '../base/CustomRadios';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ReactHookFormErrorMessage,
  hasError,
  errorMessage,
} from '../../components/base/ErrorFieldMessage';
import WorkingTeamField from '../workingTeam/WorkingTeamField';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { validate } from './CreateGamificationCampaignValidate';
import { GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE } from '../../models/GamificationCampaignModel';

function FirstPart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getValues, watch, setValue, formState, clearErrors, setError } =
    useFormContext();
  const { errors } = formState;

  const isPublished = watch('isPublished');
  const isPublic = watch('isPublic');
  const entitlementType = watch('entitlementType');
  // const isRequireLandingPage = watch('isRequireLandingPage');

  return (
    <>
      <CustomTitleWithSwitch
        title={'Is public gamification campaign'}
        defaultValue={isPublic}
        tips={`If ON, gamification campaign will be displayed in frontend for all customers`}
        customTitleClass="start-head"
        setValue={(value) => {
          setValue('isPublic', value, { shouldDirty: true });
        }}
        disabled={isPublished}
      />
      {isPublic ? null : (
        <>
          <CustomTitleLabel title="Entitlement" />
          <div style={{ display: 'flex', marginTop: 12 }}>
            <CustomRadios
              options={Object.values(GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE)}
              default={entitlementType}
              onChange={(value) => {
                setValue('entitlementType', value, {
                  shouldDirty: true,
                });
              }}
              disabled={isPublished}
            />
          </div>
          <label className="tips-message" style={{ marginTop: '8px' }}>
            {entitlementType ===
            GAMIFICATION_CAMPAIGN_ENTITLEMENT_TYPE.GENERIC.value
              ? 'Gamification campaign is displayed after clicking the generic link.'
              : 'Gamification campaign is displayed after clicking the unique link. The link will no longer be valid after the campaign is acquired.'}
          </label>
          {/* <CustomTitleWithSwitch
            title={'Require landing page'}
            defaultValue={isRequireLandingPage}
            tips={`If ON, customers will go to a landing page instead of the gamification campaign after clicking the entitlement link`}
            setValue={(value) => {
              setValue('isRequireLandingPage', value, { shouldDirty: true });
            }}
            disabled={isPublished}
          /> */}
        </>
      )}
    </>
  );
}

function SecondPart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const { marketList, channelList, countryCodeList } = useSelector((state) => ({
    marketList: state.targetMarket.notPagedAllList,
    channelList: state.channel.notPagedAllList,
    countryCodeList: state.countryCode.notPagedAllList,
  }));

  const formChannel = watch('channels') || [];
  const watchChannels = formChannel.filter((item) => !!item.pk);
  const watchMarkets = watch('markets') || [];
  const watchCountryCode = watch('countryCodes');

  const [showAddPrompt, setShowAddPrompt] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState();

  const publishChannelPart = (
    <Controller
      control={control}
      name="channels"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={'Publish channel'}
            namespace="channel"
            hideTopSpace={true}
            data={{
              sourceData: channelList,
              targetData: watchChannels,
              targetChange: (value) => {
                setValue('channels', value, { shouldDirty: true });
                const channelsExist = value?.map((item) => item.pk) || [];
                const marketsCleaned = watchMarkets?.filter((market) =>
                  channelsExist?.some((channel) =>
                    market.channels?.some((item) => item.pk === channel),
                  ),
                );
                setValue('markets', marketsCleaned, { shouldDirty: true });
              },
            }}
          />
        </>
      )}
    />
  );

  const targetMarketPart = (
    <Controller
      control={control}
      name="markets"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={'Target market'}
            tips={
              'Control which Target market users can "view" this gamification campaign.'
            }
            namespace="targetMarket"
            data={{
              sourceData: marketList,
              targetData: watchMarkets,
              targetChange: (value) => {
                setValue('markets', value, { shouldDirty: true });
              },
            }}
            addButton={{
              action: () => {
                setAddPromptConfig({
                  button: {
                    text: 'create market',
                    actionPath: '/markets/create',
                  },
                  title: 'Go to create market?',
                  description: `You will leave the gamification campaign creation process. After you create a new market, you can back to this page.`,
                });
                setShowAddPrompt(true);
              },
              title: 'Add Market',
              requires: PermissionCodes.changeTargetMarkets,
              customClass: 'general-section-add-new btn-add-button-common',
            }}
            error={{
              error: hasError(errors, 'markets'),
              message: errorMessage(errors, 'markets'),
              id: 'campaign-markets',
            }}
          />
        </>
      )}
    />
  );

  const countryCodePart = (
    <Controller
      control={control}
      name="countryCodes"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title="Country code (Optional)"
            tips={
              'Control which country code users can "acquire" this gamification campaign.'
            }
            namespace="countryCode"
            data={{
              sourceData: countryCodeList,
              targetData: watchCountryCode,
              targetChange: (value) => {
                setValue('countryCodes', value, {
                  shouldDirty: true,
                });
              },
            }}
          />
        </>
      )}
    />
  );

  return (
    <>
      <div>{publishChannelPart}</div>
      <div
        style={{
          display: watchChannels?.length > 0 ? 'inherit' : 'none',
          marginTop: '35px',
        }}
      >
        {targetMarketPart}
      </div>
      <div>{countryCodePart}</div>
      <BasePrompt
        show={showAddPrompt}
        closeAction={() => {
          setShowAddPrompt(false);
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig?.button.text,
                action: () =>
                  history.push({
                    pathname: addPromptConfig?.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'Continue to Create gamification campaign',
                      content:
                        'You can continue to create the gamification campaign.',
                    },
                  }),
              }
            : null
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

function CreateGamificationCampaignStepOne({ status }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const { errors } = formState;

  const formChannel = watch('channels') || [];
  const watchChannels = formChannel.filter((item) => !!item.pk);
  const watchMarkets = watch('markets') || [];

  useEffect(() => {
    dispatch(
      createAction('targetMarket/getAllList')({
        channel: (watchChannels || []).map((item) => item.pk),
        needChannel: true,
      }),
    );
  }, [watchChannels?.length]);

  const getSection = () => {
    if (status === APIStatus.calling) {
      return [<Loading />];
    }
    const parts = [
      <WorkingTeamField
        from="Gamification campaign"
        title="Content Access Control"
        showPrompt={!params.id}
      />,
      <FirstPart />,
      <SecondPart />,
    ];
    return parts;
  };

  const sections = getSection();

  const goToNextStep = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('@167', isValid, errors, getValues());
    dispatch({
      type: 'gamificationCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  const buttonDisable = useMemo(() => {
    if (!watchChannels || !watchMarkets) {
      return true;
    }
    return watchChannels.length <= 0 || watchMarkets.length <= 0;
  }, [watchChannels, watchMarkets]);

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep();
        }}
        disabledContinue={buttonDisable}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.gamificationCampaign.createStatus,
});

export default connect(mapPropsToState)(CreateGamificationCampaignStepOne);
