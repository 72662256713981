import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import './ProfileSection.scss';
import CheckOnIcon from '../../../assets/images/check_on.svg';
import { CampaignType } from '../../../config/CustomEnums';
import {
  GENERAL_TYPE,
  isShowCouponRelated,
  isGoodieBagType,
  getCampaignDisplayName,
} from '../../../models/CreateCampaignModel';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';
import { useFormContext } from 'react-hook-form';
import BasePrompt from '../../base/prompt/BasePrompt';
import checkHookImage from '../../../assets/images/check_hook.png';
import { isChannelWithoutMarket } from './CouponCampaignSection';
import { isEmpty } from '../../../containers/engagement/campaign/CreateCampaignValidate';

function ProfileSection({ data, couponAcquired = {} }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const isGoodieBag = isGoodieBagType(campaign.campaignType);
  const isCouponRelated = isShowCouponRelated(campaign.campaignType);
  const attributeArea = campaign?.attributeTags;
  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);
  const [showChannelLimit, setShowChannelLimit] = useState(false);
  console.log('ProfileSection:', campaign);
  const goodieBag = campaign.goodieBag || {};

  const getAttributeTags = (attributeTags) => {
    attributeTags =
      attributeTags &&
      attributeTags?.map((data, index) => `[ID:tag_${data.pk}] ${data.name}`);
    return (
      <>
        <div className="list-new-line">
          {attributeTags?.slice(0, 10).join('\r\n')}
        </div>

        {attributeTags?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(attributeTags);
              setRelatedPromptTitle('Attribute tag');
            }}
          >
            show more
          </button>
        ) : null}
      </>
    );
  };

  const getListStrings = (list, isChannel) => {
    const listName = list?.map((item) => item.name)?.toString() || '';
    if (isGoodieBag && !listName.includes('e-Solution') && isChannel) {
      return `${listName ? 'e-Solution,' : 'e-Solution'}${listName}`;
    }
    return listName;
  };

  const getChannelLimitTotalData = () => {
    let totalAssigned = 0;
    Object.values(campaign.channelLimit || {}).forEach((item) => {
      const limit = item?.overallLimit;
      if (limit) {
        totalAssigned = Number(totalAssigned) + Number(limit);
      }
    });

    return (
      <>
        Available single coupons:{' '}
        {campaign.linkedCoupon?.totalNubmerOfAvailableCoupons ||
          campaign.linkedCoupon?.stock ||
          0}
        <br />
        Total single coupons:{' '}
        {campaign.linkedCoupon?.totalNubmerOfGeneratedCoupons || 0}
        <br />
        Total acquired coupons from the linked coupon set:{' '}
        {campaign.linkedCoupon?.totalNubmerOfAcquiredCoupons || 0}
        <br />
        <li>
          Acquired coupons from this campaign:{' '}
          {campaign.acquiredCouponsFromThisCampaign || 0}
        </li>
        Expired coupons:{' '}
        {campaign.linkedCoupon?.totalNubmerOfExpiredCoupons || 0}
        <br />
        Deactivated coupons:{' '}
        {campaign.linkedCoupon?.totalNubmerOfDeactivatedCoupons || 0}
        <br />
        Total assigned limit in this campaign: {totalAssigned}
      </>
    );
  };

  const perTargetMarketLimitField = ({
    name = '',
    overallLimit,
    acquired = 0,
    displayLimit = false,
    isOtherMarket = false,
  }) => {
    if (!overallLimit && isEmpty(overallLimit)) {
      return <></>;
    }

    return (
      <div className="channel-limit-prompt-market-area">
        <label className="channel-limit-prompt-market-title">{name}</label>
        <div className="channel-limit-prompt-content">
          {overallLimit} number of coupons
          <br />
          Acquired: {acquired}, Available to acquired{' '}
          {isOtherMarket
            ? 'in the remaining target markets'
            : 'in this target market'}
          : {overallLimit - acquired}
        </div>
        {displayLimit ? (
          <div className="channel-limit-prompt-tips-messages">
            <Image
              src={checkHookImage}
              className="channel-limit-prompt-check-hook"
            />
            <label>Display the overall limit in the campaign.</label>
          </div>
        ) : null}
      </div>
    );
  };

  const profileField = (
    field,
    value,
    showValue = true,
    customClass = '',
    customeContentClass = '',
  ) => {
    if (field === 'Per channel limit') {
      return (
        <>
          <label
            className={`create-section-label create-section-label-bottom-space ${customClass}`}
          >
            Per channel limit
          </label>
          <div className="create-section-field-input">
            {getChannelLimitTotalData()}
          </div>
          <div
            className="view-detail-of-channel-limit"
            onClick={() => {
              setShowChannelLimit(true);
            }}
          >
            View detail of channel and target market limit
          </div>
        </>
      );
    }

    return (
      <>
        <label
          className={`create-section-label create-section-label-bottom-space ${customClass}`}
        >
          {field}
        </label>
        {showValue ? (
          <label
            className={`create-section-field-input profile-section-label-bottom ${customeContentClass}`}
          >
            {value || '-'}
          </label>
        ) : null}
      </>
    );
  };

  const activeDisplay = () => {
    if (isGoodieBag && campaign.isFollowGoodieBagActivePeriod) {
      return campaign?.goodieBag?.displayActivePeriod;
    }
    if (campaign.isRedirectionCampaign) {
      return '-';
    }
    return getReadablePeriod(
      campaign.activeStartDate,
      campaign.activeEndDate,
      campaign.isAlwaysActivePeriod,
    );
  };

  const visibleDisplay = () => {
    if (isGoodieBag && campaign.isFollowGoodieBagVisiblePeriod) {
      return campaign?.goodieBag?.displayVisiblePeriod;
    }
    return getReadablePeriod(
      campaign.visibleStartDate,
      campaign.visibleEndDate,
      campaign.isAlwaysVisiblePeriod,
    );
  };

  // const groupDisplay = () => {
  //   const campaignGroups =
  //     campaign?.groups &&
  //     campaign.groups?.map((data, index) => `[ID:${data.pk}] ${data.name}`);
  //   return (
  //     <>
  //       <div className="list-new-line">
  //         {campaignGroups?.slice(0, 10).join('\r\n')}
  //       </div>
  //       {campaignGroups?.length > 10 ? (
  //         <button
  //           type="button"
  //           className="btn-link reset-button show-more-button channel-show-more-button"
  //           onClick={() => {
  //             setShowRelatedPrompt(true);
  //             setRelatedPromptKey(campaignGroups);
  //             setRelatedPromptTitle('Fortune Bag group');
  //           }}
  //         >
  //           show more
  //         </button>
  //       ) : null}
  //     </>
  //   );
  // };

  const displayFieldContentList =
    campaign.campaignType === CampaignType.fortuneBagForGoodieBagCampaign
      ? [
          {
            field: 'Campaign type',
            value: getCampaignDisplayName(campaign.campaignType, true),
          },
          {
            field: 'Link to coupon set',
            value: campaign.linkedCoupon?.name,
          },
          {
            field: 'Campaign category',
            value: getListStrings(campaign.categories),
          },
          {
            field: 'Attribute tag',
            value: getAttributeTags(attributeArea),
          },
          {
            field: 'This is a starred campaign',
            value: campaign?.isStarred ? 'Yes' : 'No',
          },
          {
            field: 'Active period',
            value: activeDisplay(),
          },
          {
            field: 'Related brand',
            value: campaign.linkedBrand?.name,
          },
          {
            field:
              'Per entitlement \nNumber of coupon can be acquired per entitlement',
            value: campaign.goodieBagReward
              ? `${campaign.goodieBagReward} number of coupon(s)`
              : '-',
          },
        ]
      : [
          {
            field: 'Campaign type',
            value: getCampaignDisplayName(campaign.campaignType, true),
          },
          ...(isGoodieBag
            ? [
                {
                  field: 'For Goodie bag',
                  value: goodieBag.name,
                },
              ]
            : []),
          {
            field: 'Publish channels',
            value: getListStrings(campaign.channels, true),
          },
          {
            field: 'Target markets',
            value: getListStrings(campaign.markets),
          },
          isCouponRelated
            ? {
                field: 'Link to coupon set',
                value: campaign.linkedCoupon?.name,
              }
            : {},
          ...(campaign.haveLandingPage
            ? [
                {
                  field: 'Landing page URL',
                  value: campaign.translations.en.landingPageUrl,
                },
                {
                  field: 'Landing page button name',
                  value: campaign.translations.en.landingPageButtonName,
                },
              ]
            : [{}]),
          {
            field: 'Campaign category',
            value: getListStrings(campaign.categories),
          },
          ...(campaign.campaignType === CampaignType.cashVoucherCampaign
            ? [
                {
                  field: 'Required credit for cash voucher',
                  value: campaign.goodieBagReward
                    ? `${campaign.goodieBagReward} credit(s)`
                    : '-',
                },
              ]
            : []),
          {
            field: 'Offer type label',
            value: campaign?.offerTypeLabel?.label,
          },
          {
            field: 'Attribute tag',
            value: getAttributeTags(attributeArea),
          },
          {
            field: 'This is a featured campaign',
            value: campaign?.isFeatured ? 'Yes' : 'No',
          },
          ...(!isGoodieBagType(campaign.campaignType) &&
          campaign.campaignType !== CampaignType.fortuneBagCampaign
            ? [
                {
                  field: 'Show in WeChat homepage',
                  value: campaign?.showInWechatHomepage ? 'Yes' : 'No',
                },
              ]
            : []),

          ...(campaign.campaignType !== CampaignType.generalMessageCampaign
            ? [
                {
                  field: 'This is a starred campaign',
                  value: campaign?.isStarred ? 'Yes' : 'No',
                },
              ]
            : []),
          {
            field: 'Show low stock label (optional)',
            value: campaign?.showLowStock ? 'Yes' : 'No',
          },
          {
            field: 'Show out of stock label (optional)',
            value: campaign?.showOutOfStock ? 'Yes' : 'No',
          },
          {
            field: 'Is age restricted',
            value: campaign?.inAgeRestricted ? 'Yes' : 'No',
          },
          ...(campaign.campaignType === CampaignType.couponsCampaign
            ? [
                {
                  field: 'Confirmation popup required for coupon download',
                  value: campaign?.showCouponDownloadConfirmation
                    ? 'Yes'
                    : 'No',
                },
              ]
            : []),
          ...(isGoodieBag
            ? [
                {
                  field:
                    'Apply to e-Solution Goodie bag - cash voucher / goodie bag coupon sorting order',
                  value: campaign.isFeatured ? 'YES' : 'NO',
                },
              ]
            : [
                // {
                //   field: 'Target customer groups',
                //   value: getListStrings(
                //     campaign.generalType === GENERAL_TYPE.customerGroup
                //       ? campaign.customerGroup
                //       : [],
                //   ),
                // },
                // {
                //   field: 'Segments',
                //   value: getListStrings(
                //     campaign.generalType === GENERAL_TYPE.segment
                //       ? campaign.segments
                //       : [],
                //   ),
                // },
              ]),
          {
            field: 'Active period',
            value: activeDisplay(),
          },
          {
            field: 'Visible period',
            value: visibleDisplay(),
          },
          {
            field: 'Access the campaign detail page via URL only',
            value: campaign.accessCampaignCopyUrl ? 'On' : 'Off',
          },
          {
            field: 'Related brand',
            value: campaign.linkedBrand?.name,
          },
          ...(isCouponRelated
            ? [
                {
                  field: 'Per channel limit',
                  value: '',
                  showValue: false,
                },
                {
                  field: 'Per head limit',
                  value: '',
                  showValue: false,
                  customClass: 'campaign-per-head-limit',
                },
                {
                  field:
                    'Per head coupon download limit during the campaign active period ',
                  value: campaign.perHeadLimit
                    ? `${campaign.perHeadLimit} number of coupons`
                    : '-',
                  customClass: 'campaign-per-head-coupon',
                },
                {
                  field: 'Per head coupon download limit (for each action)',
                  value:
                    `${
                      campaign.perHeadDownloadLimit || 1
                    } max coupon can be download per call [X number can be download]\n` +
                    `${
                      campaign.perHeadDownloadForEachTime || 1
                    } per 1 amount in front end = x number of coupons added to My Wallet`,
                  customClass: 'campaign-per-head-coupon',
                  customeContentClass: 'campaign-number-of-coupon',
                },
                {
                  field: 'Per head owned coupon limit ',
                  value: campaign.perHeadOwnedLimit
                    ? `${campaign.perHeadOwnedLimit} number of coupons`
                    : '-',
                  customClass: 'campaign-per-head-coupon',
                },
              ]
            : []),
        ];

  return (
    <>
      <label className="create-section-title">Properties</label>
      {displayFieldContentList.map((item, index) => {
        if (item.field)
          return profileField(
            item.field,
            item.value,
            item.showValue,
            item.customClass,
            item.customeContentClass,
          );
      })}
      <BasePrompt
        show={showChannelLimit}
        customClass="channel-limit-prompt-container"
        title="Per channel and target market limit"
        closeAction={() => {
          setShowChannelLimit(false);
        }}
        otherBody={() => (
          <div className="channel-limit-prompt">
            <div className="channel-limit-prompt-total-data">
              {getChannelLimitTotalData()}
            </div>
            {Object.values(campaign?.channelLimit || [])
              .filter(
                (channel) => channel.overallLimit || channel.overallLimit === 0,
              )
              ?.map((channel) => {
                const channelPK = channel.channelId
                  ? `channel${channel.channelId}`
                  : null;
                const channelAcquired =
                  couponAcquired?.[channelPK]?.acquired || 0;
                const remainMarketUnSelected =
                  campaign?.markets
                    ?.filter(
                      // market is in this channel
                      (market) =>
                        market.channels
                          ?.map((item) => item.pk)
                          ?.includes(channel.channelId),
                    )
                    ?.filter(
                      // market is selected
                      (market) =>
                        Object.values(
                          campaign?.channelLimit?.[channel.channelId]?.[
                            'perTargetMarketLimit'
                          ] || {},
                        ).filter(
                          (item) =>
                            item.pk === market.pk &&
                            !isEmpty(item.overallLimit),
                        )?.length <= 0,
                    )?.length > 0; //remain market unSelected
                return (
                  <>
                    <div className="channel-limit-prompt-channel-title">
                      <label
                        style={{ marginBottom: 'unset' }}
                        className="create-section-label"
                      >
                        {channel.name}
                      </label>
                    </div>
                    <div className="channel-limit-prompt-channel-area">
                      <div className="channel-limit-prompt-content">
                        {channel.overallLimit || 0} number of coupons
                        <br />
                        Acquired: {channelAcquired}, Available to acquired in
                        this channel:{' '}
                        {(channel.overallLimit || 0) - channelAcquired}
                      </div>
                      {/* {channel.displayLimit
                    ? <div className="channel-limit-prompt-tips-messages">
                      <Image
                        src={checkHookImage}
                        className="channel-limit-prompt-check-hook"
                      />
                      <label>Display the overall limit in the campaign.</label>
                    </div>
                    : null
                  } */}
                      {Object.values(channel.perTargetMarketLimit || []).map(
                        (market) => {
                          const marketPK = market?.pk
                            ? `market${market.pk}`
                            : null;
                          const marketAcquired =
                            couponAcquired?.[channelPK]?.['markets']?.[marketPK]
                              ?.acquired || 0;
                          return perTargetMarketLimitField({
                            name: market.name,
                            acquired: marketAcquired,
                            overallLimit: market.overallLimit,
                          });
                        },
                      )}
                      {!isChannelWithoutMarket(channel) &&
                      remainMarketUnSelected ? (
                        perTargetMarketLimitField({
                          name: 'Limit for other target markets',
                          acquired: Object.values(
                            couponAcquired[channelPK]?.['markets'] || {},
                          )
                            ?.filter((value) =>
                              isEmpty(
                                channel.perTargetMarketLimit?.[value.pk]
                                  ?.overallLimit,
                              ),
                            )
                            ?.map((market) => market.acquired || 0)
                            ?.reduce(
                              (perviousValue, currentValue) =>
                                perviousValue + currentValue,
                              0,
                            ),
                          overallLimit:
                            channel.overallLimit -
                            Object.values(channel.perTargetMarketLimit || {})
                              .map((market) => market.overallLimit || 0)
                              ?.reduce(
                                (perviousValue, currentValue) =>
                                  perviousValue + currentValue,
                                0,
                              ),
                          isOtherMarket: true,
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              })}
            {campaign.channels?.filter((channel) => {
              const channelOverallLimit =
                campaign.channelLimit?.[channel.pk]?.overallLimit;
              return !channelOverallLimit && channelOverallLimit !== 0;
            })?.length > 0 ? (
              <div className="channel-limit-prompt-unLimited">
                <label>
                  Below channels and target markets has no limit assigned, user
                  is able to acquired all the available coupons from them：
                </label>
                {campaign.channels
                  ?.filter((channel) => {
                    const channelOverallLimit =
                      campaign.channelLimit?.[channel.pk]?.overallLimit;
                    return !channelOverallLimit && channelOverallLimit !== 0;
                  })
                  ?.map((channel) => (
                    <>
                      <li
                        id={`channel-unlimit-${channel.pk}`}
                        className="channel-limit-prompt-unLimited-channel"
                      >
                        {channel.name}
                      </li>
                      {/* {campaign.channelLimit?.[channel.pk]?.displayLimit
                      ? <div className="channel-limit-prompt-tips-messages">
                        <Image
                          src={checkHookImage}
                          className="channel-limit-prompt-check-hook"
                        />
                        <label>Display the overall limit in the campaign.</label>
                      </div>
                      : null
                    } */}
                    </>
                  ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      />
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description=""
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container terms-and-conditions-related-items-container">
            {relatedPromptKey?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      />
    </>
  );
}

export default ProfileSection;
