import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { APIStatus } from '../../config/CustomEnums';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import WorkingTeamField from '../workingTeam/WorkingTeamField';
import { useFormContext } from 'react-hook-form';
import { validate } from './CreateMissionValidate';

function CreateMissionStepOne({ status }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getValues, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;

  const getSection = () => {
    if (status === APIStatus.calling) {
      return [<Loading />];
    }
    const parts = [
      <WorkingTeamField
        from="Mission"
        title="Content Access Control"
        showPrompt={!params.id}
      />,
    ];
    return parts;
  };

  const sections = getSection();

  const goToNextStep = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('@167', isValid, errors, getValues());
    dispatch({
      type: 'mission/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  const buttonDisabled = () => {
    return false;
  };

  const buttonDisable = buttonDisabled();

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep();
        }}
        disabledContinue={buttonDisable}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.mission.createStatus,
});

export default connect(mapPropsToState)(CreateMissionStepOne);
