import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { PublishTagType } from '../../../config/CustomEnums';

import LabelWithToolTips from '../../../components/base/LabelWithToolTips';

function Filter({
  backAction = () => {},
  inPrompt = false,
  filetrItems = [],
  defaultSearch = {},
}) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { attributeTags, districtList } = useSelector((state) => ({
    attributeTags: state.attributeTag.notPagedAllList,
    districtList: state.storeModel.notPagedAllList,
  }));

  const parsedSearch = queryString.parse(location.search);

  const searchPublishStatus =
    parsedSearch['status'] || (inPrompt && defaultSearch.status) || '';
  const searchDistrict =
    parsedSearch['district'] || (inPrompt && defaultSearch.district) || '';

  const searchAttributeTag =
    parsedSearch['attribute_tags'] ||
    (inPrompt && defaultSearch.attribute_tags) ||
    '';

  const [status, setStatus] = useState(searchPublishStatus);
  const [district, setDistrict] = useState(ArrayStringData(searchDistrict));
  const [tags, setTags] = useState(ArrayStringData(searchAttributeTag));

  const [districtData, setDistrictData] = useState();
  const [sourceTagsData, setSourceTagsData] = useState();
  const [tagsData, setTagsData] = useState();

  useEffect(() => {
    if (filetrItems.indexOf('District') >= 0) {
      dispatch(
        createAction('storeModel/getDistrictList')({
          isAll: true,
          isSimplify: true,
        }),
      );
    }
    if (filetrItems.indexOf('attribute_tags') >= 0) {
      dispatch({
        type: 'attributeTag/getAllList',
        // payload: { linkedCampaign: true },
      });
    }

    return () => {
      dispatch({
        type: 'storeModel/clearData',
        payload: { selectedAllList: [] },
      });
      dispatch({ type: 'attributeTag/clearData' });
    };
  }, []);

  useEffect(() => {
    console.log('attributeTags: ', attributeTags);
    const treeData = [];
    let others = {};
    attributeTags.forEach((item) => {
      let parent = item.tagKey;
      const parentPK = item.tagKey?.pk || -1;
      if (!parent) {
        parent = {
          pk: -1,
          title: (
            <LabelWithToolTips
              title={'Other attribute tags'}
              className="list-tree-title"
            />
          ),
          name: 'Other attribute tags',
        };

        if (others.title) {
          others.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
        } else {
          others = {
            ...parent,
            key: `group-${parentPK}`,
            children: [
              {
                ...item,
                title: (
                  <LabelWithToolTips
                    title={item.title}
                    className="list-tree-sub-title"
                  />
                ),
                key: item.pk,
              },
            ],
          };
        }
      } else {
        parent.title = (
          <LabelWithToolTips
            title={`[ID:${parent.pk}] ${parent.name}`}
            className="list-tree-title"
          />
        );

        const exsitGroup = treeData.find((group) => group.pk == parentPK);
        if (exsitGroup) {
          exsitGroup.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
          return;
        }

        treeData.push({
          ...parent,
          key: `group-${parentPK}`,
          children: [
            {
              ...item,
              title: (
                <LabelWithToolTips
                  title={item.title}
                  className="list-tree-sub-title"
                />
              ),
              key: item.pk,
            },
          ],
        });
      }
    });
    setTagsData(
      Object.keys(others).length > 0 ? [others].concat(treeData) : treeData,
    );
    setSourceTagsData(
      Object.keys(others).length > 0 ? [others].concat(treeData) : treeData,
    );
  }, [attributeTags]);

  const content = [
    {
      title: 'District',
      data: !!districtData ? districtData : districtList,
      value: district,
      setValue: setDistrict,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by Name',
      renderDisplayName: (item) => `${item.name}`,
      filterAction: (searchKey) => {
        const newData = districtList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return name.includes(searchKey?.toLowerCase() || '');
        });
        setDistrictData(newData);
      },
    },
    {
      title: 'attribute_tags',
      displayTitle: 'Attribute Tags',
      data: tagsData,
      value: tags,
      setValue: setTags,
      componment: FilterTypeEnum.searchable2LevelChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = sourceTagsData
          ?.filter((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            return (
              name.includes(searchKey?.toLowerCase() || '') ||
              // item.pk === searchKey ||
              item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ).length > 0
            );
          })
          ?.map((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            const categoryMatched = name.includes(
              searchKey?.toLowerCase() || '',
            );

            if (categoryMatched) {
              return item;
            }

            return {
              ...item,
              children: item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ),
            };
          });
        setTagsData(newData);
      },
    },

    {
      title: 'Status',
      data: [
        { name: 'Inactive', pk: PublishTagType.unPublished },
        { name: 'Active', pk: PublishTagType.published },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return (
    <BaseFilter
      content={
        filetrItems?.length > 0
          ? content.filter((item) => filetrItems.indexOf(item.title) >= 0)
          : content
      }
      backAction={backAction}
      inPrompt={inPrompt}
    />
  );
}

export default Filter;
