import React from 'react';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';

function NumberInputArea({
  labelText,
  labelClass = '',
  type,
  valueChange,
  defaultValue = undefined,
  errors,
  errorFieldName,
  index = null,
  disabled = false,
  customStyle = {},
  customClass = '',
}) {
  return (
    <>
      <label
        className={`create-section-label create-section-label-bottom-space ${labelClass}`}
        style={{ color: '#404040', ...customStyle }}
      >
        {labelText}
      </label>
      <div className={`${customClass}`}>
        <input
          disabled={disabled}
          style={disabled ? { backgroundColor: '#F7F7F7' } : {}}
          value={defaultValue}
          type="number"
          min="0"
          className={`coupon-campaign-section-input ${
            hasError(errors, errorFieldName) ? 'error-field' : ''
          }`}
          onChange={({ target }) => {
            let value = target.value;
            if (value === '') {
              value = null;
            }
            valueChange(parseInt(value));
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 189) {
              event.preventDefault();
              return false;
            }
          }}
        />
        <label className="section-short-description">{type}</label>
      </div>
      <ErrorFieldMessage
        id={`${errorFieldName}-${index}`}
        error={hasError(errors, errorFieldName)}
        message={errorMessage(errors, errorFieldName)}
      />
    </>
  );
}

export default NumberInputArea;
