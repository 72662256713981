import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TablePrompt from '../base/prompt/TablePrompt';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import Filter from '../../containers/engagement/campaign/Filter';
import { CampaignType } from '../../config/CustomEnums';

function CampaignTablePrompt(props) {
  const dispatch = useDispatch();
  const params = useParams();

  const onCancel = props.onCancel || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const title = props.title || 'Select campaign';
  const table = props.table || {
    title: 'After checking, click confirm to complete the selection',
    fields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      {
        displayName: 'Available Coupon',
        fieldName: 'couponStock',
        // orderField: 'merchantName',
      },
      {
        displayName: 'Coupon Used / Acquired/Generated (eWallet|Physical)',
        fieldName: 'couponUsedAndAcquired',
        width: 180,
      },
      // { displayName: 'Goodie bag ID', fieldName: 'goodieBagID' },
      // { displayName: 'Goodie bag name', fieldName: 'goodieBagName' },
      {
        displayName: 'Active Period',
        fieldName: 'displayActivePeriod',
        orderField: 'startDate',
        isDate: true,
      },
      {
        displayName: 'Visible Period',
        fieldName: 'displayVisiblePeriod',
        orderField: 'displayStartDate',
        isDate: true,
      },
      { displayName: 'Status', fieldName: 'displayStatus', width: 121 },
    ],
  };

  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  // const [isLoading, setIsLoading] = useState(true);
  // const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { pagedList, pageInfo } = useSelector((state) => ({
    pagedList: state.campaignList.pagedList,
    pageInfo: state.campaignList.pageInfo,
  }));

  const baseSearch = {
    status: true,
    useStock: true,
    getAll: 'false',
    // typeIn: `${CampaignType.couponsCampaign},${CampaignType.cashVoucherCampaign},${CampaignType.giftCampaign},${CampaignType.fortuneBagForGoodieBagCampaign}`,
    typeIn: `${CampaignType.couponsCampaign}`,
    excludeInOtherMission: params?.id || 0,
    ...(props.baseSearch || {}),
  };

  useEffect(() => {
    setDataList([]);
    setIsLoading(false);
    setIsLoadingMore(true);
    // setIsLoading(true);
    // setIsLoadingMore(false);
    // console.log('??? set first loading true');
    dispatch({
      type: 'campaignList/clearData',
      payload: {
        pagedList: [],
        pageInfo: {},
      },
    });
    // if (props.show) {
    //   dispatch(
    //     createAction('campaignList/getCampaignList')({
    //       ...baseSearch,
    //       sort: '-pk',
    //       afterAction: () => {
    //         setIsLoading(false);
    //       },
    //     }),
    //   );
    // }
  }, [props.show]);

  useEffect(() => {
    // console.log('??? pagedList set false', pagedList, isLoadingMore);
    setDataList([...dataList, ...pagedList]);
    if (isLoadingMore) {
      setIsLoading(false);
    }
  }, [pagedList]);

  return (
    <TablePrompt
      show={props.show}
      customClass="campaign-table-prompt"
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={onConfirm}
      title={title}
      table={{
        ...table,
        dataList,
        isLoading,
        selectedData: props.selectedData || [],
        loadMore: (page, search) => {
          // console.log('??? loadmore set true');
          setIsLoading(true);
          setIsLoadingMore(true);
          dispatch(
            createAction('campaignList/getCampaignList')({
              ...baseSearch,
              ...search,
              page: page + 1,
            }),
          );
        },
        hasNext: pageInfo.hasNextPage,
        filter: {
          filterComponent: Filter,
          filetrItems: ['Brand', 'tags'],
          keywordSearch: {
            keys: [
              {
                placeholder: 'Search by brand',
                search: 'brandIcontains',
              },
              {
                placeholder: 'Search by campaign category',
                search: 'categoryIcontains',
              },
              {
                placeholder: 'Search by attribute tag',
                search: 'attributeTagIcontains',
              },
              {
                placeholder: 'Search by ID & name',
                search: 'search',
              },
            ],
          },
          searchFunc: (search) => {
            setIsLoading(true);
            dispatch(
              createAction('campaignList/getCampaignList')({
                ...baseSearch,
                ...search,
                page: 1,
              }),
            );
            setDataList([]);
          },
        },
      }}
    />
  );
}

export default CampaignTablePrompt;
