import React, { useEffect } from 'react';
import CreateCouponStepOne from './CreateCouponStepOne';
import CreateCouponStepTwo from './CreateCouponStepTwo';
import CreateCouponStepThree from './CreateCouponStepThree';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { createCouponBreads } from '../../../config/CustomBreadConfig';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import {
  removeCouponSessionStroage,
  saveCouponToSessionStorage,
} from '../../../models/CreateCouponModel';
import {
  getCmapaignCreateSucessStepBar,
  GENERATE_COUPON,
  CRATE_COUPON_SET,
} from '../../../components/base/prompt/NextStepConfig';
import {
  APIStatus,
  CampaignType,
  PublishTagType,
  STORE_LOADING_TYPE,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CouponURLFragments } from '../../../components/coupon/CouponUrlConfig';
import { TAB_LIST_OF_COUPON_SETS } from './CouponList';
import GenerateCouponView from '../../../components/coupon/couponCreation/GenerateCouponView';
import BaseForm from '../../../components/base/v2/BaseForm';
import NextStepPrompt from '../../../components/base/prompt/NextStepPrompt';
import Loading from '../../../components/base/Loading';

function CreateCoupon({
  stepConfig,
  currentStep,
  formHasSubmitted,
  couponTemplate,
  hasUpdatedDefaultValues,
  createCouponTemplateStatus,
  couponQuantity,
  couponGenerated,
  generateCouponInfo,
  showEditSuccessPrompt,
}) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const {
    formats: couponFormat,
    couponTemplatePk,
    generateCouponStatus,
    name: couponName,
    brand,
    couponTemplateType,
    redemptionMethod,
    couponCodeType,
    generateCoupon,
    couponMedium,
    totalNubmerOfGeneratedCoupons,
    totalNubmerOfGeneratedPhysicalCoupons,
  } = generateCouponInfo;

  const stepSet = [
    <CreateCouponStepOne id={id} />,
    <CreateCouponStepTwo />,
    <CreateCouponStepThree />,
  ];

  useEffect(() => {
    const newItem = location.state || {};
    console.log('newItem:', location);
    if (id) {
      dispatch(
        createAction('storeModel/getAllList')({
          isAll: true,
          isSelectorLoad: true,
          couponTemplateIn: params.id,
        }),
      );
      dispatch({
        type: 'storeModel/doStoreRequest',
        payload: {
          [STORE_LOADING_TYPE.COUPON]: id,
          notBrandRelate: true,
          status: PublishTagType.published,
        },
      });
      dispatch(
        createAction('couponList/getCouponSet')({
          couponSetPK: params.id,
          ...newItem,
        }),
      );
      dispatch(
        createAction('createCoupon/getCouponRelatedCampaignWorkingTeams')({
          couponId: id,
        }),
      );
    } else {
      dispatch(
        createAction('createCoupon/loadCouponFromCookie')({ ...newItem }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch({ type: 'createCoupon/clearState' });
      dispatch({ type: 'storeModel/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCouponButton = (title) => {
    return {
      text: title,
      action: () => {
        dispatch({
          type: 'createCoupon/updateGenerateCouponState',
          payload: {
            generateCoupon: true,
            generateCouponStatus: APIStatus.none,
          },
        });
        dispatch({
          type: 'createCoupon/updateState',
          payload: {
            createCouponTemplateStatus: APIStatus.none,
          },
        });
      },
    };
  };

  const goToCouponList = () => {
    history.push({ pathname: '/coupons', hash: TAB_LIST_OF_COUPON_SETS });
  };

  const goToSignleCouponList = () => {
    history.push({
      pathname: `/coupons/${couponTemplatePk}`,
      hash: CouponURLFragments.list,
    });
  };

  const mayBeLaterAction = () => {
    if (couponGenerated) {
      goToSignleCouponList();
      return;
    }
    goToCouponList();
  };

  const getNextStepConfig = () => {
    let title = 'Successfully Create!';
    let description =
      'The coupon set is successfully created. Let’s Generate some coupons base this coupon set.';
    let type = CRATE_COUPON_SET;
    const stepNames = getCmapaignCreateSucessStepBar(
      CampaignType.couponsCampaign,
      true,
    );
    let buttons = [generateCouponButton('Generate coupon')];
    if (couponGenerated) {
      type = GENERATE_COUPON;
      title = 'Successfully Generate!';
      description = `${couponQuantity} Coupon is successfully generted. Let’s create a coupon campaign based on these coupon or you can generate a single coupon to a customer.`;
      buttons = [
        {
          text: 'Create Coupon campaign',
          action: () =>
            history.push({
              pathname: '/campaigns/create',
              search: `type=${CampaignType.couponsCampaign}&id=${couponTemplatePk}`,
            }),
          requires: PermissionCodes.addCampaign,
        },
        generateCouponButton('Continue generating coupon'),
      ];
    }
    buttons.push({
      text: 'Maybe Later',
      action: mayBeLaterAction,
    });
    return {
      title,
      description,
      type,
      stepNames,
      buttons,
    };
  };

  const nextStepConfig = getNextStepConfig();
  if (id && !couponTemplate?.pk) {
    return <Loading customClass={'common-full-height'} />;
  }
  return (
    <>
      <BaseForm
        defaultValues={couponTemplate}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveCouponToSessionStorage(getValues());
          } else {
            removeCouponSessionStroage();
          }
        }}
        nextStepConfig={{
          title: nextStepConfig.title,
          description: nextStepConfig.description,
          steps: null,
          buttons: nextStepConfig.buttons,
        }}
        showFinishPop={
          !generateCoupon &&
          (generateCouponStatus === APIStatus.success ||
            createCouponTemplateStatus === APIStatus.success)
        }
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: mayBeLaterAction,
          onClose: mayBeLaterAction,
        }}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={
          <CustomBreadcrumb breadcrumbs={createCouponBreads(params?.id)} />
        }
        stepsConfig={stepConfig}
        caution={{
          detail:
            'Coupon set stores the content and controls a list of couopn.After you create a coupon set, you can general a list of single coupons for your customers',
          title: 'Coupon Set',
        }}
      />
      <GenerateCouponView
        couponFormat={couponFormat}
        couponTemplatePk={couponTemplatePk}
        couponTemplateName={couponName}
        relateBrand={brand}
        couponTemplateType={couponTemplateType}
        couponRedemptionMethod={redemptionMethod}
        couponCodeType={couponCodeType}
        couponMedium={couponMedium}
        totalNubmerOfGeneratedCoupons={totalNubmerOfGeneratedCoupons}
        totalNubmerOfGeneratedPhysicalCoupons={
          totalNubmerOfGeneratedPhysicalCoupons
        }
      />
      <NextStepPrompt
        show={showEditSuccessPrompt}
        title="Successfully Saved!"
        description="Copuon is successfully saved."
        steps={null}
        buttons={[
          {
            text: 'Back to coupon listing page',
            action: goToCouponList,
            requires: PermissionCodes.viewCoupon,
          },
          {
            text: 'Continue edit',
            action: () => {
              dispatch({ type: 'createCoupon/hideEditorSuccessPrompt' });
            },
          },
        ]}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createCoupon.stepConfig,
  currentStep: state.createCoupon.currentStep,
  formHasSubmitted: state.createCoupon.formHasSubmitted,
  couponTemplate: state.createCoupon.couponTemplate,
  hasUpdatedDefaultValues: state.createCoupon.hasUpdatedDefaultValues,
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus,
  generateCouponInfo: state.createCoupon.generateCoupon,
  couponQuantity: state.createCoupon.couponQuantity,
  couponGenerated: state.createCoupon.couponGenerated,
  showEditSuccessPrompt: state.createCoupon.showEditSuccessPrompt,
});

export default connect(mapPropsToState)(CreateCoupon);
