import React, { useEffect, useState } from 'react';
import './GoodieBagEntitlement.scss';
import queryString from 'query-string';
import ContentSections from '../../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../../components/base/BottomStepComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../../config/PermissionCodes';
import CustomListComponent from '../../../../components/base/CustomListComponent';
import AuthButton from '../../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_TYPE } from '../../../../config/CustomEnums';
import { Image } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../../../assets/images/campaign/copy_icon.svg';
import Instruction_PDF from '../../../../media/OperationInstructionGoodieBag.pdf';
import { ToastType } from '../../../../models/NavBarModel';
import { GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY } from '../../../../models/GoodieBagsModel';
import { goodieBagsEntitlementTrackingCodeBreads } from '../../../../config/CustomBreadConfig';
import GoodieBagEntitlementTrackingCodeFilter from './GoodieBagEntitlementTrackingCodeFilter';
import CountComponent from '../../../../components/base/CountComponent';
import { ImageTextButton } from '../../../../components/base/CustomImageButton';
import filterImage from '../../../../assets/images/32filter.svg';
import ListFilterComponent from '../../../../components/base/ListFilterComponent';
import BaseSimpleTable from '../../../../components/base/BaseSimpleTable';
import './GoodieBagEntitlementTrackingCode.scss';
import { TrackingCodeActivePeriod } from '../../../../components/goodieBags/CreateTrackingCodePrompt';
import BaseForm from '../../../../components/base/v2/BaseForm';
import {
  removeDataFromSessionStorage,
  saveDataToSessionStorage,
} from '../../../../models/CreateCampaignModel';
import MembershipInfoCard from '../../../../components/customer/MembershipInfoCard';
import { Controller, useFormContext } from 'react-hook-form';
import Loading from '../../../../components/base/Loading';
import MultipleMapLocationSelectors, {
  checkMultipleLocationValid,
} from '../../../../components/base/MultipleMapLocationSelectors';
import { getCurrentDay } from '../../../../utils/TimeFormatUtil';

function validate({ getValues, setError, clearErrors, goodieBag }) {
  console.log('tracking code:', getValues());
  clearErrors();

  const errorList = [];
  const [activePeriod, locations, checkGPS] = getValues([
    'activePeriod',
    'locations',
    'checkGPS',
  ]);

  if (!activePeriod.isFollowGoodieBag) {
    const start = activePeriod.startDate || getCurrentDay();
    const end = activePeriod.endDate || getCurrentDay();

    if (end <= start) {
      errorList.push({
        name: `endDate`,
        message: 'endDate',
      });
    } else {
      if (
        goodieBag.displayEndDate &&
        start < new Date(goodieBag.displayStartDate)
      ) {
        errorList.push({
          name: `startDate`,
          message: 'startDate',
        });
      }
      if (
        goodieBag.displayEndDate &&
        end > new Date(goodieBag.displayEndDate)
      ) {
        errorList.push({
          name: `goodiebagEndDate`,
          message: 'goodiebagEndDate',
        });
      }
    }
  }

  const locationErrors = checkMultipleLocationValid(checkGPS, locations, true);

  [...errorList, ...locationErrors].forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length && !locationErrors.length;
}

function TrackCodeContent({ detail }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { getValues, setError, clearErrors, control, setValue, formState } =
    useFormContext();
  const { errors } = formState;
  const formatData = () => {
    return {
      ...detail,
      merchantDisplay: `[ID:${detail?.merchant?.pk}] ${detail?.merchant?.name}`,
    };
  };

  return (
    <>
      <ContentSections
        sections={[
          <MembershipInfoCard
            title={'INFORMATION'}
            data={formatData()}
            fields={[
              [
                {
                  title: 'Merchant name',
                  field: 'merchantDisplay',
                },
              ],
              [
                {
                  title: 'Tracking code',
                  field: 'trackingCode',
                },
              ],
            ]}
          />,
          <>
            <label className="create-section-title">Active period</label>
            <Controller
              control={control}
              name={'activePeriod'}
              render={({ field: { value } }) => (
                <TrackingCodeActivePeriod
                  index={0}
                  source={value}
                  goodiebag={{
                    startDate: detail.displayStartDate,
                    endDate: detail.displayEndDate,
                  }}
                  onChange={(index, name, changeValue) => {
                    setValue('activePeriod', { ...value, [name]: changeValue });
                  }}
                  errors={[[...Object.keys(errors || {})]]}
                />
              )}
            />
          </>,
          <MultipleMapLocationSelectors
            title={'Restrict acquire location by user GPS info'}
            tips={
              'If turn on, restrict the location where goodie bag can be acquired'
            }
          />,
        ]}
        hidePreview
      />

      <SaveAndBackButtons
        backAction={() => history.goBack()}
        saveText={'Save'}
        saveAction={async () => {
          const isValid = validate({
            getValues: getValues,
            setError,
            clearErrors,
            goodieBag: detail.goodieBag,
          });
          const data = getValues();
          if (isValid) {
            dispatch(
              createAction('entitlementTrackingCodes/updateTrackingCodeGPS')({
                data: {
                  id: data.pk,
                  isFollowGoodieBagActivePeriod:
                    data.activePeriod.isFollowGoodieBag || false,
                  startDate: data.activePeriod.startDate,
                  endDate: data.activePeriod.endDate,
                  isCheckGps: data.checkGPS,
                  locations: data.locations,
                  toDeleteLocations: data.toDeleteLocations,
                },
                // afterAction: () => history.push(location),
              }),
            );
          }
        }}
      />
    </>
  );
}

export default function GoodieBagEntitlementTrackingCode() {
  const params = useParams();
  const { id, entitlementId, codeId } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    codeDetail,
    hasUpdatedDefaultValues,
    formHasSubmitted,
    entitlementDetail,
    userAdminType,
  } = useSelector((state) => ({
    codeDetail: state.entitlementTrackingCodes.detail,
    hasUpdatedDefaultValues:
      state.entitlementTrackingCodes.hasUpdatedDefaultValues,
    formHasSubmitted: state.entitlementTrackingCodes.formHasSubmitted,
    entitlementDetail: state.fortuneBagEntitlement.entitlementDetail,
    userAdminType: state.users.adminType,
  }));

  useEffect(() => {
    dispatch(
      createAction('entitlementTrackingCodes/getOneDetail')({
        id: codeId,
        withGoodiebag: true,
      }),
    );
  }, [dispatch, codeId]);

  useEffect(() => {
    if (formHasSubmitted) {
      history.push({
        pathname: `/goodie_bags/${id}/entitlement/${entitlementId}/`,
      });
    }
  }, [formHasSubmitted]);

  const buttons = [
    <AuthButton
      title="Operation Instruction pdf"
      action={() => {
        window.open(Instruction_PDF);
      }}
    />,
  ];

  return (
    <>
      {/*  */}
      <BaseForm
        defaultValues={codeDetail}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveDataToSessionStorage(getValues());
          } else {
            removeDataFromSessionStorage();
          }
        }}
        // nextStepConfig={{
        //   title: 'Successfully Created!',
        //   description: 'Fortune Bag group is successfully created.',
        //   steps: null,
        //   buttons: [
        //     {
        //       text: 'Back to fortune bag groups list',
        //       action: gotoCampaignGroupList,
        //     },
        //   ],
        // }}
        showFinishPop={formHasSubmitted}
        // continueCreateConfig={{
        //   onConfirm: () => {
        //     history.push(history.location.state?.from);
        //   },
        //   onCancel: gotoCampaignGroupList,
        //   onClose: gotoCampaignGroupList,
        // }}
        // content={[]}
        buttons={buttons}
        tabs={[
          {
            name: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title,
            content: [
              codeDetail.trackingCode ? (
                <TrackCodeContent detail={codeDetail} />
              ) : (
                <Loading />
              ),
            ],
          },
        ]}
        hideTab={false}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={goodieBagsEntitlementTrackingCodeBreads(
              id,
              history.location?.state?.goodieBagName ||
                entitlementDetail?.goodieBag?.name,
              {
                id: entitlementId,
                name: codeDetail?.merchant?.name,
              },
              codeDetail.trackingCode,
            )}
          />
        }
        // stepsConfig={[]}
        caution={{
          detail: '',
          title: `${codeDetail.trackingCode}`,
        }}
      />
    </>
  );
}
