export const StoreErrorHandleFields = {
  name: {
    required: 'Please provide name',
  },
  displayPriority: {
    required: 'Please provide display priority',
  },
  coverPhoto: {
    required: 'Please provide cover photo',
  },
  description: {
    required: 'Please provide description',
  },
  subcategories: {
    required: 'Please provide subcategories',
  },
  address: {
    required: 'Please provide address',
  },
  latitude: {
    required: 'Please provide the latitude',
  },
  longitude: {
    required: 'Please provide the longitude',
  },
  longitudeLength1: {
    required: 'Longitude should be no more than 9 digits',
  },
  latitudeLength1: {
    required: 'Latitude should be no more than 9 digits',
  },
  longitudeLength2: {
    required: 'Longitude should be no more than 6 decimal places',
  },
  latitudeLength2: {
    required: 'Latitude should be no more than 6 decimal places',
  },
  staffCode: {
    required: 'Please provide staffcode',
    length: 'Staffcode should be 6 digits',
  },
  brand: {
    required: 'Please provide brand',
  },
  brandName: {
    required: 'Please provide brand name',
  },
  brandIcon: {
    required: 'Please provide a brand logo image.',
  },
  emailAddress: {
    valid: 'please input the valid email',
  },
  district: {
    required: 'Please provide district',
  },
  weekHours: {
    required: 'Please provide a valid week hours',
  },
  tags: {
    required: 'Please provide a attribute tag',
  },
  radius: {
    required: 'Please provide the radius',
    valid: 'Ensure that there are no more than 9 digits in total.',
  },
  coverPhoto: {
    required: 'Please provide cover photo',
  },
};
