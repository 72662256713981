import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import BasePrompt from '../base/prompt/BasePrompt';
import CheckboxItem from '../coupon/couponList/CheckboxItem';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MissionLocationCodeExportPrompt.scss';
import { ImportResource } from '../../models/DownloadImportModel';
import { ExportType } from '../../config/CustomEnums';
import { createAction } from '../../utils';
import { ArrayStringData } from '../../utils/index';

function MissionLocationCodeExportPrompt({
  show = false,
  mission,
  exportTotalCount,
  closeAction = () => {},
  exportAction = () => {},
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  // const parsedSearch = queryString.parse(location.search);
  // const searchBrands = parsedSearch['ota'] || '';

  const [qrCodeOnly, setQrCodeOnly] = useState(false);
  const [qrCodeString, setQrCodeString] = useState(false);

  // useEffect(() => {
  //   if (show) {
  //     if (mission) {
  //       dispatch(
  //         createAction('missionLocation/getList')({
  //           missionId: mission?.pk,
  //           // merchants: searchBrands,
  //         }),
  //       );
  //     }
  //   }
  // }, [show]);

  const otherBody = () => {
    return (
      <>
        <CustomTitleLabel title={'Export'} />
        <div className="tracking-code-export-checkbox-container">
          <CheckboxItem
            title="QR code only (.png)"
            checked={qrCodeOnly}
            onChange={() => {
              setQrCodeOnly(!qrCodeOnly);
            }}
          />
          <CheckboxItem
            title="QR code, QR code string (.csv)"
            checked={qrCodeString}
            onChange={() => {
              setQrCodeString(!qrCodeString);
            }}
          />
        </div>
        <CustomTitleLabel
          title={'Per Export type, the number of items to be exported'}
        />
        <label className="tracking-code-export-tips">
          {`Maximum export records per system task is 100,000. If over 100,000, will split into separate system tasks.`}
        </label>
        <label>{exportTotalCount}</label>
      </>
    );
  };
  return (
    <div>
      <BasePrompt
        show={show}
        title={'Export'}
        closeAction={closeAction}
        otherBody={otherBody}
        rightButton={{
          text: 'Export',
          action: () => {
            const exportType = [];
            if (qrCodeOnly) {
              exportType.push(ExportType.QRCODE_IMAGE);
            }
            if (qrCodeString) {
              exportType.push(ExportType.QRCODE_CSV);
            }

            exportAction(exportType);
          },
          disabled: (!qrCodeOnly && !qrCodeString) || !exportTotalCount,
        }}
      />
    </div>
  );
}

export default MissionLocationCodeExportPrompt;
