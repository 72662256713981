import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTitleWithInput from './CustomTitleWithInput';
import ErrorFieldMessage, {
  ReactHookFormErrorMessage,
} from './ErrorFieldMessage';
import CustomMap from './CustomGoogleMap';
import { createAction } from '../../utils';
import { StoreErrorHandleFields } from '../../containers/merchants/stores/StoreErrorHandleFields';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import searchIcon from '../../assets/images/searchIcon.svg';
import { ImageButton } from './CustomImageButton';
import './MapLocationSelector.scss';
import NumberInputArea from './NumberInputArea';
import { Image } from 'react-bootstrap';

export const checkLocationValid = (
  data,
  required = false,
  withRadius = true,
) => {
  if (!data) {
    return { valid: true, errors: {} };
  }

  // if (data.latitude && data.longitude && data.radius) {
  //   return { valid: true, errors: {} };
  // }

  if (!required && (!data.latitude || !data.longitude)) {
    return { valid: true, errors: {} };
  }

  // if (!required && !data.latitude && !data.longitude && !data.radius) {
  //   return { valid: true, errors: {} };
  // }

  // if (!data.latitude && !data.longitude && !data.radius) {
  //   return { valid: true, errors: {} };
  // }

  const errorRequired = {
    latitude: [
      StoreErrorHandleFields.latitude.required,
      StoreErrorHandleFields.latitudeLength1.required,
      StoreErrorHandleFields.latitudeLength2.required,
    ],
    longitude: [
      StoreErrorHandleFields.longitude.required,
      StoreErrorHandleFields.longitudeLength1.required,
      StoreErrorHandleFields.longitudeLength2.required,
    ],
  };
  const errors = {};
  console.log('@@store-120: ', data);
  ['latitude', 'longitude'].forEach((key) => {
    const value = data[key];

    if (!value || value?.length <= 0) {
      errors[key] = errorRequired[key][0];
    } else if (value.length >= 11) {
      errors[key] = errorRequired[key][1];
    } else {
      const numberList = `${value}`.split('.');
      const decimal = numberList[1];
      if (decimal?.length >= 7) {
        errors[key] = errorRequired[key][2];
      }
      // else if (
      //   key === 'latitude' &&
      //   value <= 90 &&
      //   value >= -90
      // ) {
      //   errors[key] = errorRequired[key][0];
      // } else if (
      //   key === 'longitude' &&
      //   value <= 180 &&
      //   value >= -180
      // ) {
      //   errors[key] = errorRequired[key][0];
      // }
    }
  });

  if (withRadius) {
    if (!data.radius) {
      errors.radius = 'Please provide the radius';
    } else if (data.radius.toString().length > 9) {
      errors.radius = 'Ensure that there are no more than 9 digits in total.';
    }
  }

  console.log('@@store-143: ', errors);
  return { valid: Object.keys(errors).length <= 0, errors };
};

function InputField({ title, name, type, defaultValue, setValue, error }) {
  return (
    <>
      <CustomTitleWithInput
        customClass={'latitude-and-latitude'}
        title={title}
        type={type}
        defaultValue={defaultValue}
        setValue={(value) => setValue(value)}
        // focusChange={() => focusChange(name)}
        // disabled={disabled}
        // blurAction={blurAction}
        useDefaultValue={false}
      />
      {error ? (
        <ErrorFieldMessage id={name} error={error} message={error} />
      ) : null}
    </>
  );
}

export default function MapLocationSelector({
  data,
  setData,
  errors,
  withRadius = true,
  radiusTitle = null,
  radiusCustomClass = '',
  optional = false,
  // focusChange,
}) {
  console.log('@@39: ', errors);
  const dispatch = useDispatch();
  const mapAddressRef = useRef(null);
  // const { watch, setValue, control, formState } = useFormContext();
  // const [data, setData] = useState({
  //   mapAddress: '',
  //   latitude: null,
  //   longitude: null,
  //   radius: null,
  // });

  const getAddressLocation = (addressValue) => {
    if (addressValue) {
      dispatch(
        createAction('storeModel/getAddressMarker')({
          address: addressValue,
          onSuccess: (result) => {
            if (result.status === 'OK' && result.results.length > 0) {
              const location = result.results[0].geometry.location;
              setData({
                ...data,
                mapAddress: addressValue,
                latitude: location.lat.toFixed(6),
                longitude: location.lng.toFixed(6),
              });
            }
          },
        }),
      );
    }
  };

  return (
    <>
      <CustomTitleLabel
        title={'Address for below map location selection'}
        className={''}
      />
      <div className="map-address-input-area">
        <input
          type={'text'}
          ref={mapAddressRef}
          value={data.mapAddress}
          className={`custom-markdown-area-title custom-markdown-area-title-short`}
          onChange={({ target }) =>
            setData({ ...data, mapAddress: target.value })
          }
          onKeyDown={(event) => {
            if (
              event.keyCode === 13 ||
              event.key === 'Enter' //Enter
            ) {
              event.preventDefault();
              if (event.target.value) {
                getAddressLocation(event.target.value);
              }
            }
          }}
        />

        {/* <ImageButton
          action={() => {
            setData({ ...data, mapAddress: mapAddressRef.current.value });
            getAddressLocation(mapAddressRef.current.value);
          }}
          image={searchIcon}
        /> */}
        <button
          className="custom-image-button"
          onClick={() => {
            setData({ ...data, mapAddress: mapAddressRef.current.value });
            getAddressLocation(mapAddressRef.current.value);
          }}
        >
          <Image src={searchIcon} />
        </button>
      </div>

      <CustomMap
        onClick={(e) => {
          const lat = e.latLng.lat().toFixed(6);
          const long = e.latLng.lng().toFixed(6);

          setData({
            ...data,
            latitude: lat,
            longitude: long,
          });
          // setLatitude(lat);
          // setLongitude(long);

          dispatch(
            createAction('storeModel/getPointMarkerDetail')({
              lat,
              long,
              onSuccess: (result) => {
                if (result.status === 'OK' && result.results.length > 0) {
                  const location = result.results[0];
                  setData({
                    ...data,
                    mapAddress: location.formatted_address,
                    latitude: lat,
                    longitude: long,
                  });
                }
              },
            }),
          );
        }}
        lat={data.latitude}
        lng={data.longitude}
        radius={data.radius}
        style={{ container: { marginTop: '30px' } }}
      />
      <InputField
        title={`Latitude ${optional ? '(Optional)' : ''}`}
        name="latitude"
        type="number"
        defaultValue={data.latitude}
        error={errors?.latitude}
        setValue={(value) => setData({ ...data, latitude: value })}
      />
      <InputField
        title={`Longitude ${optional ? '(Optional)' : ''}`}
        name="longitude"
        type="number"
        defaultValue={data.longitude}
        setValue={(value) => setData({ ...data, longitude: value })}
        error={errors?.longitude}
      />
      {withRadius ? (
        <div>
          <NumberInputArea
            labelText={
              radiusTitle ||
              'Radius (Calculate a circular eligible zone with pinned location'
            }
            // labelClass="create-section-label"
            type="meters"
            valueChange={(value) =>
              setData({ ...data, radius: value && parseInt(value) })
            }
            defaultValue={data.radius}
            errors={
              errors?.radius ? { radius: { message: errors.radius } } : null
            }
            errorFieldName={'radius'}
            customClass={radiusCustomClass}
            // index = null
          />
        </div>
      ) : null}

      {/* <InputField
        title="radius"
        name="radius"
        type="number"
        defaultValue={data.radius}
        setValue={(value) =>
          setData({ ...data, radius: value && parseInt(value) })
        }
        error={errors?.radius}
      /> */}
    </>
  );
}
