import React, { useEffect, useState } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb.js';
import CustomListComponent from '../../../components/base/CustomListComponent.js';
import { PermissionCodes } from '../../../config/PermissionCodes.js';
import AuthButton from '../../../components/base/AuthButton.js';
import { useHistory, useLocation } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt.js';
import { useDispatch, connect } from 'react-redux';
import BaseTabListContainer from '../../base/BaseTabListContainer.js';
import Filter from './Filter.js';
import BaseListContainer from '../../base/BaseListContainer.js';
import queryString from 'query-string';
import './MissionList.scss';
import { t } from 'i18n-js';
import { MISSION_LIST_FIELDS } from '../../../models/MissionModel.js';
import { createAction, checkHasPermission } from '../../../utils';

function MissionList({
  pageInfo,
  totalCount,
  missionList,
  totalPage,
  checkedList,
  user,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    console.log('@37', missionList);

    const searchDict = queryString.parse(location.search);
    console.log('@38', searchDict);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch({
      type: 'mission/getList',
      payload: {
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      },
    });
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Mission"
      action={() => {
        history.push('/mission/create');
      }}
      requires={PermissionCodes.changeMission}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeMission,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={MISSION_LIST_FIELDS}
              model={'mission'}
              permissionGroup={PermissionCodes.mission}
              dataList={missionList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Mission',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
              hasOtherActions={true}
              customActions={{
                publish: {
                  condition: (item) => {
                    return item.isPublished
                      ? checkHasPermission(
                          user,
                          PermissionCodes.unPublishMission,
                        )
                      : checkHasPermission(
                          user,
                          PermissionCodes.publishMission,
                        );
                  },
                  action: (item) => {
                    console.log('@126, publish', item);
                    dispatch(
                      createAction('mission/publish')({
                        missionPk: item.pk,
                        isPublished: item.isPublished,
                        afterAction: () => {
                          history.push(location);
                        },
                      }),
                    );
                  },
                  requires: [
                    PermissionCodes.publishMission,
                    PermissionCodes.unPublishMission,
                  ],
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: 'Mission',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'mission/delete',
            payload: {
              afterAction: () => history.push(location),
            },
          });
        }}
        data={checkedList}
        title={'Mission'}
      />
    </>
  );
}
const mapPropsToState = (state) => ({
  pageInfo: state.mission.pageInfo,
  totalCount: state.mission.totalCount,
  missionList: state.mission.pagedList,
  totalPage: state.mission.totalPage,
  checkedList: state.mission.checkedList,
  user: state.users,
});
export default connect(mapPropsToState)(MissionList);
