import React, { useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import ContentSections from '../base/ContentSections';
import {
  SaveAndBackButtons,
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import ListButtonsGroup from '../base/ListButtonsGroup';
import { useHistory, useParams } from 'react-router-dom';
import {
  createAction,
  isArrayEmpty,
  removeFromSessionStorage,
  saveToSessionStorage,
  checkHasPermission,
} from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';
import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
} from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { validate } from './CreateGamificationCampaignValidate';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import PublishGamificationCampaignPrompt from './PublishGamificationCampaignPrompt';
import { getImageUrl } from '../../models/CreateCampaignModel';
import BasePrompt from '../base/prompt/BasePrompt';
import PreviewButton from './PreviewButton';
import defaultStampIcon from '../../assets/images/gamificationCampaign/stamp-icon.png';
import defaultStampIconCollected from '../../assets/images/gamificationCampaign/stamp-icon-collected.png';
import defaultRewardIcon from '../../assets/images/gamificationCampaign/reward-icon.png';
import defaultRewardIconCollected from '../../assets/images/gamificationCampaign/reward-icon-collected.png';
import { GAMIFICATION_CAMPAIGN_REWARD_TYPE } from '../../models/GamificationCampaignModel';

const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

export function StepFiveContentSection({ data }) {
  const { languageList } = useSelector((state) => ({
    languageList: state.language.allList,
  }));

  const { getValues } = useFormContext() || {};
  const gamificationCampaign = data || getValues() || {};
  const translations = gamificationCampaign.translations || {};

  const getCoverImagesContent = (
    language,
    valueKey1,
    valueKey2,
    title,
    desktopTitle,
    mobileTitle,
  ) => {
    const value1 = getImageUrl(translations?.[language]?.[valueKey1]);
    const value2 = getImageUrl(translations?.[language]?.[valueKey2]);

    return (
      <div className="cover-images-double-title-container">
        <label
          className={`create-section-label create-section-label-bottom-space`}
        >
          {title}
        </label>
        <div className="cover-images-row">
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              {desktopTitle}
            </label>
            {value1 ? <img src={value1} /> : '-'}
          </div>
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              {mobileTitle}
            </label>
            {value2 ? <img src={value2} /> : '-'}
          </div>
        </div>
      </div>
    );
  };

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach((valueKey) => {
      switch (valueKey) {
        case 'coverPhoto':
        case 'mobilePhoto':
        case 'coverPhotoForTaskList':
        case 'mobilePhotoForTaskList': {
          const url = getImageUrl(translations?.[language]?.[valueKey]);
          if (!url || url === '') {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });

    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'name',
      'shortDescription',
      'coverPhoto',
      'mobilePhoto',
      'coverPhotoForTaskList',
      'mobilePhotoForTaskList',
      'genericTermsAndConditionsTitle',
      'genericTermsAndConditionsContent',
    ]);

    return (
      <>
        <MembershipInfoCard
          title={i18n.t('content', { locale: 'en' })}
          data={data}
          fields={[
            [
              {
                title: 'Title',
                field: 'name',
              },
            ],
            [
              {
                title: 'Short Description',
                field: 'shortDescription',
                isHtml: true,
              },
            ],
            [
              {
                title: 'Cover image on Gamification Campaign List',
                field: 'coverPhoto',
                componment: getCoverImagesContent(
                  language,
                  'coverPhoto',
                  'mobilePhoto',
                  'Cover image on Gamification Campaign List',
                  'Desktop',
                  'Mobile (optional)',
                ),
              },
            ],
            [
              {
                title: 'Cover image on Task List',
                field: 'coverPhotoForTaskList',
                componment: getCoverImagesContent(
                  language,
                  'coverPhotoForTaskList',
                  'mobilePhotoForTaskList',
                  'Cover image on gamification campaign detail page',
                  'Desktop',
                  'Mobile (optional)',
                ),
              },
            ],
            [
              {
                title: 'Generic Terms & Conditions Title',
                field: 'genericTermsAndConditionsTitle',
              },
            ],
            [
              {
                title: 'Generic Terms & Conditions Content',
                field: 'genericTermsAndConditionsContent',
                isHtml: true,
              },
            ],
          ]}
        />
      </>
    );
  };

  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: languageList?.map((item) => {
          console.log('@221', item);
          return {
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName || item.name,
          };
        }),
      }}
      activeSection={LanguageConfig.english}
      contentStyle="campaign-detail-language-content-sections"
    />
  );
}

export function StepFivePropertiesSection({ data }) {
  const { getValues } = useFormContext() || {};
  const gamificationCampaign = data || getValues() || {};

  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);

  const getMissions = (missions) => {
    missions =
      missions &&
      missions?.map((data, index) => `[ID:${data.pk}] ${data.name}`);
    return (
      <>
        <div className="list-new-line">
          {missions?.slice(0, 10).join('\r\n')}
        </div>

        {missions?.length > 10 ? (
          <button
            type="button"
            className="btn-link reset-button show-more-button channel-show-more-button"
            onClick={() => {
              setShowRelatedPrompt(true);
              setRelatedPromptKey(missions);
              setRelatedPromptTitle('Mission');
            }}
          >
            See more
          </button>
        ) : null}
      </>
    );
  };

  const getStampRewards = (stampRewards) => {
    stampRewards = stampRewards
      ?.map((item, index) => {
        if (!item.quantity) {
          return {};
        }
        let selectedObj = item.goodieBag;
        if (
          item.rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value
        ) {
          selectedObj = item.couponTemplate;
        }
        return {
          ...item,
          rowIndex: `#${index + 1}`,
          label: selectedObj
            ? `[ID:${selectedObj.pk}] ${selectedObj.name}`
            : '-',
          // rows: (item.rewardType === GAMIFICATION_CAMPAIGN_REWARD_TYPE.COUPON.value
          //   ? [
          //       `Available Coupon: ${selectedObj?.stock || 0}`,
          //       `Coupon set quantity: ${item.quantity}`,
          //     ]
          //   : [
          //       `Valid goodie bag: ${selectedObj?.validGoodiebagNumber || '-'}`,
          //       `Goodie bag quantity: ${item.quantity}`,
          //     ]
          // ).concat([
          //   `Upload special stamp icon: ${
          //     item.rewardIcon || item.rewardIconCollected ? 'Yes' : 'No'
          //   }`,
          // ]),
        };
      })
      ?.filter((item) => item.rewardType);
    return (
      <div className="preview-stamp-reward-container">
        {stampRewards?.length ? (
          <ul>
            {stampRewards?.map((stampReward, index) => {
              return (
                <li>
                  <label
                    className={`create-section-label create-section-label-bottom-space`}
                  >
                    {stampReward.rowIndex} {stampReward.label}
                  </label>
                  <div className="cover-images-row icon-images">
                    <div className="cover-image-item">
                      <label
                        className={`create-section-label create-section-label-bottom-space`}
                      >
                        Reward icon - before collected
                      </label>
                      <img
                        src={
                          getImageUrl(stampReward.rewardIcon) ||
                          getImageUrl(gamificationCampaign.rewardIcon) ||
                          defaultRewardIcon
                        }
                      />
                    </div>
                    <div className="cover-image-item">
                      <label
                        className={`create-section-label create-section-label-bottom-space`}
                      >
                        Reward icon - collected
                      </label>
                      <img
                        src={
                          getImageUrl(stampReward.rewardIconCollected) ||
                          getImageUrl(
                            gamificationCampaign.rewardIconCollected,
                          ) ||
                          defaultRewardIconCollected
                        }
                      />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          '-'
        )}
      </div>
    );
  };

  const getStampIconsContent = () => {
    return (
      <div>
        <div className="cover-images-row icon-images">
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              Stamp icon - before collected
            </label>
            <img
              src={
                getImageUrl(gamificationCampaign.stampIcon) || defaultStampIcon
              }
            />
          </div>
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              Stamp icon - collected
            </label>
            <img
              src={
                getImageUrl(gamificationCampaign.stampIconCollected) ||
                defaultStampIconCollected
              }
            />
          </div>
        </div>
        <div className="cover-images-row icon-images">
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              Reward icon - before collected
            </label>
            <img
              src={
                getImageUrl(gamificationCampaign.rewardIcon) ||
                defaultRewardIcon
              }
            />
          </div>
          <div className="cover-image-item">
            <label
              className={`create-section-label create-section-label-bottom-space`}
            >
              Reward icon - collected
            </label>
            <img
              src={
                getImageUrl(gamificationCampaign.rewardIconCollected) ||
                defaultRewardIconCollected
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const profileField = (
    field,
    value,
    show = true,
    showField = true,
    showValue = true,
    customValue = null,
    customClass = '',
    customeContentClass = '',
  ) => {
    return (
      <>
        {show ? (
          <>
            {showField ? (
              <label
                className={`create-section-label create-section-label-bottom-space ${customClass}`}
              >
                {field}
              </label>
            ) : null}
            {showValue
              ? customValue || (
                  <label
                    className={`create-section-field-input profile-section-label-bottom ${customeContentClass}`}
                  >
                    {value || '-'}
                  </label>
                )
              : null}
          </>
        ) : null}
      </>
    );
  };

  const displayFieldContentList = [
    {
      field: 'Selected mission',
      customValue: getMissions(gamificationCampaign.missions),
      show: true,
    },
    {
      field: 'Total stamps that can be earned',
      value: gamificationCampaign.missions
        ?.filter((item) => item.maxNumberOfStampEarningLimit)
        ?.reduce(function (total, item) {
          return total + item.maxNumberOfStampEarningLimit;
        }, 0),
    },
    {
      field: 'stamp_icons',
      customValue: getStampIconsContent(),
      showField: false,
    },
    {
      field: 'Added reward',
      customValue: getStampRewards(gamificationCampaign.stampRewards),
    },
    {
      field: 'Max. stamp',
      value: gamificationCampaign.stampCount,
    },
    {
      field: 'Stamp card reset limit',
      value: gamificationCampaign.stampCardResetLimit,
    },
    {
      field: 'Active period',
      value: getReadablePeriod(
        gamificationCampaign.startDate,
        gamificationCampaign.endDate,
        gamificationCampaign.isAlwaysActivePeriod,
      ),
    },
    {
      field: 'Visible period',
      value: getReadablePeriod(
        gamificationCampaign.displayStartDate,
        gamificationCampaign.displayEndDate,
        gamificationCampaign.isAlwaysVisiblePeriod,
      ),
    },
  ];

  return (
    <>
      <label className="create-section-title">Properties</label>
      {displayFieldContentList.map((item, index) => {
        if (item.field)
          return profileField(
            item.field,
            item.value,
            item.show,
            item.showField,
            item.showValue,
            item.customValue,
            item.customClass,
            item.customeContentClass,
          );
      })}
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description=""
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container terms-and-conditions-related-items-container">
            {relatedPromptKey?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      />
    </>
  );
}

function CreateGamificationCampaignStepFive() {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);

  const dispatch = useDispatch();
  const history = useHistory();

  const { createStatus, originalGamificationCampaign, user } = useSelector(
    (state) => ({
      createStatus: state.gamificationCampaign.createStatus,
      originalGamificationCampaign: state.gamificationCampaign.detail,
      user: state.users,
    }),
  );

  const gamificationCampaign = getValues() || {};

  const hasSetStampReward = gamificationCampaign.stampRewards?.filter(
    (item) => item.quantity,
  )?.length;
  console.log(
    'CreateGamificationCampaignStepFive:',
    gamificationCampaign,
    hasSetStampReward,
  );

  useEffect(() => {
    saveToSessionStorage(
      SESSION_KEYS.CREATE_GAMIFICATION,
      gamificationCampaign,
    );
    return () => {
      removeFromSessionStorage(SESSION_KEYS.CREATE_GAMIFICATION);
    };
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(!history.location?.state?.from);
    }

    if (
      createStatus === APIStatus.success ||
      createStatus === APIStatus.failed
    ) {
      setSaveDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const sections = [<StepFivePropertiesSection />];

  const saveGamificationCampaign = (isPublish, afterAction) => {
    dispatch(
      createAction('gamificationCampaign/createOrUpdateGamificationCampaign')({
        data: gamificationCampaign,
        isPublished: isPublish,
        afterAction,
      }),
    );
  };

  const saveTempAction = (afterAction) => {
    setSaveDisabled(true);
    if (gamificationCampaign.isPublished) {
      setActionType(ActionType.save);
      saveGamificationCampaign(true, afterAction);
    } else {
      setActionType(ActionType.save);
      dispatch(
        createAction('gamificationCampaign/createOrUpdateGamificationCampaign')(
          {
            data: gamificationCampaign,
            afterAction: afterAction,
          },
        ),
      );
    }
  };

  const saveAction = (isPublished) => {
    if (isPublished && !hasSetStampReward) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: 'When publish gamification campaign at first time, must set stamp reward',
          },
        },
      });
      return;
    }
    setActionType(isPublished ? ActionType.publish : ActionType.none);
    setSaveDisabled(true);
    saveGamificationCampaign(isPublished);
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 4,
      isBack,
    });
    dispatch({
      type: 'gamificationCampaign/stepChange',
      payload: { isBack: isBack, step: 4, isValid },
    });
  };

  const getButtons = () => {
    const buttons = [];
    let hasPublishRelatedPermission = false;
    if (!gamificationCampaign.isPublished) {
      hasPublishRelatedPermission = checkHasPermission(
        user,
        PermissionCodes.publishGamificationCampaign,
      );
    } else {
      hasPublishRelatedPermission = checkHasPermission(
        user,
        PermissionCodes.unPublishGamificationCampaign,
      );
    }

    if (!params.id && !gamificationCampaign.pk) {
      buttons.push(
        <SaveTempButton
          text="Save only"
          action={saveTempAction}
          disabled={saveDisabled}
        />,
      );
    }
    if (params.id || gamificationCampaign.pk) {
      if (gamificationCampaign.isPublished) {
        buttons.push(
          <PreviewButton gamificationCampaign={gamificationCampaign} />,
        );
      }

      const ComponentInstance = !hasPublishRelatedPermission
        ? SaveButton
        : SaveTempButton;
      buttons.push(
        <ComponentInstance
          text="Update"
          action={() => {
            saveTempAction();
          }}
          disabled={saveDisabled}
        />,
      );
    }
    if (hasPublishRelatedPermission) {
      if (!gamificationCampaign.isPublished) {
        buttons.push(
          <SaveButton
            text="Save and publish"
            action={() => {
              saveAction(true);
            }}
            disabled={saveDisabled}
          />,
        );
      } else {
        buttons.push(
          <SaveButton
            text="UnPublished"
            action={() => {
              saveAction(false);
            }}
            disabled={saveDisabled}
          />,
        );
      }
    }

    return buttons;
  };
  const bottomButtons = getButtons();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <StepFiveContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={bottomButtons}
          />,
        ]}
      />

      <PublishGamificationCampaignPrompt
        isSave={actionType === ActionType.save}
        isPublish={!gamificationCampaign.isPublished}
        show={showPublishPrompt}
        continueId={originalGamificationCampaign.pk}
      />
    </>
  );
}

export default CreateGamificationCampaignStepFive;
