import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import {
  LanguageConfig,
  EMAIL_REG,
  StoreType,
} from '../../../config/CustomEnums';
import {
  hasError,
  errorMessage,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import { useHistory } from 'react-router-dom';
import BrandSelectDropdown from '../../../components/campaign/campaignCreation/BrandSelectDropdown';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomEditor from '../../../components/base/CustomEditor';
import { ReactHookFormMarkDown } from '../../../components/base/CustomMarkDownArea';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import { CustomTitleWithDropDownAndInput } from '../../../components/customer/CustomTitleWithDropDown';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';
import { MissionLabelsSection } from '../../../components/mission/MissionLabelsSection';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';
import ImageUploader from '../../../components/base/ImageUploader';
import LabelWithSwitch, {
  ApplySwitch,
} from '../../../components/base/LabelWithSwitch';

const countryCodes = [
  { name: '853', pk: '853' },
  { name: '852', pk: '852' },
  { name: '86', pk: '86' },
];

export default function ContentGeneralSection({
  language,
  languages,
  focusChange,
}) {
  const { register, watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const translations = watch('storeTranslations') || {};
  const watchSelectedBrand = watch('brand');
  const sourceBrandName = watch('displayBrand');
  const applyBrandIconToAllLanguage = watch('applyBrandIconToAllLanguage');
  const brandIcon = translations[language]?.brandIcon || '';
  const watchMobileNumber = watch('phoneNumberSubscriberNumber');
  const watchCountryCode = watch('phoneNumberCountryCode');
  const storeType = watch('storeType');

  const [promptConfig, setPromtConfig] = useState(false);
  const history = useHistory();

  const brandSection = (
    <Controller
      control={control}
      name="brand"
      rules={{ required: StoreErrorHandleFields.brand.required }}
      render={() => (
        <>
          <BrandSelectDropdown
            customClass={hasError(errors, 'brand') ? 'field-has-error' : ''}
            title={
              i18n.t('related_brand_required', {
                locale: 'en',
              }) + (storeType === StoreType.LOCATION ? ' (optional)' : '')
            }
            defaultValue={{
              value: watchSelectedBrand,
              label: watchSelectedBrand?.name,
            }}
            onSelectChange={(item) => {
              focusChange('brand');
              if (item.value !== watchSelectedBrand) {
                setValue('brand', item.value, { shouldDirty: true });
              }
            }}
            addButton={{
              title: 'Create Now',
              action: () => {
                setPromtConfig({
                  rightButton: {
                    text: `Go to create Brand`,
                    action: () => {
                      setPromtConfig(null);
                      history.push({
                        pathname: '/brands/create',
                        state: {
                          from: history.location,
                          title: 'Continue to create store?',
                          content: 'You can continue to create the store.',
                        },
                      });
                    },
                  },
                  title: `Go to create a Brand?`,
                  description: `You will leave the store creation process. After you create a new Brand, you can back to this page.`,
                });
              },
              customClass: 'multiple-select-option-add btn-add-button-common',
            }}
            editable={
              !history.location.pathname?.includes('edit') || !sourceBrandName
            }
            onFocus={() => focusChange('brand')}
          />
          <label className="tips-message">Once set, cannot be changed.</label>
          {language === LanguageConfig.english ? (
            <ReactHookFormErrorMessage errors={errors} id="brand" />
          ) : null}
        </>
      )}
    />
  );
  const customBrandSection = (
    <>
      <Controller
        control={control}
        name={`storeTranslations.${language}.brandName`}
        // rules={{ required: StoreErrorHandleFields.brandName.required }}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Brand name (optional)"
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`storeTranslations.${language}.brandName`, val, {
                  shouldDirty: true,
                });
              }}
              focusChange={() => {
                focusChange('brandName');
              }}
              error={{
                id: `storeTranslations.${language}.brandName`,
                error: hasError(
                  errors,
                  `storeTranslations.${language}.brandName`,
                ),
                message: errorMessage(
                  errors,
                  `storeTranslations.${language}.brandName`,
                ),
              }}
            />
          </>
        )}
      />
      <div className={'create-section-label create-section-label-bottom-space'}>
        Brand logo image (optional)
      </div>
      <div className="second-section_description">
        Suggested Image Size: 300 px * 300 px, At least : 180 px * 180 px (Less
        than 2MB, support JPG, PNG and GIF only)
      </div>
      <Controller
        control={control}
        name={`storeTranslations.${language}.brandIcon`}
        // rules={{ required: StoreErrorHandleFields.brandIcon.required }}
        render={({ field: { value } }) => (
          <ImageUploader
            minWidth={180}
            minHeight={180}
            images={value ? [value] : []}
            disabled={
              applyBrandIconToAllLanguage &&
              language &&
              language !== LanguageConfig.english
            }
            onImageStateChange={(newState) => {
              const applyLanguages = applyBrandIconToAllLanguage
                ? languages
                : [language];
              applyLanguages.forEach((item) => {
                setValue(
                  `storeTranslations.${item?.code || item}.brandIcon`,
                  newState?.[0] || null,
                  {
                    shouldDirty: true,
                  },
                );
              });
            }}
            aspect={1 / 1}
            maxImageNum={1}
            language={language}
            uploadImageClicked={() => {}}
            errorMessage={
              hasError(errors, `storeTranslations.${language}.brandIcon`)
                ? errorMessage(
                    errors,
                    `storeTranslations.${language}.brandIcon`,
                  )
                : ''
            }
          />
        )}
      />
      <ApplySwitch
        disabled={
          language === LanguageConfig.english &&
          !(brandIcon?.value || brandIcon)
        }
        show={language === LanguageConfig.english}
        checked={applyBrandIconToAllLanguage}
        onChange={(value) => {
          setValue('applyBrandIconToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            languages.forEach((item) => {
              setValue(
                `storeTranslations.${item?.code || item}.brandIcon`,
                brandIcon,
                {
                  shouldDirty: true,
                },
              );
            });
          }
        }}
      />
    </>
  );

  const mobileNumber = (
    <Controller
      control={control}
      name={'phoneNumberSubscriberNumber'}
      render={() => (
        <CustomTitleWithDropDownAndInput
          title="Mobile number(optional)"
          source={countryCodes}
          defaultValue={watchCountryCode}
          defaultInputValue={watchMobileNumber}
          needFilter={false}
          customClass="dropdown-short"
          inputCustomClass="dropdown-input-long"
          setValue={(value) => {
            if (value !== watchCountryCode) {
              setValue('phoneNumberCountryCode', value, { shouldDirty: true });
            }
          }}
          setInputValue={(value) => {
            if (value !== watchMobileNumber) {
              setValue('phoneNumberSubscriberNumber', value, {
                shouldDirty: true,
              });
            }
          }}
          onFocus={() => {
            focusChange('phoneNumberSubscriberNumber');
          }}
          inputOnFocusChange={() => focusChange('phoneNumberSubscriberNumber')}
        />
      )}
    />
  );

  const email = (
    <>
      <CustomTitleLabel title={'Email(optional)'} />
      <input
        {...register('emailAddress', {
          validate: {
            reg: (value) => {
              if (!value) {
                return true;
              }
              return (
                EMAIL_REG.test(value) ||
                StoreErrorHandleFields.emailAddress.valid
              );
            },
          },
        })}
        type="emailAddress"
        className={`text-input-field ${
          hasError(errors, 'emailAddress') ? 'error-field' : ''
        }`}
        onFocus={() => focusChange('emailAddress')}
      />
      <ReactHookFormErrorMessage errors={errors} id="emailAddress" />
    </>
  );
  return (
    <>
      <div className="first-section-title">
        {i18n.t('general', { locale: 'en' })}
      </div>
      <Controller
        control={control}
        name={`storeTranslations.${language}.name`}
        rules={{ required: StoreErrorHandleFields.name.required }}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title={i18n.t('name', {
                locale: 'en',
              })}
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                setValue(`storeTranslations.${language}.name`, val, {
                  shouldDirty: true,
                });
              }}
              focusChange={() => {
                focusChange('name');
              }}
              error={{
                id: `storeTranslations.${language}.name`,
                error: hasError(errors, `storeTranslations.${language}.name`),
                message: errorMessage(
                  errors,
                  `storeTranslations.${language}.name`,
                ),
              }}
            />
          </>
        )}
      />
      {storeType === StoreType.LOCATION ? (
        <>
          <MissionLabelsSection
            translationField={'storeTranslations'}
            language={language}
            languages={languages}
          />
        </>
      ) : null}
      <div className={'create-section-label create-section-label-bottom-space'}>
        {i18n.t('description_optional', {
          locale: 'en',
        })}
      </div>
      <Controller
        control={control}
        name={`storeTranslations.${language}.description`}
        render={({ field: { value } }) => (
          <>
            <CustomEditor
              initialValue={value}
              onValueChange={(value) => {
                setValue(`storeTranslations.${language}.description`, value, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {
                focusChange('description');
              }}
              errorMessage={errorMessage(
                errors,
                `storeTranslations.${language}.description`,
              )}
              error={hasError(
                errors,
                `storeTranslations.${language}.description`,
              )}
            />
          </>
        )}
      />
      {language !== LanguageConfig.english || storeType === StoreType.LOCATION
        ? null
        : brandSection}
      {storeType === StoreType.LOCATION ? customBrandSection : null}
      {language !== LanguageConfig.english ? null : mobileNumber}
      {language !== LanguageConfig.english ? null : email}
      <BasePrompt
        show={!!promptConfig}
        closeAction={() => {
          setPromtConfig(null);
        }}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        title={promptConfig?.title}
        description={promptConfig?.description}
      />
    </>
  );
}
