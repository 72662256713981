import React, { useEffect } from 'react';
import './CreateCampaign.scss';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateCampaignStepOne from '../../../components/campaign/campaignCreation/CreateCampaignStepOne';
import CreateCampaignStepTwo from '../../../components/campaign/campaignCreation/CreateCampaignStepTwo';
import CreateCampaignStepThree from '../../../components/campaign/campaignCreation/CreateCampaignStepThree';
import CreateCampaignStepFour from '../../../components/campaign/campaignCreation/CreateCampaignStepFour';
import { createAction, copyCampaignLink } from '../../../utils';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import {
  CampaignType,
  APIStatus,
  PublishTagType,
} from '../../../config/CustomEnums';
import AuthButton from '../../../components/base/AuthButton';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
  sessionDataKey,
} from '../../../models/CreateCampaignModel';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CreateCampaign({
  stepConfig,
  currentStep,
  campaign = {},
  campaignPublish,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  createStatus,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignType = campaign.campaignType;
  const campaignId = campaign.pk;
  const accessCampaignCopyUrl = campaign.accessCampaignCopyUrl;
  const campaignPublished = campaign.isPublished;
  const campaignGeneralName = campaign.translations?.en?.generalName;

  const loadData = (data) => {
    if (!params.id) {
      dispatch({
        type: 'createCampaign/loadCampaignFromCookie',
        payload: data,
      });
      return;
    }
    dispatch(
      createAction('createCampaign/getOneCampaign')({
        id: params.id,
        ...data,
      }),
    );
    dispatch(
      createAction('createCampaign/getCampaignParticipationRecordsList')({
        id: params.id,
        page: 1,
      }),
    );
    dispatch(
      createAction('storeModel/getAllList')({
        isAll: true,
        isSelectorLoad: true,
        campaignIn: params.id,
        status: PublishTagType.published,
      }),
    );
  };

  const clearData = () => {
    dispatch({ type: 'createCampaign/clearData' });
    dispatch({ type: 'couponList/clearData' });
    dispatch({ type: 'storeModel/clearData' });
    dispatch({ type: 'attributeTagKey/clearData' });
    dispatch({ type: 'channel/clearState' });
    dispatch({ type: 'brand/clearState' });
    dispatch({ type: 'targetMarket/clearState' });
    dispatch({ type: 'campaignCategoryList/clearState' });
    dispatch({ type: 'countryCode/clearState' });
  };

  useEffect(() => {
    const newItem = history.location.state || {};
    clearData();
    dispatch({ type: 'channel/getAllList' });
    dispatch({
      type: 'brand/getAllList',
      payload: { params: { handleFortuneBagEntitlement: true } },
    });
    dispatch({ type: 'countryCode/getAllList' });
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    const attributePk = newItem.newAttribute?.pk;
    if (!attributePk) {
      loadData(newItem);
    } else {
      dispatch(
        createAction('attributeTagKey/getOneDetail')({
          id: attributePk,
          afterAction: (data) => {
            console.log('attributePk:', data);
            loadData({ newAttribute: data } || {});
          },
        }),
      );
    }
    return () => {
      clearData();
    };
  }, []);

  const stepSet = [
    <CreateCampaignStepOne id={params?.id} />,
    <CreateCampaignStepTwo />,
    <CreateCampaignStepThree />,
    <CreateCampaignStepFour />,
  ];

  const toCampaignList = () => {
    history.push({
      pathname: `/campaigns`,
      hash: URLFragments.list,
    });
  };

  const getButton = (name, className) => {
    return (
      <AuthButton
        title={name}
        className={className}
        action={() => {
          history.push({
            pathname: 'entitlement/',
          });
        }}
        requires={[
          PermissionCodes.viewFortunebagEntitlement,
          PermissionCodes.changeFortunebagEntitlement,
        ]}
      />
    );
  };

  const copyUrlAction = () => {
    copyCampaignLink(dispatch, campaignId);
  };

  const buttons =
    campaignPublished && campaignType === CampaignType.fortuneBagCampaign
      ? [getButton('Entitlement', 'btn-further')]
      : [];
  if (accessCampaignCopyUrl && params.id && !currentStep) {
    buttons.unshift(
      <AuthButton
        title={'Copy Campaign’s URL'}
        className={'btn-back-button-common'}
        action={copyUrlAction}
        requires={null}
      />,
    );
  }
  if (!campaignId && params.id) {
    return <Loading customClass={'common-full-height '} />;
  }

  return (
    <BaseForm
      defaultValues={campaign}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: toCampaignList,
        onClose: toCampaignList,
      }}
      showFinishPop={createStatus === APIStatus.success}
      sessionDataKey={sessionDataKey}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={
        <CustomBreadcrumb
          name={campaignId ? campaignGeneralName : 'Create Campaign'}
        />
      }
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: campaignId ? campaignGeneralName : 'Create Campaign',
      }}
      buttons={buttons}
      hasleavePagePrompt={params.id}
    />
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createCampaign.stepConfig,
  currentStep: state.createCampaign.currentStep,
  campaign: state.createCampaign.campaign,
  campaignPublish: state.createCampaign.campaignPublish,
  formHasSubmitted: state.createCampaign.formHasSubmitted,
  hasUpdatedDefaultValues: state.createCampaign.hasUpdatedDefaultValues,
  createStatus: state.createCampaign.createStatus,
});

export default connect(mapPropsToState)(CreateCampaign);
