import moment from 'moment';
import { createModel } from './BaseModel';
import {
  getMissionList,
  getMissionDetail,
  createMission,
  updateMission,
  deleteMission,
  publishMission,
  unPublishMission,
  getMissionLocations,
} from '../services/MissionApiHelper';
import { getCampaignListWithCouponStock } from '../services/CampaignAPIHelper';
import { getGoodieBagsList } from '../services/GoodieBagsApiHelper';
import { getAttributeTagList } from '../services/AttributeTagAPIHelper';
import { getImageImage, getDetailPhotos } from './CreateCampaignModel';
import { parseMissionLocationDetail } from './MissionLocationModel';
import {
  formatInformations,
  cleanInformations,
} from './GamificationCampaignModel';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  createAction,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  removeElementFromArray,
  removeFromSessionStorage,
  saveToSessionStorage,
  addDomainToImage,
} from '../utils';
import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
  PublishTagType,
  StatusTag,
} from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  TimeFormater,
  formatDate,
  checkCampaignIsExpired,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';

export const MISSION_SESSION_KEY = SESSION_KEYS.CREATE_MISSION;

export const MISSION_TYPE = {
  SPECIAL_CAMPAIGN: {
    value: 'SPECIAL_CAMPAIGN',
    label: 'Redeem specific coupons',
  },
  SPECIAL_GOODIE_BAG: {
    value: 'SPECIAL_GOODIE_BAG',
    label: 'Acquire specific goodie bag',
  },
  CHECK_IN: { value: 'CHECK_IN', label: 'Check-in' },
};

export const MISSION_CHECK_IN_MODE_TYPE = {
  GPS: { value: 'GPS', label: 'GPS check-in only' },
  QR_CODE: { value: 'QR_CODE', label: 'Scan QR code only' },
  BOTH: { value: 'BOTH', label: 'Scan QR code & GPS check-in' },
};

const stepNames = ['Core', 'Content', 'Properties', 'Preview'];

export const MISSION_LIST_FIELDS = [
  { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
  { displayName: 'Name', fieldName: 'name', orderField: 'name' },
  { displayName: 'Type', fieldName: 'displayMissionType' },
  { displayName: 'No. of tasks', fieldName: 'numberOfTasks' },
  { displayName: 'Linked gamification', fieldName: 'displayGamification' },
  {
    displayName: 'Active Period',
    fieldName: 'activePeriodList',
    orderField: 'startDate',
  },
  {
    displayName: 'Visible Period',
    fieldName: 'visiblePeriodList',
    orderField: 'displayStartDate',
  },
  {
    displayName: 'Status',
    fieldName: 'status',
  },
];

export const saveMissionToSessionStorage = (data) => {
  saveToSessionStorage(MISSION_SESSION_KEY, data);
};

export const removeMissionSessionStorage = () => {
  removeFromSessionStorage(MISSION_SESSION_KEY);
};

const getMissionStatus = (mission) => {
  if (mission.isPublished) {
    if (checkCampaignIsExpired(mission.endDate)) {
      return PublishTagType.expired;
    }
    return PublishTagType.published;
  } else {
    return PublishTagType.unPublished;
  }
};

export const getPeriodDetail = (data) => {
  if (!data) {
    return;
  }
  if (data.isAlwaysActivePeriod) {
    data.startDate = new Date();
    data.endDate = null;
  }
  const activePeriodDetail = `${formatDate(
    data.startDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.endDate, TimeFormater.dayMonthYearWeekTime)}`;

  if (data.isAlwaysVisiblePeriod) {
    data.displayStartDate = new Date();
    data.displayEndDate = null;
  }
  const visiblePeriodDetail = `${formatDate(
    data.displayStartDate,
    TimeFormater.dayMonthYearWeekTime,
  )} - ${formatDate(data.displayEndDate, TimeFormater.dayMonthYearWeekTime)}`;

  return {
    activePeriodDetail,
    visiblePeriodDetail,
  };
};

const getDisplayDate = (startDate, endDate) => {
  const startDisplay = formatDate(
    startDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  const endDisplay = formatDate(
    endDate,
    TimeFormater.dayMonthYearWeekTimeAWithComma,
  );
  return `${startDisplay} - ${endDisplay}`;
};

function translationAssemble(node, allData) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;

  const tutorials = formatInformations(allData.tutorials, language, 1);

  const data = {
    ...node,
    pk: node.pk,
    coverPhoto: allData.applyCoverPhotoToAllLanguage
      ? addDomainToImage(allData.coverPhoto)
      : addDomainToImage(node.coverPhoto),
    mobilePhoto: allData.applyCoverPhotoToAllLanguage
      ? addDomainToImage(allData.mobilePhoto)
      : addDomainToImage(node.mobilePhoto),
    detailPhotos: allData.applyDetailPhotosToAllLanguage
      ? getDetailPhotos(allData)
      : getDetailPhotos(node),
    tutorials,
  };
  return {
    [language]: data,
  };
}

const parseTranslations = (data) => {
  const enTranslation = translationAssemble(data, data) || {};
  const translations = data?.translations?.edges?.map((item) => {
    const translation = translationAssemble(item.node, data) || {};
    return translation;
  });
  const reducedTranslation = translations?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});

  return {
    ...enTranslation,
    ...reducedTranslation,
  };
};

export const parseMission = (data) => {
  if (!data) {
    return;
  }
  if (Object.keys(data)?.length === 2) {
    // only have pk, name
    return {
      pk: data.pk,
      name: data.name,
      label: `[ID:${data.pk}] ${data.name}`,
    };
  }

  const translations = parseTranslations(data);

  const linkedGamificationCampaign =
    data.missions?.gamificationCampaign || null;

  let startDate = data.startDate;
  let endDate = data.endDate;
  let displayStartDate = data.displayStartDate;
  let displayEndDate = data.displayEndDate;
  if (
    data.isFollowGamificationCampaignActivePeriod &&
    linkedGamificationCampaign
  ) {
    startDate = linkedGamificationCampaign.startDate;
    endDate = linkedGamificationCampaign.endDate;
  }
  if (
    data.isFollowGamificationCampaignVisiblePeriod &&
    linkedGamificationCampaign
  ) {
    displayStartDate = linkedGamificationCampaign.displayStartDate;
    displayEndDate = linkedGamificationCampaign.displayEndDate;
  }

  const startDateWithFormat =
    formatDate(startDate, TimeFormater.dayMonthWeek) || '-';
  const endDateWithFormat =
    formatDate(endDate, TimeFormater.dayMonthWeek) || '-';
  const displayStartDateWithFormat =
    formatDate(displayStartDate, TimeFormater.dayMonthWeek) || '-';
  const displayEndDateWithFormat =
    formatDate(displayEndDate, TimeFormater.dayMonthWeek) || '-';
  const periodDetail = getPeriodDetail(data);

  let numberOfTasks = 0;
  if (data.missionType === MISSION_TYPE.SPECIAL_CAMPAIGN.value) {
    numberOfTasks = data.numberOfCampaigns;
  } else if (data.missionType === MISSION_TYPE.SPECIAL_GOODIE_BAG.value) {
    numberOfTasks = data.numberOfGoodieBags;
  } else if (data.missionType === MISSION_TYPE.CHECK_IN.value) {
    numberOfTasks = data.numberOfLocations;
  }

  return {
    ...data,
    label: `[${data.pk}] ${data.name}`,
    translations,
    missionType: MISSION_TYPE[data.missionType],
    displayMissionType: MISSION_TYPE[data.missionType]?.label,
    checkInMode: data.checkInMode,
    displayCheckInMode: MISSION_CHECK_IN_MODE_TYPE[data.checkInMode]?.label,
    startDate,
    endDate,
    displayStartDate,
    displayEndDate,
    activePeriod: data?.endDate
      ? `${formatDate(
          startDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(endDate, TimeFormater.dayMonthYearWeekTimeA)}`
      : 'All the time',
    visiblePeriod: displayEndDate
      ? `${formatDate(
          displayStartDate,
          TimeFormater.dayMonthYearWeekTimeA,
        )} - ${formatDate(displayEndDate, TimeFormater.dayMonthYearWeekTimeA)}`
      : 'All the time',
    activePeriodList: `From ${startDateWithFormat} to ${endDateWithFormat}`,
    visiblePeriodList: `From ${displayStartDateWithFormat} to ${displayEndDateWithFormat}`,
    createAt: formatDate(data.creationDate, TimeFormater.dayMonthYearWeekTime),
    lastModified: formatDate(
      data.lastModifiedDate,
      TimeFormater.dayMonthYearWeekTime,
    ),
    displayStatus: data.isPublished ? 'Published' : 'Unpublished',
    ...periodDetail,
    isAlwaysActivePeriod: !endDate,
    isAlwaysVisiblePeriod: !displayEndDate,
    status: getMissionStatus(data),
    numberOfTasks,
    displayGamification: linkedGamificationCampaign
      ? `[ID: ${linkedGamificationCampaign.pk}] ${linkedGamificationCampaign.name}`
      : '-',
    attributeTags: data.attributeTags?.edges.map((item) => item.node),
    workingTeams: data.workingTeams?.edges?.map((item) => item.node),
    tutorialsKeys: [...Array(data.tutorials?.edges?.length || 1).keys()],
    maxNumberOfStampEarningLimit: data.perHeadLimit,
    linkedGamificationCampaign,
  };
};

const cleanMissionTranslation = (translations, data) => {
  const cleanedTranslations = [];
  console.log('@107', translations);
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      name: translation.name,

      coverPhoto: getImageImage(translation.coverPhoto) || null,
      mobilePhoto: getImageImage(translation.mobilePhoto) || null,
      detailPhoto1: getImageImage(translation.detailPhotos?.[0]) || null,
      detailPhoto2: getImageImage(translation.detailPhotos?.[1]) || null,
      detailPhoto3: getImageImage(translation.detailPhotos?.[2]) || null,
      detailPhoto4: getImageImage(translation.detailPhotos?.[3]) || null,

      shortDescription: translation.shortDescription,
      tutorialTitle: translation.tutorialTitle,
      tutorialContent: translation.tutorialContent,
      statementWordingForStampLimit: data.showStatementWordingForStampLimit
        ? translation.statementWordingForStampLimit
        : null,
    };

    const tutorials = Object.keys(translation.tutorials || {})
      .sort()
      .filter((key) => !translation.tutorials[key].isDeleted)
      .map((key) => {
        const item = translation.tutorials[key];
        return {
          id: item.pk || null,
          image: getImageImage(item.image) || null,
          content: item.content || null,
        };
      });
    cleanedOneTranslation['tutorials'] = tutorials;

    if (translation?.pk) {
      cleanedOneTranslation.id = translation?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

const cleanMission = (data) => {
  const enData = data.translations[LanguageConfig.english];
  const translations = cleanMissionTranslation(data.translations, data);

  const tutorials = cleanInformations(
    enData.tutorials || {},
    translations,
    'tutorials',
  );

  const payload = {
    name: enData.name,

    coverPhoto: getImageImage(enData.coverPhoto) || null,
    mobilePhoto: getImageImage(enData.mobilePhoto) || null,
    detailPhoto1: getImageImage(enData.detailPhotos?.[0]) || null,
    detailPhoto2: getImageImage(enData.detailPhotos?.[1]) || null,
    detailPhoto3: getImageImage(enData.detailPhotos?.[2]) || null,
    detailPhoto4: getImageImage(enData.detailPhotos?.[3]) || null,
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,

    shortDescription: enData.shortDescription,
    tutorialTitle: enData.tutorialTitle,
    tutorialContent: enData.tutorialContent,
    tutorials,

    isFollowGamificationCampaignActivePeriod:
      data.isFollowGamificationCampaignActivePeriod,
    startDate: data.startDate,
    endDate: data.endDate,
    isFollowGamificationCampaignVisiblePeriod:
      data.isFollowGamificationCampaignVisiblePeriod,
    displayStartDate: data.displayStartDate,
    displayEndDate: data.displayEndDate,

    missionType: data.missionType?.value,
    checkInMode: data.checkInMode,
    campaigns:
      (data.missionType?.value === MISSION_TYPE.SPECIAL_CAMPAIGN.value
        ? data.campaigns?.map((item) => {
            return item.pk;
          })
        : null) || [],
    goodieBags:
      (data.missionType?.value === MISSION_TYPE.SPECIAL_GOODIE_BAG.value
        ? data.goodieBags?.map((item) => {
            return item.pk;
          })
        : null) || [],
    missionLocations:
      (data.missionType?.value === MISSION_TYPE.CHECK_IN.value
        ? data.missionLocations?.map((item) => {
            return {
              location: item.locationForMissoin?.pk,
            };
          })
        : null) || [],

    perActionStampRewardQuantity: data.perActionStampRewardQuantity,
    perHeadLimit: data.perHeadLimit || null,
    showStatementWordingForStampLimit:
      data.showStatementWordingForStampLimit || false,
    statementWordingForStampLimit: enData.statementWordingForStampLimit,
    hideOutOfStockCampaign: data.hideOutOfStockCampaign,

    attributeTags: data.attributeTags?.map((item) => item.pk),
    workingTeams: data.workingTeams?.map((item) => item.pk),
    isPublished: data.isPublished,

    translations,
  };

  if (
    data.isAlwaysActivePeriod ||
    data.isFollowGamificationCampaignActivePeriod
  ) {
    payload.startDate = new Date();
    payload.endDate = null;
  }
  if (
    data.isAlwaysVisiblePeriod ||
    data.isFollowGamificationCampaignVisiblePeriod
  ) {
    payload.displayStartDate = new Date();
    payload.displayEndDate = null;
  }

  if (data?.pk) {
    payload.id = data?.pk;
  }
  console.log('@453', payload);
  return payload;
};

const parseMissionList = (data) =>
  data?.missions?.edges?.map((item) => parseMission(item?.node));

const parseCampaignDetail = (node) => {
  const {
    quantityOfGenerate,
    quantityOfCouponGenerate,
    quantityOfPhysicalCouponGenerate,
    quantityOfRemaining,
    quantityOfAcquired,
    quantityOfUsed,
    quantityOfPhysicalCouponUsed,
    quantityOfLeft,
  } = node?.couponCampaignInfo || {};
  return {
    pk: node.pk,
    name: node.name,
    label: `[ID:${node.pk}] ${node.name}`,
    couponStock: node.couponCampaignTypeCouponTemplate?.stock,
    couponUsedAndAcquired: node.couponCampaignInfo
      ? `${
          (quantityOfUsed || 0) + (quantityOfPhysicalCouponUsed || 0) || '-'
        } / ${quantityOfAcquired || '-'} / ${quantityOfGenerate || '-'} (${
          quantityOfCouponGenerate || '-'
        } | ${quantityOfPhysicalCouponGenerate || '-'})`
      : '-',
    displayActivePeriod: getCampignListDisplayTime(
      node.endDate ? node.startDate : node.creationDate,
      node.endDate,
    ),
    displayVisiblePeriod: getCampignListDisplayTime(
      node.displayEndDate ? node.displayStartDate : node.creationDate,
      node.displayEndDate,
    ),
    isCheckGps: node.isCheckGps,
    couponIsCheckGps: node.couponCampaignTypeCouponTemplate?.isCheckGps,
  };
};

const parseGoodieBagDetail = (node) => {
  const startDateWithFormat =
    formatDate(node.startDate, TimeFormater.dayMonthWeek) || '-';
  const endDateWithFormat =
    formatDate(node.endDate, TimeFormater.dayMonthWeek) || '-';
  const displayStartDateWithFormat =
    formatDate(node.displayStartDate, TimeFormater.dayMonthWeek) || '-';
  const displayEndDateWithFormat =
    formatDate(node.displayEndDate, TimeFormater.dayMonthWeek) || '-';

  return {
    pk: node.pk,
    name: node.name,
    label: `[ID:${node.pk}] ${node.name}`,
    minCampaignStock: node.minCampaignStock,
    perHeadLimit: node.perHeadLimit,
    activePeriodList: `From ${startDateWithFormat} to ${endDateWithFormat}`,
    visiblePeriodList: `From ${displayStartDateWithFormat} to ${displayEndDateWithFormat}`,
  };
};

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  detail: {
    tutorialsKeys: [...Array(1).keys()],
  },
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  apiStatus: APIStatus.none,
  createStatus: APIStatus.none,
});

export default createModel({
  namespace: 'mission',
  params: {
    dataKey: 'missions',
    listAPI: getMissionList,
    pkNode: 'MissionNode',
    detailAPI: getMissionDetail,
    parse: parseMissionList,
    parseDetail: (data) => parseMission(data.mission),
    deleteAPI: deleteMission,
    objectKey: 'mission',
  },
  states: getInitialState(),
  reducers: {
    updateMission(state, { payload }) {
      console.log('updateMission: ', payload);
      const detail = {
        ...state.detail,
        ...payload,
      };
      return { ...state, detail: detail };
    },

    clearData(state, { payload }) {
      const newState = {
        ...state,
        ...getInitialState(),
      };
      return newState;
    },

    loadMissionFromCookie(state, { payload }) {
      const mission = getObjectFromSessionStorage(MISSION_SESSION_KEY);
      console.log('@147', mission);
      if (!mission) {
        return {
          ...state,
          detail: getInitialState().detail,
          hasUpdatedDefaultValues: true,
        };
      }
      return {
        ...state,
        detail: {
          ...mission,
        },
        hasUpdatedDefaultValues: true,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },
  },
  effects: {
    getOneMission: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const id = convertPKToId('MissionNode', payload.id);
        const serviceArgs = [getMissionDetail, id];
        function* onSuccess(data) {
          const detail = parseMission(data.mission);

          afterAction();
          const puts = [
            put({
              type: 'updateState',
              payload: {
                detail: detail,
                hasUpdatedDefaultValues: true,
                selectedAllListLoading: false,
              },
            }),
            put({
              type: 'updateProperty',
              payload: {
                missionIn: [payload.id],
                pageSize: 100,
                page: 1,
                stateName: 'detail',
                dataKey: 'attributeTags',
                updateKey: 'attributeTags',
                updateApi: getAttributeTagList,
              },
            }),
          ];
          if (
            detail.missionType?.value === MISSION_TYPE.SPECIAL_CAMPAIGN.value
          ) {
            puts.push(
              put({
                type: 'updateProperty',
                payload: {
                  others: {
                    getAll: true,
                    isAll: true,
                    needQuantity: true,
                    missionIn: [payload.id],
                  },
                  useStock: true,
                  pageSize: 100,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'campaigns',
                  dataKey: 'campaignsWithCouponStock',
                  updateApi: getCampaignListWithCouponStock,
                  parseDetail: parseCampaignDetail,
                },
              }),
            );
          } else if (
            detail.missionType?.value === MISSION_TYPE.SPECIAL_GOODIE_BAG.value
          ) {
            puts.push(
              put({
                type: 'updateProperty',
                payload: {
                  missionIn: [payload.id],
                  needQuantity: true,
                  isAll: true,
                  pageSize: 100,
                  page: 1,
                  stateName: 'detail',
                  updateKey: 'goodieBags',
                  dataKey: 'goodieBags',
                  updateApi: getGoodieBagsList,
                  parseDetail: parseGoodieBagDetail,
                },
              }),
            );
          } else if (
            detail.missionType?.value === MISSION_TYPE.CHECK_IN.value
          ) {
            puts.push(
              put({
                type: 'updateProperty',
                payload: {
                  missionIn: [payload.id],
                  isAll: true,
                  pageSize: 1000,
                  page: 1,
                  reverse: true,
                  stateName: 'detail',
                  updateKey: 'missionLocations',
                  dataKey: 'missionLocations',
                  updateApi: getMissionLocations,
                  parseDetail: parseMissionLocationDetail,
                },
              }),
            );
          }
          yield all(puts);
        }

        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateMission: [
      function* ({ payload }, { all, select, put }) {
        const { isPublished, data, afterAction, isOptionD } = payload;
        console.log('@@1034: ', payload.data);

        const serviceArgs = [
          data?.pk ? updateMission : createMission,
          {
            ...cleanMission(data),
          },
        ];

        console.log('@@803: ', data);
        yield put(
          createAction('updateState')({
            createStatus: APIStatus.calling,
          }),
        );

        function* onSuccess(successData) {
          removeMissionSessionStorage();
          const mission =
            successData?.[`${data?.pk ? 'update' : 'create'}Mission`]?.node;

          if (!mission) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateMission',
              payload: { id: mission.id, pk: mission.pk },
            }),
          ]);

          afterAction &&
            typeof afterAction === 'function' &&
            afterAction(mission.pk);
        }

        function* onError(err) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    publish: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const missionPk = payload.missionPk;
        let serviceArgs = [unPublishMission, { id: missionPk }];
        console.log('@@309: ', payload);
        if (!payload.isPublished) {
          serviceArgs = [
            publishMission,
            {
              id: missionPk,
            },
          ];
        }

        function* onSuccess(data) {
          console.log('@@309: ', payload.isPublished);
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.success,
              },
            }),
            put({
              type: 'updateMission',
              payload: { isPublished: payload.isPublished },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put(
            createAction('updateState')({
              createStatus: APIStatus.failed,
            }),
          );
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
