import { BaseHelper } from './BaseGraphQLAPIHelper';

const getGamificationCampaignNode = ({
  isList,
  isSimplify,
  isAll,
  callFromEntitlement,
}) => {
  if (isSimplify) {
    return `{
      pk
      name
    }`;
  }
  if (callFromEntitlement) {
    return `{
      id
      pk
      name
      displayStartDate
      displayEndDate
      isPublic
      entitlementType
    }`;
  }

  return `{
    creationDate
    lastModifiedDate
    language
    id
    pk
    name
    isPublic
    entitlementType
    isRequireLandingPage
    stampCount
    stampCardResetLimit
    startDate
    endDate
    displayStartDate
    displayEndDate
    isPublished
    numberOfMissions
    numberOfActiveMissions
    numberOfParticipatingCustomers
    numberOfParticipatingTimes
    ${
      isList
        ? ``
        : `
      themeColor
      shortDescription
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      tutorialTitle
      tutorialContent
      rewardSectionTitle
      rewardSectionDescription
      missionSectionTitle
      missionSectionDescription
      missionCardTitle
      missionCardSubtitle
      missionCardOrientation
      showMissionLabels
      missionLabels

      applyCoverPhotoToAllLanguage
      coverPhoto
      mobilePhoto
      applyCoverPhotoForTaskListToAllLanguage
      coverPhotoForTaskList
      mobilePhotoForTaskList
      applyDetailPhotosToAllLanguage
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      detailMobilePhoto1
      detailMobilePhoto2
      detailMobilePhoto3
      detailMobilePhoto4
      stampIcon
      stampIconCollected
      rewardIcon
      rewardIconCollected
      applyCoverPhotoForMissionSectionToAllLanguage
      coverPhotoForMissionSection
      mobilePhotoForMissionSection

      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content
              pk
            }
          }
        }
      }
      isFollowGenericTermsAndConditionsTemplate
      otherInformations(first: 100, orderBy: "index") {
        edges {
          node {
            pk
            title
            applyImageToAllLanguage
            image
            content
            translations {
              edges {
                node {
                  pk
                  language
                  title
                  image
                  content
                }
              }
            }
          }
        }
      }
      tutorials(first: 100, orderBy: "index") {
        edges {
          node {
            pk
            applyImageToAllLanguage
            image
            content
            translations {
              edges {
                node {
                  pk
                  language
                  image
                  content
                }
              }
            }
          }
        }
      }
      rewardInformations(first: 100, orderBy: "index") {
        edges {
          node {
            pk
            title
            applyImageToAllLanguage
            image
            content
            translations {
              edges {
                node {
                  pk
                  language
                  title
                  image
                  content
                }
              }
            }
          }
        }
      }

      publishChannels {
        edges {
          node {
            pk
            name
          }
        }
      }
      targetMarkets {
        edges {
          node {
            pk
            name
            channels {
              pk
              name
            }
            languages {
              edges {
                node {
                  pk
                  name
                  parameter
                  code
                }
              }
            }
          }
        }
      }
      workingTeams {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    `
    }
    ${
      isList
        ? ``
        : `
      translations {
        edges {
          node {
            pk
            language
            name
            shortDescription
            shortDescription
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            tutorialTitle
            tutorialContent
            rewardSectionTitle
            rewardSectionDescription
            missionSectionTitle
            missionSectionDescription
            missionCardTitle
            missionCardSubtitle
            missionLabels

            coverPhoto
            mobilePhoto
            coverPhotoForTaskList
            mobilePhotoForTaskList
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            detailMobilePhoto1
            detailMobilePhoto2
            detailMobilePhoto3
            detailMobilePhoto4
            coverPhotoForMissionSection
            mobilePhotoForMissionSection
          }
        }
      }
    `
    }
  }`;
};

export const getGamificationCampaignList = (afterCursor, others) => {
  let filter = '';
  console.log('@68', afterCursor, others);
  const {
    fromList,
    filterName,
    sort,
    status,
    status_in,
    active_period,
    visible_period,
    create_at,
    last_modified,
    search,
    isSimplify,
    isAll,
    entitlement_type,
    landing_page,
  } = others || {};
  if (fromList) {
    filter += `, withQuery: true`;
  }
  if (search) {
    filter += `, search:"${search}"`;
  }
  if (filterName) {
    filter += `, nameIcontains:"${filterName}"`;
  }
  if (sort) {
    filter += `, orderBy: "${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (entitlement_type) {
    if (entitlement_type === 'public') {
      filter += `, isPublic: true`;
    } else {
      filter += `, entitlementType:"${entitlement_type}"`;
    }
  }
  if (landing_page) {
    filter += `, isRequireLandingPage: ${landing_page}`;
  }
  if (status) {
    if (status === 'inactive') {
      filter += `, isExpired: true`;
    } else if (status === 'unexpired') {
      filter += `, isExpired: false`;
    } else {
      filter += `, isPublished: ${status}`;
    }
  }
  if (status_in) {
    let statusInValue = status_in;
    if (status_in === 'true') {
      statusInValue = 'upcoming,expired,active';
    } else if (status_in === 'false') {
      statusInValue = 'inactive';
    }
    filter += `, statusIn: "${statusInValue}"`;
  }
  if (active_period) {
    filter += `, activeTimeRange: "${active_period}"`;
  }
  if (visible_period) {
    const displayStartDateGte = visible_period?.split(',')?.[0];
    const displayEndDateLte = visible_period?.split(',')?.[1];
    filter += `, displayStartDateGte: "${displayStartDateGte}", displayEndDateLte: "${displayEndDateLte}"`;
  }
  if (create_at) {
    const creationDateGte = create_at?.split(',')?.[0];
    const creationDateLte = create_at?.split(',')?.[1];
    if (creationDateGte && creationDateLte) {
      filter += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
    }
  }
  if (last_modified) {
    const lastModifiedDateGte = last_modified?.split(',')?.[0];
    const lastModifiedDateLte = last_modified?.split(',')?.[1];
    if (lastModifiedDateGte && lastModifiedDateLte) {
      filter += `, lastModifiedDateGte: "${lastModifiedDateGte}", lastModifiedDateLte: "${lastModifiedDateLte}"`;
    }
  }

  const query = `{
    gamificationCampaigns(first: ${
      isAll ? others.pageSize : 20
    }, after:"${afterCursor}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${getGamificationCampaignNode({
          isList: true,
          isSimplify,
          isAll,
          needQuantity: others?.needQuantity,
        })}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGamificationCampaignDetail = (id, callFromEntitlement) => {
  const query = `{
    gamificationCampaign(id: "${id}")
      ${getGamificationCampaignNode({ callFromEntitlement })}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createGamificationCampaign = (payload) => {
  console.log('@198', payload);
  const query = `mutation createGamificationCampaign($input: CreateGamificationCampaignInput!) {
    createGamificationCampaign(input: $input) {
      node ${getGamificationCampaignNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGamificationCampaign = (payload) => {
  console.log('@216', payload);
  const query = `mutation updateGamificationCampaign($input: UpdateGamificationCampaignInput!) {
    updateGamificationCampaign(input: $input) {
      node ${getGamificationCampaignNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGamificationCampaign = (ids) => {
  console.log('@@251', ids);
  const query = `mutation deleteGamificationCampaigns($input: DeleteGamificationCampaignsInput!) {
    deleteGamificationCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishGamificationCampaign = (input) => {
  const query = `mutation PublishGamificationCampaign($input: PublishGamificationCampaignInput!) {
    publishGamificationCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishGamificationCampaign = (input) => {
  const query = `mutation UnpublishGamificationCampaign($input: UnpublishGamificationCampaignInput!) {
    unpublishGamificationCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createGamificationCampaignBatch = (payload) => {
  console.log('@216', payload);
  const query = `mutation createGamificationCampaignBatch($input: CreateGamificationCampaignBatchInput!) {
    createGamificationCampaignBatch(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGamificationCampaignBatch = (payload) => {
  console.log('@216', payload);
  const query = `mutation updateGamificationCampaignBatch($input: UpdateGamificationCampaignBatchInput!) {
    updateGamificationCampaignBatch(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStampRewardList = (afterCursor, others) => {
  let filter = '';
  console.log('@68', afterCursor, others);
  const { isAll, gamificationCampaignIn } = others || {};
  if (gamificationCampaignIn) {
    filter += `, gamificationCampaignIn: "${gamificationCampaignIn}"`;
  }

  const query = `{
    stampRewards(first: ${
      isAll ? others.pageSize : 20
    }, after:"${afterCursor}", orderBy: "index"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          pk
          index
          rewardType
          couponTemplate {
            pk
            name
            stock
          }
          goodieBag {
            pk
            name
          }
          quantity
          rewardIcon
          rewardIconCollected
          prizeTitle
          prizeContent
          prizeCategories
          applyCoverPhotoForPrizeToAllLanguage
          coverPhotoForPrize
          applyMobilePhotoForPrizeToAllLanguage
          mobilePhotoForPrize
          translations {
            edges {
              node {
                pk
                language
                prizeTitle
                prizeContent
                prizeCategories
                coverPhotoForPrize
                mobilePhotoForPrize
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getLinkedMissions = (afterCursor, others) => {
  let filter = '';
  console.log('@68', afterCursor, others);
  const { isAll, gamificationCampaignIn } = others || {};
  if (gamificationCampaignIn) {
    filter += `, gamificationCampaignIn: "${gamificationCampaignIn}"`;
  }

  const query = `{
    gamificationCampaignMissions(first: ${
      isAll ? others.pageSize : 20
    }, after:"${afterCursor}", orderBy: "displayPriority"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          pk
          displayPriority
          mission {
            id
            pk
            name
            missionType
            checkInMode
            startDate
            endDate
            displayStartDate
            displayEndDate
            isPublished
            perActionStampRewardQuantity
            perHeadLimit
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGamificationCampaignParticipationRecordsCount = (filter) => {
  const {
    gamificationCampaignIn,
    filterName,
    filterConditions,
    activeTimeRange,
  } = filter;
  let searchString = '';
  if (gamificationCampaignIn) {
    searchString += `, gamificationCampaignIn: "${gamificationCampaignIn}"`;
  }

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (filterConditions) {
    searchString += `, filterConditions: "${filterConditions}"`;
  }

  if (activeTimeRange) {
    const creationDateGte = activeTimeRange?.split(',')?.[0];
    const creationDateLte = activeTimeRange?.split(',')?.[1];
    searchString += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
  }

  const query = `{
    gamificationCampaignParticipationRecords(first: 1000 ${searchString} ) {
      totalCount
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

const GamificationCampaignParticipationRecordNode = `
pk
gamificationCampaign {
  pk
  name
  isPublic
  entitlementType
  displayStartDate
  displayEndDate
  startDate
  endDate
}
trackingCode {
  pk
  trackingCode
}
creationDate
`;

export const getCustomerGamificationCampaignParticipationRecords = (
  afterCursor,
  filter,
) => {
  const { filterName, customer } = filter;
  let searchString = '';

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (customer) {
    searchString += `, customer:"${customer}"`;
  }

  const query = `{
    gamificationCampaignParticipationRecords(first: 20, after: "${afterCursor}", orderBy:"-pk" ${searchString} ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${GamificationCampaignParticipationRecordNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCustomerOneGamificationCampaignParticipationRecord = (id) => {
  const query = `{
    gamificationCampaignParticipationRecord(id: "${id}" ) {
      ${GamificationCampaignParticipationRecordNode}
      customer {
        membershipId
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getMissionLocationParticipationRecordsCount = (filter) => {
  const {
    gamificationCampaignIn,
    filterName,
    filterConditions,
    activeTimeRange,
  } = filter;
  let searchString = '';
  if (gamificationCampaignIn) {
    searchString += `, gamificationCampaignIn: "${gamificationCampaignIn}"`;
  }

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (filterConditions) {
    searchString += `, filterConditions: "${filterConditions}"`;
  }

  if (activeTimeRange) {
    const creationDateGte = activeTimeRange?.split(',')?.[0];
    const creationDateLte = activeTimeRange?.split(',')?.[1];
    searchString += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
  }

  const query = `{
    missionLocationParticipationRecords(first: 1000 ${searchString} ) {
      totalCount
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

const MissionLocationParticipationRecordNode = `
pk
gamificationCampaign {
  pk
  name
  isPublic
  entitlementType
  displayStartDate
  displayEndDate
  startDate
  endDate
}
missionLocation {
  pk
  location {
    pk
    name
  }
}
creationDate
`;

export const getCustomerMissionLocationParticipationRecords = (
  afterCursor,
  filter,
) => {
  const { filterName, customer } = filter;
  let searchString = '';

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (customer) {
    searchString += `, customer:"${customer}"`;
  }

  const query = `{
    missionLocationParticipationRecords(first: 20, after: "${afterCursor}", orderBy:"-pk" ${searchString} ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${MissionLocationParticipationRecordNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCustomerOneMissionLocationParticipationRecord = (id) => {
  const query = `{
    missionLocationParticipationRecord(id: "${id}" ) {
      ${MissionLocationParticipationRecordNode}
      customer {
        membershipId
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGamificationCampaignRewardParticipationRecordsCount = (
  filter,
) => {
  const {
    gamificationCampaignIn,
    filterName,
    filterConditions,
    activeTimeRange,
  } = filter;
  let searchString = '';
  if (gamificationCampaignIn) {
    searchString += `, gamificationCampaignIn: "${gamificationCampaignIn}"`;
  }

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (filterConditions) {
    searchString += `, filterConditions: "${filterConditions}"`;
  }

  if (activeTimeRange) {
    const creationDateGte = activeTimeRange?.split(',')?.[0];
    const creationDateLte = activeTimeRange?.split(',')?.[1];
    searchString += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
  }

  const query = `{
    gamificationCampaignRewardParticipationRecords(first: 1000 ${searchString} ) {
      totalCount
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

const GamificationCampaignRewardParticipationRecordNode = `
pk
gamificationCampaign {
  pk
  name
  isPublic
  entitlementType
  displayStartDate
  displayEndDate
  startDate
  endDate
}
isStamp
quantity
stampReward {
  pk
}
creationDate
`;

export const getCustomerGamificationCampaignRewardParticipationRecords = (
  afterCursor,
  filter,
) => {
  const { filterName, customer } = filter;
  let searchString = '';

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (customer) {
    searchString += `, customer:"${customer}"`;
  }

  const query = `{
    gamificationCampaignRewardParticipationRecords(first: 20, after: "${afterCursor}", orderBy:"-pk" ${searchString} ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${GamificationCampaignRewardParticipationRecordNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCustomerOneGamificationCampaignRewardParticipationRecord = (
  id,
) => {
  const query = `{
    gamificationCampaignRewardParticipationRecord(id: "${id}" ) {
      ${GamificationCampaignRewardParticipationRecordNode}
      customer {
        membershipId
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
