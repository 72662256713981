import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import BasePrompt from '../base/prompt/BasePrompt';
import PromptButton from '../base/prompt/BaseButtons';
import AuthButton from '../base/AuthButton';
import vertical1 from '../../assets/images/gamificationCampaign/vertical1.png';
import vertical2 from '../../assets/images/gamificationCampaign/vertical2.png';
import horizontal1 from '../../assets/images/gamificationCampaign/horizontal1.png';
import horizontal2Short1 from '../../assets/images/gamificationCampaign/horizontal2-short1.png';
import horizontal2Short2 from '../../assets/images/gamificationCampaign/horizontal2-short2.png';
import horizontal2Middle1 from '../../assets/images/gamificationCampaign/horizontal2-middle1.png';
import horizontal2Middle2 from '../../assets/images/gamificationCampaign/horizontal2-middle2.png';
import horizontal2Tall1 from '../../assets/images/gamificationCampaign/horizontal2-tall1.png';
import horizontal2Tall2 from '../../assets/images/gamificationCampaign/horizontal2-tall2.png';

const TabFragments = {
  vertical: 'Vertical card',
  horizontal1: 'Horizontal card = 1 card',
  horizontal2: 'Horizontal card > 1 card',
};

function getCardSection(title, descCompenent, imageUrl) {
  return (
    <div className="mission-card-container">
      <div className="card-head-container">
        <div className="title">{title}</div>
        {descCompenent}
      </div>
      <img src={imageUrl} />
    </div>
  );
}

function VerticalSection() {
  const DescCompenent2 = () => {
    return (
      <>
        <ul>
          <li>Width: 1024px for all cards</li>
          <li>
            Height:
            <ul>
              <li>3~6 cards = 654px + 1 * 483px</li>
              <li>6~9 cards = 654px + 2 * 483px</li>
              <li>And so on</li>
            </ul>
          </li>
        </ul>
      </>
    );
  };
  return (
    <>
      <div className="mission-cards-container">
        {getCardSection('1~3 cardS', null, vertical1)}
        {getCardSection('3~6 cardS', <DescCompenent2 />, vertical2)}
      </div>
    </>
  );
}

function Horizontal1Section() {
  return (
    <>
      <div className="mission-cards-container">
        {}
        {getCardSection('1 card', null, horizontal1)}
      </div>
    </>
  );
}

function Horizontal2Section() {
  const ButtonTypes = {
    short: 'short',
    middle: 'middle',
    tall: 'tall',
  };
  const [activeButton, setActiveButton] = useState(ButtonTypes.short);

  const ShortDescCompenent = () => {
    return (
      <>
        <ul>
          <li>Width: 1024px for all cards</li>
          <li>
            Height:
            <ul>
              <li>3~4 cards = 368px + 1 * 197px</li>
              <li>5~6 cards = 368px + 2 * 197px</li>
              <li>And so on</li>
            </ul>
          </li>
        </ul>
      </>
    );
  };
  const MiddleDescCompenent = () => {
    return (
      <>
        <ul>
          <li>Width: 1024px for all cards</li>
          <li>
            Height:
            <ul>
              <li>3~4 cards = 401px + 1 * 230px</li>
              <li>5~6 cards = 401px + 2 * 230px</li>
              <li>And so on</li>
            </ul>
          </li>
        </ul>
      </>
    );
  };
  const TallDescCompenent = () => {
    return (
      <>
        <ul>
          <li>Width: 1024px for all cards</li>
          <li>
            Height:
            <ul>
              <li>3~4 cards = 418px + 1 * 247px</li>
              <li>5~6 cards = 418px + 2 * 247px</li>
              <li>And so on</li>
            </ul>
          </li>
        </ul>
      </>
    );
  };

  return (
    <>
      <div className="buttons-container">
        <AuthButton
          title={'Short (no stamp limit statement)'}
          className={
            activeButton === ButtonTypes.short
              ? 'btn-further'
              : 'btn-back-button-common'
          }
          action={() => {
            setActiveButton(ButtonTypes.short);
          }}
        />
        <AuthButton
          title={'Middle (1 line statement)'}
          className={
            activeButton === ButtonTypes.middle
              ? 'btn-further'
              : 'btn-back-button-common'
          }
          action={() => {
            setActiveButton(ButtonTypes.middle);
          }}
        />
        <AuthButton
          title={'Tall (2 lines statement)'}
          className={
            activeButton === ButtonTypes.tall
              ? 'btn-further'
              : 'btn-back-button-common'
          }
          action={() => {
            setActiveButton(ButtonTypes.tall);
          }}
        />
      </div>
      <div className="mission-cards-container">
        {activeButton === ButtonTypes.short ? (
          <>
            {getCardSection('2 cardS', null, horizontal2Short1)}
            {getCardSection(
              '3～4 cardS',
              <ShortDescCompenent />,
              horizontal2Short2,
            )}
          </>
        ) : null}
        {activeButton === ButtonTypes.middle ? (
          <>
            {getCardSection('2 cardS', null, horizontal2Middle1)}
            {getCardSection(
              '3～4 cardS',
              <MiddleDescCompenent />,
              horizontal2Middle2,
            )}
          </>
        ) : null}
        {activeButton === ButtonTypes.tall ? (
          <>
            {getCardSection('2 cardS', null, horizontal2Tall1)}
            {getCardSection(
              '3～4 cardS',
              <TallDescCompenent />,
              horizontal2Tall2,
            )}
          </>
        ) : null}
      </div>
    </>
  );
}

function MissionSectionImageReference({ show, onClose = () => {} }) {
  const getBody = () => {
    var tabs = [
      {
        key: TabFragments.vertical,
        title: TabFragments.vertical,
        container: [<VerticalSection />],
      },
      {
        key: TabFragments.horizontal1,
        title: TabFragments.horizontal1,
        container: [<Horizontal1Section />],
      },
      {
        key: TabFragments.horizontal2,
        title: TabFragments.horizontal2,
        container: [<Horizontal2Section />],
      },
    ];
    return (
      <>
        <div className="all-list-table-content">
          <ContentSections
            defaultTab={TabFragments.vertical}
            fieldsError={0}
            sections={[]}
            languageTabContent={{
              containers: tabs,
            }}
            hidePreview
          />
        </div>
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      customClass={'mission-section-image-reference-prompt'}
      closeAction={onClose}
      title={'Mission section image for desktop reference'}
      otherBody={getBody}
    />
  );
}

export default MissionSectionImageReference;
