import axios from 'axios';
import { GOOGLE_API_KEY } from '../utils';

const CMS_BASE_URL =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com:443';
const UPLOAD_FILE_PATH = '/files';

const GoogleAPIinstance = axios.create({
  baseURL: 'https://maps.googleapis.com',
  timeout: 10000,
  headers: {
    // Authorization:
    //   'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6Im1vdGhlcmFwcCIsImV4cCI6MTU5MTI0ODgwNSwib3JpZ0lhdCI6MTU5MTI0ODUwNX0.hKWmCMjkAoV3_dYLqu75yrQcOsS6ZsVvMc3PGR874PA',
    'Content-Type': '*/*',
  },
});

class GoogleAPIHelper {
  getAddressDetail = (address) => {
    return GoogleAPIinstance.get(
      `/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`,
    ).catch((error) => error);
  };

  getPointDetail = ({ lat, long }) => {
    return GoogleAPIinstance.get(
      `/maps/api/geocode/json?latlng=${`${lat},${long}`}&key=${GOOGLE_API_KEY}`,
    ).catch((error) => error);
  };

  // uploadFile = (file, accessToken) => {
  //   // let blob = dataURItoBlob(file);
  //   // let fileData = new File([blob], 'import.csv', { type: file.type });
  //   let bodyFormData = new FormData();
  //   bodyFormData.append('import', file);
  //   return UploadAPIinstance.post(UPLOAD_FILE_PATH, bodyFormData, {
  //     headers: { Authorization: 'JWT ' + accessToken },
  //   }).catch((error) => error);
  // };
}
export const googleAPIHelper = new GoogleAPIHelper();
