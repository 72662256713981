import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateGamificationCampaignStepOne from '../../../components/gamificationCampaign/CreateGamificationCampaignStepOne';
import CreateGamificationCampaignStepTwo from '../../../components/gamificationCampaign/CreateGamificationCampaignStepTwo';
import CreateGamificationCampaignStepThree from '../../../components/gamificationCampaign/CreateGamificationCampaignStepThree';
import CreateGamificationCampaignStepFour from '../../../components/gamificationCampaign/CreateGamificationCampaignStepFour';
import CreateGamificationCampaignStepFive from '../../../components/gamificationCampaign/CreateGamificationCampaignStepFive';
import { createAction } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';
import {
  saveGamificationCampaignToSessionStorage,
  removeGamificationCampaignSessionStorage,
  GAMIFICATION_CAMPAIGN_SESSION_KEY,
} from '../../../models/GamificationCampaignModel';
import './CreateGamificationCampaign.scss';

function CreateGamificationCampaign({
  stepConfig,
  currentStep = 0,
  gamificationCampaign = {},
  formHasSubmitted,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const loadData = (data) => {
    if (!params.id) {
      dispatch({
        type: 'gamificationCampaign/loadGamificationCampaignFromCookie',
        payload: data,
      });
      return;
    } else {
      dispatch(
        createAction('gamificationCampaign/getOneGamificationCampaign')({
          id: params.id,
          ...data,
        }),
      );
    }
  };

  const clearData = () => {
    dispatch({ type: 'gamificationCampaign/clearData' });
    dispatch({ type: 'channel/clearState' });
    dispatch({ type: 'targetMarket/clearState' });
    dispatch({ type: 'countryCode/clearState' });
  };

  useEffect(() => {
    clearData();
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch({ type: 'channel/getAllList' });
    dispatch({ type: 'countryCode/getAllList' });
    loadData();

    return () => {
      clearData();
    };
  }, []);

  const stepSet = [
    <CreateGamificationCampaignStepOne />,
    <CreateGamificationCampaignStepTwo />,
    <CreateGamificationCampaignStepThree />,
    <CreateGamificationCampaignStepFour />,
    <CreateGamificationCampaignStepFive />,
  ];

  const toGamificationCampaignList = () => {
    history.push({
      pathname: `/gamification_campaign`,
    });
  };
  console.log('@@93: ', gamificationCampaign);
  return (
    <BaseForm
      defaultValues={gamificationCampaign}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveGamificationCampaignToSessionStorage(getValues());
        } else {
          removeGamificationCampaignSessionStorage();
        }
      }}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: toGamificationCampaignList,
        onClose: toGamificationCampaignList,
      }}
      sessionDataKey={GAMIFICATION_CAMPAIGN_SESSION_KEY}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params?.id
              ? gamificationCampaign?.name
              : 'Create Gamification campaign'
          }
        />
      }
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: params?.id
          ? gamificationCampaign?.name
          : 'Create Gamification campaign',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.gamificationCampaign.stepConfig,
  currentStep: state.gamificationCampaign.currentStep,
  gamificationCampaign: state.gamificationCampaign.detail,
  formHasSubmitted: state.gamificationCampaign.formHasSubmitted,
  hasUpdatedDefaultValues: state.gamificationCampaign.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateGamificationCampaign);
