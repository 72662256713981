import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import './CreateCampaignStepOne.scss';
import { Image } from 'react-bootstrap';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../base/ItemTypeSet';
import CouponSelectDropdown from './CouponSelectDropdown';
import GoodieBagSelector from './GoodieBagSelector';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { createAction, filterWorkingTeams } from '../../../utils';
import { useHistory } from 'react-router-dom';
import {
  getCampaignTypeSelectPromptConfig,
  CampaignTypesConfig,
} from './CreateCampaignStepOneConfigs';
import ContentSections from '../../base/ContentSections';
import Loading from '../../base/Loading';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { ContinueCreate } from '../CampaignUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import addImage from '../../../assets/images/add.svg';
import FortuneBagLinkCouponPrompt from './FortuneBagLinkCouponPrompt';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ReactHookFormErrorMessage,
  hasError,
  errorMessage,
} from '../../../components/base/ErrorFieldMessage';
import {
  fieldTransfer,
  switchAll,
  isGoodieBagType,
  isShowCouponRelated,
} from '../../../models/CreateCampaignModel';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../../models/GoodieBagsModel';
import WorkingTeamField from '../../workingTeam/WorkingTeamField';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

const CAMPAIGN_TYPE_VS_REWARD_TYPE = {
  [CampaignType.cashVoucherCampaign]:
    GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
  [CampaignType.giftCampaign]: GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value,
};

function CreateCampaignStepOne({
  status,
  channelList,
  marketList,
  brandList,
  countryCodeList,
  isPublished,
  id,
  userWorkingTeamIds,
  userIsSuperuser,
  goodiebagCampaignSlots,
  linkedCoupon,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const watchCampaignType = watch('campaignType');
  const formChannel = watch('channels') || [];
  const watchOta = watch('ota') || [];
  const watchBatchOta = watch('batchOta') || [];
  const watchCoupon = watch('linkedCoupon') || {};
  const watchMarkets = watch('markets') || [];
  const channelLimit = watch('channelLimit') || {};
  const isRedirectionCampaign = watch('isRedirectionCampaign');
  const watchGoodieBag = watch('goodieBag') || {};
  const watchWorkingTeams = watch('workingTeams');
  const initSelectedCoupon = watch('sourceLinkedCoupon');

  const watchGoodieBagCampaignSlot = watch('goodieBagCampaignSlots') || [];
  const watchCountryCode = watch('countryCodes');

  // const watchFortunes = watch('fortunes') || {};
  const pk = watch('pk');
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  const [showAddPrompt, setShowAddPrompt] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState();
  const [showFortuneBagLink, setShowFortuneBagLink] = useState(false);
  const isGoodieBag = isGoodieBagType(watchCampaignType);
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const watchChannels = formChannel.filter((item) => !!item.pk);

  // const [ota, setOTA] = useState(
  //   campaign.isPublished ? campaign.batchOta || [] : campaign.ota,
  // );
  // const [published, setOTA] = useState(campaign.isPublished ? [] : campaign.ota);

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);

  const getLanguagesFromMarket = (markets = []) => {
    const langauges = [];
    markets.forEach((element) => {
      const eleLanguage = element?.languages?.edges || element?.languages || [];

      eleLanguage.forEach((language) => {
        langauges.push(language.node || language);
      });
    });
    return [...new Map(langauges.map((item) => [item['code'], item])).values()];
  };

  useEffect(() => {
    if (watchCoupon?.pk) {
      dispatch(
        createAction('createCampaign/getAndLinkCouponDetail')({
          couponId: watchCoupon.pk,
          inEdit: watch('pk'),
          couponGetted: (coupon) => {
            setValue('linkedCoupon', coupon);
          },
        }),
      );
    } else {
      setValue('linkedCoupon', {});
    }
  }, [watchCoupon?.pk]);

  // temp solution: reset linkcoupon
  // useEffect(() => {
  //   if (
  //     linkedCoupon?.pk &&
  //     Object.keys(watchCoupon || {}).length !==
  //       Object.keys(linkedCoupon || {}).length &&
  //     Object.keys(watchCoupon || {}).length <
  //       Object.keys(linkedCoupon || {}).length
  //   ) {
  //     console.log(
  //       '11.174: ',
  //       Object.keys(watchCoupon || {}),
  //       watchCoupon.pk,
  //       Object.keys(linkedCoupon || {}),
  //       linkedCoupon.pk,
  //     );
  //     setValue('linkedCoupon', linkedCoupon);
  //   } else {
  //     console.log('@@189: ', linkedCoupon, watchCoupon);
  //   }
  // }, [linkedCoupon, watchCoupon]);

  useEffect(() => {
    if (watchGoodieBag?.pk) {
      dispatch(
        createAction('createCampaign/getAndLinkGoodieBagDetail')({
          id: watchGoodieBag.pk,
          setValue: setValue,
        }),
      );
      dispatch(
        createAction('goodieBags/getGoodieBagSlots')({
          isSelector: true,
          rewardType:
            watchCampaignType === CampaignType.cashVoucherCampaign
              ? GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
              : watchCampaignType === CampaignType.giftCampaign
              ? GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
              : GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value,
          id: watchGoodieBag.pk,
          page: 1,
        }),
      );
    }
  }, [watchGoodieBag?.pk]);

  useEffect(() => {
    if (watchCampaignType === CampaignType.fortuneBagForGoodieBagCampaign) {
      setValue('goodieBagCampaignSlots', goodiebagCampaignSlots, {
        shouldDirty: true,
      });
    }
  }, [goodiebagCampaignSlots]);

  useEffect(() => {
    dispatch(
      createAction('targetMarket/getAllList')({
        channel: (watchChannels || []).map((item) => item.pk),
        needChannel: true,
      }),
    );
  }, [watchChannels?.length]);

  const checkCouponInWorkingTeams = (coupon, workingTeamIds) => {
    return (
      workingTeamIds?.filter(
        (item) =>
          coupon.workingTeamIds?.length <= 0 ||
          coupon.workingTeamIds?.indexOf(item) !== -1,
      ).length > 0
    );
  };

  useEffect(() => {
    if (
      id &&
      watchWorkingTeams?.filter(
        (item) =>
          watchCoupon.workingTeamIds?.length <= 0 ||
          watchCoupon.workingTeamIds?.indexOf(item.pk) !== -1,
      ).length <= 0
    ) {
      setValue('linkedCoupon', null, { shouldDirty: true });
    }
  }, [watchWorkingTeams]);

  const canExtraSelected =
    !userIsSuperuser &&
    initSelectedCoupon &&
    checkCouponInWorkingTeams(
      initSelectedCoupon,
      watchWorkingTeams.map((item) => item.pk),
    ) &&
    !checkCouponInWorkingTeams(initSelectedCoupon, userWorkingTeamIds);

  const linkSet = (
    <Controller
      control={control}
      name="linkedCoupon"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <CouponSelectDropdown
              title={'Link to coupon set'}
              isGoodieBag={isGoodieBag}
              isFortuneBag={
                watchCampaignType ===
                CampaignType.fortuneBagForGoodieBagCampaign
              }
              workingTeams={watchWorkingTeams}
              defaultValue={{
                // value: {
                //   name: 'team coupon',
                //   pk: 302,
                // },
                // label: 'team coupon',
                value: watchCoupon,
                label: watchCoupon?.name,
              }}
              defaultFilter={{
                workingTeamsIn: filterWorkingTeams(
                  userIsSuperuser,
                  userWorkingTeamIds,
                  watchWorkingTeams,
                ),
                getAll: true,
                addDefaultValue: canExtraSelected ? initSelectedCoupon : null,
              }}
              setValue={(item) => {
                const coupon = item || {};
                setValue('linkedCoupon', coupon, { shouldDirty: true });
              }}
              addButtonInfo={{
                requires: PermissionCodes.changeCouponTemplate,
                children: <Image src={addImage} />,
                action: () => {
                  setAddPromptConfig({
                    button: {
                      text: promptconfig.button,
                      actionPath: isCouponRelated ? '/coupons/create' : '',
                    },
                    title: promptconfig.title,
                    description: promptconfig.message,
                  });
                  setShowAddPrompt(true);
                },
                customClass: 'earning-rule-add-new-link-button',
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedCoupon" />
        </>
      )}
    />
  );

  const goodieBagSet = (
    <Controller
      control={control}
      name="goodieBag"
      render={() => (
        <>
          <div className="create-section-label-bottom-space" />
          <div className="step-link-coupon-area-select">
            <GoodieBagSelector
              title={'Link to Goodie bag'}
              rewardType={
                watchCampaignType === CampaignType.cashVoucherCampaign
                  ? GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
                  : watchCampaignType === CampaignType.giftCampaign
                  ? GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
                  : GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
              }
              defaultValue={{
                value: watchGoodieBag,
                label: watchGoodieBag?.label || watchGoodieBag?.name,
              }}
              setValue={(item) => {
                setValue('goodieBag', item || {}, { shouldDirty: true });

                if (watchGoodieBag?.pk && item?.pk === watchGoodieBag?.pk) {
                } else {
                  setValue('goodieBagCampaignSlots', [], {
                    shouldDirty: true,
                  });
                }
              }}
            />
          </div>
        </>
      )}
    />
  );

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
        <div
          className="step-type-area campaign-step-one-bottom"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {CampaignTypesConfig.map((typeItem) => (
            <ItemTypeSet
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={watchCampaignType}
              disabled={isPublished}
              onClick={(id) => {
                if (id === watchCampaignType) {
                  return;
                }
                clearErrors();
                setValue('campaignType', id, { shouldDirty: true });
                if (id !== CampaignType.generalMessageCampaign) {
                  setValue('isRedirectionCampaign', false);
                }

                if (
                  [
                    CampaignType.cashVoucherCampaign,
                    CampaignType.giftCampaign,
                  ].indexOf(id) >= 0 &&
                  watchGoodieBag.rewardType !==
                    CAMPAIGN_TYPE_VS_REWARD_TYPE[id] &&
                  watchGoodieBag.rewardType !==
                    GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
                ) {
                  if (watchGoodieBag?.pk) {
                    setValue('goodieBag', null, { shouldDirty: true });
                  }

                  if (watchGoodieBagCampaignSlot?.length > 0) {
                    setValue('goodieBagCampaignSlots', [], {
                      shouldDirty: true,
                    });
                  }
                }

                const newCouponRelated = isShowCouponRelated(id);
                if (!newCouponRelated) {
                  switchAll({ getValues, setValue, isOn: false });
                }
              }}
            />
          ))}
        </div>
      )}
    />
  );

  const redirectionPart = (
    <Controller
      control={control}
      name="isRedirectionCampaign"
      render={() => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label
            className={`create-section-label`}
            style={{ marginBottom: '10px' }}
          >
            Redirection campaign
          </label>
          <SpecificCustomSwitchButton
            checked={isRedirectionCampaign}
            onChange={() =>
              setValue('isRedirectionCampaign', !isRedirectionCampaign, {
                shouldDirty: true,
              })
            }
          />
        </div>
      )}
    />
  );

  const firstPart = (
    <div>
      <label className="step-one-title">Campaign Type</label>
      {campaignTypeSelectArea}
      {watchCampaignType === CampaignType.generalMessageCampaign
        ? redirectionPart
        : null}
      {isCouponRelated ? linkSet : null}
    </div>
  );

  const publishChannelPart = (
    <Controller
      control={control}
      name="channels"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={
              isGoodieBag
                ? 'Publish channel (Optional, by default show in e-Solution)'
                : 'Publish channel'
            }
            namespace="channel"
            data={{
              sourceData: channelList,
              targetData: watchChannels,
              targetChange: (value) => {
                setValue('channels', value, { shouldDirty: true });
                const channelsExist = value?.map((item) => item.pk) || [];
                const marketsCleaned = watchMarkets?.filter((market) =>
                  channelsExist?.some((channel) =>
                    market.channels?.some((item) => item.pk === channel),
                  ),
                );
                setValue('markets', marketsCleaned, { shouldDirty: true });
              },
            }}
          />
        </>
      )}
    />
  );

  const targetMarketPart = (
    <Controller
      control={control}
      name="markets"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={'Target market'}
            tips={'Control which Target market users can "view" this campaign.'}
            namespace="targetMarket"
            data={{
              sourceData: marketList,
              targetData: watchMarkets,
              targetChange: (value) => {
                setValue('markets', value, { shouldDirty: true });
              },
            }}
            addButton={{
              action: () => {
                setAddPromptConfig({
                  button: {
                    text: 'create market',
                    actionPath: '/markets/create',
                  },
                  title: 'Go to create market?',
                  description: `You will leave the campaign creation process. After you create a new market, you can back to this page.`,
                });
                setShowAddPrompt(true);
              },
              title: 'Add Market',
              requires: PermissionCodes.changeTargetMarkets,
              customClass: 'general-section-add-new btn-add-button-common',
            }}
            error={{
              error: hasError(errors, 'markets'),
              message: errorMessage(errors, 'markets'),
              id: 'campaign-markets',
            }}
          />
        </>
      )}
    />
  );

  const targetMerchantData = brandList?.filter((item) => {
    if (
      isPublished &&
      watchOta?.filter((val) => val.pk === item.pk).length > 0
    ) {
      return false;
    }

    if (watchOta?.length > 0) {
      return (
        item?.handleFortuneBagEntitlement &&
        item?.handleOption.value &&
        item?.handleOption.value === watchOta[0].handleOption.value
      );
    }
    return item?.handleFortuneBagEntitlement && item?.handleOption.value;
  });

  const tartgetMerchantPart = (
    <Controller
      control={control}
      name="ota"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title="Target Merchant (OTA)"
            namespace="brand"
            data={{
              sourceData: targetMerchantData,
              targetData: isPublished ? watchBatchOta : watchOta,
              targetChange: (value) => {
                setValue(isPublished ? 'batchOta' : 'ota', value, {
                  shouldDirty: true,
                });
              },
            }}
            groupKey="handleOption"
            addButton={{
              action: () => {
                setAddPromptConfig({
                  button: {
                    text: 'Add merchant',
                    actionPath: '/brands/create',
                  },
                  title: 'Go to create brand?',
                  description: `You will leave the campaign creation process. After you create a new brand, you can back to this page.`,
                });
                setShowAddPrompt(true);
              },
              title: 'Add Merchants',
              requires: PermissionCodes.addBrand,
              customClass:
                'general-section-add-new-brand btn-add-button-common',
            }}
            error={{
              id: 'ota',
              error: false,
              message: 'please select ota',
            }}
          />
        </>
      )}
    />
  );

  const countryCodePart = (
    <Controller
      control={control}
      name="countryCodes"
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title="Country code (Optional)"
            tips={
              'Control which Country code users can "acquire" coupon for this campaign.'
            }
            namespace="countryCode"
            data={{
              sourceData: countryCodeList,
              targetData: watchCountryCode,
              targetChange: (value) => {
                setValue('countryCodes', value, {
                  shouldDirty: true,
                });
              },
            }}
          />
        </>
      )}
    />
  );

  const getSecondPart = () => {
    if (!watchCampaignType) {
      return null;
    }
    let merchantDisplay = 'none';
    let channelDisplay = 'none';
    let marketDisplay = 'none';
    let countryCodeDisplay = 'none';

    if (watchCampaignType === CampaignType.fortuneBagCampaign) {
      merchantDisplay = 'inherit';
    } else {
      channelDisplay = 'inherit';
      if (watchChannels?.length > 0) {
        marketDisplay = 'inherit';
      }
    }
    if (watchCampaignType !== CampaignType.generalMessageCampaign) {
      countryCodeDisplay = 'inherit';
    }
    return (
      <>
        <div style={{ display: merchantDisplay }}>{tartgetMerchantPart}</div>
        <div style={{ display: channelDisplay }}>{publishChannelPart}</div>
        <div style={{ display: marketDisplay, marginTop: '35px' }}>
          {targetMarketPart}
        </div>
        <div style={{ display: countryCodeDisplay, marginTop: '35px' }}>
          {countryCodePart}
        </div>
      </>
    );
  };

  const goodieBagPart = (
    <div>
      <label className="step-one-title">GOODIE BAG</label>
      {goodieBagSet}

      {watchCampaignType !== CampaignType.fortuneBagForGoodieBagCampaign ? (
        <Controller
          control={control}
          name="goodieBagCampaignSlot"
          render={() => (
            <>
              <BaseMutipleSelectorV2
                title={'Goodie bag slot'}
                searchPlaceholder="Search by name"
                size={['wider']}
                namespace="goodieBags"
                data={{
                  sourceData: watchGoodieBag?.pk ? goodiebagCampaignSlots : [],
                  targetData: watchGoodieBagCampaignSlot,
                  targetChange: (value) => {
                    setValue('goodieBagCampaignSlots', value, {
                      shouldDirty: true,
                    });
                  },
                }}
                custom={{
                  // customTitle: (
                  //   <div className="slot-head" style={{ paddingRight: '20px' }}>
                  //     <label className="slot-label">
                  //       <label className="slot-title">Goodie bag slot </label>
                  //     </label>
                  //   </div>
                  // ),
                  customItem: (value) => (
                    <>
                      <label className="slot-title">[{value.pk}]</label>
                      {value.name}
                    </>
                  ),
                  customFilter: (inputValue, option) => {
                    const inputIgnoreCase = inputValue?.toLowerCase();
                    const optionIgnoreCase = option?.name?.toLowerCase();
                    return (
                      optionIgnoreCase?.includes(inputIgnoreCase) ||
                      option.pk == inputValue
                    );
                  },
                }}
              />
            </>
          )}
        />
      ) : null}
    </div>
  );

  const getSection = () => {
    if (status === APIStatus.calling) {
      return [<Loading />];
    }
    const parts = [
      <WorkingTeamField
        from="Campaign"
        showPrompt={!id && !watchCampaignType}
      />,
      ,
      firstPart,
    ];
    const secondPart = getSecondPart();
    if (isGoodieBag) {
      parts.push(goodieBagPart);
    }
    if (watchCampaignType !== CampaignType.fortuneBagForGoodieBagCampaign) {
      parts.push(secondPart);
    }

    return parts;
  };

  const sections = getSection();

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });

    setValue('assignRemain', assignRemain);
    if (isCouponRelated) {
      fieldTransfer({ setValue, getValues });
    }
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  const buttonDisable = useMemo(() => {
    if (!watchCampaignType) {
      return true;
    }
    if (watchCampaignType === CampaignType.fortuneBagCampaign) {
      return !isPublished && (!watchOta || watchOta?.length <= 0);
    }

    if (
      isCouponRelated &&
      (!watchCoupon?.pk ||
        linkedCoupon?.totalNubmerOfAvailableCoupons === undefined)
    ) {
      return true;
    }
    if (isGoodieBag) {
      if (watchCampaignType === CampaignType.fortuneBagForGoodieBagCampaign) {
        return !watchGoodieBag.pk;
      }

      return !watchGoodieBag.pk || watchGoodieBagCampaignSlot?.length <= 0;
    }
    if (!watchChannels || !watchMarkets) {
      return true;
    }
    return watchChannels.length <= 0 || watchMarkets.length <= 0;
  }, [
    watchCampaignType,
    watchCoupon,
    watchGoodieBag,
    watchGoodieBagCampaignSlot,
    watchChannels,
    watchMarkets,
    linkedCoupon,
  ]);

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          if (watchCampaignType === CampaignType.fortuneBagCampaign) {
            if (
              (!isPublished && watchOta?.length > 0) ||
              (isPublished && watchBatchOta?.length > 0)
            ) {
              setShowFortuneBagLink(true);
              return;
            }
            goToNextStep(true);
            return;
          }

          //TODO: requirement need to be confirmed
          const alreadyIncludeEsolution = watchChannels.find(
            (item) => !item.pk,
          );
          const newChannels =
            isGoodieBag && !alreadyIncludeEsolution
              ? [{ name: 'e-Solution', pk: null }, ...watchChannels]
              : watchChannels;

          setValue('channels', newChannels);
          const newChannelLimit = {};
          (newChannels || []).forEach((channel) => {
            newChannelLimit[channel.pk] = {
              ...(channelLimit[channel.pk] || {}),
            };
          });
          setValue('channelLimit', newChannelLimit, {
            shouldDirty: true,
          });

          const selectedMarkets = isGoodieBag ? marketList : watchMarkets;
          if (selectedMarkets?.length) {
            const languagesMarket = getLanguagesFromMarket(
              selectedMarkets || [],
            );
            setValue('selectedLanguages', languagesMarket, {
              shouldDirty: true,
            });
          }
          goToNextStep(true);
        }}
        disabledContinue={buttonDisable}
      />

      <BasePrompt
        show={showAddPrompt}
        closeAction={() => {
          setShowAddPrompt(false);
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig?.button.text,
                action: () =>
                  history.push({
                    pathname: addPromptConfig?.button.actionPath,
                    state: { from: history.location, ...ContinueCreate },
                  }),
              }
            : null
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
      {watchCampaignType === CampaignType.fortuneBagCampaign ? (
        <FortuneBagLinkCouponPrompt
          isRefill={isPublished}
          show={showFortuneBagLink}
          closeAction={() => {
            setShowFortuneBagLink(false);
          }}
          nextAction={(assignRemain) => {
            setShowFortuneBagLink(false);
            goToNextStep(assignRemain);
          }}
        />
      ) : null}
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.createCampaign.createStatus,
  marketList: state.targetMarket.notPagedAllList,
  channelList: state.channel.notPagedAllList,
  brandList: state.brand.notPagedAllList,
  countryCodeList: state.countryCode.notPagedAllList,
  isPublished: state.createCampaign.campaign.isPublished,
  userWorkingTeamIds: state.users.workingTeams,
  userIsSuperuser: state.users.isSuperuser,
  goodiebagCampaignSlots: state.goodieBags.goodiebagCampaignSlots,
  linkedCoupon: state.createCampaign.linkedCoupon,
});

export default connect(mapPropsToState)(CreateCampaignStepOne);
