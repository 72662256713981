import {
  LanguageConfig,
  APIStatus,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  SESSION_KEYS,
  COUPON_LOW_STOCK,
  COUPON_FORMATS,
  CouponMediumType,
  COUPON_LOCATION_SET,
} from '../config/CustomEnums';
import {
  createCouponTemplate,
  generateCoupon,
  updateCouponSet,
  getBarcodeFormats,
  getRelatedCampaignWorkingTeams,
} from '../services/CouponAPIHelper';
import {
  createAction,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  convertNumberToCursor,
  convertCursorToNumber,
} from '../utils';
import { ToastType } from './NavBarModel';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  TimeFormater,
  formatDate,
  getCouponExpiryDate,
} from '../utils/TimeFormatUtil';
import { t } from 'i18n-js';
import { getLogoLabelList } from '../services/LogoLabelAPIHelper';
import { checkPartnerCodeFormat } from './CouponUtil';
import { CouponErrorFields } from '../components/coupon/couponCreation/CouponHandleError';
export const VALID_PERIOD_TYPE = {
  ALL_TIME: 'ALL_TIME',
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
};

export const DISPLAY_IN_TYPE = {
  COUPON_SECTION: 'COUPON_SECTION',
  VOUCHER_SECTION: 'VOUCHER_SECTION',
  LIMITED_OFFER_SECTION: 'LIMITED_OFFER_SECTION',
  TICKET_SECTION: 'TICKET_SECTION',
};

export const DISCOUNT_CODE_FORMAT = {
  CODE: 'COUPON_CODE',
  URL: 'COUPON_URL',
  QRCODE: 'PARTNER_QR_CODE',
  BARCODE: 'PARTNER_BARCODE',
};

export const displayDiscountCodeFormat = {
  [DISCOUNT_CODE_FORMAT.CODE]: 'Coupon code',
  [DISCOUNT_CODE_FORMAT.URL]: 'Coupon URL',
  [DISCOUNT_CODE_FORMAT.QRCODE]: 'Partner QR code',
  [DISCOUNT_CODE_FORMAT.BARCODE]: 'Partner Barcode',
};

const stepNameList = ['Type', 'Content', 'Properties'];

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_COUPON_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_COUPON_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_COUPON_ORIGIN_DATA_SESSION_KEY,
};

export const checkValidTimePeriods = (time, validTimePeriod) => {
  if (!validTimePeriod) {
    return true;
  }

  let valid = true;
  const date =
    time.date.getFullYear() +
    '-' +
    (time.date.getMonth() + 1) +
    '-' +
    time.date.getDate();

  if (!time.isWholeDay) {
    (time.timePeriods || []).forEach((period) => {
      const start =
        typeof period.start === 'string'
          ? new Date(period.start)
          : period.start;
      const end =
        typeof period.end === 'string' ? new Date(period.end) : period.end;

      const startTime = start.getHours() + ':' + start.getMinutes() + ':00';
      const endTime = end.getHours() + ':' + end.getMinutes() + ':59';

      if (typeof validTimePeriod[0] === 'boolean') {
        if (
          validTimePeriod[0] &&
          new Date(`${date} ${endTime}`) > validTimePeriod[1]
        ) {
          valid = false;
          return;
        }
      } else {
        if (
          new Date(`${date} ${startTime}`) < validTimePeriod[0] ||
          new Date(`${date} ${endTime}`) > validTimePeriod[1]
        ) {
          valid = false;
          return;
        }
      }
    });
  } else {
    if (typeof validTimePeriod[0] === 'boolean') {
      if (
        validTimePeriod[0] &&
        new Date(`${date} 23:59:59`) > validTimePeriod[1]
      ) {
        valid = false;
        return;
      }
    } else {
      if (
        new Date(`${date} 00:00:00`) < validTimePeriod[0] ||
        new Date(`${date} 23:59:59`) > validTimePeriod[1]
      ) {
        valid = false;
        return;
      }
    }
  }

  return valid;
};

export const saveCouponToSessionStorage = (data) => {
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeCouponSessionStroage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
};

function createNewItemAutoAdd(originData, newItems) {
  const newBrand = newItems.newBrand;
  const newStore = newItems.newStore;
  const newLogoLabel = newItems.newLogoLabel;
  const logoLabelTranslation = newItems.language;
  const newTourCard = newItems.newTourCard;
  if (newBrand) {
    originData.selectedBrand = newBrand;
  }
  if (newStore && newStore.brandPK === originData.selectedBrand.pk) {
    originData.selectedStores = [...originData.selectedStores, newStore];
  }
  if (newTourCard) {
    originData.tourCards = [...originData.tourCards, newTourCard];
  }
  if (logoLabelTranslation && newLogoLabel) {
    const translationLogoLabel =
      originData.translations[logoLabelTranslation] || {};

    translationLogoLabel.logoLabel = [
      ...(translationLogoLabel.logoLabel || []),
      newLogoLabel,
    ];
  }
}

function parseOtherPhotoForAPI(otherPhotos) {
  let detailPhotos = {};
  if (otherPhotos && otherPhotos.length > 0) {
    otherPhotos.forEach((image, index) => {
      const detailPhotoKey = 'detailPhoto' + (index + 1);
      detailPhotos[detailPhotoKey] = parsePhotoUrlForAPI(image);
    });
  }
  let detailPhotosLength = 4;
  [...Array(detailPhotosLength)].map((e, i) => {
    const detailPhotoKey = 'detailPhoto' + (i + 1);
    detailPhotos[detailPhotoKey] = detailPhotos[detailPhotoKey] || null;
  });
  return detailPhotos;
}

const cleanTranslations = (translations, origionalData, inputData) => {
  const data = [];
  Object.keys(translations).forEach((language) => {
    if (language !== LanguageConfig.english) {
      const logoLabel = translations[language].logoLabel;
      let logoLabelData = [];
      if (logoLabel) {
        logoLabelData = logoLabel.map((label) => label.pk);
      }
      const showColorIndicator = inputData?.showColorIndicator;
      const translation = {
        language,
        name: translations[language].name,
        shortDescription: translations[language].shortDescription,
        tourCardSectionTitle: translations[language].tourCardSectionTitle,
        instructionSectionTitle: translations[language].instructionSectionTitle,
        instructionSectionContent:
          translations[language].instructionSectionContent,
        // detailSectionTitle: translations[language].detailSectionTitle,
        // detailSectionContent: translations[language].detailSectionContent,
        merchantTermsAndConditionsTitle:
          translations[language].merchantTermsAndConditionsTitle,
        merchantTermsAndConditionsContent:
          translations[language].merchantTermsAndConditionsContent,
        genericTermsAndConditionsTitle:
          translations[language].genericTermsAndConditionsTitle,
        genericTermsAndConditionsContent:
          translations[language].genericTermsAndConditionsContent,
        customStoresTitle: translations[language].customStoresTitle,
        logoLabels: logoLabelData,
        coverPhoto: parsePhotoUrlForAPI(translations[language].coverPhoto),
        ...parseOtherPhotoForAPI(translations[language].otherPhoto),
        offerDetailTitle: translations[language].offerDetailTitle,
        offerDetailContent: translations[language].offerDetailContent,
        landingPageUrl: translations[language].landingPageUrl,
        landingPageButtonName:
          translations[language].landingPageButtonName ||
          t('book_now2', { locale: language }),
        indicatorText: showColorIndicator
          ? translations[language].indicatorText
          : null,
      };
      if (origionalData?.translations?.[language]?.pk) {
        translation.id = origionalData.translations[language].pk;
      }
      data.push(translation);
    }
  });
  return data;
};

const weeklyRedeemPeriods = (data, isPhysical) => {
  const redeemPeriodsData = {};
  let weeklyRuleName = 'weeklyRules';
  let dateRuleName = 'dateRules';
  let excludeDateRuleName = 'excludeDateRules';

  if (isPhysical) {
    weeklyRuleName =
      'physical' + weeklyRuleName[0].toUpperCase() + weeklyRuleName.slice(1);
    dateRuleName =
      'physical' + dateRuleName[0].toUpperCase() + dateRuleName.slice(1);
    excludeDateRuleName =
      'physical' +
      excludeDateRuleName[0].toUpperCase() +
      excludeDateRuleName.slice(1);
  }

  redeemPeriodsData[weeklyRuleName] =
    data[weeklyRuleName]?.map((rule) => {
      return {
        weekDays: rule.weekdays.map((day) => day.pk.toString()),
        timePeriodType: rule.isWholeDay ? 'WHOLE_DAY' : 'PERIOD',
        timePeriods: rule.isWholeDay
          ? null
          : rule.timePeriods.map((period) => ({
              startTime: formatDate(period.start, TimeFormater.timeHourMinutes),
              endTime: formatDate(period.end, TimeFormater.timeHourMinutes),
            })),
      };
    }) || null;

  [dateRuleName, excludeDateRuleName].forEach((ruleName) => {
    redeemPeriodsData[ruleName] =
      (data[ruleName] || []).map((rule) => {
        return {
          dayDate: formatDate(rule.date, TimeFormater.yearMonthDay),
          timePeriodType: rule.isWholeDay ? 'WHOLE_DAY' : 'PERIOD',
          timePeriods: rule.isWholeDay
            ? null
            : rule.timePeriods.map((period) => ({
                startTime: formatDate(
                  period.start,
                  TimeFormater.timeHourMinutes,
                ),
                endTime: formatDate(period.end, TimeFormater.timeHourMinutes),
              })),
        };
      }) || null;
  });

  return redeemPeriodsData;
};

const cleanFormData = (data, origionalData) => {
  let { validPeriodType, physicalValidPeriodType, discountCodeFormat } = data;
  const hasCouponMedium =
    data.couponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED &&
    data.redemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE;
  const hasPhysical =
    hasCouponMedium &&
    (data?.couponMedium === CouponMediumType.Physical ||
      data?.couponMedium === CouponMediumType.Both);
  physicalValidPeriodType = hasPhysical
    ? physicalValidPeriodType || VALID_PERIOD_TYPE.ALL_TIME
    : null;
  const isPartnerCodeFormat = checkPartnerCodeFormat(discountCodeFormat);
  const showColorIndicator = data.showColorIndicator;
  const cleanedData = {
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,
    name: data.translations[LanguageConfig.english].name,
    shortDescription:
      data.translations[LanguageConfig.english].shortDescription,
    instructionSectionTitle:
      data.translations[LanguageConfig.english].instructionSectionTitle,
    instructionSectionContent:
      data.translations[LanguageConfig.english].instructionSectionContent,
    // detailSectionTitle:
    //   data.translations[LanguageConfig.english].detailSectionTitle,
    // detailSectionContent:
    //   data.translations[LanguageConfig.english].detailSectionContent,
    merchantTermsAndConditionsTemplate:
      data.merchantTermsAndConditionsTemplate?.pk || null,
    isFollowMerchantTermsAndConditionsTemplate:
      data.merchantTermsAndConditionsTemplate
        ? data.isFollowMerchantTermsAndConditionsTemplate
        : false,
    genericTermsAndConditionsTemplate:
      data.genericTermsAndConditionsTemplate?.pk || null,
    isFollowGenericTermsAndConditionsTemplate:
      data.genericTermsAndConditionsTemplate
        ? data.isFollowGenericTermsAndConditionsTemplate
        : false,
    merchantTermsAndConditionsTitle:
      data.translations[LanguageConfig.english].merchantTermsAndConditionsTitle,
    merchantTermsAndConditionsContent:
      data.translations[LanguageConfig.english]
        .merchantTermsAndConditionsContent,
    genericTermsAndConditionsTitle:
      data.translations[LanguageConfig.english].genericTermsAndConditionsTitle,
    genericTermsAndConditionsContent:
      data.translations[LanguageConfig.english]
        .genericTermsAndConditionsContent,
    offerDetailTitle:
      data.translations[LanguageConfig.english].offerDetailTitle,
    offerDetailContent:
      data.translations[LanguageConfig.english].offerDetailContent,
    customStoresTitle:
      data.translations[LanguageConfig.english].customStoresTitle,
    displayIn: data.displayIn,
    coverPhoto: parsePhotoUrlForAPI(
      data.translations[LanguageConfig.english].coverPhoto,
    ),
    ...parseOtherPhotoForAPI(
      data.translations[LanguageConfig.english].otherPhoto,
    ),
    couponTemplateType: data.couponSetType,
    redemptionMethod: data.redemptionMethod,
    tourCardSectionTitle:
      data.translations[LanguageConfig.english].tourCardSectionTitle,
    validPeriodType,
    requireHkidOrPassportNumber: data.requireHkidOrPassportNumber,

    settlementValueForHktb: parseFloat(data.settlementValueForHktb),
    settlementValueForMerchant: parseFloat(data.settlementValueForMerchant),

    showTourCard: data.showTourCard,
    tourCardIds: data.showTourCard
      ? data.tourCards?.map((card) => card.pk)
      : [],

    couponCodeType: data.couponCodeType,
    genericCode: data.genericCode,

    requireTransactionAmountSurvey: data.requireTransactionAmountSurvey,
    transactionAmountSurvey: data.transactionAmountSurvey?.pk,

    barcodeFormat:
      (data.redemptionMethod !==
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
        data?.formats === COUPON_FORMATS.COUPON_BAR_CODE) ||
      (data.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
        discountCodeFormat === DISCOUNT_CODE_FORMAT.BARCODE)
        ? data?.barcodeFormat?.value?.pk
        : null,

    discountCodeFormat: data?.discountCodeFormat || DISCOUNT_CODE_FORMAT.CODE,
    haveLandingPage:
      data?.discountCodeFormat === DISCOUNT_CODE_FORMAT.URL
        ? true
        : isPartnerCodeFormat
        ? false
        : data?.showLandingPage,
    landingPageUrl: data.translations[LanguageConfig.english].landingPageUrl,
    landingPageButtonName:
      data.translations[LanguageConfig.english].landingPageButtonName,

    showColorIndicator: showColorIndicator,
    indicatorColor: showColorIndicator ? data.indicatorColor : null,
    indicatorText: showColorIndicator
      ? data.translations[LanguageConfig.english].indicatorText
      : null,

    showLowStockLabel: data.showLowStockLabel,
    measureLowStockWay: data.showLowStockLabel ? data.lowStockMethod : null,
    lowStockStandardValue: data.showLowStockLabel
      ? data.lowStockMethod === COUPON_LOW_STOCK.SPECIFIC_VALUE
        ? parseInt(data.lowStockValue)
        : data.lowStockMethod === COUPON_LOW_STOCK.SPECIFIC_PERCENTAGE
        ? parseFloat(parseFloat(data.lowStockValue).toFixed(2))
        : null
      : null,
    workingTeams: data.workingTeams?.map((item) => item.pk),

    periodRestriction: data.hasSpecialDays,
    updatePeriodRestriction:
      (data.hasSpecialDays && data.redeemPeriodUpdated) || false,
    ...(data.hasSpecialDays && data.redeemPeriodUpdated
      ? weeklyRedeemPeriods(data, false)
      : {}),
    isCheckGps: false,
    locations: [],
    radius: null,
    toDeleteLocations: [],
    // ...(data.physicalHasSpecialDays && data.physicalRedeemPeriodUpdated
    //   ? weeklyRedeemPeriods(data, true)
    //   : {}),
  };

  if (
    data.redemptionMethod !==
    COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE
  ) {
    cleanedData.isCheckGps = data.checkGPS;
    if (data.locationSet === COUPON_LOCATION_SET.BY_STORES) {
      cleanedData.locationSet = data.locationSet;
      cleanedData.radius = data.radius;
    } else {
      cleanedData.locationSet =
        data.locationSet || COUPON_LOCATION_SET.CUSTOMIZED;
      cleanedData.locations = data.locations;
      cleanedData.toDeleteLocations = data.toDeleteLocations;
    }
  }

  if (
    data.couponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
    data.redemptionMethod !==
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
  ) {
    cleanedData.formats = data.formats;
  } else {
    cleanedData.formats = null;
  }
  if (hasCouponMedium) {
    cleanedData.couponMedium = data?.couponMedium || CouponMediumType.EWallet;
  }
  if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE) {
    cleanedData.numberOfDaysToExpireAfterAcquisition =
      data.numberOfDaysToExpireAfterAcquisition;
    cleanedData.relativeValidSetDeadline = data.relativeSetDeadline;
    if (data.relativeSetDeadline) {
      cleanedData.absoluteExpiryDate = data.relativeDeadline;
    }
  }
  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
    cleanedData.absoluteEffectiveDate = data.absoluteEffectiveDate;
    cleanedData.absoluteExpiryDate = data.absoluteExpiryDate;
    //getCouponExpiryDate(
    //   data.absoluteExpiryDate,
    // );
  }
  if (physicalValidPeriodType) {
    cleanedData.physicalValidPeriodType = physicalValidPeriodType;
  }
  if (physicalValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
    cleanedData.physicalAbsoluteEffectiveDate =
      data.physicalAbsoluteEffectiveDate;
    cleanedData.physicalAbsoluteExpiryDate = data.physicalAbsoluteExpiryDate;
  }
  if (data.selectedBrand?.pk) {
    cleanedData.brand = data.selectedBrand.pk;
  }
  if (data.selectedStores) {
    const applicableStores = data.selectedStores.map((store) => {
      return store.pk;
    });
    cleanedData.applicableStores = applicableStores;
  }
  const logoLabel = data.translations[LanguageConfig.english].logoLabel;
  if (logoLabel) {
    cleanedData.logoLabels = logoLabel.map((label) => label.pk);
  }
  cleanedData.displayCode =
    (data.couponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
      ((data.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE &&
        cleanedData.formats === COUPON_FORMATS.COUPON_QR_CODE) ||
        (data.redemptionMethod ===
          COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
          cleanedData.discountCodeFormat === DISCOUNT_CODE_FORMAT.QRCODE)) &&
      data?.displayCode) ||
    false;
  cleanedData.translations = cleanTranslations(
    data.translations,
    origionalData,
    data,
  );

  if (origionalData?.pk) {
    cleanedData.id = origionalData.pk;
  }

  return cleanedData;
};

function getInitState() {
  return {
    couponTemplate: {
      selectedBrand: null,
      selectedStores: null,
      applyCoverPhotoToAllLanguage: false,
      applyDetailPhotosToAllLanguage: false,
      numberOfDaysToExpireAfterAcquisition: null,
      redemptionMethod:
        COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
      couponMedium: CouponMediumType.EWallet,
      validPeriodType: VALID_PERIOD_TYPE.ALL_TIME,
      physicalValidPeriodType: VALID_PERIOD_TYPE.ALL_TIME,
      pk: null,
      id: null,
      requireHkidOrPassportNumber: false,
      formats: null,
      displayCode: false,
      displayIn: DISPLAY_IN_TYPE.COUPON_SECTION,
      requireTransactionAmountSurvey: false,
      transactionAmountSurvey: null,
      showTourCard: true,
      showColorIndicator: false,
      indicatorColor: '',
      radius: 100,
      translations: {
        [LanguageConfig.english]: {
          name: null,
          shortDescription: '',
          instructionSectionTitle: '',
          instructionSectionContent: '',
          // detailSectionTitle: '',
          // detailSectionContent: '',
          merchantTermsAndConditionsTitle: '',
          merchantTermsAndConditionsContent: '',
          genericTermsAndConditionsTitle: '',
          genericTermsAndConditionsContent: '',
          offerDetailTitle: '',
          offerDetailContent: '',
          coverPhoto: null,
          otherPhoto: null,
          logoLabel: null,
          tourCardSectionTitle: '',
          landingPageButtonName: 'Book Now',
        },
        [LanguageConfig.traditionalChinese]: {
          name: null,
          shortDescription: '',
          instructionSectionTitle: '',
          instructionSectionContent: '',
          // detailSectionTitle: '',
          // detailSectionContent: '',
          merchantTermsAndConditionsTitle: '',
          merchantTermsAndConditionsContent: '',
          genericTermsAndConditionsTitle: '',
          genericTermsAndConditionsContent: '',
          offerDetailTitle: '',
          offerDetailContent: '',
          coverPhoto: null,
          otherPhoto: null,
          logoLabel: null,
          tourCardSectionTitle: '',
          landingPageButtonName: '立即預訂',
        },
        [LanguageConfig.simplifiedChinese]: {
          name: null,
          shortDescription: '',
          instructionSectionTitle: '',
          instructionSectionContent: '',
          // detailSectionTitle: '',
          // detailSectionContent: '',
          merchantTermsAndConditionsTitle: '',
          merchantTermsAndConditionsContent: '',
          genericTermsAndConditionsTitle: '',
          genericTermsAndConditionsContent: '',
          offerDetailTitle: '',
          offerDetailContent: '',
          coverPhoto: null,
          otherPhoto: null,
          logoLabel: null,
          tourCardSectionTitle: '',
          landingPageButtonName: '立即预订',
        },
      },
    },
    hasUpdatedDefaultValues: false,
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    formHasSubmitted: false,
    createCouponTemplateStatus: APIStatus.none,
    generateCoupon: {
      generateCouponStatus: APIStatus.none,
      couponTemplatePk: null,
      name: '',
      brandName: '',
      formats: null,
      couponTemplateType: null,
      redemptionMethod: null,
      couponCodeType: null,
      generateCoupon: false,
      couponMedium: null,
      totalNubmerOfGeneratedCoupons: 0,
      totalNubmerOfGeneratedPhysicalCoupons: 0,
    },
    couponQuantity: 0,
    couponGenerated: false,
    showEditSuccessPrompt: false,
  };
}

export const parseBarcode = (barcode) => {
  if (!barcode) {
    return {};
  }

  return {
    id: barcode.id,
    pk: barcode.pk,
    name: barcode.name,
  };
};

export default {
  namespace: 'createCoupon',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateGenerateCouponState(state, { payload }) {
      return {
        ...state,
        generateCoupon: {
          ...state.generateCoupon,
          ...payload,
        },
      };
    },

    updateCouponTemplate(state, { payload }) {
      if (payload.absoluteEffectiveDate) {
        payload.startDateTime = payload.absoluteEffectiveDate;
      }
      if (payload.absoluteExpiryDate) {
        payload.endDateTime = payload.absoluteExpiryDate;
      }
      const coupon = {
        ...state.couponTemplate,
        ...payload,
      };
      if (!payload.notSaveToSession) {
        console.log('@@662-0: ');
        delete coupon.notSaveToSession;
        saveToSessionStorage(sessionDataKey.objectKey, coupon);
      }
      console.log('@@662: ', coupon, state.couponTemplate);
      return {
        ...state,
        couponTemplate: coupon,
      };
    },

    saveOrRemoveCouponFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    loadCouponFromAPI(state, { payload }) {
      const { data, newItems } = payload;
      createNewItemAutoAdd(data, newItems);
      return {
        ...state,
        hasUpdatedDefaultValues: true,
        couponTemplate: {
          ...data,
        },
      };
    },
    loadCouponFromCookie(state, { payload }) {
      const coupon = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!coupon) {
        return {
          ...state,
          couponTemplate: {
            ...getInitState().couponTemplate,
          },
        };
      }
      createNewItemAutoAdd(coupon, payload);
      return {
        ...state,
        hasUpdatedDefaultValues: true,
        couponTemplate: {
          ...coupon,
        },
      };
    },

    stepChange(state, { payload }) {
      let { isBack, currentStep, isValid } = payload;
      let newStep = currentStep;
      const stepConfig = getNewStepConfig(
        currentStep,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        if (isBack) {
          newStep = currentStep - 1;
        } else {
          newStep = currentStep + 1;
        }
      }
      return {
        ...state,
        currentStep: newStep,
        stepConfig,
      };
    },

    clearState(state, { payload }) {
      return { ...getInitState() };
    },

    hideEditorSuccessPrompt(state, { payload }) {
      return {
        ...state,
        showEditSuccessPrompt: false,
      };
    },
  },

  effects: {
    createCouponTemplate: [
      function* ({ payload }, { all, select, put }) {
        const { data, isGenerater } = payload;
        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        let inputBody = {};
        if (data) {
          inputBody = cleanFormData(data);
        }
        if (payload.isDuplicate) {
          const couponTemplate = yield select(
            (state) => state.createCoupon.couponTemplate,
          );
          inputBody = cleanFormData(couponTemplate);
          inputBody.name = `Copy of ${inputBody.name}`;
        }
        console.log('741: ', inputBody, data);
        const serviceArgs = [createCouponTemplate, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
        }

        function* onSuccess(data) {
          const couponTemplateInfo = data.createCouponTemplate.node;
          if (couponTemplateInfo) {
            yield put(
              createAction('updateGenerateCouponState')({
                generateCoupon: isGenerater,
                couponTemplatePk: couponTemplateInfo.pk,
                name: couponTemplateInfo.name,
                couponTemplateType: couponTemplateInfo.couponTemplateType,
                redemptionMethod: couponTemplateInfo.redemptionMethod,
                formats: couponTemplateInfo.formats,
                brand: couponTemplateInfo.brand,
                brandName: couponTemplateInfo.brand.name,
                couponCodeType: couponTemplateInfo.couponCodeType,
                couponMedium: couponTemplateInfo?.couponMedium,
                totalNubmerOfGeneratedCoupons:
                  couponTemplateInfo?.totalNubmerOfGeneratedCoupons,
                totalNubmerOfGeneratedPhysicalCoupons:
                  couponTemplateInfo?.totalNubmerOfGeneratedPhysicalCoupons,
              }),
            );

            yield put(
              createAction('updateState')({
                createCouponTemplateStatus: isGenerater
                  ? APIStatus.none
                  : APIStatus.success,
                formHasSubmitted: true,
              }),
            );
          }
          yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });

          if (payload.afterAction) {
            yield payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],

    generateCoupon: [
      function* ({ payload }, { call, select, put, all }) {
        const redirectAction = payload.redirectAction || (() => {});
        const { quantity, couponTemplatePk } = payload;
        const couponQuantity = yield select(
          (state) => state.createCoupon.couponQuantity,
        );
        yield put(
          createAction('updateState')({
            couponQuantity: (
              parseInt(quantity) + parseInt(couponQuantity)
            ).toLocaleString(),
          }),
        );
        yield put(
          createAction('updateGenerateCouponState')({
            generateCouponStatus: APIStatus.calling,
          }),
        );

        const inputBody = {
          couponTemplate: couponTemplatePk,
          quantity: quantity,
        };
        const serviceArgs = [generateCoupon, inputBody];
        function* onFailed() {
          yield put(
            createAction('updateGenerateCouponState')({
              generateCouponStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          if (data.generateCoupons.success) {
            const couponTemplatePK = yield select(
              (state) => state.createCoupon.couponTemplate.pk,
            );
            yield all([
              put(
                createAction('updateState')({
                  couponGenerated: true,
                }),
              ),
              put(
                createAction('updateGenerateCouponState')({
                  generateCouponStatus: APIStatus.success,
                }),
              ),
              put({ type: 'saveOrRemoveCouponFromCookie', payload: false }),
              put({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.couponGenerate,
                  },
                },
              }),
              redirectAction(couponTemplatePK),
            ]);
            saveToSessionStorage(sessionDataKey.stepEndKey, true);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCouponTemplateInput: [
      function* ({ payload }, { call, put, select }) {
        const { data } = payload;

        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        const { couponTemplate, storeEditMode } = yield select((state) => ({
          couponTemplate: state.createCoupon.couponTemplate,
          storeEditMode: state.storeModel.storeEditMode,
        }));
        console.log('@@498: ', data);
        const inputBody = cleanFormData(data, couponTemplate);
        const isGenerater = payload.isGenerater;
        if (!storeEditMode) {
          delete inputBody.selectedStores;
          delete inputBody.applicableStores;
        }
        console.log('@@498-2: ', inputBody);
        yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });

        const serviceArgs = [updateCouponSet, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          yield put(
            createAction('couponList/getCurrentPageTemplateList')({
              rank: true,
              search: '',
              page: 1,
              getAll: 'false',
            }),
          );
          if (isGenerater) {
            const couponTemplateInfo = data.updateCouponTemplate.node;
            yield put(
              createAction('updateGenerateCouponState')({
                generateCoupon: true,
                couponTemplatePk: couponTemplateInfo.pk,
                name: couponTemplateInfo.name,
                couponTemplateType: couponTemplateInfo.couponTemplateType,
                redemptionMethod: couponTemplateInfo.redemptionMethod,
                formats: couponTemplateInfo.formats,
                brand: couponTemplateInfo.brand,
                brandName: couponTemplateInfo.brand.name,
                couponCodeType: couponTemplateInfo.couponCodeType,
                couponMedium: couponTemplateInfo?.couponMedium,
                totalNubmerOfGeneratedCoupons:
                  couponTemplateInfo?.totalNubmerOfGeneratedCoupons,
                totalNubmerOfGeneratedPhysicalCoupons:
                  couponTemplateInfo?.totalNubmerOfGeneratedPhysicalCoupons,
              }),
            );
          }
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.success,
              formHasSubmitted: true,
              showEditSuccessPrompt: !isGenerater,
            }),
          );
          removeFromSessionStorage(sessionDataKey.objectKey);
          if (payload.afterAction) {
            yield payload.afterAction(data?.updateCouponTemplate?.node?.pk);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getBarcodeList: [
      function* ({ payload }, { put }) {
        const { searchKey } = payload;
        const serviceArgs = [getBarcodeFormats, searchKey];

        function* onSuccess(data) {
          const barcodeData = data.barcodeFormats.edges;
          const barcodeList = barcodeData.map((item) =>
            parseBarcode(item.node),
          );
          yield put(
            createAction('updateState')({
              couponBarcodeList: barcodeList,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCouponRelatedCampaignWorkingTeams: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getRelatedCampaignWorkingTeams, payload.couponId];

        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              relatedCampaigns: data.couponTemplateRelatedWorkingTeams,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
