import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import BasePrompt from '../base/prompt/BasePrompt';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomSwitchButton from '../base/CustomSwitchButton';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { createAction } from '../../utils';
import './EditMissionLocationPrompt.scss';

function hasError(errors, fieldName) {
  return errors[fieldName];
}

function EditMissionLocationPrompt({
  displayQRCode,
  missionLocation = {},
  hasChangeLocaionPermission,
  hasChangeMissionLocationQrcodePermission,
  show,
  onClose = () => {},
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [code, setCode] = useState(missionLocation.code);
  const [displayPriority, setDisplayPriority] = useState(
    missionLocation.displayPriority,
  );
  const [isFeatured, setIsFeatured] = useState(missionLocation.isFeatured);
  const [isForcedInactive, setIsForcedInactive] = useState(
    missionLocation.isForcedInactive,
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (show) {
      setCode(missionLocation.code);
      setDisplayPriority(missionLocation.displayPriority);
      setIsForcedInactive(missionLocation.isForcedInactive);
      setIsFeatured(missionLocation.isFeatured);
      setErrors({});
    }
  }, [show]);

  const queryList = () => {
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const status = querySearch['status'];
      const page = parseInt(stringPage);
      const queryData = {
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
        fromLocation: true,
        missionIn: params?.id,
      };
      if (status) {
        queryData.isPublished = status;
      }
      dispatch(createAction('missionLocation/getList')(queryData));
    }
  };

  const getBody = () => {
    return (
      <div className="edit-mission-location-content">
        {displayQRCode ? (
          <>
            <CustomTitleWithInput
              title="Edit QR code string"
              customClass="create-mission-input"
              defaultValue={code}
              useDefaultValue={false}
              setValue={(val) => {
                setCode(val);
              }}
              disabled={!hasChangeMissionLocationQrcodePermission}
            />
            <ErrorFieldMessage
              id={`code`}
              error={hasError(errors, 'code')}
              message={'Please input.'}
            />
          </>
        ) : null}
        {/* <CustomTitleWithInput
          title="Display order"
          customClass="create-mission-input"
          defaultValue={displayPriority}
          useDefaultValue={false}
          setValue={(val) => {
            setDisplayPriority(val);
          }}
          disabled={!hasChangeLocaionPermission}
          type={'number'}
        />
        <ErrorFieldMessage
          id={`displayPriority`}
          error={hasError(errors, 'displayPriority')}
          message={'Please input.'}
        /> */}
        <div className="d-flex flex-column">
          <label className="create-section-label create-section-label-bottom-space">
            Featured
          </label>
          <CustomSwitchButton
            defaultChecked={isFeatured}
            onChange={(value) => setIsFeatured(value)}
            disabled={!hasChangeLocaionPermission}
          />
        </div>
        <div className="d-flex flex-column">
          <label className="create-section-label create-section-label-bottom-space">
            Active
          </label>
          <CustomSwitchButton
            defaultChecked={!isForcedInactive}
            onChange={(value) => setIsForcedInactive(!value)}
            disabled={!hasChangeLocaionPermission}
          />
        </div>
      </div>
    );
  };

  return (
    <BasePrompt
      show={show}
      customClass={'edit-mission-location-prompt'}
      closeAction={onClose}
      rightButton={{
        text: 'Done',
        action: () => {
          console.log(
            '@131 data',
            code,
            displayPriority,
            isFeatured,
            isForcedInactive,
          );
          let _errors = {};
          if (displayQRCode && !code) {
            _errors['code'] = 'Please input.';
          }
          // if (!displayPriority) {
          //   _errors['displayPriority'] = 'Please input.';
          // }
          setErrors(_errors);
          if (Object.keys(_errors).length) {
            return;
          }
          dispatch(
            createAction('missionLocation/updateMissionLocation')({
              data: {
                id: missionLocation?.pk,
                code,
                displayPriority,
                isFeatured,
                isForcedInactive,
              },
              afterAction: () => {
                queryList();
                onClose();
              },
            }),
          );
        },
        disabled: false,
      }}
      leftButton={{
        text: 'Cancel',
        action: onClose,
      }}
      title={'Set location'}
      otherBody={getBody}
    />
  );
}

export default EditMissionLocationPrompt;
