import React, { useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomSwitchButton from './CustomSwitchButton';

import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomTips from './CustomTips';

function CustomTitleWithSwitch({
  title,
  defaultValue,
  setValue,
  error = {},
  tips,
  tipsDetail,
  disabled,
  tipsDetailOnTitle,
  customTitleClass = '',
}) {
  return (
    <div className="d-flex flex-column">
      {tipsDetailOnTitle ? (
        <div style={{ display: 'flex' }}>
          <CustomTitleLabel title={title} />
          <CustomTips
            detail={tipsDetail}
            customClass={{
              PopoverContentClass: 'switch-tips',
              area: 'create-section-label create-section-label-bottom-space',
            }}
          />
        </div>
      ) : (
        <CustomTitleLabel title={title} className={customTitleClass} />
      )}
      {tipsDetailOnTitle && tipsDetail ? (
        <label className="tips-message">{tips}</label>
      ) : tipsDetail ? (
        <CustomTips
          detail={tipsDetail}
          customClass={{ PopoverContentClass: 'switch-tips' }}
        >
          <label className="tips-message">{tips}</label>
        </CustomTips>
      ) : tips ? (
        <label className="tips-message">{tips}</label>
      ) : null}

      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={defaultValue}
        onChange={(isChecked) => {
          setValue(isChecked);
        }}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

export default CustomTitleWithSwitch;
