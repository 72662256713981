import {
  getPagedAdmins,
  deleteAdmin,
  duplicateAdmin,
  updateOrCreateAdmin,
  getOneAdmin,
  getPagedPermissions,
  changePassword,
  getAllAdmins,
} from '../services/AdminApiHelper';
import {
  StatusTag,
  CheckStatus,
  SavedStatus,
  ADMIN_TYPE,
  SESSION_KEYS,
  AdminLoginMethod,
  DisplayAdminType,
} from '../config/CustomEnums';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  delay,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { defaultStep } from './StepBarUtil';
import { createModel } from './BaseModel';
import { PermissionCodes } from '../config/PermissionCodes';
export const MERCHANT_ADMIN_PERMISSIONS = [
  'view_customeractivitylog',
  'change_administrator_password',
  'view_administrator',
  'change_paymentandsettlementreport',
  'view_paymentandsettlementreport',
  'view_brand',
  'view_store',
  'change_entitlement',
  'change_entitlementcustomerparticipationrecord',
  'view_campaign',
  'view_entitlement',
  'view_entitlementcustomerparticipationrecord',
  'view_coupontransaction',
  'view_language',
  'import_export_jobs.change_exportjob',
  'import_export_jobs.change_importjob',
  'login_merchant_app',
  'receive_report_email',
  'receive_fortune_bag_email',
];

const adminSessionKey = SESSION_KEYS.ADMIN_SESSION_KEY;
export const saveDataToSessionStorage = (data) => {
  saveToSessionStorage(adminSessionKey, data);
};
export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(adminSessionKey);
};

const parseAdmin = (item) => {
  console.log('@@156: ', item);
  const isMerchant = item.adminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isMerchantApp = item.adminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
  const permissions =
    item?.userPermissions?.edges.map((item) => item.node) || [];
  const groupPermissions = item?.groups?.edges.map((item) => item.node) || [];

  return {
    ...item,
    name: item.username,
    groups: isMerchant ? [] : groupPermissions,
    type: item?.isSuperuser ? 'Super' : 'Ordinary ',
    status: item?.isActive ? StatusTag.active : StatusTag.inactive,
    permissions: isMerchant ? [] : permissions,
    adminGroups: isMerchant ? [] : groupPermissions,
    merchantPermissions: isMerchant ? permissions : [],
    merchantGroups: isMerchant ? groupPermissions : [],
    displayPassword: '*'.repeat(10),
    displayActive: item?.isActive ? 'Yes' : 'No',
    displayIsSuper: item?.isSuperuser ? 'Yes' : 'No',
    displayGroups: item?.groups.edges.map((item) => item.node.name).join(', '),
    displayAdminType: isMerchant
      ? 'Merchant Administrator'
      : isMerchantApp
      ? 'Merchant App account'
      : 'HKTB Administrator',
    displayPermissions: permissions?.map((item) => item.name),
    displayBrand: item.brand?.name,
    displayStore: item.store?.name,
    displayLastLogin: formatDate(
      item?.lastLogin,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    displayDateJoined: formatDate(
      item?.dateJoined,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    workingTeams: item.workingTeams?.edges?.map((item) => item.node),
    displayWorkingTeams: item.workingTeams?.edges
      ?.map((item) => item.node.name)
      .join(', '),
  };
};

const parseAdminList = (data) => {
  return data.map((item) => parseAdmin(item.node));
};

export const getDisplayAdminType = (adminType) => {
  return (
    DisplayAdminType.filter((item) => item.value === adminType)?.[0]?.label ||
    ''
  );
};

const getInitialState = () => ({
  cmsAllPermissions: [
    // {
    //   pk: 1,
    //   name: 'Can add administrator group',
    //   codename: 'add_administratorgroup',
    // },
    {
      pk: 2,
      name: 'Can change administrator group',
      codename: 'change_administratorgroup',
    },
    // {
    //   pk: 3,
    //   name: 'Can delete administrator group',
    //   codename: 'delete_administratorgroup',
    // },
    {
      pk: 4,
      name: 'Can view administrator group',
      codename: 'view_administratorgroup',
    },
    // { pk: 5, name: 'Can add administrator', codename: 'add_administrator' },
    {
      pk: 6,
      name: 'Can change administrator',
      codename: 'change_administrator',
    },
    // {
    //   pk: 7,
    //   name: 'Can delete administrator',
    //   codename: 'delete_administrator',
    // },
    { pk: 8, name: 'Can view administrator', codename: 'view_administrator' },
    // { pk: 9, name: 'Can add brand', codename: 'add_brand' },
    { pk: 10, name: 'Can change brand', codename: 'change_brand' },
    // { pk: 11, name: 'Can delete brand', codename: 'delete_brand' },
    // { pk: 12, name: 'Can view brand', codename: 'view_brand' },
    // { pk: 13, name: 'Can add store', codename: 'add_store' },
    { pk: 14, name: 'Can change store', codename: 'change_store' },
    // { pk: 15, name: 'Can delete store', codename: 'delete_store' },
    { pk: 16, name: 'Can view store', codename: 'view_store' },
    // { pk: 17, name: 'Can add store category', codename: 'add_storecategory' },
    {
      pk: 18,
      name: 'Can change store category',
      codename: 'change_storecategory',
    },
    // {
    //   pk: 19,
    //   name: 'Can delete store category',
    //   codename: 'delete_storecategory',
    // },
    { pk: 20, name: 'Can view store category', codename: 'view_storecategory' },
    // {pk:21, name: 'Can add store translation', codename: 'add_storetranslation' },
    // {pk:22,
    //   name: 'Can change store translation',
    //   codename: 'change_storetranslation',
    // },
    // {pk:23,
    //   name: 'Can delete store translation',
    //   codename: 'delete_storetranslation',
    // },
    // {pk:24, name: 'Can view store translation', codename: 'view_storetranslation' },
    // {pk:25,
    //   name: 'Can add store category translation',
    //   codename: 'add_storecategorytranslation',
    // },
    // {pk:26,
    //   name: 'Can change store category translation',
    //   codename: 'change_storecategorytranslation',
    // },
    // {pk:27,
    //   name: 'Can delete store category translation',
    //   codename: 'delete_storecategorytranslation',
    // },
    // {pk:28,
    //   name: 'Can view store category translation',
    //   codename: 'view_storecategorytranslation',
    // },
    // {pk:29, name: 'Can add brand translation', codename: 'add_brandtranslation' },
    // {pk:30,
    //   name: 'Can change brand translation',
    //   codename: 'change_brandtranslation',
    // },
    // {pk: 31,
    //   name: 'Can delete brand translation',
    //   codename: 'delete_brandtranslation',
    // },
    // {pk:32, name: 'Can view brand translation', codename: 'view_brandtranslation' },
    // { pk: 33, name: 'Can add campaign', codename: 'add_campaign' },
    { pk: 34, name: 'Can change campaign', codename: 'change_campaign' },
    // { pk: 35, name: 'Can delete campaign', codename: 'delete_campaign' },
    { pk: 36, name: 'Can view campaign', codename: 'view_campaign' },
    { pk: 37, name: 'Can publish campaign', codename: 'publishcampaign' },
    { pk: 38, name: 'Can unpublish campaign', codename: 'unpublishcampaign' },
    // {
    //   pk: 39,
    //   name: 'Can add campaign category',
    //   codename: 'add_campaigncategory',
    // },
    {
      pk: 40,
      name: 'Can change campaign category',
      codename: 'change_campaigncategory',
    },
    // {
    //   pk: 41,
    //   name: 'Can delete campaign category',
    //   codename: 'delete_campaigncategory',
    // },
    {
      pk: 42,
      name: 'Can view campaign category',
      codename: 'view_campaigncategory',
    },
    // {pk:43,
    //   name: 'Can add campaign translation',
    //   codename: 'add_campaigntranslation',
    // },
    // {pk:44,
    //   name: 'Can change campaign translation',
    //   codename: 'change_campaigntranslation',
    // },
    // {pk:45,
    //   name: 'Can delete campaign translation',
    //   codename: 'delete_campaigntranslation',
    // },
    // {pk:46,
    //   name: 'Can view campaign translation',
    //   codename: 'view_campaigntranslation',
    // },
    // {pk:47,
    //   name: 'Can add campaign category translation',
    //   codename: 'add_campaigncategorytranslation',
    // },
    // {pk:48,
    //   name: 'Can change campaign category translation',
    //   codename: 'change_campaigncategorytranslation',
    // },
    // {pk:49,
    //   name: 'Can delete campaign category translation',
    //   codename: 'delete_campaigncategorytranslation',
    // },
    // {pk:50,
    //   name: 'Can view campaign category translation',
    //   codename: 'view_campaigncategorytranslation',
    // },

    // { pk: 55, name: 'Can add coupon template', codename: 'add_coupontemplate' },
    {
      pk: 56,
      name: 'Can change coupon template',
      codename: 'change_coupontemplate',
    },
    // {
    //   pk: 57,
    //   name: 'Can delete coupon template',
    //   codename: 'delete_coupontemplate',
    // },
    {
      pk: 58,
      name: 'Can view coupon template',
      codename: 'view_coupontemplate',
    },
    // {pk: 59,
    //   name: 'Can add coupon template translation',
    //   codename: 'add_coupontemplatetranslation',
    // },
    // {pk:60,
    //   name: 'Can change coupon template translation',
    //   codename: 'change_coupontemplatetranslation',
    // },
    // {pk:61,
    //   name: 'Can delete coupon template translation',
    //   codename: 'delete_coupontemplatetranslation',
    // },
    // {pk:62,
    //   name: 'Can view coupon template translation',
    //   codename: 'view_coupontemplatetranslation',
    // },
    // { pk: 63, name: 'Can add coupon', codename: 'add_coupon' },
    { pk: 64, name: 'Can change coupon', codename: 'change_coupon' },
    // { pk: 65, name: 'Can delete coupon', codename: 'delete_coupon' },
    { pk: 66, name: 'Can view coupon', codename: 'view_coupon' },
    // {
    //   pk: 67,
    //   name: 'Can add coupon transaction',
    //   codename: 'add_coupontransaction',
    // },
    {
      pk: 68,
      name: 'Can change coupon transaction',
      codename: 'change_coupontransaction',
    },
    // {
    //   pk: 69,
    //   name: 'Can delete coupon transaction',
    //   codename: 'delete_coupontransaction',
    // },
    {
      pk: 70,
      name: 'Can view coupon transaction',
      codename: 'view_coupontransaction',
    },
    // { pk: 71, name: 'Can add customer', codename: 'add_customer' },
    { pk: 72, name: 'Can change customer', codename: 'change_customer' },
    // { pk: 73, name: 'Can delete customer', codename: 'delete_customer' },
    { pk: 74, name: 'Can view customer', codename: 'view_customer' },

    // { pk: 79, name: 'Can add earning rule', codename: 'add_earningrule' },
    { pk: 80, name: 'Can change earning rule', codename: 'change_earningrule' },
    // { pk: 81, name: 'Can delete earning rule', codename: 'delete_earningrule' },
    { pk: 82, name: 'Can view earning rule', codename: 'view_earningrule' },

    // { pk: 87, name: 'Can add permission', codename: 'add_permission' },
    { pk: 88, name: 'Can change permission', codename: 'change_permission' },
    // { pk: 89, name: 'Can delete permission', codename: 'delete_permission' },
    { pk: 90, name: 'Can view permission', codename: 'view_permission' },

    // { pk: 103, name: 'Can add customer group', codename: 'add_customergroup' },
    {
      pk: 104,
      name: 'Can change customer group',
      codename: 'change_customergroup',
    },
    // {
    //   pk: 105,
    //   name: 'Can delete customer group',
    //   codename: 'delete_customergroup',
    // },
    {
      pk: 106,
      name: 'Can view customer group',
      codename: 'view_customergroup',
    },

    // {
    //   pk: 119,
    //   name: 'Can add point transaction',
    //   codename: 'add_pointtransaction',
    // },
    {
      pk: 122,
      name: 'Can view point transaction',
      codename: 'view_pointtransaction',
    },

    // { pk: 135, name: 'Can add message', codename: 'add_message' },
    { pk: 136, name: 'Can change message', codename: 'change_message' },
    // { pk: 137, name: 'Can delete message', codename: 'delete_message' },
    { pk: 138, name: 'Can view message', codename: 'view_message' },
    // {pk:147, name: 'Can add message translation', codename: 'add_messagetranslation' },
    // { pk: 148,
    //   name: 'Can change message translation',
    //   codename: 'change_messagetranslation',
    // },
    // { pk: 149,
    //   name: 'Can delete message translation',
    //   codename: 'delete_messagetranslation',
    // },
    // { pk: 150
    //   name: 'Can view message translation',
    //   codename: 'view_messagetranslation',
    // },
    // { pk: 151, name: 'Can add inbox message', codename: 'add_inboxmessage' },
    // {
    //   pk: 152,
    //   name: 'Can change inbox message',
    //   codename: 'change_inboxmessage',
    // },
    // {
    //   pk: 153,
    //   name: 'Can delete inbox message',
    //   codename: 'delete_inboxmessage',
    // },
    // { pk: 154, name: 'Can view inbox message', codename: 'view_inboxmessage' },

    // { pk: 191, name: 'Can add segment', codename: 'add_segment' },
    { pk: 192, name: 'Can change segment', codename: 'change_segment' },
    // { pk: 193, name: 'Can delete segment', codename: 'delete_segment' },
    { pk: 194, name: 'Can view segment', codename: 'view_segment' },

    // { pk: 243, name: 'Can add transaction', codename: 'add_transaction' },
    { pk: 244, name: 'Can change transaction', codename: 'change_transaction' },
    // { pk: 245, name: 'Can delete transaction', codename: 'delete_transaction' },
    { pk: 246, name: 'Can view transaction', codename: 'view_transaction' },
    // { pk: 251, name: 'Can add purchased item', codename: 'add_purchaseditem' },
    // {
    //   pk: 252,
    //   name: 'Can change purchased item',
    //   codename: 'change_purchaseditem',
    // },
    // {
    //   pk: 253,
    //   name: 'Can delete purchased item',
    //   codename: 'delete_purchaseditem',
    // },
    // {
    //   pk: 254,
    //   name: 'Can view purchased item',
    //   codename: 'view_purchaseditem',
    // },

    // {
    //   pk: 259,
    //   name: 'Can add store subcategory',
    //   codename: 'add_storesubcategory',
    // },
    {
      pk: 260,
      name: 'Can change store subcategory',
      codename: 'change_storesubcategory',
    },
    // {
    //   pk: 261,
    //   name: 'Can delete store subcategory',
    //   codename: 'delete_storesubcategory',
    // },
    {
      pk: 262,
      name: 'Can view store subcategory',
      codename: 'view_storesubcategory',
    },
    // {pk:263,
    //   name: 'Can add store subcategory translation',
    //   codename: 'add_storesubcategorytranslation',
    // },
    // {pk:264,
    //   name: 'Can change store subcategory translation',
    //   codename: 'change_storesubcategorytranslation',
    // },
    // {pk:265,
    //   name: 'Can delete store subcategory translation',
    //   codename: 'delete_storesubcategorytranslation',
    // },
    // {pk:266,
    //   name: 'Can view store subcategory translation',
    //   codename: 'view_storesubcategorytranslation',
    // },

    // {pk:295,
    //   name: 'Can add earning rule translation',
    //   codename: 'add_earningruletranslation',
    // },
    // {pk:296,
    //   name: 'Can change earning rule translation',
    //   codename: 'change_earningruletranslation',
    // },
    // {pk:297,
    //   name: 'Can delete earning rule translation',
    //   codename: 'delete_earningruletranslation',
    // },
    // {pk:298,
    //   name: 'Can view earning rule translation',
    //   codename: 'view_earningruletranslation',
    // },
    // { pk: 283, name: 'Can add export job', codename: 'add_exportjob' },
    { pk: 284, name: 'Can change export job', codename: 'change_exportjob' },
    // { pk: 285, name: 'Can delete export job', codename: 'delete_exportjob' },
    { pk: 286, name: 'Can view export job', codename: 'view_exportjob' },
    // { pk: 287, name: 'Can add import job', codename: 'add_importjob' },
    { pk: 288, name: 'Can change import job', codename: 'change_importjob' },
    // { pk: 289, name: 'Can delete import job', codename: 'delete_importjob' },
    { pk: 290, name: 'Can view import job', codename: 'view_importjob' },
  ],
  allPermissionList: [],
  merchantPermissionList: [],
  allPermissionListTemp: [],
  merchantPermissionListTemp: [],
  oneAdmin: {},
  stepConfig: defaultStep(['Content', 'Properties']),
  currentStep: 0,
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Admin name', fieldName: 'name' },
    { displayName: 'Email', fieldName: 'email' },
    // {
    //   displayName: 'First name',
    //   fieldName: 'firstName',
    //   orderField: 'firstName',
    // },
    // { displayName: 'Last name', fieldName: 'lastName', orderField: 'lastName' },
    { displayName: 'Admin Type', fieldName: 'displayAdminType' },
    { displayName: 'Brand', fieldName: 'displayBrand' },
    { displayName: 'Store', fieldName: 'displayStore' },
    { displayName: 'Grade', fieldName: 'type' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  adminList: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  changePasswordStatus: SavedStatus.init,
  formHasSubmitted: false,
});

export default createModel({
  namespace: 'admin',
  states: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.ADMIN_SESSION_KEY,
    listAPI: getPagedAdmins,
    parse: (data) => data?.administrators?.edges?.map((item) => item.node),
    objectKey: 'administrators',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    loadDataFromCookieSession(state, { payload }) {
      const tempAdmin = getObjectFromSessionStorage(adminSessionKey) || {};
      // saveToSessionStorage(adminSessionKey, tempAdmin);
      const serverAdmin = payload?.oneAdmin ? payload.oneAdmin : {};

      let oneAdmin = { ...serverAdmin };
      if (!payload?.data) {
        oneAdmin = { ...serverAdmin, ...tempAdmin };
      }

      if (payload?.isDuplicate) {
        delete oneAdmin.pk;
        delete oneAdmin.id;
      }

      return {
        ...state,
        oneAdmin,
        hasUpdatedDefaultValues: true,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getPagedAdmins: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedAdmins, pageCursor, payload];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.administrators?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.administrators?.totalCount;

          yield put({
            type: 'updateState',
            payload: {
              adminList: parseAdminList(data?.administrators?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getAllAdmins: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getAllAdmins];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              adminList: parseAdminList(data?.administrators?.edges),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],
    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.admin.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteAdmin, pks];
        const afterAction = payload.afterAction || (() => {});

        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateAdminStatus: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [updateOrCreateAdmin, payload.data];
        const afterAction = payload.afterAction || (() => {});

        function* onSuccess(data) {
          console.log('@@115: ', data);

          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [duplicateAdmin, { id: payload.id }];

        yield apiWithResponseHandle(serviceArgs);
      },
      { type: 'takeLatest' },
    ],
    getOneAdmin: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [
          getOneAdmin,
          convertPKToId('AdministratorNode', payload.id),
        ];

        function* onSuccess(data) {
          console.log('@@428: ', data);
          const parsedAdmin = parseAdmin(data?.administrator);

          const stateName = 'oneAdmin';
          // if (payload.type === 'detail') {
          //   yield put({
          //     type: 'updateState',
          //     payload: {
          //       oneAdmin: parsedAdmin,
          //     },
          //   });
          // } else {
          //   yield put({
          //     type: 'loadDataFromCookieSession',
          //     payload: {
          //       data: parsedAdmin,
          //       isDuplicate: payload.isDuplicate,
          //     },
          //   });
          // }
          yield all([
            put({
              type: 'updateProperty',
              payload: {
                adminType:
                  data?.administrator?.adminType || ADMIN_TYPE.HKTB_ADMIN,
                administrator: payload.id,
                pageSize: 100,
                page: 1,
                stateName: stateName,
                dataKey: 'permissions',
                updateKey: 'allPermissions',
                updateApi: getPagedPermissions,
                parseWholeObject: (formatedData) => {
                  const isMerchant =
                    data?.adminType === ADMIN_TYPE.MERCHANT_ADMIN;
                  const isMerchantApp =
                    data?.adminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
                  const permissions = formatedData?.allPermissions;
                  const newFormatedData = {
                    ...parsedAdmin,
                    allPermissions: permissions,
                    userPermissions: {
                      edges: permissions?.map((item) => {
                        return { node: item };
                      }),
                    },
                    permissions: isMerchant ? [] : permissions,
                    merchantPermissions: isMerchant ? permissions : [],
                    displayPermissions: permissions?.map((item) => item.name),
                  };
                  return newFormatedData;
                },
                putWithSpecialType: {
                  type: 'loadDataFromCookieSession',
                  payload: {
                    isDuplicate: payload.isDuplicate,
                  },
                },
              },
            }),
          ]);
        }

        function* onFailed(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                oneAdmin: {},
              },
            }),
          ]);
        }

        // saveToSessionStorage(adminSessionKey, {});
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateOrCreate: [
      function* ({ payload }, { all, put, select }) {
        const allPermissions = yield select(
          (state) => state.admin.allPermissionList,
        );
        const admin = payload?.data;
        const adminType = admin.adminType;
        const isSuperuser = admin.isSuperuser;
        console.log('adminupdateOrCreate:', admin);
        const data = {
          username: admin.name || admin.username,
          isActive: admin.isActive === undefined ? true : admin.isActive,
          emailAddress: admin.email,
          mobile: admin?.mobile,
          adminType,
        };
        const isMerchant = adminType === ADMIN_TYPE.MERCHANT_ADMIN;
        const isMerchantApp = adminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
        const isSaml =
          isMerchant && admin.adminLoginMethod === AdminLoginMethod.Saml;
        if (isMerchant) {
          data.brand = admin.brand.pk;
          data.adminLoginMethod =
            admin.adminLoginMethod || AdminLoginMethod.All;
        } else if (isMerchantApp) {
          data.brand = admin.brand.pk;
          data.store = admin.store.pk;
        } else {
          data.firstName = admin.firstName;
          data.lastName = admin.lastName;
          data.isSuperuser = isSuperuser || false;
          data.adminLoginMethod = '';
        }
        const groupName = isMerchant ? 'merchantGroups' : 'groups';
        const permissionName = isMerchant
          ? 'merchantPermissions'
          : 'permissions';
        if (!isSuperuser) {
          data.groups = admin[groupName]?.map((item) => item.pk);
          data.userPermissions = admin[permissionName]?.map((item) => item.pk);
          data.workingTeams = admin.workingTeams?.map((item) => item.pk);
        }

        if (admin.pk) {
          data.id = admin.pk;
        }
        if (admin?.password && !isSaml) {
          data.password1 = admin.password;
        }
        if (admin?.confirmPassword && !isSaml) {
          data.password2 = admin.confirmPassword;
        }
        const serviceArgs = [updateOrCreateAdmin, data];

        function* onSuccess(data) {
          if (
            ('createAdmin' in data && data.createAdmin.errors) ||
            ('updateAdmin' in data && data.updateAdmin.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithFail,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                formHasSubmitted: true,
                saved: SavedStatus.savedWithSuccess,
              },
            });

            removeFromSessionStorage(adminSessionKey);
          }
        }

        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    changePassword: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [changePassword, payload.data];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              changePasswordStatus: SavedStatus.savedWithSuccess,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllPermissions: [
      function* ({ payload }, { all, put, select }) {
        const page = payload.page;
        const adminType = payload?.adminType || ADMIN_TYPE.HKTB_ADMIN;
        console.log('@777', adminType);
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const pageSize = 100;
        const serviceArgs = [
          getPagedPermissions,
          pageCursor,
          { adminType, pageSize },
        ];

        function* onSuccess(data) {
          const endCursor =
            convertCursorToNumber(data?.permissions?.pageInfo?.endCursor) || 0;
          const totalCount = data?.permissions?.totalCount || 0;
          const permissions = data?.permissions?.edges.map((item) => {
            return {
              ...item.node,
            };
          });
          let permissionKey = 'allPermissionList';
          if (adminType === ADMIN_TYPE.MERCHANT_ADMIN) {
            permissionKey = 'merchantPermissionList';
          }
          const tempPermissionKey = permissionKey + 'Temp';
          let oldTempList = yield select(
            (state) => state['admin'][tempPermissionKey],
          ) || [];
          const newList = [...oldTempList, ...permissions];
          if (
            endCursor + 1 < totalCount ||
            data?.permissions?.pageInfo?.hasNextPage
          ) {
            const currentPage = (endCursor + 1) / pageSize + 1;
            yield put({
              type: 'updateState',
              payload: {
                [tempPermissionKey]: newList,
              },
            });
            yield put({
              type: 'getAllPermissions',
              payload: { ...payload, page: currentPage },
            });
            return;
          }
          yield put({
            type: 'updateState',
            payload: {
              [tempPermissionKey]: [],
              [permissionKey]: newList,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeEvery' },
    ],
  },
});
