import {
  getCampaignList,
  publishCampaign,
  unPublishCampaign,
  deleteCampaign,
  duplicateCampaign,
  getCampaignListWithCouponStock,
  setCampaignStarLimit,
  getCampaignStarSetting,
  updateCampaignSettings,
  getCampaignSettings,
} from '../services/CampaignAPIHelper';
import { isShowCouponRelated, sessionDataKey } from './CreateCampaignModel';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  checkCampaignIsExpired,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import {
  MessageChannel,
  PublishTagType,
  APIStatus,
  YES_OR_NO_TAG,
} from '../config/CustomEnums';
import { CampaignType, SESSION_KEYS } from '../config/CustomEnums';
import { createModel } from './BaseModel';
import { parseGoodieBagFromCampaign } from './GoodieBagsModel';

export const FIRST_TIME_ENTER_CAMPAIGN = 'FIRST_TIME_ENTER_CAMPAIGN';

const SHOW_RESUME_POP = 'SHOW_RESUME_POP';

const getCampaignMessageChannel = (channels) => {
  if (!channels || channels.length === 0) {
    return '-';
  }
  const newChannels = channels.map((channel) => {
    const channelLowercase = channel.toLowerCase();
    if (channelLowercase.includes('email')) {
      return MessageChannel.email;
    }
    if (channelLowercase.includes('push')) {
      return MessageChannel.push;
    }
    if (channelLowercase.includes('sms')) {
      return MessageChannel.sms;
    }
    if (channelLowercase.includes('web')) {
      return MessageChannel.web;
    }
    return channel;
  });
  const channelString = newChannels.toString();
  return channelString || '-';
};

const getFormatedStatus = (campaign) => {
  if (!campaign.isPublished) {
    return PublishTagType.unPublished;
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return PublishTagType.published;
  }
  return PublishTagType.expired;
};

const getFormattedRedirect = (campaign) => {
  const isRedirectionCampaign = campaign.isRedirectionCampaign;
  console.log('isRedirectionCampaign:', isRedirectionCampaign);
  if (campaign?.type !== CampaignType.generalMessageCampaign) {
    return '-';
  }
  return isRedirectionCampaign ? YES_OR_NO_TAG.yes : YES_OR_NO_TAG.no;
};

const getInitialState = () => ({
  listFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    // { displayName: 'Message Channel', fieldName: 'displayMessageChannels' },
    { displayName: 'Publish Channel', fieldName: 'displayPublishChannels' },
    { displayName: 'Type', fieldName: 'displayType' },
    {
      displayName: 'Redirection campaign',
      fieldName: 'displayRedirection',
    },
    { displayName: 'For Goodie bag', fieldName: 'forGoodieBag' },
    // { displayName: 'Fortune bag group', fieldName: 'displayGroups' },
    { displayName: 'Brand', fieldName: 'brand', orderField: 'brand' },
    {
      displayName: 'Merchant Name',
      fieldName: 'merchantName',
      // orderField: 'merchantName',
    },
    {
      displayName: 'Available Coupon',
      fieldName: 'couponStock',
      // orderField: 'merchantName',
    },
    {
      displayName: 'Coupon Used / Acquired/Generated (eWallet|Physical)',
      fieldName: 'couponUsedAndAcquired',
      style: { width: '180px' },
    },
    // {
    //   displayName: 'Fortune Bag Remaining / Acquired / Generate',
    //   fieldName: 'fortuneBagAcquiredAndGenerate',
    //   style: { width: '180px' },
    // },

    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
      isDate: true,
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
      isDate: true,
    },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  campaignList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  categories: [],
  segments: [],
  allCampaignList: [],
  resume: {
    showResumeButton: false,
    showResumePop: false,
  },
  settings: {
    showCampaignCategoryFilter: false,
    showBrandFilter: false,
    showOfferTypeLabelFilter: false,
    showLocationFilter: true,
    hideOutOfStockCampaign: false,
  },
});

export const getTypeDisplay = (type) => {
  if (type === CampaignType.couponsCampaign) {
    return 'Coupon';
  }
  if (type === CampaignType.earningRulesCampaign) {
    return 'Earning Rule';
  }
  return 'General';
};

export const getDisplayType = (type) => {
  if (type === CampaignType.couponsCampaign) {
    return 'Coupon Campagin';
  }
  if (type === CampaignType.fortuneBagCampaign) {
    return 'Fortune Bag Campagin';
  }
  if (type === CampaignType.cashVoucherCampaign) {
    return 'Cash Voucher';
  }
  if (type === CampaignType.giftCampaign) {
    return 'Goodie Bag Coupon';
  }
  if (type === CampaignType.fortuneBagForGoodieBagCampaign) {
    return 'Goodie bag offer (Direct assign)';
  }
  return 'General Message Campagin';
};

const parseCampaignList = (list) => {
  return list.map((campaign) => {
    const node = campaign.node;
    const isCouponRelated = isShowCouponRelated(node?.type);
    const translationsEdges = node.translations?.edges || [];
    let translations = {};
    if (translationsEdges.length > 0) {
      translationsEdges.forEach((item) => {
        const node = item.node;
        translations[node.language] = node.language;
      });
    }

    const fortuneBagMerchants = [].concat.apply(
      [],
      node.batches?.edges?.map((batch) =>
        batch.node.fortuneBagBrands.edges?.map((item) => item.node),
      ),
    );
    const isFollowCouponName =
      node.isFollowCouponTemplateName || node.isAllFollowCouponTemplate;
    const goodieBag = parseGoodieBagFromCampaign(node.goodieBag);
    const channelNames =
      node.publishChannels?.edges?.map((item) => item.node.name).join(', ') ||
      '';
    const displayChannelName = goodieBag?.pk
      ? `${channelNames ? 'e-Solution,' : 'e-Solution'} ${channelNames}`
      : channelNames;
    const nameWithCoupon = isFollowCouponName
      ? node.couponCampaignTypeCouponTemplate?.name
      : node.name;
    const {
      quantityOfGenerate,
      quantityOfCouponGenerate,
      quantityOfPhysicalCouponGenerate,
      quantityOfRemaining,
      quantityOfAcquired,
      quantityOfUsed,
      quantityOfPhysicalCouponUsed,
      quantityOfLeft,
    } = node?.couponCampaignInfo || {};
    return {
      id: node.id,
      pk: node.pk,
      title: `[${node.pk}] ${nameWithCoupon}`,
      label: `[ID:${node.pk}] ${nameWithCoupon}`,
      name: nameWithCoupon,
      displayEndDate: node.displayEndDate,
      displayStartDate: node.displayStartDate,
      endDate: node.endDate,
      isPublished: node.isPublished,
      startDate: node.startDate,
      status: node.status,
      type: node.type,
      typeDisplay: getTypeDisplay(node.type),
      brand: isCouponRelated
        ? node.couponCampaignTypeCouponTemplate?.brand?.name
        : node.brand?.name,
      relatedBrand: node.brand,
      numberOfCustomersVisible: node.numberOfCustomersVisible,
      lastModifiedDate: node.lastModifiedDate,
      publicationDate: node.publicationDate,
      messageChannels: node.messageChannels,
      creationDate: node.creationDate,
      accessCampaignCopyUrl: node.accessCampaignCopyUrl,
      translations,
      displayMessageChannels: getCampaignMessageChannel(node.messageChannels),
      displayActivePeriod: getCampignListDisplayTime(
        node.endDate ? node.startDate : node.creationDate,
        node.endDate,
      ),
      order: node.displayPriority,
      coverPhoto: node.coverPhoto,
      displayVisiblePeriod: getCampignListDisplayTime(
        node.displayEndDate ? node.displayStartDate : node.creationDate,
        node.displayEndDate,
      ),
      displayStatus: getFormatedStatus(node),
      displayRedirection: getFormattedRedirect(node),
      displayPublishChannels: displayChannelName,
      displayType: getDisplayType(node.type),
      forGoodieBag: goodieBag?.name || '-',
      goodieBagID: goodieBag?.pk || '-',
      goodieBagName: goodieBag?.nameOnly || '-',
      isOptionC:
        node.type === CampaignType.fortuneBagCampaign &&
        node.batches?.edges[0]?.node?.fortuneBagBrands?.edges[0]?.node
          ?.handleOption === 'OPTION_C'
          ? true
          : false,
      ota: fortuneBagMerchants,
      couponSlots: node.couponSlots,
      // fortuneBagAcquiredAndGenerate: node.fortuneBagQuantityInfo
      //   ? `${node.fortuneBagQuantityInfo?.quantityOfLeft || '-'} / ${
      //       node.fortuneBagQuantityInfo?.quantityOfAcquired || '-'
      //     } / ${node.fortuneBagQuantityInfo?.quantityOfGenerate || '-'}`
      //   : '-',
      couponUsedAndAcquired: node.couponCampaignInfo
        ? `${
            (quantityOfUsed || 0) + (quantityOfPhysicalCouponUsed || 0) || '-'
          } / ${quantityOfAcquired || '-'} / ${quantityOfGenerate || '-'} (${
            quantityOfCouponGenerate || '-'
          } | ${quantityOfPhysicalCouponGenerate || '-'})`
        : '-',
      couponStock: node.couponCampaignTypeCouponTemplate?.stock,
      merchantName: fortuneBagMerchants?.map((item) => item.name).join(', '),
      batches: node.batches,
      groups: node?.groups?.edges?.map((item) => item.node),
      displayGroups: node?.groups?.edges
        ?.map((item) => `[ID: ${item?.node?.pk}] ${item?.node?.name}`)
        .join(','),

      // for goodiebag detail
      goodieBagReward: node?.goodieBagReward,
      couponTemplateID: node?.couponCampaignTypeCouponTemplate?.pk,
      couponCampaignInfo: {
        quantityOfGenerate:
          node?.couponCampaignTypeCouponTemplate?.totalNubmerOfGeneratedCoupons,
        quantityOfRemaining: node?.couponCampaignTypeCouponTemplate?.stock,
      },

      // for simple dashboard
      goodieBag: node?.goodieBag,
      isCheckGps: node?.isCheckGps,
      couponIsCheckGps: node?.couponCampaignTypeCouponTemplate?.isCheckGps,
    };
  });
};

export default createModel({
  namespace: 'campaignList',
  params: {
    parse: (data) => parseCampaignList(data.campaigns.edges),
    dataKey: SESSION_KEYS.CAMPAIGN_LIST_SESSION_KEY,
    deleteAPI: deleteCampaign,
    listAPI: getCampaignList,
    parseDetail: (data) => {},
    objectKey: 'campaigns',
  },
  states: getInitialState(),
  reducers: {},

  effects: {
    *campaignResume({ payload }, { select, put }) {
      const campaign = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!campaign?.campaignType) {
        yield put({
          type: 'updateState',
          payload: {
            resume: { showResumeButton: false, showResumePop: false },
          },
        });
        saveToSessionStorage(SHOW_RESUME_POP, null);
        return;
      }
      const showResumePop = getObjectFromSessionStorage(SHOW_RESUME_POP);
      const firstTimeEnterCampaign = getObjectFromSessionStorage(
        FIRST_TIME_ENTER_CAMPAIGN,
      );
      if (!firstTimeEnterCampaign) {
        saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN, true);
      }
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: true,
            showResumePop:
              showResumePop !== false && firstTimeEnterCampaign === false,
          },
        },
      });
    },

    *resumeAction({ payload }, { select, put }) {
      const hideResumePop = payload.hideResumePop;
      saveToSessionStorage(SHOW_RESUME_POP, !hideResumePop);
      const showResumeButton = yield select(
        (state) => state.campaignList.resume.showResumeButton,
      );
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: showResumeButton,
            showResumePop: false,
          },
        },
      });
    },

    getCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const reverse = payload.reverse;
        const type = payload.type || '';
        const typeIn = payload.typeIn || '';
        const isLinkedGoodieBag = payload.isLinkedGoodieBag;
        const searchKey = payload.searchKey;
        const afterAction = payload.afterAction || (() => {});
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';

        let listApi = getCampaignList;
        if (payload.useStock) {
          listApi = getCampaignListWithCouponStock;
        }
        console.log('@@391: ', payload);
        const serviceArgs = [
          listApi,
          pageCursor,
          {
            type: type === CampaignType.allTypes ? '' : type,
            typeIn: typeIn,
            isLinkedGoodieBag,
            reverse: reverse,
            filterName: searchKey || '',
            ssoUid: ssoUid,
            isAvailable: payload.isSelectorLoad,
            others: { ...payload, ...payload?.others },
          },
        ];

        function* onSuccess(data) {
          const campaignData = payload?.useStock
            ? data.campaignsWithCouponStock
            : data.campaigns;

          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaignData.edges),
              totalCount: campaignData.totalCount,
              pageInfo: campaignData.pageInfo,
            },
          });
          afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      // { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { put }) {
        const serviceArgs = [duplicateCampaign, payload.data.pk];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          afterAction();
        }
        function* onError(response) {
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    publish: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const campaignPk = payload.campaignPk;
        let serviceArgs = [unPublishCampaign, { id: campaignPk }];
        console.log('@@309: ', payload);
        if (!payload.isPublished) {
          serviceArgs = [
            publishCampaign,
            {
              id: campaignPk,
              otaAllocationNum: payload.otaAllocatedNum,
            },
          ];
        }

        function* onSuccess(data) {
          console.log('@@309: ', payload.isPublished);
          yield all([
            put({
              type: 'createCampaign/updateState',
              payload: {
                createStatus: APIStatus.success,
                campaignPublish: !payload.isPublished
                  ? APIStatus.success
                  : APIStatus.none,
              },
            }),
            put({
              type: 'createCampaign/updateCampaign',
              payload: { isPublished: payload.isPublished, notSave: true },
            }),
          ]);
          afterAction();
        }
        function* onError(response) {
          yield put({
            type: 'createCampaign/updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    setStarLimit: [
      function* ({ payload }, { all, select, put }) {
        let serviceArgs = [setCampaignStarLimit, payload.limit];

        yield apiWithResponseHandle(serviceArgs);
      },
      { type: 'takeLatest' },
    ],
    getStarLimit: [
      function* ({ payload }, { all, select, put }) {
        let serviceArgs = [getCampaignStarSetting];

        function* onSuccess(data) {
          console.log(
            '@@462: starPerheadLimit: ',
            data.starredCampaignLimitSettings?.perHeadOwnedLimit,
          );
          yield put({
            type: 'updateState',
            payload: {
              starPerheadLimit:
                data.starredCampaignLimitSettings?.perHeadOwnedLimit,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCampaignSettings: [
      function* ({ payload }, { all, select, put }) {
        let serviceArgs = [getCampaignSettings, payload];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              settings: {
                showCampaignCategoryFilter:
                  data.campaignSettings?.showCampaignCategoryFilter,
                showBrandFilter: data.campaignSettings?.showBrandFilter,
                showOfferTypeLabelFilter:
                  data.campaignSettings?.showOfferTypeLabelFilter,
                showLocationFilter: data.campaignSettings?.showLocationFilter,
                hideOutOfStockCampaign:
                  data.campaignSettings?.hideOutOfStockCampaign,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateCampaignSettings: [
      function* ({ payload }, { all, select, put }) {
        let serviceArgs = [updateCampaignSettings, payload];

        function* onSuccess(data) {
          console.log('@@485', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
