import {
  getCouponTemplateList,
  updateCouponSetActiveStatus,
  getCouponSet,
  getAllCouponSet,
  deleteCouponSets,
  getCouponTemplateWithStockList,
  duplicateObjects,
} from '../services/CouponAPIHelper';
import {
  assembleCouponTemplates,
  parseCouponSetListDate,
  parseSingleCoupon,
  parseCouponSet,
  parseExpiredDate,
  parsePhysicalExpiredDate,
} from './CouponUtil';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
} from '../utils';
import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import { IMAGE_TYPES } from './UploadFilesModel';
import { createModel } from './BaseModel';

const getInitialState = () => ({
  totalCount: 0,
  couponTemplateList: [],
  currentPage: 0,
  getCouponTemplateListStatus: APIStatus.none,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  exportFormData: {
    hasNextPage: false,
    couponTemplateList: [],
  },
  couponSetList: [],
  currentPageCouponSetList: [],
  currentLastCursor: '',
  totalPage: 0,
  checkedList: [],
  couponSet: {},
  couponSetListAll: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    // {
    //   displayName: 'Stock Left /Total Stock',
    //   fieldName: 'leftStockInTotal',
    //   orderField: 'stock',
    // },
    // {
    //   displayName: 'Linked Campaign',
    //   fieldName: 'linkedCampaignsName',
    //   orderField: 'linkedCampaigns',
    // },
    { displayName: 'Expiry Date (eWallet)', fieldName: 'expiryDate' },
    { displayName: 'Expiry Date (Physical)', fieldName: 'physicalExpiryDate' },
    {
      displayName: 'Create at',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Coupon type',
      fieldName: 'couponSetTypeDisplay',
    },
    {
      displayName: 'Redemption method',
      fieldName: 'redemptionMethodDisplay',
    },
    {
      displayName: 'Coupon medium',
      fieldName: 'displayCouponMedium',
    },
    {
      displayName: 'Last Modified',
      fieldName: 'displayLastModifiedDate',
      orderField: 'lastModifiedDate',
    },
    {
      displayName: 'high-value coupon',
      fieldName: 'isHighValueCoupon',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
});

export const COUPON_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  INACTIVE_CRONJOB: 'Inactive(By cronjob)',
  EWALLET_EXPIRED: 'Expried(eWallet)',
  PHYSICAL_EXPIRED: 'Expried(Physical)',
  EXPIRED: 'Expried',
  ACTIVE_ACQUIRED: 'Active(Acquired)',
  USED: 'Used',
  LINKED_TO_FORTUNE_BAG: 'Linked to Goodie bag offer (Direct assign)',
  LINKED_TO_GOODIE_BAG: 'Linked to Goodie Bag',
  VOID: 'Voided',
  LEFTOVER_HANDLED: 'Leftover handled',
  ACQUIRED: 'Acquired',
};

export const IS_HIGH_VALUE_COUPON = {
  YES: 'Yes',
  NO: 'No',
};

const parseCouponStatus = (couponTemplate) => {
  const isForcedInactive = couponTemplate.isForcedInactive;
  const expiredDate = couponTemplate.absoluteExpiryDate;
  const physicalExpiredDate = couponTemplate.physicalAbsoluteExpiryDate;
  let eWalletExpiry = false;
  let physicalExpiry = false;
  if (couponTemplate.handledLeftover) {
    return COUPON_STATUS.LEFTOVER_HANDLED;
  }

  if (isForcedInactive) {
    return COUPON_STATUS.INACTIVE;
  }
  if (couponTemplate.isLinkedFortuneBag) {
    return COUPON_STATUS.LINKED_TO_FORTUNE_BAG;
  }
  if (couponTemplate.isLinkedGoodieBag) {
    return COUPON_STATUS.LINKED_TO_GOODIE_BAG;
  }
  if (
    couponTemplate.validPeriodType === 'ABSOLUTE' &&
    new Date(expiredDate) < new Date()
  ) {
    eWalletExpiry = true;
  }
  if (
    couponTemplate.physicalValidPeriodType === 'ABSOLUTE' &&
    new Date(physicalExpiredDate) < new Date()
  ) {
    physicalExpiry = true;
  }
  if (eWalletExpiry && physicalExpiry) {
    return COUPON_STATUS.EXPIRED;
  } else if (eWalletExpiry) {
    return COUPON_STATUS.EWALLET_EXPIRED;
  } else if (physicalExpiry) {
    return COUPON_STATUS.PHYSICAL_EXPIRED;
  }
  return COUPON_STATUS.ACTIVE;
};

const parseSingleCouponWithStatus = (singleCouponList) => {
  if (singleCouponList.length > 0) {
    let parsedSingleCouponList = singleCouponList.map((singleCoupon) => {
      return parseSingleCoupon(singleCoupon);
    });
    parsedSingleCouponList = parsedSingleCouponList.map((singleCoupon) => {
      let status = COUPON_STATUS.ACTIVE;
      if (singleCoupon.isVoid) {
        status = COUPON_STATUS.VOID;
      }
      if (singleCoupon.isUsed) {
        status = COUPON_STATUS.USED;
      } else if (singleCoupon.isExpired) {
        status = COUPON_STATUS.EXPIRED;
      } else if (singleCoupon.isForcedInactive) {
        status = COUPON_STATUS.INACTIVE;
      } else if (singleCoupon.owner.owner !== '-' || singleCoupon.dateOfGrant) {
        status = COUPON_STATUS.ACTIVE_ACQUIRED;
      }
      return {
        ...singleCoupon,
        status: status,
      };
    });
    return parsedSingleCouponList;
  } else {
    return [];
  }
};

export default createModel({
  namespace: 'couponList',
  states: getInitialState(),
  params: {
    // sessionKey: SESSION_KEYS.ENTITLEMENT_CUSTOMER_SESSION_KEY,
    // dataKey: SESSION_KEYS.ENTITLEMENT_CUSTOMERS_SESSION_KEY,
    listAPI: getCouponTemplateList,
    parse: (data) =>
      data?.couponTemplates.edges.map((item) => parseCouponSet(item.node)),
    pkNode: 'CouponTemplateNode',
    objectKey: 'couponTemplates',
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleExportCouponTemplateList(state, { payload }) {
      const { templateList, hasNextPage } = payload;
      const edges = templateList.edges;
      const couponTemplateList = assembleCouponTemplates(edges).map(
        (couponTemplate) => ({
          // ...couponTemplate,
          name: `[${couponTemplate.pk}] ${couponTemplate.name}`,
          value: {
            ...couponTemplate,
          },
        }),
      );
      return {
        ...state,
        exportFormData: {
          couponTemplateList,
          hasNextPage,
        },
      };
    },

    clearExportFormData(state, { payload }) {
      return {
        ...state,
        exportFormData: {
          hasNextPage: false,
          couponTemplateList: [],
        },
      };
    },

    assembleCouponTemplateList(state, { payload }) {
      const { templateList, page } = payload;
      const edges = templateList.edges;
      const couponTemplateList = assembleCouponTemplates(edges);
      const listName = payload.listName || 'couponTemplateList';
      console.log(
        '@@214: ',
        listName,
        couponTemplateList,
        payload.addDefaultValue,
      );

      let defaultTemplateList = [];
      const newList =
        page > 1
          ? [...state[listName], ...couponTemplateList]
          : couponTemplateList;

      if (
        payload.addDefaultValue &&
        payload.addDefaultValue?.name?.indexOf(payload.search) >= 0 &&
        newList?.filter((item) => item.pk === payload.addDefaultValue.pk)
          .length <= 0
      ) {
        defaultTemplateList = [payload.addDefaultValue];
      }

      return {
        ...state,
        [listName]: [...defaultTemplateList, ...newList],
      };
    },

    updateCurrentPageTemplateList(state, { payload }) {
      const { templateList } = payload;
      const pageInfo = templateList.pageInfo;
      let currentPageCouponTemplateList = assembleCouponTemplates(
        templateList.edges,
      );
      currentPageCouponTemplateList = currentPageCouponTemplateList.map(
        (item) => {
          return {
            ...item,
            status: parseCouponStatus(item),
            expiredDate: parseExpiredDate(item),
            physicalExpiryDate: parsePhysicalExpiredDate(item),
            leftStockInTotal: `${item.stock} /${item.totalNubmerOfGeneratedCoupons}`,
          };
        },
      );
      let couponSetListAll = currentPageCouponTemplateList;
      if (payload?.page > 1) {
        couponSetListAll = [
          ...couponSetListAll,
          ...currentPageCouponTemplateList,
        ];
      }
      let newPayloadByOverview = {};
      if (payload?.isSelectorByOverview) {
        newPayloadByOverview = {
          couponSetListAllByOverview:
            payload?.page > 1
              ? [
                  ...state?.couponSetListAllByOverview,
                  ...currentPageCouponTemplateList,
                ]
              : currentPageCouponTemplateList,
        };
      }
      console.log('@230', payload, couponSetListAll);
      console.log('@227', currentPageCouponTemplateList);
      return {
        ...state,
        ...newPayloadByOverview,
        couponSetListAll,
        currentPageCouponSetList: currentPageCouponTemplateList,
        currentPageInfo: {
          ...state.currentPageInfo,
          totalCount: templateList.totalCount,
          pageInfo: pageInfo,
          currentPageCouponTemplateList: currentPageCouponTemplateList,
        },
      };
    },

    updateSingleCouponList(state, { payload }) {
      const { singleCoupons } = payload;
      const pageInfo = singleCoupons?.pageInfo;
      const totalCount = singleCoupons?.totalCount;
      const singleCouponList = parseSingleCouponWithStatus(singleCoupons.edges);
      return {
        ...state,
        currentSingleCouponList: {
          ...state.currentSingleCouponList,
          totalCount,
          pageInfo,
          singleCouponList: singleCouponList,
        },
      };
    },

    // clearData(state, { payload }) {
    //   return { ...state, ...getInitialState() };
    // },
  },

  effects: {
    getAllCouponTemplatesWithStock: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'getAllList',
          payload: {
            ...payload,
            listAPI: getCouponTemplateWithStockList,
            objectKey: 'couponStockTemplates',
            parse: (data) =>
              data?.couponStockTemplates.edges.map((item) =>
                parseCouponSet(item.node),
              ),
          },
        });
      },
      { type: 'takeLatest' },
    ],

    getCouponTemplateList: [
      function* ({ payload }, { call, select, put }) {
        console.log('@@215; ', payload);
        let callAPI = getCouponTemplateList;
        if (payload?.leftover) {
          callAPI = getCouponTemplateWithStockList;
        }
        const response = yield call(callAPI, '', {
          ...payload,
          rank: false,
          filterName: '',
          all: true,
        });
        console.log('getCouponTemplateList:', response);
        if (!response || response.status >= 300) {
          return;
        }
        const responseData = response?.data;
        if (responseData?.errors) {
          return;
        }
        const data = responseData?.data;

        yield put({
          type: 'assembleCouponTemplateList',
          payload: {
            templateList: payload?.leftover
              ? data?.couponStockTemplates
              : data?.couponTemplates,
            listName: payload?.listName,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    getCouponSet: [
      function* ({ payload }, { put }) {
        const { couponSetPK } = payload;
        const couponSetID = convertPKToId(
          'CouponStockTemplateNode',
          couponSetPK,
        );
        const serviceArgs = [getCouponSet, couponSetID];

        function* onSuccess(data) {
          const couponSetData = data.couponStockTemplate;
          const couponSet = parseCouponSet(couponSetData);
          couponSet.status = parseCouponStatus(couponSet);
          const enCoverPhoto =
            couponSet.translations[LanguageConfig.english].coverPhoto;
          couponSet.translations[LanguageConfig.english].coverPhoto = {
            type: IMAGE_TYPES.TYPE_URL,
            value: enCoverPhoto,
          };
          console.log('@328', couponSet);
          yield put(
            createAction('updateState')({
              [couponSetID]: {
                ...couponSet,
              },
              couponSet,
            }),
          );
          yield put(
            createAction('createCoupon/loadCouponFromAPI')({
              data: couponSet,
              newItems: payload,
            }),
          );
          yield put({
            type: 'createCampaign/updateAllLogoLabel',
            payload: {
              couponTemplateIn: [couponSetPK],
              pageSize: 100,
              page: 1,
              language: LanguageConfig.english,
              modelNamespace: 'createCoupon',
              stateName: 'couponTemplate',
              logoLabelKey: 'logoLabel',
            },
          });
          const translations =
            data?.couponStockTemplate?.translations?.edges?.map(
              (item) => item.node,
            );
          const languages = {};
          translations.forEach((item) => {
            languages[item.language] = item.pk;
          });
          yield put({
            type: 'createCampaign/updateAllLogoLabel',
            payload: {
              couponTemplateTranslationIn: translations.map((item) => item.pk),
              pageSize: 100,
              page: 1,
              languages,
              translationIn: 'couponTemplateTranslations',
              modelNamespace: 'createCoupon',
              stateName: 'couponTemplate',
              logoLabelKey: 'logoLabel',
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCurrentPageTemplateList: [
      function* ({ payload }, { select, put, all }) {
        yield put(
          createAction('updateState')({
            getCouponTemplateListStatus: APIStatus.calling,
          }),
        );
        console.log('@336', payload);
        const { page } = payload;
        if (!payload.filterName && payload.search) {
          payload.filterName = payload.search;
        }
        let afterCursor = undefined;
        let serviceArgs = [];
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
          console.log('@@336: ', (page - 1) * 20 - 1);
        }
        let callAPI = getCouponTemplateList;
        if (payload?.leftover || payload?.getStock) {
          callAPI = getCouponTemplateWithStockList;
        }
        serviceArgs = [callAPI, afterCursor, payload];

        function* onSuccess(data) {
          const templateList =
            payload?.leftover || payload?.getStock
              ? data?.couponStockTemplates
              : data?.couponTemplates;
          const pageInfo = templateList?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = templateList.totalCount;
          console.log('@@294: ', templateList);
          yield all([
            put({
              type: 'updateState',
              payload: {
                totalCount: totalCount,
                getCouponTemplateListStatus: APIStatus.success,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                currentLastCursor,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateCurrentPageTemplateList',
              payload: {
                ...payload,
                templateList,
              },
            }),
            put({
              type: 'assembleCouponTemplateList',
              payload: {
                ...payload,
                templateList,
                page: page,
              },
            }),
          ]);
        }
        function* onFailed() {
          yield put(
            createAction('updateState')({
              getCouponTemplateListStatus: APIStatus.failed,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    loadTemplateListForExport: [
      function* ({ payload }, { select, put, all }) {
        const hasNextPage = yield select(
          (state) => state.couponList.exportFormData.hasNextPage,
        );
        const { page } = payload;
        if (page > 1 && !hasNextPage) {
          return;
        }
        if (!payload.filterName && payload.search) {
          payload.filterName = payload.search;
        }
        let afterCursor = undefined;
        let serviceArgs = [];
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        serviceArgs = [getCouponTemplateList, afterCursor, payload];

        function* onSuccess(data) {
          const pageInfo = data?.couponTemplates?.pageInfo;
          yield put({
            type: 'assembleExportCouponTemplateList',
            payload: {
              templateList: data.couponTemplates,
              hasNextPage: pageInfo?.hasNextPage,
            },
          });
        }
        function* onFailed() {}
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { select, put }) {
        const checkedList = yield select(
          (state) => state.couponList.checkedList,
        );
        let ids = [];
        ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteCouponSets, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction;
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { put, select }) {
        const couponSet = payload.data;

        const afterAction = payload.afterAction || (() => {});

        // yield put.resolve(
        //   createAction('getCouponSet')({ couponSetPK: couponSet.pk }),
        // );

        // yield put.resolve(
        //   createAction('createCoupon/createCouponTemplate')({
        //     isDuplicate: true,
        //     afterAction: afterAction,
        //   }),
        // );
        // console.log('@@337: ', afterAction);
        // yield afterAction();

        const serviceArgs = [
          duplicateObjects,
          { id: couponSet.pk, modelName: 'CouponTemplate' },
        ];

        function* onSuccess(data) {
          if (payload.afterAction) {
            yield payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateCouponSetActiveStatus: [
      function* ({ payload }, { put, select, call }) {
        const { couponSetPK, isForcedInactive } = payload;
        const couponSet = yield select((state) => state.couponList.couponSet);
        const serviceArgs = [
          updateCouponSetActiveStatus,
          couponSetPK,
          isForcedInactive,
        ];
        function* onSuccess() {
          const updateAction = payload.updateAction || (() => {});
          const afterAction = payload.afterAction || (() => {});
          // if (couponSet?.coupons && couponSet?.coupons?.length > 0) {
          //   // yield put(
          //   //   createAction('singleCoupon/updateState')({
          //   //     singleCouponList: couponSet.coupons,
          //   //   }),
          //   // );
          //   yield updateAction();
          // } else {
          //   yield put.resolve(
          //     createAction('singleCoupon/getAllSingleCoupons')({
          //       template: couponSet,
          //       afterAction: updateAction,
          //     }),
          //   );
          // }
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAllCouponSet: [
      function* ({ payload }, { put, select, call }) {
        const { ssoUid } = payload;
        console.log('@@458: ', ssoUid);
        const serviceArgs = [getAllCouponSet, ssoUid];

        function* onSuccess(data) {
          console.log('@@462: ', data);
          console.log(
            '@@462-1: ',
            data?.coupons?.edges.map((item) => ({
              name: item.node.template.name,
              value: item.node.pk,
            })),
          );
          yield put({
            type: 'updateState',
            payload: {
              couponSetList: data?.coupons?.edges.map((item) => ({
                name: item.node.template.name,
                value: item.node.pk,
              })),
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
