import React, { useEffect, useState } from 'react';
import './OptionDEntitlement.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';

import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_TYPE } from '../../config/CustomEnums';
import { GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY } from '../../models/GoodieBagsModel';
import CountComponent from '../base/CountComponent';
import { ImageTextButton } from '../base/CustomImageButton';
import filterImage from '../../assets/images/32filter.svg';
import ListFilterComponent from '../base/ListFilterComponent';
import EntitlementFilter from './EntitlementFilter';
import BaseSimpleTable from '../base/BaseSimpleTable';
import { Image } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../assets/images/campaign/copy_icon.svg';
import AuthButton from '../base/AuthButton';
import { checkHasPermission, createAction } from '../../utils';
import { CustomDropDownButton } from '../base/CustomDropDownButton';
import { ImportResource } from '../../models/DownloadImportModel';

const WECHAT_APP_ID = process.env.REACT_APP_WECHAT_APP_ID;

export default function GoodieBagEntitlement({ setShowExportView }) {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryString = require('query-string');
  const [showFilter, setShowFilter] = useState(false);
  const [formattedEntitlementOptionDatas, setFormattedEntitlementOptionDatas] =
    useState([]);

  const getFilterCount = () => {
    let filterCount = 0;
    const querySearch = queryString.parse(location.search);
    Object.entries(querySearch).map(([key, value]) => {
      if (Object.keys(querySearch).includes(querySearch[key])) {
        return;
      }
      if (!['page', 'rank', 'search', 'sort'].includes(key) && value) {
        filterCount += 1;
      }
    });

    return filterCount;
  };

  const filterCount = getFilterCount();

  const {
    goodieBagDetail,
    entitlementOptionDatas,
    entitlementOption,
    entitlementTotalCount,
    entitlementPageInfo,
    trackingCodeCount,
    userAdminType,
    checkedList,
    fortuneBagEntitlement,
    adminUser,
    importedFile,
  } = useSelector((state) => ({
    goodieBagDetail: state.goodieBags.detail,
    entitlementOptionDatas: state.fortuneBagEntitlement.entitlementOptionDatas,
    entitlementOption: state.fortuneBagEntitlement.entitlementOption,
    entitlementTotalCount: state.fortuneBagEntitlement.entitlementTotalCount,
    entitlementPageInfo: state.fortuneBagEntitlement.entitlementPageInfo,
    trackingCodeCount: state.entitlementTrackingCodes.trackingCodeCount,
    userAdminType: state.users.adminType,
    checkedList: state.offerTypeLabel.checkedList,
    fortuneBagEntitlement: state.fortuneBagEntitlement,
    adminUser: state.users,
    importedFile: state.uploadFiles.importedFile,
  }));

  const TAB_METHOD = 'Method';
  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isAdmin = userAdminType === ADMIN_TYPE.HKTB_ADMIN;

  const filter = {
    hasFilter: true,
    componment: EntitlementFilter,
  };

  const hiddenFileInput = React.useRef(null);
  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);

    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.trackingCode,
          file: importedFile,
          related: {
            goodieBag: parseInt(id),
            goodieBagName: goodieBagDetail?.name,
          },
        }),
      );
    }
  }, [dispatch, importedFile]);

  useEffect(() => {
    const data = entitlementOptionDatas.map((item) => {
      return trackingCodeCount && item.merchantId in trackingCodeCount
        ? { ...item, ...trackingCodeCount[item.merchantId] }
        : { ...item };
    });
    setFormattedEntitlementOptionDatas(data);
  }, [trackingCodeCount]);

  return (
    <>
      {showFilter ? (
        <filter.componment backAction={() => setShowFilter(false)} />
      ) : null}

      <div className="all-list-scrollable-area entitlement-info-area">
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left">
            <label className="create-section-title">
              {isMerchant
                ? GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title
                : 'MERCHANTS'}
            </label>
          </div>

          <div className="list-section-container-header-buttons">
            {checkHasPermission(adminUser, [
              PermissionCodes.changeGamificationCampaignEntitlement,
              PermissionCodes.addImportjob,
            ]) ? (
              <div class="batch-manager tracking-code-batch-manager">
                <CustomDropDownButton
                  title={'Batch manage'}
                  items={[
                    {
                      title: 'Download Template',
                      action: () =>
                        // window.location.assign(
                        //   `${process.env.REACT_APP_IMAGE.replace(
                        //     '/cms',
                        //     '',
                        //   )}/[HKTB] Update Tracking Code Import Template.xlsx`,
                        // ),
                        dispatch(
                          createAction('downloadAndImport/createDownloadTask')({
                            from: ImportResource.merchantTrackingCodes,
                            related: {
                              goodieBag: parseInt(id),
                              goodieBagName: goodieBagDetail?.name,
                              // merchant: entitlementDetail?.merchant?.pk,
                            },
                          }),
                        ),
                    },
                    {
                      title: 'Import for update',
                      action: () => {
                        handleImportClick();
                      },
                    },
                  ]}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  accept=".xlsx"
                />
              </div>
            ) : null}
            <AuthButton
              customClass="btn-back-button-common btn-download"
              title="Export"
              action={() => {
                setShowExportView(true);
              }}
              requires={PermissionCodes.addExportjob}
            />
          </div>
        </div>

        <div id="tabindex" className="all-list-component-action-header">
          <div className="all-list-component-action-header-div">
            <label>
              Goodie bag VISIBLE PERIOD: {goodieBagDetail?.visiblePeriod}
            </label>
            <CountComponent
              start={entitlementPageInfo?.startCursor}
              end={entitlementPageInfo?.endCursor}
              total={entitlementTotalCount}
            />
          </div>
        </div>

        <div className="goodie-bag-mini-program">
          <label className="goodie-bag-mini-program-title">
            App ID (HKTB mini program):
          </label>
          <label className="goodie-bag-mini-program-id">{WECHAT_APP_ID}</label>
          <AuthButton
            className="lebel-right-button"
            onClick={(e) => {
              copy(WECHAT_APP_ID);
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: 'App ID copied',
                  },
                },
              });
            }}
            variant="link"
          >
            <Image src={CopyIcon} />
          </AuthButton>
        </div>
        {filter.hasFilter ? (
          <div
            className={`all-list-filter-container ${filter.customFilterAreaClass}`}
            style={showFilter && filter.offset ? { right: filter.offset } : {}}
          >
            <ImageTextButton
              action={() => {
                setShowFilter(!showFilter);
              }}
              image={filterImage}
              title={'Filter'}
            />
            {filterCount > 0 ? (
              <label className="filter-badge-icon">{filterCount}</label>
            ) : null}
          </div>
        ) : null}
        <div className="all-list-search-container">
          <ListFilterComponent
            searchPlaceholder={'Search by name'}
            onSearch={(key) => {
              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  ...{ search: key, page: 1 },
                }),
              });
            }}
          />
        </div>
      </div>

      <div className="all-list-table-content all-list-table-content-simple">
        <BaseSimpleTable
          customTableClassName="entitlement-simple-table"
          fields={[
            {
              displayName: 'Merchant name',
              fieldName: 'merchantName',
            },
            {
              displayName: 'Tracking codes',
              fieldName: 'total',
            },
            {
              displayName: 'Active tracking codes',
              fieldName: 'active',
            },
            {
              displayName: 'Inactive tracking codes',
              fieldName: 'inactive',
            },
            {
              displayName: 'Expired tracking codes',
              fieldName: 'expired',
            },
          ]}
          dataList={formattedEntitlementOptionDatas}
          totalCount={entitlementTotalCount}
        />
      </div>
    </>
  );
}
