import React from 'react';
import { Dropdown } from 'react-bootstrap';

const esolutionPreview = (gamificationCampaign) => {
  const domain = process.env.REACT_APP_FRONTEND_LINK;
  const link = `${domain}/en/gamification-detail?gamificationCampaignId=${gamificationCampaign.pk}?is_preview=true`;
  window.open(link);
};

export default function PreviewButton({
  gamificationCampaign,
  fromDetail = false,
}) {
  const actionButtons = () => {
    const data = [];
    data.push({
      name: 'E-Solution',
      action: () => {
        esolutionPreview(gamificationCampaign);
      },
    });
    return data;
  };
  const buttons = actionButtons();

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common"
        variant="none"
        id={`dropdown-`}
      >
        Preview campaign
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="preview-prop-area"
        style={{ zIndex: 2, width: 300 }}
      >
        <label className="preview-prop-font preview-prop-title">Channel</label>
        {buttons.map((item) => (
          <li
            className="preview-prop-font preview-prop-item"
            onClick={item.action}
          >
            {item.name}
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
